import { SpaceBetween, TextContent, Box } from '@amzn/awsui-components-react';
import { Candidate } from '../config';

const CandidateInfo: React.FC<{ candidate: Candidate }> = ({ candidate }) => (
  <SpaceBetween size="xs">
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Name:
      </Box>{' '}
      {candidate.name}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Role:
      </Box>{' '}
      {candidate.role}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Level:
      </Box>{' '}
      {candidate.level}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Tenure:
      </Box>{' '}
      {candidate.tenure}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Current Manager:
      </Box>{' '}
      {candidate.currentManager}
    </TextContent>
  </SpaceBetween>
);

export default CandidateInfo;
