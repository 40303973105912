import { useState, ForwardedRef, forwardRef, memo, useMemo } from 'react';
import { DateTime } from 'luxon';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import DatePicker from '@amzn/awsui-components-react/polaris/date-picker';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useTableContext } from '@/contexts';
import { OptionalString, PeerReviewRequest, ViewerType } from '@/models';
import { PeerReviewStatus, PeerReviewType, UserProfileStatus, WorkSummaryStatus } from '@/api/API';
import PeerReviewerPanel from './PeerReviewerPanel';
import { useProfileActions, useUserProfile } from '@/api/user-profile';
import { useHydratedPeerReviewRequests, usePeerReviewActions } from '@/api/peer-review';
import PromoSpinner from '@/components/common/PromoSpinner';
import { useWorkSummary, useWorkSummaryActions } from '@/api/work-summary';
import { FEDERATED_USER_PREFIX } from '@/common/constants';

const MIN_WINDOW_WEEKS = 2;

function isDateEnabled(date: Date) {
  const today = DateTime.now().startOf('day');
  const reference = DateTime.fromMillis(date.getTime()).startOf('day');
  return reference.toMillis() > today.plus({ weeks: MIN_WINDOW_WEEKS }).toMillis();
}

const defaultDate = DateTime.now().plus({ weeks: MIN_WINDOW_WEEKS }).toISODate();

interface SubmitPeerReviewRequestModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  contextKey?: string;
  itemId?: OptionalString;
  viewer: ViewerType;
  onSuccess?: () => void;
}

const SubmitPeerReviewRequestComponent = (
  { contextKey, isVisible, onSetVisibility, itemId, viewer, onSuccess }: SubmitPeerReviewRequestModalParams,
  ref: ForwardedRef<HTMLElement>
): JSX.Element => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { selectedId } = useTableContext(contextKey ?? '');
  const workSummaryId = useMemo(() => itemId ?? selectedId, [itemId, selectedId]);
  const { workSummary, isLoading } = useWorkSummary(workSummaryId, viewer);
  const { actions: workSummaryActions } = useWorkSummaryActions(workSummary?.id);
  const { peerReviewRequests, isRequestsLoading } = useHydratedPeerReviewRequests(
    workSummary?.id,
    viewer === ViewerType.MANAGER ? PeerReviewType.MANAGER : PeerReviewType.CANDIDATE
  );
  const { actions: peerReviewActions } = usePeerReviewActions();
  const { profile: candidate } = useUserProfile(workSummary?.alias);
  const { createProfile } = useProfileActions();
  const [isSaving, setIsSaving] = useState(false);
  const [dueDate, setDueDate] = useState(defaultDate);
  const [dueDateErrorText, setDueDateErrorText] = useState('');
  const [deletableRequests, setDeletableRequests] = useState<PeerReviewRequest[]>([]);
  const [requests, setRequests] = useState<PeerReviewRequest[]>([...peerReviewRequests]);

  function resetModalState() {
    onSetVisibility(false);
  }

  function resetModal() {
    resetModalState();
    setRequests([]);
    setDeletableRequests([]);
    setDueDate(defaultDate);
    setDueDateErrorText('');
  }

  async function createUserProfiles() {
    await Promise.all(
      requests
        .filter((req) => !req.userProfileExists)
        .map(async (req) =>
          createProfile({
            alias: req.alias as string,
            email: req.email as string,
            hasLoggedIn: false,
            owner: `${FEDERATED_USER_PREFIX}${req.alias}`,
            isManager: req.isManager as boolean,
            jobLevel: req.jobLevel as number,
            manager: req.manager as string,
            name: req.name as string,
            status: UserProfileStatus.INACTIVE,
          })
        )
    );
  }

  async function deletePeerReviewRequests() {
    await Promise.all(
      deletableRequests.filter((req) => !!req.requestId).map(async (req) => peerReviewActions.delete(req.requestId))
    );
  }

  async function savePeerReviewRequests() {
    try {
      await createUserProfiles();
      await deletePeerReviewRequests();
      await Promise.all(
        requests.map(async (req) => {
          if (req.requestId) {
            await peerReviewActions.update({ id: req.requestId, requiredBy: dueDate ?? '' });
          } else {
            await peerReviewActions.create({
              reviewType: viewer === ViewerType.MANAGER ? PeerReviewType.MANAGER : PeerReviewType.CANDIDATE,
              requiredBy: dueDate ?? '',
              reviewerAlias: req.alias ?? '',
              reviewStatus: PeerReviewStatus.REQUESTED,
              workSummaryId: workSummary?.id,
              candidatePathId: workSummary?.promoPathId,
            });
          }
        })
      );

      const addlCoOwners = new Set([...requests.map((req) => `${FEDERATED_USER_PREFIX}${req.alias}`)]);
      if (viewer === ViewerType.MANAGER) {
        addlCoOwners.add(`${FEDERATED_USER_PREFIX}${candidate?.manager}`);
      }

      let status =
        viewer === ViewerType.CANDIDATE ? WorkSummaryStatus.PEER_REVIEW : WorkSummaryStatus.MANAGER_PEER_REVIEW;
      if (!requests.length) {
        status = viewer === ViewerType.CANDIDATE ? WorkSummaryStatus.DRAFT : WorkSummaryStatus.MANAGER_REVIEW;
      }
      if (workSummary) {
        await workSummaryActions.update({ status, coOwners: [...new Set([...workSummary.coOwners, ...addlCoOwners])] });
      }
    } finally {
      setIsSaving(false);
    }
  }

  const onSave = () => {
    setIsSaving(true);
    if (!dueDate) {
      setDueDateErrorText('Due date cannot be empty.');
      setIsSaving(false);
      return;
    }
    savePeerReviewRequests().then(() => {
      resetModal();
      onSuccess?.();
    });
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModal()}
      closeAriaLabel="Close dialog"
      visible={isVisible}
      size="medium"
      header={`Request${viewer === ViewerType.MANAGER ? ' manager ' : ' '}peer review`}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isSaving} onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={isSaving}
              disabled={isSaving || (!requests.length && !deletableRequests.length)}
              onClick={() => onSave()}
            >
              {isSaving ? `Saving...` : 'Submit'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {isLoading || isRequestsLoading ? (
        <PromoSpinner variant="inline" size="big" />
      ) : (
        <ColumnLayout borders="horizontal">
          <FormField
            errorText={dueDateErrorText || ''}
            label="Due date"
            description="Specify the date peer review is due by."
          >
            <DatePicker
              ariaLabelledby="due-date-label"
              isDateEnabled={(date) => isDateEnabled(date)}
              placeholder="YYYY/MM/DD"
              previousMonthAriaLabel="Previous month"
              nextMonthAriaLabel="Next month"
              todayAriaLabel="Today"
              value={dueDate ?? ''}
              onChange={({ detail }) => {
                if (dueDateErrorText && detail.value) {
                  setDueDateErrorText('');
                }
                setDueDate(detail.value);
              }}
              openCalendarAriaLabel={(selectedDate) =>
                `Choose date${selectedDate ? `, selected date is ${selectedDate}` : ''}`
              }
            />
          </FormField>
          <PeerReviewerPanel
            requests={requests}
            setRequests={setRequests}
            setDeletedRequests={setDeletableRequests}
            candidateAlias={workSummary?.alias ?? ''}
            viewer={viewer}
          />
        </ColumnLayout>
      )}
    </Modal>
  );
};

const SubmitPeerReviewRequestModal = memo(forwardRef(SubmitPeerReviewRequestComponent));

export default SubmitPeerReviewRequestModal;
