import { useParams } from 'react-router-dom';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import PeerReviewForm from '../components/PeerReviewForm';
import PromoSpinner from '../components/common/PromoSpinner';
import { usePeerReview } from '@/api/peer-review';

const EditFeedback = () => {
  const { peerReviewId } = useParams();
  const { isPeerReviewLoading, peerReview } = usePeerReview(peerReviewId);

  return (
    <ContentLayout header={<Header variant="h1">Edit peer review</Header>}>
      {isPeerReviewLoading ? <PromoSpinner /> : <PeerReviewForm peerReview={peerReview} />}
    </ContentLayout>
  );
};

export default EditFeedback;
