import { ReactNode } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';

interface KeyValuePairParams {
  /** The label `Key` text */
  label: string;
  /** One or more elements to render as the `value` for the pair. Typically a string/number */
  children: ReactNode;
}
/**
 * Generic key-value pair do display properties/attributes on a page or form.
 *
 * See implementation guide {@link https://refresh.polaris.a2z.com/components/key-value-pairs/ key-value pairs}
 */
const KeyValuePair = ({ label, children }: KeyValuePairParams): JSX.Element => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

export default KeyValuePair;
