import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import * as Auth from 'aws-amplify/auth';
import { ConsoleLogger } from 'aws-amplify/utils';

import { DateTime } from 'luxon';
import { QueryKeys } from '@/api/queryKeys';
import type { Nullable, OptionalString } from '@/elevate-platform/types';
import type { PromoUserProfile } from '@/common/models';
import { AuthGroup } from '@/resources/rbac-constants';

const authGroups = Object.values(AuthGroup);
const logger = new ConsoleLogger('CognitoAuth');
const RETRYABLE_ERRORS = new Set(['NoSessionFoundException', 'UserUnAuthenticatedException', 'NotAuthorizedException']);
const FEDERATED_IDENTITY_PROVIDER = 'AmazonFederate';

type IDTokenIdentity = {
  userId: string;
  providerName: 'AmazonFederate';
  providerType: 'OIDC';
  issuer: string | null;
  primary: 'true' | 'false';
  dateCreated: string;
};

type AccessTokenPayload = Auth.JWT['payload'] & {
  client_id: string;
  token_use: 'access';
  username: string;
};

type IDTokenPayload = Auth.JWT['payload'] & {
  'cognito:groups': AuthGroup[];
  'custom:department_name': string;
  'custom:manager': string;
  'custom:is_manager': string;
  'custom:employee_id': string;
  'custom:hire_date': string;
  'custom:job_level': string;
  'custom:job_title': string;
  given_name: string;
  family_name: string;
  email: string;
  identities: IDTokenIdentity[];
  validPromoHubUser: 'true' | 'false';
};

type CognitoTokens = Pick<Auth.AuthSession, 'identityId'> & {
  accessToken: { payload: AccessTokenPayload; jwtToken: string };
  idToken: { payload: IDTokenPayload; jwtToken: string };
};

function isAccessTokenPayload(v: Nullable<Auth.JWT['payload']>): v is AccessTokenPayload {
  return !!v && 'token_use' in v && v.token_use === 'access';
}

function isIDTokenPayload(v: Nullable<Auth.JWT['payload']>): v is IDTokenPayload {
  return !!v && 'token_use' in v && v.token_use === 'id';
}

function getAliasAndNameFromLDAP(value: OptionalString): { alias: string; name: string } {
  if (!value) return { alias: '', name: '' };
  // The LDAP names are kinda gross so we have to parse manually.
  // ex: cn=Geoff Hatch (geohatch),ou=people,ou=us,o=amazon.com
  const segments = value.split(',')[0].split('=')[1].split(' ');
  const alias = segments.slice(-1)[0].replace('(', '').replace(')', '');
  const name = segments.slice(0, -1).join(' ');
  return { alias, name };
}

export function getOrgAndJobTitle(jobTitle: OptionalString, department: OptionalString): readonly [string, string] {
  let normalizedJobTitle = jobTitle ?? '';
  let normalizedOrgName = department ?? '';
  const locationStartIex = jobTitle?.indexOf('(');
  if (locationStartIex) {
    normalizedJobTitle = jobTitle?.slice(0, locationStartIex).trim() ?? '';
    const location = jobTitle
      ?.slice(locationStartIex)
      .replace('(', '')
      .replace(')', '')
      .split('-')
      .filter((part) => !['AL', 'GL', 'M', 'VP'].includes(part))
      .join('-');
    normalizedOrgName = `${department} - ${location}`;
  }
  return [normalizedJobTitle, normalizedOrgName] as const;
}

function getHireDate(cognitoValue: OptionalString): OptionalString {
  if (!cognitoValue) {
    return undefined;
  }
  return DateTime.fromFormat(cognitoValue, 'dd-MMM-yy', { zone: 'utc' }).toISODate();
}

function hydratePermissionGroups(allGroups: string[], isManager: boolean, isValidUser: boolean): AuthGroup[] {
  const groups = new Set(allGroups.filter((grp): grp is AuthGroup => authGroups.includes(grp as unknown as AuthGroup)));
  if (isManager) groups.add(AuthGroup.MANAGER);
  if (isValidUser) groups.add(AuthGroup.USER);
  return [...groups];
}

export function signOut() {
  try {
    void Auth.signOut();
  } catch {
    /* empty */
  }
}

function parseTokenData(session: Nullable<CognitoTokens>): Nullable<PromoUserProfile> {
  logger.debug('[start] parse cognito token data', session);
  if (!session) {
    logger.info('[end] parse cognito token data - no session data to parse');
    return null;
  }
  const idToken = session.idToken.payload;
  const isManager = parseInt(idToken['custom:is_manager'], 10) === 0;
  const isValidUser = idToken.validPromoHubUser === 'true';
  const tokenGroups = idToken['cognito:groups'] ?? [];
  const [jobTitle, orgName] = getOrgAndJobTitle(idToken['custom:job_title'], idToken['custom:department_name']);

  const result: PromoUserProfile = {
    isManager,
    isValidUser,
    jobTitle,
    orgName,
    alias: idToken.identities[0].userId,
    email: idToken.email,
    firstName: idToken.given_name,
    groups: hydratePermissionGroups(tokenGroups, isManager, isValidUser),
    hireDate: getHireDate(idToken['custom:hire_date']),
    identityId: session.identityId,
    jobLevel: parseInt(idToken['custom:job_level'], 10),
    lastName: idToken.family_name,
    manager: getAliasAndNameFromLDAP(idToken['custom:manager']),
    name: `${idToken.given_name} ${idToken.family_name}`,
    personId: idToken['custom:employee_id'],
  };
  logger.debug('[end] parse cognito token data', result);
  return result;
}

async function loadSession(): Promise<Nullable<CognitoTokens>> {
  try {
    // We don't want the output from this, but it throws a more reasonable error when the user isn't signed in
    await Auth.getCurrentUser();
    // Auth.fetchAuthSession() will automatically refresh the accessToken and idToken if expired.
    const { tokens, identityId } = await Auth.fetchAuthSession();
    // If a session is returned, we are authenticated, so set the status in the cache
    if (isIDTokenPayload(tokens?.idToken?.payload) && isAccessTokenPayload(tokens?.accessToken?.payload)) {
      return {
        identityId,
        accessToken: { payload: tokens?.accessToken?.payload, jwtToken: tokens?.accessToken.toString() },
        idToken: { payload: tokens?.idToken?.payload, jwtToken: tokens?.idToken.toString() },
      };
    }
    return null;
  } catch (ex) {
    if (ex instanceof Error && RETRYABLE_ERRORS.has(ex.name)) {
      /*
       The oAuth flow can start from any page, but drops the user on the homepage upon completion. Not great.
       Store the current location, so it can be restored after auth completes.
      */
      await Auth.signInWithRedirect({ provider: { custom: FEDERATED_IDENTITY_PROVIDER } });
      /*
       `signInWithRedirect` starts the oAuth process, and returns before the session is finalized.
       We return null and the app handles this downstream.
      */
      return null;
    }
    logger.error(ex);
  }
  throw new Error('User is not authenticated!');
}

export function useCognitoSession(): UseQueryResult<Nullable<PromoUserProfile>> {
  return useQuery({
    queryKey: QueryKeys.cognito,
    queryFn: loadSession,
    staleTime: Infinity,
    select: parseTokenData,
  });
}

// in-source test suites
if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest;

  describe('getAliasAndNameFromLDAP', () => {
    it('should return empty strings when value is undefined', () => {
      const result = getAliasAndNameFromLDAP(undefined);
      expect(result).to.deep.equal({ alias: '', name: '' });
    });
    it('should handle LDAP string with spaces in name', () => {
      const value = 'cn=John Doe (johndoe),ou=people,ou=us,o=example.com';
      const result = getAliasAndNameFromLDAP(value);
      expect(result).to.deep.equal({ alias: 'johndoe', name: 'John Doe' });
    });
  });

  describe('getOrgAndJobTitle', () => {
    it('should return empty strings when value is undefined', () => {
      const result = getOrgAndJobTitle(undefined, undefined);
      expect(result).to.deep.equal(['', '']);
    });
    it('should extract org name and job title from jobTitle when it contains location info', () => {
      const jobTitle = 'Software Engineer (Location-X)';
      const department = 'Department A';
      const result = getOrgAndJobTitle(jobTitle, department);

      expect(result).to.deep.equal(['Software Engineer', 'Department A - Location-X']);
    });
  });

  describe('getHireDate', () => {
    it('should return undefined when value is undefined', () => {
      const result = getHireDate(undefined);
      expect(result).to.equal(undefined);
    });
    it('should handle an invalid date format', () => {
      const cognitoValue = '2021-01-01';
      const result = getHireDate(cognitoValue);
      expect(result).to.equal(null);
    });
    it('should handle a different time zone', () => {
      const cognitoValue = '01-Jan-21';
      const result = getHireDate(cognitoValue);
      const expectedDate = DateTime.fromFormat(cognitoValue, 'dd-MMM-yy', { zone: 'America/New_York' }).toISODate();
      expect(result).to.equal(expectedDate);
    });
  });

  describe('hydratePermissionGroups', () => {
    it('should return empty array when isManager is false and isValidUser is false', () => {
      const result = hydratePermissionGroups([], false, false);
      expect(result).to.deep.equal([]);
    });
    it('should return empty array when isManager is true and isValidUser is false', () => {
      const result = hydratePermissionGroups([], true, false);
      expect(result).to.deep.equal(['MANAGER']);
    });
    it('should return empty array when isManager is false and isValidUser is true', () => {
      const result = hydratePermissionGroups([], false, true);
      expect(result).to.deep.equal(['USER']);
    });
    it('should return empty array when isManager is true and isValidUser is true', () => {
      const result = hydratePermissionGroups([], true, true);
      expect(result).to.deep.equal(['MANAGER', 'USER']);
    });
  });
}
