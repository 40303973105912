import { useState, useRef, useCallback } from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useWorkSummaryActions } from '@/api/work-summary';
import { Nullable, PromoUserProfile, ViewerType, WorkSummaryResource } from '@/models';
import WorkSummaryView from '@/components/common/WorkSummaryView';
import CommentList from '../Comment/CommentList';
import { CommentType, WorkSummaryStatus, WorkSummaryStatusReason } from '@/api/API';
import SubmitPeerReviewRequestModal from '../WorkSummary/SubmitPeerReviewRequestModal';
import RejectWorkSummaryModal from './RejectWorkSummaryModal';
import ReturnWorkSummaryModal from './ReturnWorkSummaryModal';
import { useCandidatePathActions, useCandidatePath } from '@/api/candidate-promo-path';
import useNavigator from '@/common/hooks/use-navigator';
import { ReviewWorkSummariesPage } from '@/common/pages';

interface FormParams {
  item: Nullable<WorkSummaryResource>;
  manager: Nullable<PromoUserProfile>;
}

const WorkSummaryReviewForm = ({ item, manager }: FormParams): JSX.Element => {
  const [isRejectVisible, setIsRejectVisible] = useState(false);
  const [isReturnVisible, setIsReturnVisible] = useState(false);
  const [currentAction, setCurrentAction] = useState<'approve' | 'reject' | 'return'>();
  const [isRequestFeedbackVisible, setIsRequestFeedbackVisible] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const { actions: workSummaryActions, isMutating } = useWorkSummaryActions(item?.id);
  const { candidatePath, isCandidatePathLoading } = useCandidatePath({ id: item?.promoPathId });
  const { actions: candidatePathActions } = useCandidatePathActions(candidatePath?.id);
  const { goToPage } = useNavigator();

  const onActionComplete = useCallback(() => {
    setCurrentAction(undefined);
    goToPage(ReviewWorkSummariesPage);
  }, [goToPage]);

  const onApprove = useCallback(async () => {
    if (candidatePath?.id) {
      const updatedWorkSummary = await workSummaryActions.update({
        status: WorkSummaryStatus.APPROVED,
        statusReason: WorkSummaryStatusReason.MANAGER_FINAL_APPROVAL,
      });
      let updatedCandidatePath: Awaited<ReturnType<typeof candidatePathActions.update>>;
      if (updatedWorkSummary) {
        updatedCandidatePath = await candidatePathActions.update({
          totalCompleted: (candidatePath?.totalCompleted ?? 0) + 1,
        });
      }
      if (updatedWorkSummary && updatedCandidatePath) {
        onActionComplete();
      }
    }
  }, [workSummaryActions, candidatePathActions, candidatePath?.totalCompleted, candidatePath?.id, onActionComplete]);

  const onActionClick = useCallback(
    (action: typeof currentAction) => {
      setCurrentAction(action);
      switch (action) {
        case 'approve':
          onApprove();
          break;
        case 'reject':
          setIsRejectVisible(true);
          break;
        case 'return':
          setIsReturnVisible(true);
          break;
        default:
      }
    },
    [onApprove]
  );

  return (
    <>
      <RejectWorkSummaryModal
        itemId={item?.id}
        manager={manager}
        isVisible={isRejectVisible}
        onSetVisibility={setIsRejectVisible}
        onSuccess={onActionComplete}
        ref={ref}
      />
      <ReturnWorkSummaryModal
        itemId={item?.id}
        manager={manager}
        isVisible={isReturnVisible}
        onSetVisibility={setIsReturnVisible}
        onSuccess={onActionComplete}
        ref={ref}
      />
      <SubmitPeerReviewRequestModal
        itemId={item?.id}
        isVisible={isRequestFeedbackVisible}
        onSetVisibility={setIsRequestFeedbackVisible}
        viewer={ViewerType.MANAGER}
        ref={ref}
      />
      <Form
        variant="full-page"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={() => goToPage(ReviewWorkSummariesPage)} variant="link" disabled={isMutating}>
              Cancel
            </Button>
            <Button
              onClick={() => onActionClick('reject')}
              variant="normal"
              loading={isMutating && currentAction === 'reject'}
              iconName="delete-marker"
              disabled={isMutating || item?.status !== WorkSummaryStatus.MANAGER_REVIEW}
            >
              {isMutating ? 'Saving...' : 'Reject'}
            </Button>
            <Button
              onClick={() => onActionClick('return')}
              variant="normal"
              loading={isMutating && currentAction === 'return'}
              iconName="undo"
              disabled={isMutating || item?.status !== WorkSummaryStatus.MANAGER_REVIEW}
            >
              {isMutating ? 'Saving...' : 'Return to candidate'}
            </Button>
            <Button
              onClick={() => onActionClick('approve')}
              variant="primary"
              loading={isMutating && currentAction === 'approve'}
              disabled={isMutating || isCandidatePathLoading || item?.status !== WorkSummaryStatus.MANAGER_REVIEW}
            >
              {isMutating ? 'Saving...' : 'Approve'}
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <WorkSummaryView item={item} />
          <CommentList commentType={CommentType.CANDIDATE} workSummaryId={item?.id ?? ''} />
        </SpaceBetween>
      </Form>
    </>
  );
};

export default WorkSummaryReviewForm;
