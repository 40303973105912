export function save(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function load<T>(key: string): T | T[] | undefined {
  const value = localStorage.getItem(key);
  try {
    return value && JSON.parse(value);
  } catch (e) {
    console.warn(
      `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`
    );
    return undefined;
  }
}

//in-source test suites
if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest

  describe('local-storage', () => {
     it('save', () => {
      save('foo', 'bar')
      expect(load('foo')).toBe('bar')
    })

    it('load', () => {
      save('foo', 'bar')
      expect(load<string>('foo')).toBe('bar')
    })

    it('load array', () => {
      save('foo', ['bar', 'baz'])
      expect(load<string[]>('foo')).toEqual(['bar', 'baz'])
    })

    it('load undefined', () => {
      save('foo', undefined)
      expect(load<string>('foo')).toBe(undefined)
    })

    it('load null', () => {
      save('foo', null)
      expect(load<string>('foo')).toBe(null)
    })

    it('load boolean', () => {
      save('foo', true)
      expect(load<boolean>('foo')).toBe(true)
    })

    it('load number', () => {
      save('foo', 123)
      expect(load<number>('foo')).toBe(123)
    })

  })
}
