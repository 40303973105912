// @ts-check
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';

import { CrossTableContextProvider } from '@/contexts/CrossTableContext';
import CalibratedPanelReviewersTable from '@/components/PanelReviewSessions/CalibratedPanelReviewersTable';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">List of Calibrated Panel Reviewers</Header>;
};

const ListCalibratedPanelReviewers = () => (
  <CrossTableContextProvider>
    <ContentLayout header={<PageHeader />}>
      <CalibratedPanelReviewersTable />
    </ContentLayout>
  </CrossTableContextProvider>
);

export default ListCalibratedPanelReviewers;
