import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import PromoPathForm from '../components/PromoPathForm/PromoPathForm';

const CreatePromoPath = () => (
  <ContentLayout header={<Header variant="h1">Create promo path</Header>}>
    <PromoPathForm />
  </ContentLayout>
);

export default CreatePromoPath;
