import { capitalize } from 'lodash';
import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { DEFAULT_EMPLOYEE_VIEW_AS_VALUE, DEFAULT_WORK_SUMMARIES_PER_PAGE, RESOURCES } from '@/common/constants';
import {
  getPageSizes,
  getPreferences,
  getFilteredColumns,
  getEmployeeColumnDef,
  getTableDefinition,
} from '@/components/common/table/table-config-helper';
import {
  ColumnDefinition,
  WorkSummaryItem,
  ViewModeDefinition,
  WorkSummaryTableConfig,
  PropertyDefinition,
  CandidateWorkSummaryItem,
  CategoryItem,
} from '@/models';
import { useAppContext } from '@/contexts/AppContext';
import {
  categoryPropertyFilter,
  getCategoryFilterOptions,
  getStatusFilterOptions,
  statusPropertyFilter,
  WorkSummaryStatusInd,
} from './common-components';
import { getFormattedDate } from '@/common/utils';
import { DatePropertyFilter, StringPropertyFilter } from '@/components/common/table/table-filters';
import { WorkSummaryStatus } from '@/api/API';
import { CategorySelection } from '../WorkSummaryView/common-components';
import { RoutableLink } from '@/components/common/links';

type ItemDefinition = WorkSummaryItem | CandidateWorkSummaryItem;

const StringKeys: (keyof WorkSummaryItem)[] = ['id', 'title', 'workSummaryType'];
const DateKeys: (keyof WorkSummaryItem)[] = ['submittedAt', 'updatedAt', 'createdAt'];

const properties: { [Property in keyof Partial<WorkSummaryItem>]: PropertyDefinition } = {
  categories: {
    key: 'categories',
    label: 'Categories',
  },
  title: {
    key: 'title',
    label: 'Title',
  },
  status: {
    key: 'status',
    label: 'Status',
  },
  workSummaryType: {
    key: 'workSummaryType',
    label: 'Type',
  },
  submittedAt: {
    key: 'submittedAt',
    label: 'Submitted',
  },
  updatedAt: {
    key: 'updatedAt',
    label: 'Last updated',
  },
  createdAt: {
    key: 'createdAt',
    label: 'Created on',
  },
};

const STATIC_COLUMN_DEFINITIONS: ColumnDefinition<ItemDefinition>[] = [
  {
    id: properties.title?.key as string,
    header: properties.title?.label as string,
    cell: (e) =>
      [WorkSummaryStatus.DRAFT, WorkSummaryStatus.PEER_REVIEW].includes(e.status as WorkSummaryStatus) ? (
        <RoutableLink href={`${RESOURCES.WORKSUMMARY.hrefToEdit}/${e.id}`} text={e.title} />
      ) : (
        e.title
      ),
    minWidth: 200,
    sortingField: properties.title?.key as string,
  },
  {
    id: properties.status?.key as string,
    header: properties.status?.label as string,
    cell: (e) => <WorkSummaryStatusInd status={e.status as WorkSummaryStatus} />,
    minWidth: 100,
    sortingField: properties.status?.key as string,
  },
  {
    id: properties.categories?.key as string,
    header: properties.categories?.label as string,
    cell: (e) => <CategorySelection categories={e.categories} />,
    minWidth: 160,
  },
  {
    id: properties.workSummaryType?.key as string,
    header: properties.workSummaryType?.label as string,
    cell: (e) => capitalize(e.workSummaryType),
    minWidth: 100,
    sortingField: properties.workSummaryType?.key as string,
  },
  {
    id: properties.submittedAt?.key as string,
    header: properties.submittedAt?.label as string,
    cell: (e) => getFormattedDate(e.submittedAt) || '-',
    sortingField: properties.submittedAt?.key as string,
  },
  {
    id: properties.updatedAt?.key as string,
    header: properties.updatedAt?.label as string,
    cell: (e) => getFormattedDate(e.updatedAt) || '-',
    sortingField: properties.updatedAt?.key as string,
  },
  {
    id: properties.createdAt?.key as string,
    header: properties.createdAt?.label as string,
    cell: (e) => getFormattedDate(e.createdAt) || '-',
    sortingField: properties.createdAt?.key as string,
  },
];

const ColumnDefinitions = <ItemDef extends ItemDefinition>(tableKey: string): ColumnDefinition<ItemDef>[] => {
  const appContext = useAppContext();
  return [getEmployeeColumnDef(tableKey, appContext), ...STATIC_COLUMN_DEFINITIONS];
};

export const VIEW_MODES: { [key: string]: ViewModeDefinition } = {
  INDIVIDUAL: {
    excludeColumnIds: ['candidate'],
    defaultPreferences: getPreferences(
      DEFAULT_WORK_SUMMARIES_PER_PAGE,
      STATIC_COLUMN_DEFINITIONS.filter((def) => !['createdAt'].includes(def.id))
    ),
  },
  MANAGER_REVIEW: {
    defaultPreferences: getPreferences(
      DEFAULT_WORK_SUMMARIES_PER_PAGE,
      STATIC_COLUMN_DEFINITIONS.filter((def) => !['categories', 'createdAt'].includes(def.id)),
      DEFAULT_EMPLOYEE_VIEW_AS_VALUE
    ),
  },
};

/**
 * Definition of the keys and resource(s) used in the `WorkSummaryTable`.
 * See parent: {@link PromoTableDefinition}
 */
const WorkSummaryTableDef = (tableKey: string) => getTableDefinition(tableKey, RESOURCES.WORKSUMMARY);

/**
 * Functional Component to set the table configuration for all WorkSummary tables.
 * The {@link ViewModeDefinition viewMode} specified will define what/how things are displayed.
 * See parent: {@link PromoTableConfig}
 */
const CreateTableConfig = <ItemDef extends ItemDefinition>(
  tableKey: string,
  viewMode: ViewModeDefinition
): WorkSummaryTableConfig<ItemDef> => {
  const allColumnDefinitions = ColumnDefinitions<ItemDef>(tableKey);
  const tableColumnDefinitions = getFilteredColumns(allColumnDefinitions, viewMode.excludeColumnIds);
  const preferences = viewMode.defaultPreferences;
  return {
    viewMode,
    tableDef: WorkSummaryTableDef(tableKey),
    allColumns: tableColumnDefinitions,
    pageSizes: getPageSizes(),
    displayableColumns: tableColumnDefinitions,
    defaultPreferences: {
      ...preferences,
      visibleContent: [...(preferences.visibleContent ?? []), 'candidate'],
    },
  };
};

export function getStaticFilterOptions(categories?: CategoryItem[]) {
  return [...getStatusFilterOptions(), ...getCategoryFilterOptions(categories || [])];
}

export function getPropertyFilters() {
  const stringFilters = StringKeys.map((key) => StringPropertyFilter(properties[key]?.key, properties[key]?.label));
  const dateFilters = DateKeys.map((key) => DatePropertyFilter(properties[key]?.key, properties[key]?.label));

  return [...stringFilters, ...dateFilters, statusPropertyFilter, categoryPropertyFilter]
    .filter((i): i is PropertyFilterProperty => !!i)
    .sort((a, b) => (a.propertyLabel.toLowerCase() > b.propertyLabel.toLowerCase() ? 1 : -1));
}

/** Hook to fetch a new instance of the table config. */
export function useTableConfig<ItemDef extends ItemDefinition>(tableKey: string, viewMode: ViewModeDefinition) {
  return CreateTableConfig<ItemDef>(tableKey, viewMode);
}
