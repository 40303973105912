import { useCallback, useMemo } from 'react';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
  CandidateStatusItem,
  HeaderActionSetParams,
  ManagerPathItem,
  TableHeaderParams,
  PromoTableDefinition,
  ActionDefinition,
  ItemAction,
  OptionalString,
  Nullable,
  FunctionActionParams,
} from '@/models';
import TableHeader from '@/components/common/table/TableHeader';
import { useManagerPromoPaths } from '@/api/promo-path';
import { useAppContext } from '@/contexts/AppContext';
import { PromoButtonDropdown } from '@/components/common/Buttons';

export function getAssignAction(onAssign: (v: ManagerPathItem) => void): ActionDefinition {
  return {
    action: 'assign',
    targetType: 'function',
    actionParams: { doAction: onAssign },
  };
}

export function getCompleteAction(onComplete: () => void): ActionDefinition {
  return {
    action: 'complete',
    targetType: 'function',
    actionParams: { doAction: onComplete },
  };
}

export function getUnassignAction(onUnassign: () => void): ActionDefinition {
  return {
    action: 'unassign',
    targetType: 'function',
    actionParams: { doAction: onUnassign },
  };
}

/**
 * Whether the `PromoPath` can be assigned to someone at this job level.
 * This assumes the employee can be assigned any path that starts from their current level.
 */
function isAssignable(promoPath: ManagerPathItem, item: Nullable<CandidateStatusItem>) {
  return item?.jobLevel && promoPath.startLevel === item.jobLevel && item.pathName !== promoPath.name;
}

/** Params - {@link HeaderActionSetParams} */
export const ActionSet = ({ selectedItem, resourceName, actionDefs }: HeaderActionSetParams<CandidateStatusItem>) => {
  const { currentUser, spoofUser } = useAppContext();
  const { promoPaths, isPromoPathsLoading } = useManagerPromoPaths(spoofUser?.alias ?? currentUser?.alias);

  const assign = useMemo(
    () => actionDefs.find((def) => def.action === 'assign' && def.targetType === 'function'),
    [actionDefs]
  );

  const actionToIsDisabled: Partial<{ [K in ItemAction]: boolean }> = useMemo(
    () => ({
      assign: !selectedItem,
      edit: !selectedItem?.pathName,
      complete: !selectedItem?.pathName || (selectedItem?.progressPct ?? 0) < 100,
      unassign: !selectedItem?.pathName,
    }),
    [selectedItem]
  );

  const onAssign = useCallback(
    (promoPathId: OptionalString) => {
      let path: Nullable<ManagerPathItem>;
      if (promoPathId) {
        path = promoPaths.find((p) => p.id === promoPathId);
      }
      if (path) {
        const callback = assign?.actionParams as FunctionActionParams;
        callback.doAction(path);
      }
    },
    [assign?.actionParams, promoPaths]
  );

  const orderedActionDefs: ActionDefinition[] = useMemo(() => {
    const orderedDefs: ActionDefinition[] = [];
    const actions: ItemAction[] = ['assign', 'edit', 'unassign', 'generate promo doc', 'complete'];
    actions.forEach((action) => {
      let actionDef = actionDefs.find((def) => def.action === action);
      if (actionDef) {
        if (actionDef.action === 'assign') {
          actionDef = {
            ...actionDef,
            actionParams: {
              ...actionDef.actionParams,
              doAction: onAssign,
            },
            actionOptions: (promoPaths ?? []).map((path) => ({
              id: `assign-${path.id}`,
              text: path.name,
              // Selectively disable options that don't apply but leave in the list for consistency
              disabled: !isAssignable(path, selectedItem),
            })),
          };
        }
        orderedDefs.push(actionDef);
      }
    });
    return orderedDefs;
  }, [actionDefs, promoPaths, selectedItem, onAssign]);

  return (
    // Dynamically set the button state based on whether an item from the table is selected
    <SpaceBetween direction="horizontal" size="s">
      <PromoButtonDropdown
        variant="normal"
        resourceName={resourceName}
        actions={orderedActionDefs}
        isLoading={isPromoPathsLoading}
        actionToIsDisabled={actionToIsDisabled}
      />
    </SpaceBetween>
  );
};

/**
 * Table header component for the `PromoPathProgress` table.
 * Header text consists of the `tableKey` from {@link PromoPathProgressTableDef}, and the total item count.
 *
 * Header offers 2 {@link https://polaris.a2z.com/components/button-dropdown/?tabId=playground ButtonDrodowns}
 * - `Assign` - List of available {@link PromoPath}s that can be assigned to selected employee.
 *    - Logic: `PromoPath` is enabled to assign if the `BaseLevel` matches employee's `JobLevel` (i.e. 5)
 * - `Actions` - `Edit`, `Delete`, and `Generate Progress Summary`
 *    - Logic: Options are enabled if a row is selected and a `PromoPath` is assigned.
 *
 * Returns a {@link https://polaris.a2z.com/components/header/?tabId=playground Polaris Header}
 */
const PromoPathProgressHeader = (params: TableHeaderParams<PromoTableDefinition, CandidateStatusItem>): JSX.Element => {
  return <TableHeader {...params} actionSet={ActionSet} />;
};

export const getHeaderComponent = () => PromoPathProgressHeader;
