// @ts-check
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import AdminWorkSummaryTable from '../components/AdminWorkSummarySearch/AdminWorkSummaryTable';
import { CrossTableContextProvider } from '@/contexts/CrossTableContext';

const Content = () => <AdminWorkSummaryTable />;

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">Admin dashboard</Header>;
};

const AdminDashboard = () => (
  <CrossTableContextProvider>
    <ContentLayout disableOverlap header={<PageHeader />}>
      <Content />
    </ContentLayout>
  </CrossTableContextProvider>
);

export default AdminDashboard;
