import { useMemo, useState } from 'react';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import { partition } from 'lodash';
import { resolveFullPath, allPages, HomePage, FilesPage, CandidatePage } from './common/pages';
import useNavigator from './common/hooks/use-navigator';
import { useAuthorizer } from './common/hooks/use-authorizer';

/** Semi-programatic way to define where `divider` lines are displayed in side nav. */
const pagesToPrependDivider = [CandidatePage.id, FilesPage.id];

type PromoSidebarItem = Exclude<
  SideNavigationProps.Item,
  SideNavigationProps.LinkGroup | SideNavigationProps.SectionGroup | SideNavigationProps.ExpandableLinkGroup
>;

/**
 * Returns the fairly complex object the `SideNavigation` component needs
 * to create the Sidebar Nav.
 * {@link https://refresh.polaris.a2z.com/components/side-navigation/?tabId=api SideNavigation Docs}
 */
function useFilteredSideNavItems() {
  const { hasAccess } = useAuthorizer();

  const navigationItems = useMemo(() => {
    const authorizedSidebarPages = allPages.filter((page) => page.isSidebarEnabled && hasAccess(page.authAction));
    const [containers, pages] = partition(authorizedSidebarPages, ['isContainerOnly', true]);

    // collect child pages to display under the parent sections
    const sidebarEligibleChildPages = pages.filter((page) => page.parentPage?.id && page.isSidebarEnabled);

    const sidebarItems: PromoSidebarItem[] = [];
    containers.forEach((container, iex) => {
      if (iex > 0 && pagesToPrependDivider.includes(container.id)) {
        sidebarItems.push({ type: 'divider' });
      }
      const childPages = sidebarEligibleChildPages.filter((page) => page.parentPage?.id === container?.id);
      if (childPages.length) {
        sidebarItems.push({
          type: 'section',
          text: container?.sidebarLabel ?? '',
          items: childPages.map((cp) => ({
            href: resolveFullPath(cp),
            text: cp.sidebarLabel,
            type: 'link',
          })),
        });
      } else {
        sidebarItems.push({ type: 'link', href: resolveFullPath(container), text: container.sidebarLabel });
      }
    });

    return sidebarItems;
  }, [hasAccess]);

  return navigationItems;
}

const ServiceNavigation = ({ hrefPath = '/promohub' }) => {
  const [activeHref, setActiveHref] = useState(hrefPath);
  const { followToRoute } = useNavigator();
  const navigationItems = useFilteredSideNavItems();

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ text: HomePage.sidebarLabel, href: `/${HomePage.path}` }}
      items={navigationItems}
      onFollow={(event) => {
        setActiveHref(event.detail.href.split('/')[1]);
        followToRoute(event);
      }}
    />
  );
};

export default ServiceNavigation;
