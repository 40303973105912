import { AwsRumConfig } from 'aws-rum-web';
import awsExports from '../aws-exports';

const APP_REGION = awsExports.aws_project_region;
const APP_VERSION = `1.0.0-${import.meta.env.REACT_APP_BUILD_ENV}`;

const guestRoleArn = () => {
  switch (import.meta.env.REACT_APP_BUILD_ENV) {
    case 'prod':
      return 'arn:aws:iam::496263720469:role/promohub-prod-cognito-unauth-role';
    case 'staging':
      return '';
    case 'beta':
      return 'arn:aws:iam::370562792648:role/promohub-beta-cognito-unauth-role';
    case 'dev':
    default:
      return 'arn:aws:iam::280611212285:role/promohub-dev-cognito-unauth-role';
  }
};

const rumMonitorConfig = (): AwsRumConfig => ({
  sessionSampleRate: 1,
  guestRoleArn: guestRoleArn(),
  identityPoolId: awsExports.aws_cognito_identity_pool_id,
  endpoint: `https://dataplane.rum.${awsExports.aws_project_region}.amazonaws.com`,
  telemetries: ['errors', 'performance', 'http'],
  allowCookies: true,
  enableXRay: true,
  enableRumClient: import.meta.env.MODE !== 'development',
});

const applicationId = () => {
  switch (import.meta.env.REACT_APP_BUILD_ENV) {
    case 'prod':
      return '';
    case 'staging':
      return '';
    case 'beta':
      return '65ebe5cf-1633-4ad7-a05b-5113382e87f0';
    case 'dev':
      return '30fdb744-89c0-49e2-8a10-6d8760673d51';
    default:
      return '';
  }
};

export default () => [applicationId(), APP_VERSION, APP_REGION, rumMonitorConfig()] as const;
