import { SpaceBetween, TextContent, Box } from '@amzn/awsui-components-react';
import { Nullable, PapiProfile } from '@/models';

const CandidateInfo: React.FC<{ candidateProfile: Nullable<PapiProfile> }> = ({ candidateProfile }) => (
  <SpaceBetween size="xs">
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Name:
      </Box>{' '}
      {candidateProfile?.firstName} {candidateProfile?.lastName}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Role:
      </Box>{' '}
      {candidateProfile?.businessTitle}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Level:
      </Box>{' '}
      {candidateProfile?.jobLevel}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Last hire date:
      </Box>{' '}
      {candidateProfile?.lastHireDate}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Current manager:
      </Box>{' '}
      {candidateProfile?.manager}
    </TextContent>
  </SpaceBetween>
);

export default CandidateInfo;
