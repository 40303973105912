import React from 'react';
import { Table, Header, Pagination } from '@amzn/awsui-components-react/polaris';
import { useCollection } from '@amzn/awsui-collection-hooks';

const defaultItems = [
  {
    name: 'John Doe',
    role: 'Senior Reviewer',
    level: 'Expert',
    timezone: 'UTC-5',
    completedSessions: 42,
  },
  {
    name: 'Jane Smith',
    role: 'Junior Reviewer',
    level: 'Intermediate',
    timezone: 'UTC+1',
    completedSessions: 17,
  },
  {
    name: 'Alex Johnson',
    role: 'Team Lead',
    level: 'Advanced',
    timezone: 'UTC-8',
    completedSessions: 76,
  },
];

const CalibratedPanelReviewersTable = ({ items: initialItems = defaultItems }) => {
  const columnDefinitions = [
    {
      id: 'name',
      header: 'Name',
      cell: (item) => item.name,
      sortingField: 'name',
    },
    {
      id: 'role',
      header: 'Role',
      cell: (item) => item.role,
      sortingField: 'role',
    },
    {
      id: 'level',
      header: 'Level',
      cell: (item) => item.level,
      sortingField: 'level',
    },
    {
      id: 'timezone',
      header: 'Timezone',
      cell: (item) => item.timezone,
      sortingField: 'timezone',
    },
    {
      id: 'completedSessions',
      header: '# of completed document review sessions',
      cell: (item) => item.completedSessions,
      sortingField: 'completedSessions',
    },
  ];

  const { items, paginationProps, collectionProps } = useCollection(initialItems, {
    pagination: { pageSize: 25 },
    sorting: {},
  });

  return (
    <Table
      columnDefinitions={columnDefinitions}
      items={items}
      header={<Header counter={`(${items.length})`}>Calibrated Panel Reviewers</Header>}
      pagination={<Pagination {...paginationProps} />}
      {...collectionProps}
    />
  );
};

export default CalibratedPanelReviewersTable;
