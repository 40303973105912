import { useState } from 'react';
import Badge from '@amzn/awsui-components-react/polaris/badge';
import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table from '@amzn/awsui-components-react/polaris/table';
import KeyValuePair from '@/components/common/KeyValuePair';
import { ColumnDefinition, FileRecordAttachment, Nullable, WorkSummaryResource } from '@/models';
import { formatFileSize } from './file-upload-helper';
import { getFormattedDate } from '@/common/utils';
import ManagerView from '../WorkSummaryView/ManagerView';
import { FileDownloadLink } from './links';

const fileColumns: ColumnDefinition<FileRecordAttachment>[] = [
  {
    id: 'name',
    header: 'File name',
    cell: (e) => <FileDownloadLink fileName={e.name} fileRecordId={e.id} workSummaryFileId={e.fileAttachmentId} />,
    sortingField: 'name',
    minWidth: 250,
  },
  {
    id: 'size',
    header: 'Size',
    cell: (e) => (e.size > 0 ? formatFileSize(e.size) : '-'),
    sortingField: 'size',
    width: 120,
  },
  {
    id: 'uploadedAt',
    header: 'Uploaded',
    cell: (e) => getFormattedDate(e.uploadedAt) || '-',
    sortingField: 'uploadedAt',
  },
];

const FileSectionHeader = ({ count }: { count: number }): JSX.Element => (
  <SpaceBetween direction="horizontal" size="xxs">
    <Box variant="div">Supporting documents</Box>
    {count > 0 && (
      <Box variant="div">
        <Badge color="blue">{count}</Badge>
      </Box>
    )}
  </SpaceBetween>
);

const WorkSummaryView = ({ item }: { item: Nullable<WorkSummaryResource> }): JSX.Element => {
  const [isDocsExpanded, setIsDocsExpanded] = useState(false);
  return (
    <Container
      variant="stacked"
      footer={
        <ExpandableSection
          defaultExpanded={false}
          headerText={<FileSectionHeader count={item?.documents?.length ?? 0} />}
          headingTagOverride="h3"
          variant="footer"
          expanded={isDocsExpanded}
          onChange={({ detail }) => setIsDocsExpanded(detail.expanded)}
        >
          <Table
            columnDefinitions={fileColumns}
            items={item?.documents || []}
            empty="No supporting docs attached to this work summary."
            sortingDisabled
            variant="embedded"
          />
        </ExpandableSection>
      }
    >
      <ColumnLayout columns={1}>
        <ManagerView workSummary={item} />
        <KeyValuePair label="Detail">{item?.content}</KeyValuePair>
      </ColumnLayout>
    </Container>
  );
};

export default WorkSummaryView;
