import { ForwardedRef, forwardRef, memo, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { useTableContext } from '@/contexts';
import { Nullable, OptionalString, ViewerType } from '@/models';
import { useWorkSummary, useWorkSummaryActions } from '@/api/work-summary';
import { WorkSummaryStatus } from '@/api/API';
import KeyValuePair from '@/components/common/KeyValuePair';
import PromoSpinner from '@/components/common/PromoSpinner';
import { usePeerReviewActions } from '@/api/peer-review';
import { FEDERATED_USER_PREFIX } from '@/common/constants';

interface SubmitRecallModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  contextKey?: string;
  itemId?: OptionalString;
  onSuccess?: () => void;
  viewer: ViewerType;
  candidate: Nullable<{
    alias: OptionalString;
    manager: {
      alias: OptionalString;
      name: OptionalString;
    };
  }>;
}

interface ModalMode {
  type: 'submit' | 'recall';
  title: string;
  targetPerson: string;
  completedText: string;
  inProgressText: string;
  panelText: string;
}

const SubmitMode: ModalMode = {
  type: 'submit',
  targetPerson: 'Manager',
  title: 'Submit work summary',
  completedText: 'submitted',
  inProgressText: 'submitting',
  panelText: 'Submit work summary to manager for review? You can recall the summary if needed at a later time.',
};

const RecallFromManagerMode: ModalMode = {
  type: 'recall',
  targetPerson: 'Manager',
  title: 'Recall work summary',
  completedText: 'recalled',
  inProgressText: 'recalling',
  panelText: 'Recall work summary from manager? You can re-submit at a later time.',
};

const RecallFromPeerReviewMode: ModalMode = {
  type: 'recall',
  title: 'Recall work summary',
  targetPerson: 'Peer reviewer(s)',
  completedText: 'recalled',
  inProgressText: 'recalling',
  panelText: 'Recall work summary from peer reviewer(s)? You can re-submit at a later time.',
};

const SubmitRecallComponent = (
  { contextKey, isVisible, onSetVisibility, itemId, onSuccess, viewer, candidate }: SubmitRecallModalParams,
  ref: ForwardedRef<HTMLElement>
) => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { selectedId } = useTableContext(contextKey ?? '');
  const workSummaryId = useMemo(() => itemId ?? selectedId, [itemId, selectedId]);
  const { workSummary, isLoading } = useWorkSummary(workSummaryId, viewer);
  const { actions: workSummaryActions } = useWorkSummaryActions(workSummary?.id);
  const { actions: peerReviewActions } = usePeerReviewActions();
  const [isSaving, setIsSaving] = useState(false);

  let mode: ModalMode;
  let status: WorkSummaryStatus;
  let people: string[] = [`${candidate?.manager.name} (${candidate?.manager.alias})`];
  let coOwners = [...(workSummary?.coOwners ?? [])];
  switch (workSummary?.status) {
    case WorkSummaryStatus.DRAFT:
      mode = SubmitMode;
      status = WorkSummaryStatus.MANAGER_REVIEW;
      coOwners = [...coOwners, `${FEDERATED_USER_PREFIX}${candidate?.manager.alias}`];
      break;
    case WorkSummaryStatus.MANAGER_REVIEW:
      mode = RecallFromManagerMode;
      status = WorkSummaryStatus.DRAFT;
      coOwners = coOwners.filter((coOwner) => coOwner.split('_')[1] !== candidate?.manager.alias);
      break;
    default:
      mode = RecallFromPeerReviewMode;
      people = workSummary?.peerReviewers.map((reviewer) => `${reviewer.name} (${reviewer.alias})`) ?? [];
      if (workSummary?.status === WorkSummaryStatus.PEER_REVIEW) {
        status = WorkSummaryStatus.DRAFT;
        coOwners = [];
      } else {
        status = WorkSummaryStatus.MANAGER_REVIEW;
        coOwners = coOwners.filter(
          (coOwner) => !workSummary?.peerReviewers.some((pr) => pr.alias === coOwner.split('_')[1])
        );
      }
  }

  function resetModal() {
    onSetVisibility(false);
  }

  const submitOrRecall = async () => {
    try {
      if (mode === RecallFromPeerReviewMode) {
        await Promise.allSettled((workSummary?.peerReviewers ?? []).map(async (r) => peerReviewActions.delete(r.id)));
      }
      await workSummaryActions.update({
        status,
        coOwners: [...new Set(coOwners)],
        submittedAt: mode.type === 'submit' ? DateTime.utc().toISO() : undefined,
      });
      onSuccess?.();
    } finally {
      setIsSaving(false);
    }
  };

  const onSave = async () => {
    setIsSaving(true);
    submitOrRecall().then(resetModal);
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModal()}
      closeAriaLabel="Close dialog"
      visible={isVisible}
      size="medium"
      header={mode.title}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isSaving} onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button variant="primary" loading={isSaving} disabled={isSaving} onClick={() => onSave()}>
              {isSaving ? `${mode.inProgressText}...` : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {isLoading ? (
        <PromoSpinner variant="inline" size="big" />
      ) : (
        <SpaceBetween direction="vertical" size="l">
          <Box variant="span">{mode.panelText}</Box>
          <ColumnLayout columns={2} variant="text-grid">
            <KeyValuePair label="Work summary">{workSummary?.title}</KeyValuePair>
            <KeyValuePair label={mode.targetPerson}>
              <SpaceBetween direction="vertical" size="xxs">
                {people.map((person) => (
                  <Box key={`${person}-alias-label`} variant="div">
                    {person}
                  </Box>
                ))}
              </SpaceBetween>
            </KeyValuePair>
          </ColumnLayout>
        </SpaceBetween>
      )}
    </Modal>
  );
};

const SubmitRecallModal = memo(forwardRef(SubmitRecallComponent));

export default SubmitRecallModal;
