import { useAppContext } from '@/contexts';
import FileRecordTable from '../components/FileRecord/FileRecordTable';
import { CrossTableContextProvider } from '@/contexts/CrossTableContext';

const FileManager = (): JSX.Element => {
  const { currentUser, spoofUser } = useAppContext();

  return (
    <CrossTableContextProvider>
      <div id="filemanager-fptable">
        <FileRecordTable userAlias={spoofUser?.alias ?? currentUser?.alias} />
      </div>
    </CrossTableContextProvider>
  );
};

export default FileManager;
