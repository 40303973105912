import { ReactNode } from 'react';

export enum FileSize {
  BYTES = 'bytes',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
}

export const convertBytesTo = (bytes: number, unit: FileSize): number => {
  switch (unit) {
    case FileSize.KB:
      return bytes / 1000;
    case FileSize.MB:
      return bytes / 1000 ** 2;
    case FileSize.GB:
      return bytes / 1000 ** 3;
    case FileSize.BYTES:
    default: {
      return bytes;
    }
  }
};

export function getBaseButtonText(multiple: boolean, buttonText?: ReactNode): ReactNode {
  return buttonText || `Choose file${multiple ? 's' : ''}`;
}

function getIdealSizeUnit(size: number): FileSize {
  let unit = FileSize.BYTES;

  if (size > 1000 ** 2) {
    unit = FileSize.MB;
  } else if (size > 1000) {
    unit = FileSize.KB;
  }
  return unit;
}

export function formatFileSize(size: number, requestedUnit?: FileSize): string | null {
  if (!size) {
    return null;
  }
  const unit = requestedUnit || getIdealSizeUnit(size);
  const convertedSize = convertBytesTo(size, unit).toFixed(2);
  return `${convertedSize} ${unit}`;
}

// in-source test suites
if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest

  describe('file-upload-helper', () => {
    it('should formatFileSize', () => {
      expect(formatFileSize(1000)).toBe('1000.00 bytes');
      expect(formatFileSize(1000100, undefined)).toBe('1.00 MB');
      expect(formatFileSize(1000, FileSize.BYTES)).toBe('1000.00 bytes');
    })
  })

  describe('convertBytesTo', () => {
    it('should convertBytesTo', () => {
      expect(convertBytesTo(1000, FileSize.KB)).toBe(1);
      expect(convertBytesTo(1000, FileSize.MB)).toBe(0.001);
      expect(convertBytesTo(1000, FileSize.BYTES)).toBe(1000);
    })
  })

  describe('getIdealSizeUnit', () => {
    it('should getIdealSizeUnit', () => {
      expect(getIdealSizeUnit(1000)).toBe(FileSize.BYTES);
      expect(getIdealSizeUnit(1001)).toBe(FileSize.KB);
      expect(getIdealSizeUnit(1001 ** 2)).toBe(FileSize.MB);
    })
  })

  describe('getBaseButtonText', () => {
    it('should getIdealSizeUnit', () => {
      expect(getBaseButtonText(false)).toBe('Choose file');
      expect(getBaseButtonText(true)).toBe('Choose files');
    })
  })
}
