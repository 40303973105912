import {
  Table,
  Header,
  SpaceBetween,
  Button,
  FormField,
  Form,
  Select,
  TextContent,
} from '@amzn/awsui-components-react';

import { UserVote, Vote } from '../config';
import { CalibratedReviewerItem, DocumentReviewSessionVoteItem, DocumentReviewSessionResource } from '@/models';
import { DocumentReviewSessionState } from '@/api/API';

interface VotingSectionProps {
  isOwner: boolean;
  sessionData: DocumentReviewSessionResource;
  sessionVotes: Map<string, DocumentReviewSessionVoteItem>;
  userVote: UserVote;
  onVote: (voteType: keyof UserVote, value: string) => void;
  onSave: () => void;
  onRefresh: () => void;
}

const VotingSection: React.FC<VotingSectionProps> = ({
  isOwner,
  sessionData,
  sessionVotes,
  userVote,
  onVote,
  onSave,
  onRefresh,
}) => {
  if (isOwner || sessionData.sessionState === DocumentReviewSessionState.COMPLETE) {
    return (
      <Table
        items={sessionData.documentReviewers}
        header={
          <Header
            counter={`(${sessionData.documentReviewers?.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => {
                    console.log('REFRESHING');
                    onRefresh();
                  }}
                >
                  Refresh
                </Button>
              </SpaceBetween>
            }
          >
            CalibratedDocumentReviewerRecord
          </Header>
        }
        columnDefinitions={[
          { header: 'Reviewer', cell: (item: CalibratedReviewerItem) => item.alias },
          {
            header: 'Document Ready',
            cell: (item: CalibratedReviewerItem) => sessionVotes.get(item.alias)?.documentReady || '-',
          },
          {
            header: 'Candidate Ready',
            cell: (item: CalibratedReviewerItem) => sessionVotes.get(item.alias)?.candidateReady || '-',
          },
        ]}
      />
    );
  }

  const isVotingEnabled = sessionData.sessionState === DocumentReviewSessionState.SCHEDULED;

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Document ready">
            <Select
              selectedOption={userVote.documentReady ? { label: userVote.documentReady } : null}
              onChange={({ detail }) => onVote('documentReady', detail.selectedOption.value as Vote)}
              options={[
                { label: Vote.Yes, value: Vote.Yes },
                { label: Vote.No, value: Vote.No },
              ]}
              disabled={!isVotingEnabled}
              placeholder="Select your vote"
            />
          </FormField>
          <FormField label="Candidate ready">
            <Select
              selectedOption={userVote.candidateReady ? { label: userVote.candidateReady } : null}
              onChange={({ detail }) => onVote('candidateReady', detail.selectedOption.value as Vote)}
              options={[
                { label: Vote.Yes, value: Vote.Yes },
                { label: Vote.No, value: Vote.No },
              ]}
              disabled={!isVotingEnabled}
              placeholder="Select your vote"
            />
          </FormField>
          {!isVotingEnabled && (
            <TextContent>
              <p>Voting is only enabled when the session is in the "SCHEDULED" state.</p>
            </TextContent>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default VotingSection;
