/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { PromoAppError } from './pages/utility-pages';

interface ErrorBoundaryState {
  hasError: boolean;
}

/**
 * Used to create a fallback barrier in the app, so users aren't brought to an unpleasant state.
 * See {@link https://reactjs.org/docs/error-boundaries.html React docs}
 */
class PromoErrorBoundary extends React.Component<Record<string, JSX.Element>, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false } as ErrorBoundaryState;
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <PromoAppError />;
    }
    return children;
  }
}

export default PromoErrorBoundary;
