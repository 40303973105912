/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';

export function useMultiQueryResult<T>(results: T[]) {
  const ref = useRef<T[]>([]);

  const isResultSetsIdentical =
    ref.current && results.length === ref.current.length
      ? results.every((item, iex) => item === ref.current?.[iex])
      : false;

  useEffect(() => {
    if (!isResultSetsIdentical) {
      ref.current = results;
    }
  }, [isResultSetsIdentical, results]);

  return isResultSetsIdentical ? ref.current : results;
}
