import { OptionalString } from '@/models';
import '../../../styles/base.scss';
import { PHOTO_URL, PHONETOOL_URL } from '@/common/constants';

interface UserPhotoParams {
  alias: OptionalString;
  imageLink?: OptionalString;
  imageSize?: 's' | 'm' | 'l';
}
const UserPhoto = ({ alias, imageLink, imageSize = 's' }: UserPhotoParams): JSX.Element => (
  <a href={`${PHONETOOL_URL}${alias}`} target="_blank" rel="noopener noreferrer" title={alias ?? ''}>
    <img className={`user-photo ${imageSize}`} src={imageLink || `${PHOTO_URL}${alias}`} alt="Badge" />
  </a>
);

export default UserPhoto;
