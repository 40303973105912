import { useEffect, useState } from 'react';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { borderRadiusInput } from '@amzn/awsui-design-tokens';
import KeyValuePair from '../components/common/KeyValuePair';
import { useAppContext } from '@/contexts/AppContext';
import '../styles/landing-page.scss';
import UserPhoto from '../components/common/person/UserPhoto';
import DisplayName from '../components/common/person/DisplayName';
import PromoSpinner from '../components/common/PromoSpinner';
import { Nullable, PromoUserProfile } from '@/models';

interface ContentParams {
  user: Nullable<PromoUserProfile>;
  isLoading?: boolean;
}

const Content = ({ user, isLoading = false }: ContentParams) => (
  <Box margin={{ bottom: 'l' }}>
    <div className="promohub-home_header">
      <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
        <Box padding={{ vertical: 'xxxl' }}>
          <Grid
            gridDefinition={[
              { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
              { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
            ]}
          >
            <Box fontWeight="heavy" padding={{ top: 'xs' }}>
              <span className="promohub-home_category">Hub for Promotion Artifacts and tasks</span>
            </Box>

            <div className="promohub-home_header-title">
              <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                PromoHub
              </Box>
              <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                one stop shop for storing and sharing your work for Promotion
              </Box>
              <Box variant="p" fontWeight="light">
                <span className="promohub-home_header-sub-title">
                  PromoHub is THE place for creating high quality promodocs! Create and store your work summaries,
                  upload supporting documents, and enjoy seemless collabaration with your manager and feedback
                  providers. Managers can use this portal view into a candidate's promotion journey to provide timely
                  and effective guidance. The goal of PromoHub is to provide candidates AND managers an easy way to
                  start early, save time, and have Bias for Action.
                </span>
              </Box>
            </div>
          </Grid>
        </Box>
      </Grid>
    </div>
    <Box padding={{ top: 'xxxl' }}>
      <Grid
        gridDefinition={[
          { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
          { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } },
        ]}
      >
        <div>
          <SpaceBetween size="xxl">
            <Container header={<Header variant="h2">How it works</Header>} variant="stacked">
              <Box variant="div">
                <img
                  height="100%"
                  width="100%"
                  style={{ borderRadius: borderRadiusInput }}
                  src="workflow.png"
                  alt="Sample of light mode focussed color scheme."
                />
              </Box>
            </Container>
            <Container header={<Header variant="h2">Benefits and features</Header>} variant="stacked">
              <ColumnLayout columns={2} variant="text-grid">
                <div>
                  <Box variant="h3" padding={{ top: 'n' }} fontWeight="bold">
                    Centralized work summary curation
                  </Box>
                  <Box variant="p">
                    Store your technical achievements, customer wins, customer quotes, and other artifacts to be used as
                    promodoc work summaries.
                  </Box>
                </div>
                <div>
                  <Box variant="h3" padding={{ top: 'n' }} fontWeight="bold">
                    Easy collaboration with your manager
                  </Box>
                  <Box variant="p">
                    When the time is right, candidates can submit work summaries for manager review, comment, revision,
                    and approval. Also, work summary comment functionality enables 2-way communication.
                  </Box>
                </div>
                <div>
                  <Box variant="h3" padding={{ top: 'n' }} fontWeight="bold">
                    Prepare for promotion from Day 1
                  </Box>
                  <Box variant="p">
                    Whether or not an employee is on the path to promotion, PromoHub is a great place to store
                    achievements.
                  </Box>
                </div>
                <div>
                  <Box variant="h3" padding={{ top: 'n' }} fontWeight="bold">
                    Help and guidance
                  </Box>
                  <Box variant="p">
                    Promotion tracks are templatized to reduce manager and candidate effort. However, layout and
                    tailored for a candidates target role and level.
                  </Box>
                </div>
              </ColumnLayout>
            </Container>
          </SpaceBetween>
        </div>

        <div className="promohub-home_sidebar">
          <SpaceBetween size="xxl">
            {isLoading ? (
              <PromoSpinner />
            ) : (
              <Container
                header={
                  <SpaceBetween direction="horizontal" size="xxs">
                    <UserPhoto alias={user?.alias || '-'} imageSize="m" />
                    <Header variant="h3">
                      {`${user?.firstName} ${user?.lastName}`}
                      <Box color="text-body-secondary">
                        <DisplayName item={user} format="alias" />
                      </Box>
                    </Header>
                  </SpaceBetween>
                }
              >
                <ColumnLayout columns={1} variant="text-grid">
                  <KeyValuePair label="Manager">
                    <SpaceBetween direction="horizontal" size="xxs">
                      <UserPhoto alias={user?.manager?.alias || '-'} imageSize="s" />
                      <Box margin={{ top: 's' }}>
                        <DisplayName item={user?.manager} format="both" />
                      </Box>
                    </SpaceBetween>
                  </KeyValuePair>
                  <KeyValuePair label="Organization">{user?.orgName || '-'}</KeyValuePair>
                  <KeyValuePair label="Current Level">{`L${user?.jobLevel} - ${user?.jobTitle}`}</KeyValuePair>
                </ColumnLayout>
              </Container>
            )}
            <Container
              header={
                <Header variant="h3">
                  Getting started <Icon name="external" size="inherit" />
                </Header>
              }
            >
              <ul className="custom-list-separator">
                <li>
                  <Link href="https://w.amazon.com/bin/view/PromoHub/About/" target="_blank">
                    Wiki
                  </Link>
                </li>
                <li>
                  <Link href="https://w.amazon.com/bin/view/PromoHub/Candidate/" target="_blank">
                    Quickstart for candidates
                  </Link>
                </li>
                <li>
                  <Link href="https://w.amazon.com/bin/view/PromoHub/Manager/" target="_blank">
                    Quickstart for managers
                  </Link>
                </li>
                <li>
                  <Link href="https://w.amazon.com/bin/view/PromoHub/FAQ/" target="_blank">
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://amazon.awsapps.com/workdocs/index.html#/document/5ddaffef52a2eae60f5e6295960103608ed3fd436b0cbf7e1960d2866fbb7c68"
                    target="_blank"
                  >
                    Enterprise Account Engineer Guidelines
                  </Link>
                </li>
              </ul>
            </Container>
            <Container
              header={
                <Header variant="h3">
                  More resources <Icon name="external" size="inherit" />
                </Header>
              }
            >
              <ul className="custom-list-separator">
                <li>
                  <Link
                    href="https://amazon.awsapps.com/workdocs/index.html#/document/5a80c3878197f435fa7a96ca22838203c68649df8a5952259b631a03c192b641"
                    target="_blank"
                  >
                    TAM Promo Planning WorkBook
                  </Link>
                </li>
                <li>
                  <Link href="https://w.amazon.com/bin/view/PromoHub/Contactus">Contact us</Link>
                </li>
              </ul>
            </Container>
            <Container header={<Header variant="h3">Related services</Header>}>
              <ColumnLayout columns={2} variant="text-grid">
                <div>
                  <Box variant="h3" padding={{ top: 'n' }} fontWeight="bold">
                    <Link href="https://promote.talent.a2z.com/" fontSize="heading-m" external>
                      Promote
                    </Link>
                  </Box>
                </div>
              </ColumnLayout>
            </Container>
          </SpaceBetween>
        </div>
      </Grid>
    </Box>
  </Box>
);

const PromoHub = () => {
  const { currentUser, spoofUser } = useAppContext();
  const [isLoading, setIsLoading] = useState(currentUser === undefined);
  useEffect(() => {
    if (isLoading && currentUser) {
      setIsLoading(false);
    }
  }, [currentUser, isLoading]);
  return <Content user={spoofUser ?? currentUser} isLoading={isLoading} />;
};

export default PromoHub;
