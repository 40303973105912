// @ts-check
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';

import { CrossTableContextProvider } from '@/contexts/CrossTableContext';
import CalibratedDocumentReviewersTable from '@/components/CalibratedDocumentReviewers/CalibratedDocumentReviewersTable';
import { useAppContext } from '@/contexts';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">List of Calibrated Document Reviewers</Header>;
};

const ListCalibratedDocumentReviewers = () => {
  const { currentUser, spoofUser } = useAppContext();
  return (
    <CrossTableContextProvider>
      <ContentLayout header={<PageHeader />}>
        <CalibratedDocumentReviewersTable user={spoofUser ?? currentUser} />
      </ContentLayout>
    </CrossTableContextProvider>
  );
};

export default ListCalibratedDocumentReviewers;
