import React, { useRef, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import { NoItemsTableState, NoMatchTableState } from '@/components/common/table/table-states';
import { CommentCardDefinition, CommentCardHeader } from './common-components';
import CreateEditCommentModal from './CreateEditCommentModal';
import { getFilterCountTextLabel, getNameDefinition } from '@/common/labels';
import { RESOURCES } from '@/common/constants';
import { useComments } from '@/api/comment';
import { CommentType } from '@/api/API';
import DeleteCommentModal from './DeleteCommentModal';

const DEFAULT_CARDS_PER_ROW = 1;
const DEFAULT_TEXT_FILTER_FIELDS = ['content', 'createdAt', 'updatedAt', 'authorAlias', 'authorName'];
const resource = RESOURCES.COMMENT;

interface CommentListParams {
  workSummaryId: string;
  commentType: CommentType;
}

const CommentList = ({ workSummaryId, commentType }: CommentListParams): JSX.Element => {
  const [isCreateEditVisible, setIsCreateEditVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [createEditMode, setCreateEditMode] = useState<'create' | 'edit'>('create');
  const ref = useRef<HTMLElement>(null);
  const { comments, isCommentsLoading } = useComments(workSummaryId, commentType);

  const { items, actions, filteredItemsCount, collectionProps, filterProps } = useCollection(comments, {
    filtering: {
      fields: DEFAULT_TEXT_FILTER_FIELDS,
      empty: <NoItemsTableState resourceName={getNameDefinition(resource)} showCreate={false} />,
      noMatch: <NoMatchTableState onClearFilter={() => actions.setFiltering('')} />,
    },
    selection: { trackBy: resource.idKey },
  });

  return (
    <>
      <Cards
        {...collectionProps}
        cardsPerRow={[{ cards: DEFAULT_CARDS_PER_ROW }]}
        stickyHeader
        stickyHeaderVerticalOffset={69}
        variant="container"
        loading={isCommentsLoading}
        loadingText="Loading comments"
        items={items}
        selectionType="single"
        header={
          <CommentCardHeader
            item={collectionProps?.selectedItems?.[0]}
            setIsCreateEditVisible={setIsCreateEditVisible}
            setCreateEditMode={setCreateEditMode}
            setIsDeleteVisible={setIsDeleteVisible}
            countText={getFilterCountTextLabel(filteredItemsCount)}
            {...filterProps}
          />
        }
        cardDefinition={CommentCardDefinition()}
      />
      <CreateEditCommentModal
        commentType={commentType}
        workSummaryId={workSummaryId}
        isVisible={isCreateEditVisible}
        onSetVisibility={setIsCreateEditVisible}
        mode={createEditMode}
        comment={collectionProps.selectedItems?.[0]}
        ref={ref}
      />
      <DeleteCommentModal
        isVisible={isDeleteVisible}
        onSetVisibility={setIsDeleteVisible}
        comment={collectionProps.selectedItems?.[0]}
      />
    </>
  );
};

export default React.memo(CommentList);
