import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import { NoMatchTableStateParams, EmptyTableStateParams, NoItemsTableStateParams } from './models';
import { PromoButton } from '../Buttons';

/** Empty table state which can be customized for 'no items' or 'no matches' use cases */
export const EmptyTableState = ({ title, subtitle, children }: EmptyTableStateParams): React.ReactElement => (
  // Content to display if no items are in the table
  // Allow pass-thru of child elements (i.e. buttons) to display
  <Box textAlign="center" color="inherit">
    <b>{title}</b>
    <Box padding={{ bottom: 's' }} variant="p" color="inherit">
      {subtitle}
    </Box>
    {children}
  </Box>
);

/**
 * Generates a table state element for a 'no items' / empty use case.
 * Allows you to control if/when the button to Create a new item is displayed.
 */
export const NoItemsTableState = ({
  resourceName,
  showCreate,
  createActionDef,
}: NoItemsTableStateParams): React.ReactElement => {
  return (
    <EmptyTableState
      title={`No ${resourceName.shortNamePlural}`}
      subtitle={`No ${resourceName.fullNamePlural} to display.`}
    >
      {showCreate && createActionDef && (
        <PromoButton
          action={createActionDef.action}
          targetType={'href' in createActionDef.actionParams ? 'route' : 'function'}
          actionParams={createActionDef.actionParams}
          resourceName={resourceName}
        />
      )}
    </EmptyTableState>
  );
};

/**
 * Generates a table state element for a 'no matches to your filter' use case.
 * Requires a function which will undo the filter on click... thus preventing users from being trapped.
 */
export const NoMatchTableState = ({ onClearFilter }: NoMatchTableStateParams): React.ReactElement => (
  <EmptyTableState title="No matches" subtitle="We can't find a match.">
    <Button onClick={onClearFilter}>Clear filter</Button>
  </EmptyTableState>
);
