import {
  Table,
  Header,
  SpaceBetween,
  Button,
  FormField,
  Form,
  Select,
  TextContent,
} from '@amzn/awsui-components-react';

import { SessionData, Reviewer, SESSION_STATES, UserVote, Vote } from '../config';

interface VotingSectionProps {
  isCreator: boolean;
  sessionData: SessionData;
  userVote: UserVote;
  onVote: (voteType: keyof UserVote, value: string) => void;
  currentUser: { id: string; isSkipLevel: boolean };
}

const VotingSection: React.FC<VotingSectionProps> = ({ isCreator, sessionData, userVote, onVote, currentUser }) => {
  if (isCreator) {
    return (
      <Table
        items={sessionData.reviewers}
        header={
          <Header
            counter={`(${sessionData.reviewers.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => {
                    /* Implement refresh logic */
                  }}
                >
                  Refresh
                </Button>
              </SpaceBetween>
            }
          >
            Reviewers
          </Header>
        }
        columnDefinitions={[
          { header: 'Reviewer', cell: (item: Reviewer) => item.label },
          { header: 'Initial Vote', cell: (item: Reviewer) => item.initialVote || '-' },
          { header: 'Final Vote', cell: (item: Reviewer) => item.finalVote || '-' },
          { header: 'Final Outcome', cell: (item: Reviewer) => item.finalOutcome || '-' },
        ]}
      />
    );
  }

  const isInitialVotingEnabled = sessionData.state === SESSION_STATES.INITIAL_VOTE;
  const isFinalVotingEnabled = sessionData.state === SESSION_STATES.FINAL_VOTE;
  const isFinalOutcomeEnabled = sessionData.state === SESSION_STATES.FINAL_OUTCOME && currentUser.isSkipLevel;

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              onClick={() => {
                /* Implement save vote logic */
              }}
            >
              Save
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Initial Vote">
            <Select
              selectedOption={userVote.initialVote ? { label: userVote.initialVote } : null}
              onChange={({ detail }) => onVote('initialVote', detail.selectedOption.value as Vote)}
              options={[
                { label: Vote.Yes, value: Vote.Yes },
                { label: Vote.No, value: Vote.No },
              ]}
              disabled={!isInitialVotingEnabled}
              placeholder="Select your vote"
            />
          </FormField>
          <FormField label="Final Vote">
            <Select
              selectedOption={userVote.finalVote ? { label: userVote.finalVote } : null}
              onChange={({ detail }) => onVote('finalVote', detail.selectedOption.value as Vote)}
              options={[
                { label: Vote.Yes, value: Vote.Yes },
                { label: Vote.No, value: Vote.No },
              ]}
              disabled={!isFinalVotingEnabled}
              placeholder="Select your vote"
            />
          </FormField>
          {currentUser.isSkipLevel && (
            <FormField label="Final Outcome">
              <Select
                selectedOption={userVote.finalOutcome ? { label: userVote.finalOutcome } : null}
                onChange={({ detail }) => onVote('finalOutcome', detail.selectedOption.value as string)}
                options={[
                  { label: 'Inclined', value: 'Inclined' },
                  { label: 'Not Inclined', value: 'Not Inclined' },
                  { label: 'Did not attend review', value: 'Did not attend review' },
                ]}
                disabled={!isFinalOutcomeEnabled}
                placeholder="Select final outcome"
              />
            </FormField>
          )}
          {!isInitialVotingEnabled && !isFinalVotingEnabled && !isFinalOutcomeEnabled && (
            <TextContent>
              <p>Voting is only enabled during specific session states.</p>
            </TextContent>
          )}
        </SpaceBetween>
      </Form>
    </form>
  );
};

export default VotingSection;
