import React, { useMemo } from 'react';
import BreadcrumbGroup from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import { PageDefinition, resolveBreadcrumbFromPage } from './common/pages';
import useNavigator from './common/hooks/use-navigator';

interface BreadcrumbParams {
  currentPage: PageDefinition;
}

const BreadcrumbComponent = ({ currentPage }: BreadcrumbParams) => {
  const { followToRoute } = useNavigator();
  // render the breadcrumbs list from our current page
  // Memorize the value as it only changes if the user moves pages, but the component can re-render for other reasons.
  const crumbs = useMemo(() => resolveBreadcrumbFromPage(currentPage), [currentPage]);

  return <BreadcrumbGroup items={crumbs} onFollow={followToRoute} />;
};

// Memorize the component to limit re-rendering from the `AppLayout` (optimization)
const Breadcrumbs = React.memo(BreadcrumbComponent);

export default Breadcrumbs;
