import { useState } from 'react';
import { capitalize } from 'lodash';
import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { Nullable, PeerReviewResource } from '@/models';
import KeyValuePair from '@/components/common/KeyValuePair';
import { formatFileSize } from '@/components/common/file-upload-helper';
import { getFormattedDate } from '@/common/utils';
import { CategorySelection } from '../WorkSummaryView/common-components';
import { FileDownloadLink } from '../common/links';

const fileColumns: TableProps.ColumnDefinition<PeerReviewResource['documents'][number]>[] = [
  {
    id: 'name',
    header: 'File name',
    cell: (e) => <FileDownloadLink fileName={e.name} fileRecordId={e.id} workSummaryFileId={e.workSummaryFileId} />,
    sortingField: 'name',
    minWidth: 250,
  },
  {
    id: 'size',
    header: 'Size',
    cell: (e) => (e.size > 0 ? formatFileSize(e.size) : '-'),
    sortingField: 'size',
    width: 120,
  },
  {
    id: 'uploadedAt',
    header: 'Uploaded',
    cell: (e) => getFormattedDate(e.uploadedAt) || '-',
    sortingField: 'uploadedAt',
  },
];

interface WorkSummaryPanelContentParams {
  content: Nullable<PeerReviewResource>;
}

const Content = ({ content }: WorkSummaryPanelContentParams) => {
  const [isDocsExpanded, setIsDocsExpanded] = useState(false);
  return (
    <ColumnLayout columns={1} borders="horizontal">
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <KeyValuePair label="Candidate alias">{content?.candidateAlias ?? '-'}</KeyValuePair>
          <KeyValuePair label="Manager alias">{content?.candidateManagerAlias ?? '-'}</KeyValuePair>
        </SpaceBetween>
        <SpaceBetween size="l">
          <KeyValuePair label="Type">{capitalize(content?.workSummaryType ?? '') ?? '-'}</KeyValuePair>
          <KeyValuePair label="Category">
            <CategorySelection categories={content?.workSummaryCategories} />
          </KeyValuePair>
        </SpaceBetween>
        <SpaceBetween size="l">
          <KeyValuePair label="Assigned promo path">{content?.candidatePromoPathName ?? '-'}</KeyValuePair>
          <KeyValuePair label="Target level">{content?.candidatePromoPathTargetLevel ?? '-'}</KeyValuePair>
        </SpaceBetween>
      </ColumnLayout>
      <SpaceBetween direction="vertical" size="s">
        <Header variant="h3" description="">
          Details
        </Header>
        <Box variant="p">{content?.workSummaryDetail}</Box>
      </SpaceBetween>
      <ExpandableSection
        defaultExpanded={false}
        headerText={
          <SpaceBetween direction="horizontal" size="xxs">
            <Box variant="div" fontWeight="heavy" fontSize="heading-s" margin="n">
              Supporting documents
            </Box>
            <Box variant="div" fontWeight="normal" color="text-status-inactive" fontSize="heading-s" margin="n">
              {`(${content?.documents.length})`}
            </Box>
          </SpaceBetween>
        }
        headerCounter={`(${content?.documents.length})`}
        variant="default"
        expanded={isDocsExpanded}
        onChange={({ detail }) => setIsDocsExpanded(detail.expanded)}
      >
        <Table
          columnDefinitions={fileColumns}
          items={content?.documents ?? []}
          empty="No supporting docs attached to this work summary."
          sortingDisabled
          variant="embedded"
        />
      </ExpandableSection>
    </ColumnLayout>
  );
};

interface WorkSummaryPanelParams {
  contentParams?: Nullable<PeerReviewResource>;
}

const WorkSummaryPanel = ({ contentParams }: WorkSummaryPanelParams) => {
  return (
    <Container header={<Header variant="h2">Work summary</Header>}>
      <SpaceBetween size="l">
        <Content content={contentParams} />
      </SpaceBetween>
    </Container>
  );
};

export default WorkSummaryPanel;
