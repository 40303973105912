import React from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import { NoItemsTableState, NoMatchTableState } from '@/components/common/table/table-states';
import { AuditTrailsCardDefinition } from './common-components';
import { getNameDefinition } from '@/common/labels';
import { RESOURCES } from '@/common/constants';
import { AuditTrailRecordType } from '@/api/API';
import { useWorkSummaryAuditTrails } from '@/api/audit-trails';
import { AuditTrailItem } from '@/models';

const DEFAULT_CARDS_PER_ROW = 1;
const DEFAULT_TEXT_FILTER_FIELDS: (keyof AuditTrailItem)[] = ['modifiedByAlias', 'humanreadablelog'];
const resource = RESOURCES.AUDITTRAIL;

interface AuditTrailsListParams {
  workSummaryId: string;
  recordType: AuditTrailRecordType;
}

const AuditTrailsList = ({ workSummaryId }: AuditTrailsListParams): JSX.Element => {
  const { auditTrails, isAuditTrailsLoading } = useWorkSummaryAuditTrails(workSummaryId);

  const { items, actions, collectionProps } = useCollection(auditTrails, {
    filtering: {
      fields: DEFAULT_TEXT_FILTER_FIELDS,
      empty: <NoItemsTableState resourceName={getNameDefinition(resource)} showCreate={false} />,
      noMatch: <NoMatchTableState onClearFilter={() => actions.setFiltering('')} />,
    },
    selection: { trackBy: resource.idKey },
  });

  return (
    <Cards
      {...collectionProps}
      cardsPerRow={[{ cards: DEFAULT_CARDS_PER_ROW }]}
      stickyHeader
      stickyHeaderVerticalOffset={69}
      variant="container"
      loading={isAuditTrailsLoading}
      loadingText="Loading auditrails"
      items={items}
      cardDefinition={AuditTrailsCardDefinition()}
    />
  );
};

export default React.memo(AuditTrailsList);
