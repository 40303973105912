import '../styles/markdown-content.scss';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkSqueezeParagraphs from 'remark-squeeze-paragraphs';
import remarkBreaks from 'remark-breaks';

const CSP_ALLOWED_SRC = new Set(['.cloudfront.net', 'elevate.aws.dev', 'elevate.hire.aws.dev', 'internal-cdn.amazon.com']);

// Safe default plugins to make the markdown fancier
const DefaultPlugins = [remarkGfm, remarkBreaks, remarkSqueezeParagraphs];

/**
 * Given the input text, `ReactMarkdown` is used to parse the raw text into markdown/react-node elements
 * which are sanitized (cuz security). The output is wrapped in the {@link https://refresh.polaris.a2z.com/components/text-content/?tabId=api TextContent}
 * component. This applies all AWSUI standard styles and design specs to the html.
 */
const MarkdownContent = ({ children }: { children: string }): JSX.Element => (
  <TextContent>
    <ReactMarkdown
      remarkPlugins={DefaultPlugins}
      className="markdown-content"
        allowElement={(element) =>
          // Only allow images if they're from targets allowed in the CSP
          element.tagName !== 'img' ||
          (typeof element.properties.src === 'string' && CSP_ALLOWED_SRC.has(element.properties.src))
        }
        components={{
          // Override default behavior and set target and rel attributes for security
          a: ({ href, children, ...props }) => (
            <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
              {children}
            </a>
          ),
        }}
    >
      {children}
    </ReactMarkdown>
  </TextContent>
);

export default MarkdownContent;
