import Box from '@amzn/awsui-components-react/polaris/box';
import Badge from '@amzn/awsui-components-react/polaris/badge';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Tabs from '@amzn/awsui-components-react/polaris/tabs';
import { useState } from 'react';
import { PeerReviewItem } from '@/models';
import CommentList from '../Comment/CommentList';
import { CommentType } from '@/api/API';

const CommentsTabLabel = ({ count }: { count: number }): JSX.Element => (
  <SpaceBetween direction="horizontal" size="xxs">
    <Box variant="div">Comments</Box>
    {count > 0 && (
      <Box variant="div">
        <Badge color="blue">{count}</Badge>
      </Box>
    )}
  </SpaceBetween>
);

interface PeerReviewDetailParams {
  peerReview?: PeerReviewItem;
}

const PeerReviewDetailView = ({ peerReview }: PeerReviewDetailParams): JSX.Element => {
  const [tabId, setTabId] = useState('comments');

  return (
    <Tabs
      activeTabId={tabId}
      onChange={({ detail }) => setTabId(detail.activeTabId)}
      ariaLabel="comments tabbed view"
      tabs={[
        {
          id: 'comments',
          label: <CommentsTabLabel count={peerReview?.commentCount || 0} />,
          content: (
            <CommentList workSummaryId={peerReview?.workSummaryId || ''} commentType={CommentType.PEER_REVIEW} />
          ),
        },
        {
          id: 'audit-history',
          label: 'Audit history',
          content: 'Coming soon to a PromoHub near you...',
        },
      ]}
      variant="default"
    />
  );
};

export default PeerReviewDetailView;
