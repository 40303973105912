export enum SESSION_STATES {
  SCHEDULED = 'Scheduled',
  INITIAL_VOTE = 'Initial vote',
  FINAL_VOTE = 'Final vote',
  FINAL_OUTCOME = 'Final outcome vote',
  COMPLETE = 'Complete',
}

export enum Vote {
  Yes = 'Yes',
  No = 'No',
}

export interface UserVote {
  initialVote: Vote | null;
  finalVote: Vote | null;
  finalOutcome: Vote | null;
}

export interface Candidate {
  name: string;
  role: string;
  level: string;
  tenure: string;
  currentManager: string;
}

export interface Reviewer {
  id: string;
  initialVote: Vote;
  finalVote: Vote;
  finalOutcome: Vote;
  label: string;
}

export interface SessionData {
  id: string;
  state: SESSION_STATES;
  candidate: Candidate;
  reviewers: Reviewer[];
  workdocsLink: string;
  originalDocReviewLink: string;
  chimeLink?: string;
  date: string;
  time: string;
  timezone: { label: string; value: string };
  creator: { id: string; label: string };
}
