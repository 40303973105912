import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { groupBy } from 'lodash';
import { PropertyFilterQuery } from '@amzn/awsui-collection-hooks';
import { getPropertyFilters, getStaticFilterOptions, useTableConfig, VIEW_MODES } from './table-config';
import PromoTable from '@/components/common/table/PromoTable';
import ViewEmployeeAsOption from '@/components/common/person/ViewEmployeeAsOption';
import {
  getApproveAction,
  getDeleteAction,
  getHeaderComponent,
  getReqPeerReviewAction,
  getReviewAction,
  getCandidateReturnAction,
  getRecallAction,
} from './common-components';
import {
  CandidateWorkSummaryItem,
  DirectReportRecord,
  Nullable,
  PromoUserProfile,
  ViewerType,
  WorkSummaryItem,
} from '@/models';
import DeleteWorkSummaryModal from './DeleteWorkSummaryModal';
import SubmitPeerReviewRequestModal from './SubmitPeerReviewRequestModal';
import { WorkSummaryStatus } from '@/api/API';
import WorkSummaryDetailView from './WorkSummaryDetailView';
import ApproveWorkSummaryModal from './ApproveWorkSummaryModal';
import ReturnWorkSummaryModal from '../WorkSummaryReview/ReturnWorkSummaryModal';
import SubmitRecallModal from './SubmitRecallModal';
import { useSplitPanel, useTableContext } from '@/contexts';
import { useWorkSummariesCoalesce } from '@/api/work-summary';

/** Identifier for this table. Also used as the key for tracking context. */
const TABLE_KEY = 'Candidate work summaries';

const defaultFilter: PropertyFilterQuery = {
  operation: 'and',
  tokens: [{ operator: '!=', propertyKey: 'status', value: WorkSummaryStatus.APPROVED.toString() }],
};

interface WorkSummaryReviewTableParams {
  directReports: DirectReportRecord[];
  isParentLoading: boolean;
  manager: Nullable<PromoUserProfile>;
}

/**
 * A management review table displaying a list of Work Summaries for all direct reports.
 * Table provides a `BaseTextFilter` and `BaseSelectFilter` which allows quick filtering
 * by `status` categories. By default it shows all in-progress (non Approved) items
 *
 * User interactions, preferences, etc are tracked and held in state with `ManagerContext`.
 *
 * A custom UI preference is also available to choose how to view the `Employee` column.
 */
const WorkSummaryReviewTable = ({ directReports, isParentLoading, manager }: WorkSummaryReviewTableParams) => {
  const [isApproveVisible, setIsApproveVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isReqPeerReviewVisible, setIsReqPeerReviewVisible] = useState(false);
  const [IsReturnToCandidateVisible, setIsReturnToCandidateVisible] = useState(false);
  const [isSubmitRecallVisible, setIsSubmitRecallVisible] = useState(false);
  const { isLoading, workSummaries, refresh } = useWorkSummariesCoalesce(directReports.map((report) => report.alias));

  const candidateSummaryItems: CandidateWorkSummaryItem[] = useMemo(() => {
    const candidateToSummaries: Record<string, WorkSummaryItem[]> = groupBy(workSummaries, 'alias');
    return directReports
      .map((directReport) => {
        const candidateSummaries = (candidateToSummaries[directReport.alias] ?? []).map((summary) => {
          return {
            ...summary,
            name: directReport.name || `${directReport.firstName} ${directReport.lastName}`,
          };
        });
        return candidateSummaries;
      })
      .flat(2);
  }, [workSummaries, directReports]);

  const config = useTableConfig(TABLE_KEY, VIEW_MODES.MANAGER_REVIEW);
  const ref = useRef<HTMLElement>(null);
  const onApprove = () => setIsApproveVisible(true);
  const onDelete = () => setIsDeleteVisible(true);
  const onRequestPeerReview = () => setIsReqPeerReviewVisible(true);
  const onReturnToCandidate = () => setIsReturnToCandidateVisible(true);
  const onRecall = () => setIsSubmitRecallVisible(true);
  const { setSplitPanelData, resetSplitPanel } = useSplitPanel();
  const { selectedId, setSelectedId } = useTableContext(TABLE_KEY);

  const onSuccess = useCallback(() => {
    setSelectedId(null);
    refresh();
  }, [setSelectedId, refresh]);

  const selectedWorkSummary = useMemo(
    () => candidateSummaryItems?.find((item) => item.id === selectedId),
    [candidateSummaryItems, selectedId]
  );

  const selectedCandidate = useMemo(
    () => ({
      alias: selectedWorkSummary?.alias,
      manager: {
        alias: manager?.alias,
        name: `${manager?.firstName} ${manager?.lastName}`,
      },
    }),
    [selectedWorkSummary?.alias, manager?.firstName, manager?.lastName, manager?.alias]
  );

  useEffect(() => {
    if (selectedWorkSummary) {
      setSplitPanelData({
        header: `${selectedWorkSummary.title} - Candidate ${selectedWorkSummary.alias}@`,
        content: <WorkSummaryDetailView item={selectedWorkSummary} variant="manager" />,
      });
    } else {
      resetSplitPanel();
    }
  }, [selectedId, selectedWorkSummary, setSplitPanelData, resetSplitPanel]);

  return (
    <>
      <SubmitPeerReviewRequestModal
        contextKey={TABLE_KEY}
        isVisible={isReqPeerReviewVisible}
        onSetVisibility={setIsReqPeerReviewVisible}
        viewer={ViewerType.MANAGER}
        onSuccess={onSuccess}
        ref={ref}
      />
      <DeleteWorkSummaryModal
        contextKey={TABLE_KEY}
        isVisible={isDeleteVisible}
        onSetVisibility={setIsDeleteVisible}
        onSuccess={onSuccess}
        ref={ref}
      />
      <SubmitRecallModal
        viewer={ViewerType.MANAGER}
        candidate={selectedCandidate}
        contextKey={TABLE_KEY}
        isVisible={isSubmitRecallVisible}
        onSetVisibility={setIsSubmitRecallVisible}
        onSuccess={onSuccess}
        ref={ref}
      />
      <ApproveWorkSummaryModal
        contextKey={TABLE_KEY}
        manager={manager}
        isVisible={isApproveVisible}
        onSetVisibility={setIsApproveVisible}
        onSuccess={onSuccess}
        ref={ref}
      />
      <ReturnWorkSummaryModal
        contextKey={TABLE_KEY}
        manager={manager}
        onSuccess={onSuccess}
        isVisible={IsReturnToCandidateVisible}
        onSetVisibility={setIsReturnToCandidateVisible}
        ref={ref}
      />
      <PromoTable
        allItems={candidateSummaryItems}
        tableConfig={config}
        headerComponent={getHeaderComponent()}
        customPreferenceComponent={ViewEmployeeAsOption}
        isLoading={isParentLoading || isLoading}
        tableVariant="borderless"
        actionDefs={[
          getReviewAction(),
          getApproveAction(onApprove),
          getReqPeerReviewAction(onRequestPeerReview),
          getRecallAction(onRecall),
          getCandidateReturnAction(onReturnToCandidate),
          getDeleteAction(onDelete),
        ]}
        defaultFilter={defaultFilter}
        propertyFilters={getPropertyFilters()}
        staticFilterOptions={getStaticFilterOptions()}
      />
    </>
  );
};

export default WorkSummaryReviewTable;
