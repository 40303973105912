import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { DEFAULT_REVIEWERS_PER_PAGE, RESOURCES } from '@/common/constants';
import {
  getPageSizes,
  getFilteredColumns,
  getTableDefinition,
  getPreferences,
} from '@/components/common/table/table-config-helper';
import {
  ColumnDefinition,
  PropertyDefinition,
  PromoTableDefinition,
  PromoTableConfig,
  CalibratedReviewerItem,
} from '@/models';
import { DatePropertyFilter, StringPropertyFilter } from '@/components/common/table/table-filters';
import DisplayName from './common-components';

/** Identifier for this table. Also used as the key for tracking context. */
export const TABLE_KEY = 'Calibrated document reviewers';

const StringKeys: (keyof CalibratedReviewerItem)[] = ['alias', 'completedSessions'];
const DateKeys: (keyof CalibratedReviewerItem)[] = [];

const properties: { [Property in keyof Partial<CalibratedReviewerItem>]: PropertyDefinition } = {
  alias: {
    key: 'alias',
    label: 'Alias',
  },
  completedSessions: {
    key: 'completedSessions',
    label: 'Completed review sessions',
  },
};

const STATIC_COLUMN_DEFINITIONS: ColumnDefinition<CalibratedReviewerItem>[] = [
  {
    id: properties.alias?.key as string,
    header: 'Name',
    cell: () => '',
    minWidth: 30,
    maxWidth: 31,
    sortingField: properties.alias?.key as string,
  },
  {
    id: properties.alias?.key as string,
    header: properties.alias?.label as string,
    cell: (e: CalibratedReviewerItem) => <DisplayName item={e} />,
    minWidth: 30,
    maxWidth: 31,
    sortingField: properties.alias?.key as string,
  },
  {
    id: properties.alias?.key as string,
    header: 'Role',
    cell: () => '',
    minWidth: 30,
    maxWidth: 31,
    sortingField: properties.alias?.key as string,
  },
  {
    id: properties.alias?.key as string,
    header: 'Timezone',
    cell: () => '',
    minWidth: 30,
    maxWidth: 31,
    sortingField: properties.alias?.key as string,
  },
  {
    id: properties.completedSessions?.key as string,
    header: properties.completedSessions?.label as string,
    cell: (e) => e.completedSessions,
    minWidth: 30,
    maxWidth: 31,
    sortingField: properties.completedSessions?.key as string,
  },
];

const ColumnDefinitions = <ItemDef extends CalibratedReviewerItem>(): ColumnDefinition<ItemDef>[] => {
  return [...STATIC_COLUMN_DEFINITIONS];
};

/**
 * Definition of the keys and resource(s) used in the `FeedbackSummaryTable`.
 * See parent: {@link PromoTableDefinition}
 */
const CalibratedDocumentReviewersTableDef = getTableDefinition(TABLE_KEY, RESOURCES.CALIBRATED_REVIEWER);

type CalibratedDocumentReviewersTableConfig = PromoTableConfig<PromoTableDefinition, CalibratedReviewerItem>;

/**
 * Functional Component to set the table configuration for all tables.
 * The {@link ViewModeDefinition viewMode} specified will define what/how things are displayed.
 * See parent: {@link PromoTableConfig}
 */
const CreateTableConfig = (): CalibratedDocumentReviewersTableConfig => {
  return {
    tableDef: CalibratedDocumentReviewersTableDef,
    allColumns: ColumnDefinitions(),
    pageSizes: getPageSizes(),
    displayableColumns: getFilteredColumns(ColumnDefinitions()),
    defaultPreferences: getPreferences(DEFAULT_REVIEWERS_PER_PAGE, getFilteredColumns(ColumnDefinitions())),
  };
};

export function getPropertyFilters() {
  const stringFilters = StringKeys.map((key) => StringPropertyFilter(properties[key]?.key, properties[key]?.label));
  const dateFilters = DateKeys.map((key) => DatePropertyFilter(properties[key]?.key, properties[key]?.label));

  return [...stringFilters, ...dateFilters].filter((i): i is PropertyFilterProperty => i !== undefined);
}

/** Hook to fetch a new instance of the table config. */
export function useTableConfig() {
  return CreateTableConfig();
}
