import FormField, { FormFieldProps } from '@amzn/awsui-components-react/polaris/form-field';

interface PromoFieldParams extends FormFieldProps {
  isRequired?: boolean;
  label: string;
}

export const OptionalLabel = ({ baseLabel }: { baseLabel: string }) => (
  <span>
    {baseLabel} <i>- optional</i>{' '}
  </span>
);

const PromoFormField = ({ label, isRequired = false, children, ...params }: PromoFieldParams) => {
  return (
    <FormField {...params} label={isRequired ? label : <OptionalLabel baseLabel={label} />}>
      {children}
    </FormField>
  );
};

export default PromoFormField;
