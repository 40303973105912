import { Box, SpaceBetween, Button, Select, Form, StatusIndicator } from '@amzn/awsui-components-react';
import { DocumentReviewSessionState } from '@/api/API';

interface SessionStateProps {
  isOwner: boolean;
  initialSessionState: DocumentReviewSessionState;
  sessionState: DocumentReviewSessionState;
  onStateChange: (newState: DocumentReviewSessionState) => void;
  onSaveStateChange: (newState: DocumentReviewSessionState) => void;
}

const SessionState: React.FC<SessionStateProps> = ({
  isOwner,
  initialSessionState,
  sessionState,
  onStateChange,
  onSaveStateChange,
}) => (
  <Box>
    {isOwner ? (
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                disabled={sessionState === initialSessionState}
                onClick={() => onSaveStateChange(sessionState)}
              >
                Save
              </Button>
            </SpaceBetween>
          }
        >
          <Select
            selectedOption={{ label: sessionState }}
            onChange={({ detail }) => onStateChange(detail.selectedOption.value as DocumentReviewSessionState)}
            options={[
              {
                label: DocumentReviewSessionState.SCHEDULED,
                value: DocumentReviewSessionState.SCHEDULED,
                disabled: sessionState === DocumentReviewSessionState.SCHEDULED,
              },
              {
                label: DocumentReviewSessionState.COMPLETE,
                value: DocumentReviewSessionState.COMPLETE,
                disabled: sessionState === DocumentReviewSessionState.COMPLETE,
              },
            ]}
          />
        </Form>
      </form>
    ) : (
      <StatusIndicator type="info">{sessionState}</StatusIndicator>
    )}
  </Box>
);

export default SessionState;
