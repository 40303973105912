/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
// @ts-check
import { SpaceBetween } from '@amzn/awsui-components-react';
import { RESOURCES } from '@/common/constants';
import { getNameDefinition } from '@/common/labels';
import {
  WorkSummaryAdminItem,
  TableHeaderParams,
  PromoTableDefinition,
  HeaderActionSetParams,
  ItemAction,
  ActionDefinition,
} from '@/models';
import { PromoButtonDropdown } from '@/components/common/Buttons';
import TableHeader from '@/components/common/table/TableHeader';
import { EditWorkSummaryAdminPage } from '@/common/pages';

const CounterText = (items: readonly WorkSummaryAdminItem[]) => {
  const totalItems = items && items.length > 0 ? items.length : 0;
  // Per AWS Style Guide always set to 0 if no items.
  if (totalItems === 0) {
    return '(0)';
  }
  return `(${totalItems})`;
};

export function getEditAction(): ActionDefinition {
  return {
    action: 'edit',
    targetType: 'route',
    actionParams: { page: EditWorkSummaryAdminPage },
  };
}

const Resource = RESOURCES.WORKSUMMARY;
const ResourceName = getNameDefinition(Resource);

/** Params - {@link HeaderActionSetParams} */
export const ActionSet = ({ selectedItem, actionDefs }: HeaderActionSetParams<WorkSummaryAdminItem>) => {
  const actionToIsDisabled: Partial<{ [K in ItemAction]: boolean }> = {
    create: false,
    edit: !selectedItem,
    submit: !selectedItem,
  };
  const actions: ItemAction[] = ['edit', 'submit'];
  // Action buttons have a specific order of precedence, which the caller doesn't need to know about
  // so deliberately order based on the setup specific to this action set.
  const orderedActionDefs: ActionDefinition[] = [];
  actions.forEach((action) => {
    const actionDef = actionDefs.find((def) => def.action === action);
    if (actionDef) {
      orderedActionDefs.push(actionDef);
    }
  });

  return (
    // Dynamically set the button state based on whether an item from the table is selected
    <SpaceBetween direction="horizontal" size="s">
      <PromoButtonDropdown
        resourceName={ResourceName}
        isDisabled={!selectedItem}
        actionToIsDisabled={actionToIsDisabled}
        actions={orderedActionDefs}
      />
    </SpaceBetween>
  );
};

/**
 * Table header component for a `WorkSummary` table.
 * Header text consists of the `tableKey` from {@link PromoTableDefinition}, and the total item count.
 * When all available summaries are being shown, the header will indicate the total `Approved` summaries.
 *
 * Returns a {@link https://refresh.polaris.a2z.com/components/header/?tabId=playground Polaris Header}
 */
const AdminWorkSummaryHeader = ({
  items,
  ...params
}: TableHeaderParams<PromoTableDefinition, WorkSummaryAdminItem>): JSX.Element => {
  return <TableHeader {...params} counter={CounterText(items || [])} actionSet={ActionSet} />;
};

export const getHeaderComponent = () => AdminWorkSummaryHeader;

// in-source test suites
if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest

  describe('ActionSet', () => {
    it('APPROVED item ActionSet', () => {
      const actionDefs: ActionDefinition[] = [
        {
          action: 'edit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
        {
          action: 'submit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
      ];
      const selectedItem: WorkSummaryAdminItem = {
        id: '1',
        status: 'APPROVED',
        reviewerAlias: 'test',
        managerAlias: 'test',
        candidatePromoPathVersion: 0,
        alias: '',
        title: '',
        createdAt: undefined,
        updatedAt: undefined,
        workSummaryType: '',
        statusReason: undefined,
        promoPathId: undefined,
        submittedAt: undefined,
        version: 0,
        categories: [],
        coOwners: new Set<string>,
      };

      const resourceName=ResourceName;

      const result = ActionSet({ selectedItem, resourceName, actionDefs });

      expect(result).toMatchSnapshot();
    })

    it('DRAFT item ActionSet', () => {
      const actionDefs: ActionDefinition[] = [
        {
          action: 'edit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
        {
          action: 'submit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
      ];
      const selectedItem: WorkSummaryAdminItem = {
        id: '1',
        status: 'DRAFT',
        reviewerAlias: 'test',
        managerAlias: 'test',
        candidatePromoPathVersion: 0,
        alias: '',
        title: '',
        createdAt: undefined,
        updatedAt: undefined,
        workSummaryType: '',
        statusReason: undefined,
        promoPathId: undefined,
        submittedAt: undefined,
        version: 0,
        categories: [],
        coOwners: new Set<string>,
      };

      const resourceName=ResourceName;

      const result = ActionSet({ selectedItem, resourceName, actionDefs });

      expect(result).toMatchSnapshot();
    })

    it('PEER_REVIEW item ActionSet', () => {
      const actionDefs: ActionDefinition[] = [
        {
          action: 'edit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
        {
          action: 'submit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
      ];
      const selectedItem: WorkSummaryAdminItem = {
        id: '1',
        status: 'PEER_REVIEW',
        reviewerAlias: 'test',
        managerAlias: 'test',
        candidatePromoPathVersion: 0,
        alias: '',
        title: '',
        createdAt: undefined,
        updatedAt: undefined,
        workSummaryType: '',
        statusReason: undefined,
        promoPathId: undefined,
        submittedAt: undefined,
        version: 0,
        categories: [],
        coOwners: new Set<string>,
      };

      const resourceName=ResourceName;

      const result = ActionSet({ selectedItem, resourceName, actionDefs });

      expect(result).toMatchSnapshot();
    })

    it('MANAGER_REVIEW item ActionSet', () => {
      const actionDefs: ActionDefinition[] = [
        {
          action: 'edit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
        {
          action: 'submit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
      ];
      const selectedItem: WorkSummaryAdminItem = {
        id: '1',
        status: 'MANAGER_REVIEW',
        reviewerAlias: 'test',
        managerAlias: 'test',
        candidatePromoPathVersion: 0,
        alias: '',
        title: '',
        createdAt: undefined,
        updatedAt: undefined,
        workSummaryType: '',
        statusReason: undefined,
        promoPathId: undefined,
        submittedAt: undefined,
        version: 0,
        categories: [],
        coOwners: new Set<string>,
      };

      const resourceName=ResourceName;

      const result = ActionSet({ selectedItem, resourceName, actionDefs });

      expect(result).toMatchSnapshot();
    })

    it('MANAGER_PEER_REVIEW item ActionSet', () => {
      const actionDefs: ActionDefinition[] = [
        {
          action: 'edit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
        {
          action: 'submit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
      ];
      const selectedItem: WorkSummaryAdminItem = {
        id: '1',
        status: 'MANAGER_PEER_REVIEW',
        reviewerAlias: 'test',
        managerAlias: 'test',
        candidatePromoPathVersion: 0,
        alias: '',
        title: '',
        createdAt: undefined,
        updatedAt: undefined,
        workSummaryType: '',
        statusReason: undefined,
        promoPathId: undefined,
        submittedAt: undefined,
        version: 0,
        categories: [],
        coOwners: new Set<string>,
      };

      const resourceName=ResourceName;

      const result = ActionSet({ selectedItem, resourceName, actionDefs });

      expect(result).toMatchSnapshot();
    })

    it('MANAGER_PEER_REVIEW item ActionSet', () => {
      const actionDefs: ActionDefinition[] = [
        {
          action: 'edit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
        {
          action: 'submit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
      ];
      const selectedItem: WorkSummaryAdminItem = {
        id: '1',
        status: 'MANAGER_PEER_REVIEW',
        reviewerAlias: 'test',
        managerAlias: 'test',
        candidatePromoPathVersion: 0,
        alias: '',
        title: '',
        createdAt: undefined,
        updatedAt: undefined,
        workSummaryType: '',
        statusReason: undefined,
        promoPathId: undefined,
        submittedAt: undefined,
        version: 0,
        categories: [],
        coOwners: new Set<string>,
      };

      const resourceName=ResourceName;

      const result = ActionSet({ selectedItem, resourceName, actionDefs });

      expect(result).toMatchSnapshot();
    })

    it('No item ActionSet', () => {
      const actionDefs: ActionDefinition[] = [
        {
          action: 'edit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
        {
          action: 'submit',
          targetType: 'route',
          actionParams: { page: EditWorkSummaryAdminPage },
        },
      ];

      const resourceName=ResourceName;

      const result = ActionSet({ selectedItem : undefined, resourceName, actionDefs });

      expect(result).toMatchSnapshot();
    })
  })

  describe('getEditAction', () => {
    it('Simple getEditAction', () => {
      const action = getEditAction();
      expect(action).toEqual({
        action: 'edit',
        targetType: 'route',
        actionParams: { page: EditWorkSummaryAdminPage },
      })
     })
  });

  describe('CounterText', () => {
    it('Non-zero CounterText', () => {
      const item : WorkSummaryAdminItem = {
        id: '1',
        status: 'APPROVED',
        reviewerAlias: 'test',
        managerAlias: 'test',
        candidatePromoPathVersion: 0,
        alias: '',
        title: '',
        createdAt: undefined,
        updatedAt: undefined,
        workSummaryType: '',
        statusReason: undefined,
        promoPathId: undefined,
        submittedAt: undefined,
        version: 0,
        categories: [],
        coOwners: new Set<string>,
      };
      const items = [item];
      const result = CounterText(items);
      expect(result).toEqual('(1)');
     })
  });

  it('Zero CounterText', () => {
    const items = [];
    const result = CounterText(items);
    expect(result).toEqual('(0)');
  });
}




