import { Mode } from '@amzn/awsui-global-styles';
import { DateTime, IANAZone, Zone, ZoneOffsetFormat } from 'luxon';
import abbreviations from '../resources/timezone/abbreviations.json';
import { Nullable, OptionalNumber, OptionalString } from './models';
import { CLOUDSCAPE_AUDO_I18N_LANGUAGES } from './constants';

const DEFAULT_FORMAT = 'yyyy-MM-dd HH:mm';
const today = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

type AWSDateTime = OptionalString;
type AWSTimestamp = OptionalNumber;
type InputDate = Date | AWSDateTime | AWSTimestamp;

function getTimezoneAbbreviation(epochMilis: number, zone: IANAZone) {
  /*
    Use Luxon to get the time zone 'long' name.. i.e. Eastern Standard Time.
    Use the long name to lookup the abbreviation. We can do this w/ Luxon, but
    the value returned is locale-based and unpredictable. So using a preset list ensures consistency.

    The value is normalized based on the epoch date passed in.
      Ex: America/New_York Timezone - in December this returns Eastern Standard Time, and Eastern Daylight Time in July
  */
  const fullTimezoneName = zone.offsetName(epochMilis, { format: 'long' });
  let abbr = '';
  if (fullTimezoneName) {
    abbr = abbreviations[fullTimezoneName];
  }
  if (!abbr && fullTimezoneName) {
    // For locales where only 1 tz is used, the abbreviations data omits the 'Daylight' / 'Standard'
    // modifier, so we strip it here to ensure a match.
    abbr = abbreviations[fullTimezoneName.replace('Standard ', '').replace('Daylight ', '')];
  }
  if (!abbr) {
    // If we got this far and no luck, fall back to the Intl abbreviation from Luxon
    abbr = zone.offsetName(epochMilis, { format: 'short' }) ?? '';
  }
  // sanity check in case we only got an offset
  if (['+', '-'].includes(abbr.charAt(0))) {
    abbr = `UTC${abbr}`;
  }
  return abbr;
}

function formatDateTime(dateTime: Nullable<DateTime>, format?: string): OptionalString {
  if (!dateTime) {
    return undefined;
  }
  return dateTime.toFormat(format || DEFAULT_FORMAT);
}

function calibrateDateTime(value: InputDate, zone: string | Zone = 'local'): Nullable<DateTime> {
  // if no date, bail altogether
  if (!value) {
    return undefined;
  }
  if (typeof value === 'string') {
    return DateTime.fromISO(value, { zone });
  }
  if (typeof value === 'number') {
    return DateTime.fromMillis(value, { zone });
  }
  return DateTime.fromJSDate(value, { zone });
}

function formatDateTimeWithZone(value: InputDate): OptionalString {
  const dateTime = calibrateDateTime(value);
  if (!dateTime) {
    return undefined;
  }
  const abbreviation = getTimezoneAbbreviation(dateTime.toUnixInteger() * 1000, dateTime.zone as IANAZone);
  return `${formatDateTime(dateTime)} ${abbreviation}`;
}

export function getDurationFromNow(value: Nullable<Date>): OptionalString {
  const calibratedDateTime = calibrateDateTime(value);
  if (!calibratedDateTime) {
    return undefined;
  }
  const then = calibratedDateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return today.diff(then, ['years', 'months', 'days']).toHuman({ unitDisplay: 'short', signDisplay: 'never' });
}

/**
 * Converts an AWSDateTime or AWSTimestamp value from the AppSync API to a localized `Date` object.
 * @param value AWSDateTime or AWSTimestamp from AppSync API. Ex: `2022-11-23T16:57:20.501Z`
 */
export function getDateFromAPIValueUTC(value: AWSDateTime | AWSTimestamp): Nullable<Date> {
  const calibratedDateTime = calibrateDateTime(value, 'utc');
  if (!calibratedDateTime) {
    return undefined;
  }
  return calibratedDateTime.setZone('local').toJSDate();
}

export function getFormattedDate(value: Nullable<Date>): OptionalString {
  return formatDateTimeWithZone(value);
}

export function getAWSDateAsQuarter(awsDate: OptionalString): OptionalString {
  if (!awsDate) {
    return undefined;
  }
  const format = 'Qq, yyyy';
  return formatDateTime(calibrateDateTime(awsDate), format);
}

export function getFormattedAWSTimestamp(awsTimestamp: OptionalNumber): OptionalString {
  if (!awsTimestamp) {
    return undefined;
  }
  return formatDateTimeWithZone(awsTimestamp);
}

export function getSystemTheme(): Mode {
  if (typeof window !== 'undefined' && typeof document !== 'undefined' && typeof localStorage !== 'undefined') {
    const mql = window.matchMedia('(prefers-color-scheme: dark)');
    const hasMediaQueryPreference = typeof mql.matches === 'boolean';
    if (hasMediaQueryPreference) {
      return mql.matches ? Mode.Dark : Mode.Light;
    }
    return Mode.Light;
  }
  return Mode.Light;
}

export function getCompactId(value: OptionalString) {
  return value?.slice(Math.max(0, value.length - 8)) ?? '';
}

export function getSupportedLocale() {
  const locale = document.documentElement.lang;
  return CLOUDSCAPE_AUDO_I18N_LANGUAGES.includes(locale) ? locale : 'en';
}

// in-source test suites
if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest;
  describe('getDurationFromNow', () => {
    it('should return formatted date', () => {
      const todayPlusOneYear = new Date();
      todayPlusOneYear.setFullYear(todayPlusOneYear.getFullYear() + 1);
      expect(getDurationFromNow(todayPlusOneYear)).toBe('1 yr, 0 mths, 0 days');
    });
  });
  describe('getSupportedLocale', () => {
    it('should return supported locale', () => {
      expect(getSupportedLocale()).toBe(
        CLOUDSCAPE_AUDO_I18N_LANGUAGES.includes(document.documentElement.lang) ? document.documentElement.lang : 'en'
      );
    });
  });
  describe('getCompactId', () => {
    it('should return compact id', () => {
      expect(getCompactId('123456789')).toBe('23456789');
      expect(getCompactId('123456789123456789')).toBe('23456789');
    });
  });

  describe('getTimezoneAbbreviation', () => {
    it('should not prepend "UTC" to abbreviation starting with other characters', () => {
      // Arrange
      const epochMillis = 1630876800000; // Replace with a valid epoch time
      const abbrWithoutPlusOrMinus = 'PST'; // Example input without "+" or "-"
      // Create a mock zone object with a mock offsetName function
      const mockZone: IANAZone = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        offsetName: (epoch: number) => {
          return abbrWithoutPlusOrMinus; // Mock the offsetName function to return 'PST'
        },
        isValid: true,
        type: '',
        name: '',
        isUniversal: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        formatOffset(ts: number, format: ZoneOffsetFormat): string {
          throw new Error('Function not implemented.');
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        offset(ts: number): number {
          throw new Error('Function not implemented.');
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        equals(other: Zone): boolean {
          throw new Error('Function not implemented.');
        },
      };
      const result = getTimezoneAbbreviation(epochMillis, mockZone);
      expect(result).toBe(abbrWithoutPlusOrMinus);
    });

    it('should prepend "UTC" to abbreviation starting with "+"', () => {
      const epochMillis = 1630876800000; // Replace with a valid epoch time
      const abbrWithPlus = '+04:00'; // Example input where abbr starts with "+"
      // Create a mock zone object with a mock offsetName function
      const mockZone: IANAZone = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        offsetName: (epoch: number) => {
          return abbrWithPlus; // Mock the offsetName function to return '+04:00'
        },
        isValid: true,
        type: '',
        name: '',
        isUniversal: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        formatOffset(ts: number, format: ZoneOffsetFormat): string {
          throw new Error('Function not implemented.');
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        offset(ts: number): number {
          throw new Error('Function not implemented.');
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        equals(other: Zone): boolean {
          throw new Error('Function not implemented.');
        },
      };
      const result = getTimezoneAbbreviation(epochMillis, mockZone);
      expect(result).toBe(`UTC${abbrWithPlus}`);
    });

    it('should prepend "UTC" to abbreviation starting with "-"', () => {
      const epochMillis = 1630876800000; // Replace with a valid epoch time
      const abbrWithMinus = '-05:00'; // Example input where abbr starts with "-"
      // Create a mock zone object with a mock offsetName function
      const mockZone: IANAZone = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        offsetName: (epoch: number) => {
          return abbrWithMinus; // Mock the offsetName function to return '-05:00'
        },
        isValid: true,
        type: '',
        name: '',
        isUniversal: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        formatOffset(ts: number, format: ZoneOffsetFormat): string {
          throw new Error('Function not implemented.');
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        offset(ts: number): number {
          throw new Error('Function not implemented.');
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        equals(other: Zone): boolean {
          throw new Error('Function not implemented.');
        },
      };
      const result = getTimezoneAbbreviation(epochMillis, mockZone);
      expect(result).toBe(`UTC${abbrWithMinus}`);
    });
  });

  describe('formatDateTime', () => {
    it('should return undefined when no date', () => {
      expect(formatDateTime(undefined)).toBeUndefined();
    });
  });

  // currently not working, need to adjust mock window.matchMedia
  // describe('GetSystemTheme', () => {
  //   it('should return Mode.Light when prefers-color-scheme is light', () => {
  //     const originalWindowMatchMedia = window.matchMedia;
  //     window.matchMedia = () => ({ matches: false });
  //     window.matchMedia = originalWindowMatchMedia;
  //     expect(getSystemTheme()).toBe(Mode.Light);
  //   });

  //   it('should return Mode.Light when prefers-color-scheme is light', () => {
  //     const originalWindowMatchMedia = window.matchMedia;
  //     window.matchMedia = () => ({ matches: true });
  //     window.matchMedia = originalWindowMatchMedia;
  //     expect(getSystemTheme()).toBe(Mode.Light);
  //   });

  //   it('should return Mode.Light when window, document, and localStorage are undefined', () => {
  //     // Mock the global objects as undefined
  //     const originalWindow = global.window;
  //     const originalDocument = global.document;
  //     const originalLocalStorage = global.localStorage;

  //     // Set the global objects to undefined
  //     global.window = undefined;
  //     global.document = undefined;
  //     global.localStorage = undefined;
  //   });
  // });

  describe('getFormattedAWSTimeStamp', () => {
    it('should return formatted timestamp', () => {
      const date = new Date();
      expect(getFormattedAWSTimestamp(date.getTime())).toBe(formatDateTimeWithZone(date));
    });
  });

  describe('getAWSDateAsQuarter', () => {
    it('should return a formatted quarter date when awsDate is truthy', () => {
      const awsDate = '2023-04-15T12:00:00Z'; // Replace with a valid AWS date
      const result = getAWSDateAsQuarter(awsDate);
      expect(result).toMatch(/^Q\d, \d{4}$/); // Match format like "1, 2023"
    });
    it('should return undefined when awsDate is falsy', () => {
      const awsDate = null; // Change this to any falsy value you want to test (e.g., undefined, 0, false)
      const result = getAWSDateAsQuarter(awsDate);
      expect(result).toBeUndefined();
    });
  });

  describe('getFormattedDate', () => {
    it('should return formatted date', () => {
      const date = new Date();
      expect(getFormattedDate(date)).toBe(formatDateTimeWithZone(date));
    });
    it('should return undefined when awsTimestamp is falsy', () => {
      const awsTimestamp = null; // Change this to any falsy value you want to test (e.g., undefined, 0, false)
      const result = getFormattedAWSTimestamp(awsTimestamp);
      expect(result).toBeUndefined();
    });
  });

  describe('getDateFromAPIValueUTC', () => {
    it('should return undefined when the input value is falsy', () => {
      const inputValues = [null, undefined, ''];
      inputValues.forEach((inputValue) => {
        const result = getDateFromAPIValueUTC(inputValue);
        expect(result).toBeUndefined();
      });
    });

    it('should return a Date object when a valid AWSDateTime is provided', () => {
      const inputAWSDateTime = '2023-09-15T12:00:00Z'; // Replace with a valid AWSDateTime
      const result = getDateFromAPIValueUTC(inputAWSDateTime);
      expect(result).not.toBeNull();
      expect(result).not.toBeUndefined();
      expect(result instanceof Date).toBe(true);
      if (result) expect(result.toISOString()).toBe('2023-09-15T12:00:00.000Z');
    });
    it('should return a Date object for a valid AWSTimestamp in UTC', () => {
      const awsTimestamp = 1630913400000; // Replace with a valid AWSTimestamp in UTC
      const result = getDateFromAPIValueUTC(awsTimestamp);
      expect(result).not.toBeNull();
      expect(result).not.toBeUndefined();
      expect(result).toBeInstanceOf(Date);
      if (result) expect(result.getTime()).toBe(awsTimestamp);
    });
  });

  describe('formatDateTimeWithZone', () => {
    it('should return formatted date', () => {
      const date = new Date();
      expect(formatDateTimeWithZone(date)).toBe(formatDateTimeWithZone(date));
    });
    it('should return undefined when the input value is falsy', () => {
      const inputValues = [null, undefined, ''];
      inputValues.forEach((inputValue) => {
        const result = formatDateTimeWithZone(inputValue);
        expect(result).toBeUndefined();
      });
    });
  });
}
