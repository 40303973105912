import { ForwardedRef, forwardRef, memo } from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { useTableContext } from '@/contexts/CrossTableContext';
import { useManagerPromoPath, useManagerPromoPathActions } from '@/api/promo-path';
import PromoSpinner from '../common/PromoSpinner';

interface DeletePromoPathModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  contextKey: string;
}

const DeletePromoPathComponent = (
  { contextKey, isVisible, onSetVisibility }: DeletePromoPathModalParams,
  ref: ForwardedRef<HTMLElement>
) => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { selectedId } = useTableContext(contextKey);
  const { promoPath, isPromoPathLoading } = useManagerPromoPath(selectedId);
  const { actions, isMutating } = useManagerPromoPathActions(selectedId);

  function resetModal() {
    onSetVisibility(false);
  }

  const deletePromoPath = async () => {
    if (!promoPath) {
      return;
    }
    try {
      await actions.delete();
    } finally {
      resetModal();
    }
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModal()}
      closeAriaLabel="Close dialog"
      visible={isVisible}
      size="medium"
      header="Delete promo path"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isMutating} onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button variant="primary" loading={isMutating} disabled={isMutating} onClick={() => deletePromoPath()}>
              {isMutating ? 'Deleting...' : 'Delete'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {isPromoPathLoading ? (
        <PromoSpinner variant="inline" size="big" />
      ) : (
        <SpaceBetween direction="vertical" size="m">
          <Box variant="span">
            Delete promo path{` `}
            <Box variant="span" fontWeight="bold">
              {promoPath?.name}
            </Box>
            {` `}
            permanently? This action cannot be undone.
          </Box>
          <Alert statusIconAriaLabel="Info">
            Proceeding with this action will delete the promo path and associated content.{' '}
          </Alert>
        </SpaceBetween>
      )}
    </Modal>
  );
};

const DeletePromoPathModal = memo(forwardRef(DeletePromoPathComponent));

export default DeletePromoPathModal;
