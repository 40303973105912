import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Form,
  FormField,
  Input,
  Select,
  DatePicker,
  TimeInput,
  Multiselect,
  SpaceBetween,
  Box,
} from '@amzn/awsui-components-react/polaris';

const completedDocReviewSessions = [
  { label: 'Alice Johnson - Session ID: 001', value: '001' },
  { label: 'Bob Smith - Session ID: 002', value: '002' },
  { label: 'Charlie Brown - Session ID: 003', value: '003' },
];

const reviewerOptions = [
  { label: 'David Lee', value: 'david', isSkipLevel: false },
  { label: 'Emma Watson', value: 'emma', isSkipLevel: true },
  { label: 'Frank Miller', value: 'frank', isSkipLevel: false },
  { label: 'Grace Taylor', value: 'grace', isSkipLevel: true },
];

const timezoneOptions = [
  { label: 'UTC', value: 'UTC' },
  { label: 'UTC-5 (EST)', value: 'UTC-5' },
  { label: 'UTC+1 (CET)', value: 'UTC+1' },
  { label: 'UTC+8 (CST)', value: 'UTC+8' },
];

export const enum SessionModalMode {
  CREATE = 'Create',
  EDIT = 'Edit',
}

const PanelReviewSessionsModal = ({ sessionData, sessionModalMode, isOpen, onClose, onCreate }) => {
  const [createSessionData, setCreateSessionData] = useState<any>({
    completedDocReviewSession: null,
    candidate: null,
    reviewers: [],
    skipLevelReviewer: null,
    workdocsLink: '',
    date: '',
    time: '',
    timezone: null,
    chimeLink: '',
  });

  const [formErrors, setFormErrors] = useState<any>({});
  const [minReviewers, setMinReviewers] = useState(2);
  const [maxReviewers, setMaxReviewers] = useState(4);

  useEffect(() => {
    if (sessionData) {
      setCreateSessionData(sessionData);
    }
  }, [sessionData]);

  const handleCreateSessionChange = (key, value) => {
    setCreateSessionData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [key]: undefined,
    }));
  };

  const validateForm = () => {
    const errors: any = {};
    if (!createSessionData.completedDocReviewSession)
      errors.completedDocReviewSession = 'Completed doc review session is required';
    if (!createSessionData.candidate) errors.candidate = 'Candidate is required';
    if (createSessionData.reviewers.length < minReviewers)
      errors.reviewers = `At least ${minReviewers} reviewers are required`;
    if (createSessionData.reviewers.length > maxReviewers)
      errors.reviewers = `Maximum ${maxReviewers} reviewers are allowed`;
    if (!createSessionData.skipLevelReviewer) errors.skipLevelReviewer = 'A skip level reviewer is required';
    if (!createSessionData.workdocsLink) errors.workdocsLink = 'Workdocs link is required';
    if (!createSessionData.date) errors.date = 'Date is required';
    if (!createSessionData.time) errors.time = 'Time is required';
    if (!createSessionData.timezone) errors.timezone = 'Timezone is required';
    return errors;
  };

  const handleCreateSessionSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      onCreate(createSessionData);
      setCreateSessionData({
        completedDocReviewSession: null,
        candidate: null,
        reviewers: [],
        skipLevelReviewer: null,
        workdocsLink: '',
        date: '',
        time: '',
        timezone: null,
        chimeLink: '',
      });
      setFormErrors({});
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <Modal
      visible={isOpen}
      onDismiss={() => onClose()}
      header={`${sessionModalMode} Panel Review Session`}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCreateSessionSubmit}>
              {sessionModalMode} Session
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Form>
        <SpaceBetween direction="vertical" size="xs">
          <FormField label="Completed Doc Review Session*" errorText={formErrors.completedDocReviewSession}>
            <Select
              placeholder="Select Completed Doc Review Session"
              selectedOption={createSessionData.completedDocReviewSession}
              onChange={({ detail }) => handleCreateSessionChange('completedDocReviewSession', detail.selectedOption)}
              options={completedDocReviewSessions}
            />
          </FormField>
          <FormField label="Candidate*" errorText={formErrors.candidate}>
            <Input
              placeholder="Candidate (Direct Report)"
              value={createSessionData.candidate}
              onChange={({ detail }) => handleCreateSessionChange('candidate', detail.value)}
            />
          </FormField>
          <FormField
            label="Calibrated Panel Reviewers*"
            errorText={formErrors.reviewers}
            description={`Select ${minReviewers}-${maxReviewers} reviewers`}
          >
            <Multiselect
              placeholder="Select Reviewers*"
              selectedOptions={createSessionData.reviewers}
              onChange={({ detail }) => handleCreateSessionChange('reviewers', detail.selectedOptions)}
              options={reviewerOptions}
            />
          </FormField>
          <FormField label="Skip Level Reviewer*" errorText={formErrors.skipLevelReviewer}>
            <Select
              placeholder="Select Skip Level Reviewer"
              selectedOption={createSessionData.skipLevelReviewer}
              onChange={({ detail }) => handleCreateSessionChange('skipLevelReviewer', detail.selectedOption)}
              options={reviewerOptions.filter((reviewer) => reviewer.isSkipLevel)}
            />
          </FormField>
          <FormField label="Workdocs link to doc artifact*" errorText={formErrors.workdocsLink}>
            <Input
              placeholder="Input Workdocs link*"
              value={createSessionData.workdocsLink}
              onChange={({ detail }) => handleCreateSessionChange('workdocsLink', detail.value)}
            />
          </FormField>
          <FormField label="Date and time*" errorText={formErrors.date || formErrors.time || formErrors.timezone}>
            <SpaceBetween direction="horizontal" size="xs">
              <DatePicker
                placeholder="Select Date"
                value={createSessionData.date}
                onChange={({ detail }) => handleCreateSessionChange('date', detail.value)}
              />
              <TimeInput
                placeholder="Select Time"
                value={createSessionData.time}
                onChange={({ detail }) => handleCreateSessionChange('time', detail.value)}
              />
              <Select
                placeholder="Select Time Zone"
                selectedOption={createSessionData.timezone}
                onChange={({ detail }) => handleCreateSessionChange('timezone', detail.selectedOption)}
                options={timezoneOptions}
              />
            </SpaceBetween>
          </FormField>
          <FormField label="Chime link (optional)">
            <Input
              placeholder="Input Chime link"
              value={createSessionData.chimeLink}
              onChange={({ detail }) => handleCreateSessionChange('chimeLink', detail.value)}
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </Modal>
  );
};

export default PanelReviewSessionsModal;
