import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import FormField from '@amzn/awsui-components-react/polaris/form-field';

const SettingsPanelFooter = ({ value, onChangeValue }) => (
  <ExpandableSection header="Additional settings" variant="footer">
    <FormField label="Supporting documents">
      <Checkbox checked={value} onChange={({ detail }) => onChangeValue(detail.checked)}>
        Employees can attach documents to work summaries
      </Checkbox>
    </FormField>
  </ExpandableSection>
);

export default SettingsPanelFooter;
