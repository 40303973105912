import Box from '@amzn/awsui-components-react/polaris/box';
import { CardsProps } from '@amzn/awsui-components-react/polaris/cards';
import Link from '@amzn/awsui-components-react/polaris/link';
import MarkdownContent from '@/common/MarkdownContent';
import { AuditTrailItem } from '@/models';
import { PHONETOOL_URL } from '@/common/constants';
import { getFormattedDate } from '@/common/utils';
import UserPhoto from '@/components/common/person/UserPhoto';

// eslint-disable-next-line import/prefer-default-export
export const AuditTrailsCardDefinition = (): CardsProps.CardDefinition<AuditTrailItem> => {
  const ProfilePicture = ({ profileAlias }) => (
    <Box float="left" display="inline">
      <UserPhoto alias={profileAlias} imageSize="s" />
    </Box>
  );

  const Author = ({ profileAlias, profileName }) => (
    <Link fontSize="heading-xs" target="_blank" href={`${PHONETOOL_URL}${profileAlias}`}>
      {`${profileName} (${profileAlias})`}
    </Link>
  );

  /**
   * Set the timestamp based on the createdAt.
   * If the comment was edited, append that date to the right in a different font.
   * Amplify automatically sets the createdAt and updatedAt to the same value on creation,
   * so we can safely assume if they differ, the comment was changed.
   */
  const AuditTrailsTimestamp = ({ createdAt, updatedAt }) => (
    <Box fontSize="body-s" variant="awsui-key-label">
      {getFormattedDate(createdAt)}
      {updatedAt && updatedAt !== createdAt && (
        <Box variant="span" fontSize="body-s" color="text-body-secondary">
          {` (edited: ${getFormattedDate(updatedAt)})`}
        </Box>
      )}
    </Box>
  );

  return {
    header: (item) => (
      <Box variant="div">
        <ProfilePicture profileAlias={item.modifiedByAlias} />
        <Author profileAlias={item.modifiedByAlias} profileName={item.modifiedByAlias} />
        <AuditTrailsTimestamp createdAt={item.createdAt} updatedAt={item.updatedAt} />
      </Box>
    ),
    sections: [
      {
        id: 'auditrail-content',
        content: (item) => <MarkdownContent>{item.humanreadablelog.toString() ?? ''}</MarkdownContent>,
      },
    ],
  };
};
