import type { ResourcesConfig } from 'aws-amplify';
import { parseAmplifyConfig } from 'aws-amplify/utils';

import amplifyConfig from '@/amplifyconfiguration.json';

type TConfig = Partial<typeof amplifyConfig>;
type TOAuth = Omit<NonNullable<NonNullable<NonNullable<ResourcesConfig['Auth']>['Cognito']['loginWith']>['oauth']>, 'domain'>;

const oauthParams = {
  responseType: 'code',
  scopes: ['aws.cognito.signin.user.admin', 'openid', 'email', 'profile'],
  redirectSignIn: ['http://localhost:3000/'],
  redirectSignOut: ['http://localhost:3000/'],
} satisfies TOAuth;

switch (import.meta.env.REACT_APP_BUILD_ENV) {
  case 'prod':
    oauthParams.redirectSignIn = ['https://promohub.support.aws.dev/'];
    oauthParams.redirectSignOut = ['https://promohub.support.aws.dev/'];
    break;
  case 'staging':
    oauthParams.redirectSignIn = ['https://staging.promohub.support.aws.dev/'];
    oauthParams.redirectSignOut = ['https://staging.promohub.support.aws.dev/'];
    break;
  case 'beta':
    oauthParams.redirectSignIn = ['https://beta.promohub.support.aws.dev/'];
    oauthParams.redirectSignOut = ['https://beta.promohub.support.aws.dev/'];
    break;
  case 'dev':
    oauthParams.redirectSignIn = ['https://dev.promohub.support.aws.dev/'];
    oauthParams.redirectSignOut = ['https://dev.promohub.support.aws.dev/'];
    break;
  default:
    break;
}

export function getResourceConfig(config: TConfig = amplifyConfig): ResourcesConfig {
  const resourcesConfig = parseAmplifyConfig(config);

  if (!resourcesConfig.Auth?.Cognito) return resourcesConfig;
  // Prevent unauth access and disable IAM credential retrieval
  resourcesConfig.Auth.Cognito.identityPoolId = undefined;
  resourcesConfig.Auth.Cognito.allowGuestAccess = false;
  if (!resourcesConfig.Auth.Cognito.loginWith?.oauth) return resourcesConfig;
  resourcesConfig.Auth.Cognito.loginWith.oauth = {
    ...resourcesConfig.Auth.Cognito.loginWith?.oauth,
    ...oauthParams,
    domain:
      resourcesConfig.Auth.Cognito.loginWith?.oauth?.domain ??
      `promohub-${import.meta.env.REACT_APP_BUILD_ENV || 'dev'}.auth.us-west-2.amazoncognito.com`,
  };
  return resourcesConfig;
}

// in-source test suites
if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest;
  describe('populateAuthParams', () => {
    it('should populate the oauth parameters with defaults and provided oauthParams', () => {
      const baseAwsExports = {
        aws_project_region: 'us-west-2',
        aws_user_pools_id: 'us-west-2_pYRSyIvL8',
        oauth: {
          clientId: 'client-id',
          domain: 'custom-domain',
        },
      };

      const result = getResourceConfig(baseAwsExports);

      expect(result.Auth?.Cognito.loginWith).toEqual({
        email: false,
        oauth: {
          domain: 'custom-domain',
          redirectSignIn: ['http://localhost:3000/'],
          redirectSignOut: ['http://localhost:3000/'],
          responseType: 'code',
          scopes: ['aws.cognito.signin.user.admin', 'openid', 'email', 'profile'],
        },
        phone: false,
        username: true,
      });
    });
  });
}
