import { useState } from 'react';
import _ from 'lodash';
import Container from '@amzn/awsui-components-react/polaris/container';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import Input from '@amzn/awsui-components-react/polaris/input';
import Link from '@amzn/awsui-components-react/polaris/link';
import MultiSelect from '@amzn/awsui-components-react/polaris/multiselect';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import { InfoLink } from '@/components/common/links';
import { TextFieldParams, CategoryItem, MultiSelectionFieldParams, ToolsContentSetter, BaselineRoleDimensionOptionItem, OptionalString } from '@/models';
import { MAX_CHAR_TITLE, MAX_CHAR_WORKSUMMARY } from '@/common/constants';
import FileSelector from './FileSelector';

type CategoryFieldParams = MultiSelectionFieldParams & { options: CategoryItem[] };

type BaselineRoleDimensionFieldParams = MultiSelectionFieldParams & { options: BaselineRoleDimensionOptionItem[] };

const getRemainingWordCount = (currentText: string, maxWords: number) =>
  maxWords - (_.words(currentText)?.filter((word) => word !== '')?.length || 0);

const getRemainingCharCount = (currentText: string, maxChar: number) => maxChar - (currentText?.length || 0);

const CategoryField = ({ selections, options, onChangeValue, onShowTools }: CategoryFieldParams) => {
  const allOptions: SelectProps.Option[] = options.map((opt) => ({
    value: opt.id,
    label: opt.label,
  }));
  return (
    <FormField
      stretch
      label="Category"
      info={
        <InfoLink
          onShowTools={() => onShowTools && onShowTools({ sectionKey: 'workSummaryCategory' })}
          ariaLabel="Information category options."
        />
      }
    >
      <MultiSelect
        disabled={!allOptions.length}
        ariaLabel="Category selection"
        expandToViewport
        filteringType="none"
        options={allOptions}
        placeholder={allOptions.length ? 'Choose categories' : 'No categories available'}
        selectedOptions={allOptions.filter((opt) => opt.value && selections.includes(opt.value))}
        statusType="finished"
        onChange={({ detail }) => onChangeValue(detail.selectedOptions.map((opt) => opt.value as string))}
      />
    </FormField>
  );
};

const BaselineRoleDimensionField = ({ selections, options, onChangeValue, onShowTools }: BaselineRoleDimensionFieldParams) => {
  const allOptions: SelectProps.Option[] = options.map((opt) => ({
    value: opt.id,
    label: opt.label 
  }));
  return (
    <FormField
      stretch
      label="Role Dimension"
      info={
        <InfoLink
          onShowTools={() => onShowTools && onShowTools({ sectionKey: 'workSummaryDimension' })}
          ariaLabel="Information category options."
        />
      }
    >
      <MultiSelect
        disabled={!allOptions.length}
        ariaLabel="Role Dimension selection"
        expandToViewport
        filteringType="none"
        options={allOptions}
        placeholder={allOptions.length ? 'Choose role dimension' : 'No role dimensions'}
        selectedOptions={allOptions.filter((opt) => opt.value && selections.includes(opt.value))}
        statusType="finished"
        onChange={({ detail }) => onChangeValue(detail.selectedOptions.map((opt) => opt.value as string))}
      />
    </FormField>
  );
};


const Content = ({ value, onChangeValue, onShowTools, validator }: TextFieldParams) => {
  const [validationText, setValidationText] = useState<string>();
  return (
    <FormField
      stretch
      errorText={validationText || ''}
      label="Details"
      info={
        <InfoLink onShowTools={() => onShowTools && onShowTools({})} ariaLabel="Information on work summary details." />
      }
      constraintText={`${getRemainingWordCount(value as string, MAX_CHAR_WORKSUMMARY)} characters remaining.`}
    >
      <Textarea
        ariaRequired
        onBlur={() => setValidationText(validator?.(value))}
        value={value as string}
        rows={6}
        onChange={({ detail }) => {
          if (validationText) {
            setValidationText('');
          }
          onChangeValue(detail.value);
        }}
      />
    </FormField>
  );
};

const TitleField = ({ value, onChangeValue, validator }: TextFieldParams) => {
  const [validationText, setValidationText] = useState<string>();
  return (
    <FormField
      info={
        <Popover
          dismissAriaLabel="close"
          dismissButton
          header="Allowed Characters"
          position="right"
          size="small"
          triggerType="custom"
          content="Lettters, numbers, spaces, and special characters: ,-.|"
        >
          <Link variant="info">Info</Link>
        </Popover>
      }
      errorText={validationText || ''}
      label="Title"
      description="Enter a concise and informative title."
      constraintText={`${getRemainingCharCount(value as string, MAX_CHAR_TITLE)} characters remaining.`}
    >
      <Input
        ariaRequired
        inputMode="text"
        onBlur={() => setValidationText(validator?.(value))}
        placeholder="Successful Black Friday IEM for customer"
        value={value}
        onChange={({ detail }) => {
          if (validationText) {
            setValidationText('');
          }
          onChangeValue(detail.value);
        }}
      />
    </FormField>
  );
};

interface DetailsPanelParams {
  categoryParams: CategoryFieldParams;
  contentParams: TextFieldParams;
  baselineRoleDimensionFieldParams: BaselineRoleDimensionFieldParams;
  fileSelectorParams: MultiSelectionFieldParams & { candidateAlias: OptionalString };
  titleParams: TextFieldParams;
  onShowTools?: ToolsContentSetter;
}

const DetailsPanel = ({
  categoryParams,
  contentParams,
  baselineRoleDimensionFieldParams,
  fileSelectorParams,
  titleParams,
  onShowTools,
}: DetailsPanelParams) => (
  <Container header={<Header variant="h2">Work summary details</Header>}>
    <SpaceBetween size="l">
      <TitleField {...titleParams} />
      <CategoryField {...categoryParams} onShowTools={onShowTools} />
      <BaselineRoleDimensionField {...baselineRoleDimensionFieldParams} onShowTools={onShowTools} />
      <Content {...contentParams} onShowTools={onShowTools} />
      <FileSelector
        selections={fileSelectorParams.selections}
        onChangeValue={fileSelectorParams.onChangeValue}
        candidateAlias={fileSelectorParams.candidateAlias}
      />
    </SpaceBetween>
  </Container>
);

export default DetailsPanel;
