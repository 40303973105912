import { CollectionPreferencesProps } from '@amzn/awsui-components-react';
import { noop } from 'lodash';
import React, { useContext, useMemo, useCallback } from 'react';
import { CrossTableContext } from './models';
import { OptionalNumber, OptionalString } from '@/models';
import useMap from '@/common/hooks/use-map';
import { useAppContext } from './AppContext';

const Context = React.createContext<CrossTableContext>({
  tableSelection: new Map(),
  setTableSelection: noop,
  tableActivePage: new Map(),
  setTableActivePage: noop,
});

// Context provider use with tables.
// Allows tracking and updating state of various tables on the page.
export const CrossTableContextProvider = ({ children }) => {
  const [tableSelection, tableSelectionActions] = useMap<string, OptionalString>();
  const [tableActivePage, tableActivePageActions] = useMap<string, number>();

  const setTableSelection = useCallback(
    (tableKey: string, selectedId: OptionalString) => tableSelectionActions.set(tableKey, selectedId),
    [tableSelectionActions]
  );

  const setTableActivePage = useCallback(
    (tableKey: string, pageNumber: OptionalNumber) => tableActivePageActions.set(tableKey, pageNumber ?? 1),
    [tableActivePageActions]
  );

  const context: CrossTableContext = useMemo(
    () => ({
      tableSelection,
      setTableSelection,
      tableActivePage,
      setTableActivePage,
    }),
    [tableSelection, setTableSelection, tableActivePage, setTableActivePage]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export function useTableContext(tableKey: string) {
  const { tablePreferences, setTablePreferences } = useAppContext();
  const { tableSelection, setTableSelection, tableActivePage, setTableActivePage } = useContext(Context);

  const preferences = useMemo(() => tablePreferences.get(tableKey), [tablePreferences, tableKey]);
  const selectedId = useMemo(() => tableSelection.get(tableKey), [tableSelection, tableKey]);
  const pageNumber = useMemo(() => tableActivePage.get(tableKey), [tableActivePage, tableKey]);

  const setSelectedId = useCallback(
    (id: OptionalString) => {
      setTableSelection(tableKey, id);
    },
    [tableKey, setTableSelection]
  );

  const setPageNumber = useCallback(
    (page: OptionalNumber) => {
      setTableActivePage(tableKey, page);
    },
    [tableKey, setTableActivePage]
  );

  const setPreferences = useCallback(
    (prefs: CollectionPreferencesProps.Preferences) => {
      setTablePreferences(tableKey, prefs);
    },
    [tableKey, setTablePreferences]
  );

  return {
    pageNumber,
    setPageNumber,
    selectedId,
    setSelectedId,
    preferences,
    setPreferences,
  };
}

/** custom hook to provide easy access/use of the context */
export function useCrossTableContext() {
  return useContext(Context);
}
