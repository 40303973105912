import { useRef, useState } from 'react';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import { useAppContext } from './contexts/AppContext';
import { SUPPORT_ITEMS_URLS } from './common/constants';
import EditPreferencesModal from './components/UserProfile/EditPreferencesModal';
import { Density, VisualMode } from './api/API';
import useNavigator from './common/hooks/use-navigator';

const PromoHubTopNav = () => {
  const [isProfileModalVisible, setisProfileModalVisible] = useState(false);
  const { currentUser, visualModePreference, densityPreference } = useAppContext();
  const envName = import.meta.env.REACT_APP_BUILD_ENV || 'local';
  const { followToRoute } = useNavigator();
  const ref = useRef<HTMLElement>(null);

  return (
    <>
      <EditPreferencesModal
        alias={currentUser?.alias ?? ''}
        isVisible={isProfileModalVisible}
        onSetVisibility={setisProfileModalVisible}
        currentVisualMode={visualModePreference || VisualMode.AUTO}
        currentDensity={densityPreference || Density.COMFORTABLE}
        ref={ref}
      />
      <TopNavigation
        id="promo-top-nav"
        identity={{
          href: '/',
          title: envName === 'prod' ? 'PromoHub' : `PromoHub (${envName})`,
          onFollow: (event) => followToRoute({ ...event, detail: { href: '/' } }),
        }}
        utilities={[
          {
            type: 'button',
            text: 'Wiki',
            href: SUPPORT_ITEMS_URLS.promoHubWiki,
            external: true,
            externalIconAriaLabel: '(opens in a new tab)',
          },
          {
            type: 'button',
            iconName: 'notification',
            title: 'Notifications',
            ariaLabel: 'Notifications (unread)',
            badge: false,
            disableUtilityCollapse: false,
          },
          {
            type: 'menu-dropdown',
            text: currentUser?.firstName || '',
            iconName: 'user-profile',
            onItemClick: ({ detail }) => {
              if (detail.id === 'preferences') {
                setisProfileModalVisible(true);
              }
            },
            items: [
              { id: 'preferences', text: 'Preferences' },
              {
                id: 'support-group',
                text: 'Support',
                items: [
                  {
                    id: 'documentation',
                    text: 'Documentation',
                    href: SUPPORT_ITEMS_URLS.promoHubWiki,
                    external: true,
                    externalIconAriaLabel: '(opens in new tab)',
                  },
                  {
                    id: 'slack',
                    text: '#aws-promohub-interest',
                    href: SUPPORT_ITEMS_URLS.slackChannel,
                    external: true,
                    externalIconAriaLabel: '(opens in new tab)',
                  },
                ],
              },
              {
                id: 'ticket-group',
                text: 'Submit ticket',
                items: [
                  {
                    id: 'bug',
                    text: 'Bug',
                    href: SUPPORT_ITEMS_URLS.submitBug,
                    external: true,
                    externalIconAriaLabel: ' (opens in new tab)',
                  },
                  {
                    id: 'featurerequest',
                    text: 'Feature request',
                    href: SUPPORT_ITEMS_URLS.featureRequest,
                    external: true,
                    externalIconAriaLabel: ' (opens in new tab)',
                  },
                ],
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default PromoHubTopNav;
