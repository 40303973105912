import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import Input from '@amzn/awsui-components-react/polaris/input';
import Select from '@amzn/awsui-components-react/polaris/select';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { MAX_CHAR_NAME } from '@/common/constants';
import { SelectableOption, TextFieldParams, NumberFieldParams } from '@/components/common/models';
import SettingsPanelFooter from './SettingsPanelFooter';

const getRemainingCharCount = (currentText: string, maxChar: number) => maxChar - (currentText?.length ?? 0);

const JobLevelField = ({
  value,
  onChangeValue,
  options,
  label,
}: JobLevelFieldParams & { label: string }): JSX.Element => (
  <FormField label={label}>
    <Select
      selectedOption={value}
      options={options}
      onChange={({ detail }) => onChangeValue(detail.selectedOption as SelectableOption)}
      ariaRequired
    />
  </FormField>
);

const RequiredCountField = ({ value, onChangeValue, valueError }: NumberFieldParams): JSX.Element => (
  <FormField
    label="Required work summaries"
    errorText={valueError ?? ''}
    description="Specify the required summary count."
    constraintText="Number greater than 0."
  >
    <Input
      ariaRequired
      value={value?.toString() ?? ''}
      placeholder="Enter required count"
      type="number"
      inputMode="numeric"
      step={1}
      onChange={({ detail }) => {
        if (/^(\s*|[1-9][0-9]*)$/.test(detail.value)) {
          onChangeValue(parseInt(detail.value, 10));
        }
      }}
    />
  </FormField>
);

const NameField = ({ value, onChangeValue, valueError }: TextFieldParams): JSX.Element => (
  <FormField
    label="Name"
    errorText={valueError || ''}
    description="Provide a concise name for the PromoPath."
    constraintText={`${getRemainingCharCount(value, MAX_CHAR_NAME)} characters remaining.`}
  >
    <Input
      value={value}
      placeholder="Enter name"
      type="text"
      inputMode="text"
      onChange={({ detail }) => onChangeValue(detail.value)}
      ariaRequired
    />
  </FormField>
);

interface JobLevelFieldParams {
  value: SelectableOption;
  options: SelectableOption[];
  onChangeValue: (v: SelectableOption) => void;
}

interface CheckboxFieldParams {
  value: boolean;
  onChangeValue: (v: boolean) => void;
}

interface SettingsPanelParams {
  nameParams: TextFieldParams;
  requiredCountParams: NumberFieldParams;
  baseLevelParams: JobLevelFieldParams;
  targetLevelParams: JobLevelFieldParams;
  uploadsParams: CheckboxFieldParams;
}

const SettingsPanel = ({
  nameParams,
  requiredCountParams,
  baseLevelParams,
  targetLevelParams,
  uploadsParams,
}: SettingsPanelParams): JSX.Element => (
  <Container header={<Header>Promo path settings</Header>} footer={<SettingsPanelFooter {...uploadsParams} />}>
    <SpaceBetween direction="vertical" size="l">
      <FormField>
        <ColumnLayout columns={2}>
          <NameField {...nameParams} />
          <RequiredCountField {...requiredCountParams} />
        </ColumnLayout>
      </FormField>
      <FormField label="Leveling" description="Set the base and target job levels.">
        <ColumnLayout columns={2}>
          <JobLevelField {...baseLevelParams} label="Base" />
          <JobLevelField {...targetLevelParams} label="Target" />
        </ColumnLayout>
      </FormField>
    </SpaceBetween>
  </Container>
);

export default SettingsPanel;
