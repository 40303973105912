import React, { useEffect, useState, memo, forwardRef, ForwardedRef } from 'react';
import { capitalize } from 'lodash';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { CommentItem } from '@/models';
import PromoTextEditor from '@/components/common/PromoTextEditor';
import { checkContent, checkContentChanged } from './comment-validator';
import { CommentStatus, CommentType } from '@/api/API';
import { useCommentActions } from '@/api/comment';
import { useAppContext } from '@/contexts';

interface CreateEditCommentModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  mode: 'create' | 'edit';
  comment: CommentItem | undefined;
  workSummaryId?: string | null | undefined;
  commentType: CommentType;
}

const CreateEditCommentComponent = (
  { workSummaryId, commentType, mode, isVisible, onSetVisibility, comment }: CreateEditCommentModalParams,
  ref: ForwardedRef<HTMLElement>
): JSX.Element => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const [commentText, setCommentText] = useState<string>(comment?.content || '');
  const [validationErrors, setValidationErrors] = useState({ commentText: '' });
  const { actions, isMutating } = useCommentActions(comment?.id);
  const { currentUser, spoofUser } = useAppContext();

  useEffect(() => {
    if (comment?.content && mode === 'edit' && isVisible) {
      setCommentText(comment.content);
    }
  }, [comment?.content, mode, isVisible]);

  // Benign reset that just hides the modal and resets errors
  // Use for the dismiss action as it's likely to be triggered accidentally
  // and we don't want to clear the text in that case.
  function resetModalState() {
    onSetVisibility(false);
    setValidationErrors({ commentText: '' });
  }

  // Reset state and clear text
  function resetModal() {
    resetModalState();
    setCommentText('');
  }

  const saveCommentItem = async () => {
    try {
      if (comment?.id && mode === 'edit') {
        await actions.update({
          workSummaryId,
          content: commentText,
        });
      } else {
        await actions.create({
          workSummaryId,
          alias: spoofUser?.alias ?? currentUser?.alias ?? '',
          content: commentText,
          type: commentType,
          status: CommentStatus.ACTIVE,
        });
      }
    } finally {
      resetModal();
    }
  };

  const onSave = () => {
    let contentError = checkContent(commentText);
    if (!contentError && mode === 'edit') {
      contentError = checkContentChanged(commentText, comment?.content);
    }
    if (contentError) {
      setValidationErrors({ commentText: contentError });
      return;
    }
    setValidationErrors({ commentText: '' });
    saveCommentItem();
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModalState()}
      closeAriaLabel="Close modal"
      visible={isVisible}
      size="medium"
      header={`${capitalize(mode)} comment`}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isMutating} onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button variant="primary" loading={isMutating} disabled={isMutating} onClick={() => onSave()}>
              {isMutating ? 'Saving...' : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <PromoTextEditor
        textValue={commentText}
        setTextValue={setCommentText}
        label={`${mode === 'create' ? 'Add' : 'Change'} comment`}
        validationError={validationErrors.commentText}
      />
    </Modal>
  );
};

const CreateEditCommentModal = memo(forwardRef(CreateEditCommentComponent));

export default CreateEditCommentModal;
