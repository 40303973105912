import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { capitalize } from 'lodash';
import { DEFAULT_FEEDBACKS_PER_PAGE, RESOURCES } from '@/common/constants';
import {
  getPageSizes,
  getFilteredColumns,
  getTableDefinition,
  getPreferences,
} from '@/components/common/table/table-config-helper';
import { ColumnDefinition, PropertyDefinition, PromoTableDefinition, PromoTableConfig, PeerReviewItem } from '@/models';
import { getFormattedDate } from '@/common/utils';
import { DatePropertyFilter, StringPropertyFilter } from '@/components/common/table/table-filters';
import { RoutableLink } from '../common/links';

/** Identifier for this table. Also used as the key for tracking context. */
export const TABLE_KEY = 'Peer reviews';

const StringKeys: (keyof PeerReviewItem)[] = ['reviewType', 'reviewStatus', 'candidateAlias', 'candidateManagerAlias'];
const DateKeys: (keyof PeerReviewItem)[] = ['updatedAt', 'createdAt', 'requiredBy'];

// const sortCategories = (x: CategoryItem, y: CategoryItem) => (x.label > y.label ? 1 : -1);

const properties: { [Property in keyof Partial<PeerReviewItem>]: PropertyDefinition } = {
  workSummaryTitle: {
    key: 'workSummaryTitle',
    label: 'Work summary',
  },
  candidateAlias: {
    key: 'candidateAlias',
    label: 'Candidate alias',
  },
  candidateManagerAlias: {
    key: 'candidateManagerAlias',
    label: 'Manager alias',
  },
  reviewStatus: {
    key: 'reviewStatus',
    label: 'Status',
  },
  reviewType: {
    key: 'reviewType',
    label: 'Type',
  },
  createdAt: {
    key: 'createdAt',
    label: 'Requested date',
  },
  requiredBy: {
    key: 'requiredBy',
    label: 'Required date',
  },
};

const STATIC_COLUMN_DEFINITIONS: ColumnDefinition<PeerReviewItem>[] = [
  {
    id: properties.workSummaryTitle?.key as string,
    header: properties.workSummaryTitle?.label as string,
    cell: (e) =>
      e.workSummaryTitle ? (
        <RoutableLink href={`${RESOURCES.PEER_REVIEW.hrefToEdit}/${e.id}`} text={e.workSummaryTitle} />
      ) : (
        '-'
      ),
    minWidth: 160,
    sortingField: properties.workSummaryTitle?.key as string,
  },
  {
    id: properties.candidateAlias?.key as string,
    header: properties.candidateAlias?.label as string,
    cell: (e) => e.candidateAlias,
    minWidth: 160,
    sortingField: properties.candidateAlias?.key as string,
  },
  {
    id: properties.candidateManagerAlias?.key as string,
    header: properties.candidateManagerAlias?.label as string,
    cell: (e) => e.candidateManagerAlias,
    minWidth: 160,
    sortingField: properties.candidateManagerAlias?.key as string,
  },
  {
    id: properties.reviewStatus?.key as string,
    header: properties.reviewStatus?.label as string,
    cell: (e) => capitalize(e.reviewStatus.replace('_', ' ')),
    minWidth: 160,
    sortingField: properties.reviewStatus?.key as string,
  },
  {
    id: properties.reviewType?.key as string,
    header: properties.reviewType?.label as string,
    cell: (e) => capitalize(e.reviewType?.replace('_', ' ')),
    minWidth: 200,
    sortingField: properties.reviewType?.key as string,
  },
  {
    id: properties.createdAt?.key as string,
    header: properties.createdAt?.label as string,
    cell: (e) => getFormattedDate(e.createdAt) || '-',
    sortingField: properties.createdAt?.key as string,
  },
  {
    id: properties.requiredBy?.key as string,
    header: properties.requiredBy?.label as string,
    cell: (e) => getFormattedDate(e.requiredBy) || '-',
    sortingField: properties.requiredBy?.key as string,
  },
];

const ColumnDefinitions = <ItemDef extends PeerReviewItem>(): ColumnDefinition<ItemDef>[] => {
  return [...STATIC_COLUMN_DEFINITIONS];
};

/**
 * Definition of the keys and resource(s) used in the `FeedbackSummaryTable`.
 * See parent: {@link PromoTableDefinition}
 */
const PeerReviewTableDef = getTableDefinition(TABLE_KEY, RESOURCES.PEER_REVIEW);

type PeerReviewTableConfig = PromoTableConfig<PromoTableDefinition, PeerReviewItem>;

/**
 * Functional Component to set the table configuration for all tables.
 * The {@link ViewModeDefinition viewMode} specified will define what/how things are displayed.
 * See parent: {@link PromoTableConfig}
 */
const CreateTableConfig = (): PeerReviewTableConfig => {
  return {
    tableDef: PeerReviewTableDef,
    allColumns: ColumnDefinitions(),
    pageSizes: getPageSizes(),
    displayableColumns: getFilteredColumns(ColumnDefinitions()),
    defaultPreferences: getPreferences(DEFAULT_FEEDBACKS_PER_PAGE, getFilteredColumns(ColumnDefinitions())),
  };
};

export function getPropertyFilters() {
  const stringFilters = StringKeys.map((key) => StringPropertyFilter(properties[key]?.key, properties[key]?.label));
  const dateFilters = DateKeys.map((key) => DatePropertyFilter(properties[key]?.key, properties[key]?.label));

  return [...stringFilters, ...dateFilters].filter((i): i is PropertyFilterProperty => i !== undefined);
}

/** Hook to fetch a new instance of the table config. */
export function useTableConfig() {
  return CreateTableConfig();
}
