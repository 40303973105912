import { useRef, useEffect, useMemo, useState, useCallback } from 'react';
import { Nullable, CandidateStatusItem, PromoUserProfile } from '@/models';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Button from '@amzn/awsui-components-react/polaris/button';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import Select from '@amzn/awsui-components-react/polaris/select';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import { usePromoReadinessProfile, usePromoReadinessActions } from '@/api/promo-readiness';
import { useCandidatePromoPathList } from '@/api/candidate-promo-path';
import * as $ from 'jquery';
import { usePapiProfile, useDirectReports } from '@/api/amzn-people';
import { useWorkSummaries } from '@/api/work-summary';
import Slider from "@amzn/awsui-components-react/polaris/slider";
import PieChart from "@amzn/awsui-components-react/polaris/pie-chart";
import Box from "@amzn/awsui-components-react/polaris/box";
import Table from "@amzn/awsui-components-react/polaris/table";
import Input from "@amzn/awsui-components-react/polaris/input";
import Link from "@amzn/awsui-components-react/polaris/link";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import { InfoLink } from '@/components/common/links';
import Icon from "@amzn/awsui-components-react/polaris/icon";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";
import { useBaselineRoleDimension } from '@/api/baseline-role-dimension';
import './PromoReadiness.css';


interface PromoReadinessTableParams {
  user: Nullable<PromoUserProfile>;
  isParentLoading: boolean;
  manager: Nullable<PromoUserProfile>;
}


interface OptionDefinition {
  label: string;
  value: string;
  jobLevel?: number;
}

interface ScoreBreakdown {
  [key: string]: number | string;
}

interface FeedbackProvider {
  name: string;
  status: string;
}


const PromoReadinessTable = ({ user, isParentLoading, manager }: PromoReadinessTableParams): JSX.Element => {
  const [selectedTechPillars, setSelectedTechPillars] = useState<OptionDefinition[]>([]);
  const [selectedCerts, setSelectedCerts] = useState<OptionDefinition[]>([]);
  const [selectedTfcStatus, setselectedTfcStatus] = useState<OptionDefinition[]>([]);
  const [selectedCustomerStories, setselectedCustomerStories] = useState<OptionDefinition[]>([]);
  const [selectedPerformanceStatus, setselectedPerformanceStatus] = useState<OptionDefinition[]>([]);
  const [selectedOtherContributionStatus, setselectedOtherContributionStatus] = useState<OptionDefinition[]>([]);
  const [inputtedTAMs, setInputtedTAMs] = useState<string[]>([]);
  const [AwsTenure, setAwsTenure] = useState<number | null>(null);
  const [TimeWithRoleTenure, setTimeWithRoleTenure] = useState<number | null>(null);
  const [TimeWithLevel, setTimeWithLevel] = useState<number | null>(null);

  const [totalScore, setTotalScore] = useState(0);
  const [scoreBreakdown, setScoreBreakdown] = useState<ScoreBreakdown>({});

  const [selectedESM, setSelectedESM] = useState<OptionDefinition | null>(null);
  const [selectedTAM, setSelectedTAM] = useState<OptionDefinition | null>(null);
  const { isCandidatePathsLoading, candidateToPath } = useCandidatePromoPathList(manager?.alias);

  const [certificationResponse, setCertificationResponse] = useState<string[]>([]);
   const latestTAMRef = useRef<OptionDefinition | null>(selectedTAM);
  
   const [verificationResults, setVerificationResults] = useState<string[]>([]);
   const [newTAMName, setNewTAMName] = useState<string>('');

   const [tfcupdateSuccess, settfcUpdateSuccess] = useState(false);
   const [ratingUpdateSuccess, setratingUpdateSuccess] = useState(false);
   const [othercontributionsUpdateSuccess, setothercontributionsUpdateSuccess] = useState(false);
   const [otherpillarUpdateSuccess, setotherpillarUpdateSuccess] = useState(false);
   const [feedbackUpdateSuccess, setfeedbackUpdateSuccess] = useState(false);
   const [numFeedbackProviders, setNumFeedbackProviders] = useState(Number);
   const [updatePillarMessage, setUpdatePillarMessage] = useState<string>('');
   const [pillarColor, setpillarColor] = useState<string>('')

  
   const [hiredDate, sethiredDate] = useState('');
   const [hiredyears, sethiredyears] = useState('');
   const [hireddays, sethireddays] = useState('');

   const [joblevelentrydate, setjoblevelentrydate] = useState('');
   const [levelentryyears, setlevelentryyears] = useState('');
   const [levelentrydays, setlevelentrydays] = useState('');

   const [roleentrydate, setroleentrydate] = useState('');
   const [roleentryyears, setroleentryyears] = useState('');
   const [roleentrydays, setroleentrydays] = useState('');
   const [showContainer, setShowContainer] = useState(false);

  const [showFlashbar, setShowFlashbar] = useState(true);


  const [isAwsTenureLoading, setIsAwsTenureLoading] = useState(false);
  const [isTimeWithLevelLoading, setIsTimeWithLevelLoading] = useState(false);
  const [isTimeWithRoleTenureLoading, setIsTimeWithRoleTenureLoading] = useState(false);

   const [feedbackProviders, setFeedbackProviders] = useState<{ name: string, status: string}[]>([]);

   const [feedbackVerificationResults, setFeedbackVerificationResults] = useState<string[]>([]);
  
   const { getPromoReadinessRecord, promoReadiness, fetchLatest } = usePromoReadinessProfile(selectedTAM?.value);
   
  const [alias, setAlias] = useState('');
  const { createPromoReadinessEntry, updatePromoReadinessEntry, isProfileMutating } = usePromoReadinessActions(selectedTAM?.value);
  const { workSummaries, isLoading } = useWorkSummaries(selectedTAM?.value);
  
  const { directReports, isDirectReportsLoading } = useDirectReports(user?.alias, true);
  const { directReports: selectedEsmDirectReports  } = useDirectReports(selectedESM?.value || null, true);

  const [isOverallLoading, setIsOverallLoading] = useState(true);
  
  

 const handleUpdateEntry = async (alias, message, field) => {
  try {
    const data = await getPromoReadinessRecord(alias);

    if (data !== null) {

  
        const updatedFields = {
          alias: alias,
          [field]: message.value,
          [field]: field === 'feedbackProviders' ? message : message[0]?.value,
          _version: data?.version
        };

      // console.log("Detailed updates", alias, message, [field])
      const updatedEntry = await updatePromoReadinessEntry(updatedFields);
      // console.log('Updated entry:', updatedEntry);

            if (field === 'tfcMembership') {
            settfcUpdateSuccess(true);
          } else if (field === 'rating') {
            setratingUpdateSuccess(true);
          } else if (field === 'otherContributions') {
            setothercontributionsUpdateSuccess(true); 
          }
          else if (field === 'techPillar') {
            setotherpillarUpdateSuccess(true); 
          }
          else if (field === 'feedbackProviders') {
            setfeedbackUpdateSuccess(true); 
          }
          
    } else {
      handleCreateEntry(alias, field === 'feedbackProviders' ? message : message[0]?.value, field);
    }
  } catch (error) {
    console.error('Error updating entry:', error);
  }
};

 const handleCreateEntry = async (alias, message, field) => {
  const data = await getPromoReadinessRecord(alias);
  const updatedFields = {

    alias: alias,
    tfcMembership: data?.tfcMembership || "" ,
    rating: data?.rating || "",
    otherContributions: data?.otherContributions || "" ,
    [field]: message
  };
  try {
    const updatedEntry = await createPromoReadinessEntry(updatedFields);
    // console.log('Updated entry:', updatedEntry);
  } catch (error) {
    console.error('Error updating entry:', error);
  }
};

 
   const certificateOptions: ReadonlyArray<OptionDefinition> = [
     { label: 'AWS Certified Cloud Practitioner', value: 'AWS Certified Cloud Practitioner' },
     { label: 'AWS Certified Solutions Architect - Professional', value: 'AWS Certified Solutions Architect - Professional' },
     { label: 'AWS Certified DevOps Engineer - Professional', value: 'AWS Certified DevOps Engineer - Professional' },
     { label: 'AWS Certified Security - Specialty', value: 'AWS Certified Security - Specialty' },
     { label: 'AWS Certified Machine Learning - Specialty', value: 'AWS Certified Machine Learning - Specialty' },
     { label: 'AWS Certified Data Analytics - Specialty', value: 'AWS Certified Data Analytics - Specialty' },
     { label: 'AWS Certified Database - Specialty', value: 'AWS Certified Database - Specialty' },
     { label: 'AWS Certified Advanced Networking - Specialty', value: 'AWS Certified Advanced Networking - Specialty' },
     { label: 'AWS Certified SysOps Administrator - Associate', value: 'AWS Certified SysOps Administrator - Associate' },
     { label: 'AWS Certified Solutions Architect - Associate', value: 'Solution Architect Associate' },
     { label: 'AWS Certified Developer - Associate', value: 'AWS Certified Developer - Associate' },
     { label: 'AWS Certified Data Engineer - Associate', value: 'AWS Certified Data Engineer - Associate' },
     { label: 'AWS Certified Machine Learning Engineer - Associate', value: 'AWS Certified Machine Learning Engineer - Associate' }
   ];
  

   const calculateScore = () => {
    let score = 0;
    const breakdown: ScoreBreakdown = {};

    // Certifications
    let certificationScore = 0;
    let certificationPercentage = 0;

    // Count types of certifications
    const professionalCerts = selectedCerts.filter(cert => cert.value.includes('Professional'));
    const specialtyCerts = selectedCerts.filter(cert => cert.value.includes('Specialty'));
    const associateCerts = selectedCerts.filter(cert => cert.value.includes('Associate'));

    // Calculate the score for Professional and Specialty
    const totalProfessionalAndSpecialty = professionalCerts.length + specialtyCerts.length;

     if (TAM_level === 6)
     {
    if (professionalCerts.length > 0 && specialtyCerts.length > 0) {
        // Both Professional and Specialty present
        certificationScore = 50;
        certificationScore += (totalProfessionalAndSpecialty - 2) * 10; // Subtract 2 for the first Professional and Specialty
    } else if (totalProfessionalAndSpecialty > 0) {
        // Either Professional or Specialty present
        certificationScore = 25;
        certificationScore += (totalProfessionalAndSpecialty - 1) * 10; // Subtract 1 for the first cert (Professional or Specialty)
    }
    else if (totalProfessionalAndSpecialty > 0) {certificationScore = 0;}
     }

     if (TAM_level === 5)
      {
     if (professionalCerts.length > 0 && specialtyCerts.length > 0) {

         certificationScore = 50;
         certificationScore += (totalProfessionalAndSpecialty - 2) * 10; 
     } else if (professionalCerts.length > 0) {

         certificationScore = 25;
         certificationScore += (totalProfessionalAndSpecialty - 1) * 10; 
     }
     else if (totalProfessionalAndSpecialty > 0) {certificationScore = 0;}
      }

      if (TAM_level === 4)
        {
       if ((professionalCerts.length > 0 || specialtyCerts.length > 0) && associateCerts.length > 0 ) {

           certificationScore = 50;
           certificationScore += (totalProfessionalAndSpecialty - 2) * 10; 
       } else if ((professionalCerts.length || specialtyCerts.length) > 0) {

           certificationScore = 25;
           certificationScore += (totalProfessionalAndSpecialty - 1) * 10; 
       }
       else if (totalProfessionalAndSpecialty > 0) {certificationScore = 0;}
        }

    certificationScore += associateCerts.length * 4;




    score += certificationScore;

    // Calculate the percentage based on the score
if (certificationScore === 0) {
  certificationPercentage = 0;
} else if (certificationScore === 50) {
  certificationPercentage = 50;
} else if (certificationScore === 75) {
  certificationPercentage = 75;
} else if (certificationScore === 100) {
  certificationPercentage = 100;
} else {
  // For any other score, calculate the percentage based on the maximum score of 100
  certificationPercentage = Math.round((certificationScore / 100) * 100);
}

    // Add to total score and breakdown
 
    breakdown['Certifications'] = `${certificationScore}  (${certificationPercentage}%)`;


    // Customer Stories

    const listofStories = workSummaries.filter(summary => summary.workSummaryType === "Customer");
    if (listofStories.length === 0) {
      setUpdatePillarMessage('-------------No Story/WorkSummary submitted yet-------');}

    let customerStoriesScore = 0;
    let customerStoriesPercentage = 0;
    let totalBaselineRoleDimensionScore = 0;
    let totalLeadershipPrinciplesScore = 0;
    

    if(TAM_level === 4) {

    for (const story of listofStories) {

      let baselineRoleDimensionScore = 0
      let leadershipPrinciplesScore = 0;

      if (Array.isArray(story.baselineRoleDimensionIds)) {
      const baselineRoleDimensionIdsLength = story.baselineRoleDimensionIds.length ;
      if (baselineRoleDimensionIdsLength === 1) {
        baselineRoleDimensionScore = 10;
      } else if (baselineRoleDimensionIdsLength === 2) {
        baselineRoleDimensionScore = 20;
      } else if (baselineRoleDimensionIdsLength === 3) {
        baselineRoleDimensionScore = 30;
      } else if (baselineRoleDimensionIdsLength >= 4) {
        baselineRoleDimensionScore = 40;
      }
    }

  
    if (Array.isArray(story.leadershipPrinciples)) {
    for (const principle of story.leadershipPrinciples) {
      const levelMatch = principle.match(/Level\s(\d+)/);
      console.log("levelMatch", levelMatch);
      
      if (levelMatch) {
        const level = parseInt(levelMatch[1], 10);
        if (level === 100) {
          leadershipPrinciplesScore += 15;
        } else if (level === 200) {
          leadershipPrinciplesScore += 20;
        } else if (level === 300 || level === 400) {
          leadershipPrinciplesScore += 20;
        }
      }
    }
  }

  totalBaselineRoleDimensionScore += baselineRoleDimensionScore;
  totalLeadershipPrinciplesScore += leadershipPrinciplesScore;
  customerStoriesScore += baselineRoleDimensionScore + leadershipPrinciplesScore;

  const message = `${selectedTAM?.label} has submitted ${listofStories.length} story/stories. Dimensions covered earns ${totalBaselineRoleDimensionScore} points, and Pillars & its Level earns ${totalLeadershipPrinciplesScore} for a total of ${customerStoriesScore} points for customer stories.`;
  setUpdatePillarMessage(message);
   }
  }

  if(TAM_level === 5) {

    for (const story of listofStories) {

      let baselineRoleDimensionScore = 0
      let leadershipPrinciplesScore = 0;

      if (Array.isArray(story.baselineRoleDimensionIds)) {
      const baselineRoleDimensionIdsLength = story.baselineRoleDimensionIds.length ;
      if (baselineRoleDimensionIdsLength === 1) {
        baselineRoleDimensionScore = 10; 
      } else if (baselineRoleDimensionIdsLength === 2) {
        baselineRoleDimensionScore = 20;
      } else if (baselineRoleDimensionIdsLength === 3) {
        baselineRoleDimensionScore = 30;
      } else if (baselineRoleDimensionIdsLength >= 4) {
        baselineRoleDimensionScore = 40;
      }
    }

  
    if (Array.isArray(story.leadershipPrinciples)) {
    for (const principle of story.leadershipPrinciples) {
      const levelMatch = principle.match(/Level\s(\d+)/);
      console.log("levelMatch", levelMatch);
      
      if (levelMatch) {
        const level = parseInt(levelMatch[1], 10);
        if (level === 100) {
          leadershipPrinciplesScore += 10;
        } else if (level === 200) {
          leadershipPrinciplesScore += 15;
        } else if (level === 300 || level === 400) {
          leadershipPrinciplesScore += 20;
        }
      }
    }
  }

  totalBaselineRoleDimensionScore += baselineRoleDimensionScore;
  totalLeadershipPrinciplesScore += leadershipPrinciplesScore;
  customerStoriesScore += baselineRoleDimensionScore + leadershipPrinciplesScore;

  const message = `${selectedTAM?.label} has submitted ${listofStories.length} story/stories. Dimensions covered earns ${totalBaselineRoleDimensionScore} points, and Pillars & its Level earns ${totalLeadershipPrinciplesScore} for a total of ${customerStoriesScore} points for customer stories.`;
  setUpdatePillarMessage(message);  
}
  }

  if(TAM_level === 6) {

    for (const story of listofStories) {

      let baselineRoleDimensionScore = 0
      let leadershipPrinciplesScore = 0;

      if (Array.isArray(story.baselineRoleDimensionIds)) {
      const baselineRoleDimensionIdsLength = story.baselineRoleDimensionIds.length ;
      if (baselineRoleDimensionIdsLength === 1) {
        baselineRoleDimensionScore = 10;
      } else if (baselineRoleDimensionIdsLength === 2) {
        baselineRoleDimensionScore = 20;
      } else if (baselineRoleDimensionIdsLength === 3) {
        baselineRoleDimensionScore = 30;
      } else if (baselineRoleDimensionIdsLength >= 4) {
        baselineRoleDimensionScore = 40;
      }
    }

  
    if (Array.isArray(story.leadershipPrinciples)) {
    for (const principle of story.leadershipPrinciples) {
      const levelMatch = principle.match(/Level\s(\d+)/);
      console.log("levelMatch", levelMatch);
      
      if (levelMatch) {
        const level = parseInt(levelMatch[1], 10);
        if (level === 100) {
          leadershipPrinciplesScore += 5;
        } else if (level === 200) {
          leadershipPrinciplesScore += 10;
        } else if (level === 300 ) {
          leadershipPrinciplesScore += 15;
        } else if (level === 400) {
          leadershipPrinciplesScore += 20;
        }
      }
    }
  }

  totalBaselineRoleDimensionScore += baselineRoleDimensionScore;
  totalLeadershipPrinciplesScore += leadershipPrinciplesScore;
  customerStoriesScore += baselineRoleDimensionScore + leadershipPrinciplesScore;

  const message = `${selectedTAM?.label} has submitted ${listofStories.length} story/stories. Dimensions covered earns ${totalBaselineRoleDimensionScore} points, and Pillars & its Level earns ${totalLeadershipPrinciplesScore} for a total of ${customerStoriesScore} points for customer stories.`;
  setUpdatePillarMessage(message);  
}
  }


  // Calculate customerStoriesPercentage based on customerStoriesScore
if (customerStoriesScore <= 75) {
  customerStoriesPercentage = 25; setpillarColor('Red');
} else if (customerStoriesScore <= 150) {
  customerStoriesPercentage = 50;  setpillarColor('yellow');
} else if (customerStoriesScore <= 225) {
  customerStoriesPercentage = 75; setpillarColor('orange');
} else if (customerStoriesScore <= 290) {
  customerStoriesPercentage = 90; setpillarColor('forestgreen');
} else if (customerStoriesScore === 300) {
  customerStoriesPercentage = 100; setpillarColor('forestgreen');
} else {
  customerStoriesPercentage = 100; // For any score above 300
}


    score += customerStoriesScore;
    breakdown['Customer Stories/Pillar Areas'] = `${customerStoriesScore}  (${customerStoriesPercentage}%)`;

     
       // TFC Contributions
       const tfcStatus = selectedTfcStatus.length > 0 ? selectedTfcStatus[0].value : 'Bronze during Review';
       let tfcScore = 0;
       let tfcPercentage = 0;


     if (TAM_level === 6)
      {

       if (tfcStatus === 'Silver during Review'){ tfcScore = 50; tfcPercentage = 25; }
       else if (tfcStatus === 'Gold during Review'){ tfcScore = 100; tfcPercentage = 50; }
       else if (tfcStatus === 'Gold QoQ') { tfcScore = 150; tfcPercentage = 75; }
       else if (tfcStatus === 'Gold Year Round') { tfcScore = 200; tfcPercentage = 100; }
       else if (tfcStatus === 'Bronze during Review') { tfcScore = 0; tfcPercentage = 0; }
      }

      if (TAM_level === 5)
        {
  
         if (tfcStatus === 'Silver during Review'){ tfcScore = 150; tfcPercentage = 75; }
         else if (tfcStatus === 'Gold during Review'){ tfcScore = 200; tfcPercentage = 100; }
         else if (tfcStatus === 'Gold QoQ') { tfcScore = 200; tfcPercentage = 100; }
         else if (tfcStatus === 'Gold Year Round') { tfcScore = 200; tfcPercentage = 100; }
         else if (tfcStatus === 'Bronze during Review') { tfcScore = 100; tfcPercentage = 50; }
        }

        if (TAM_level === 4)
          {
    
           if (tfcStatus === 'Silver during Review'){ tfcScore = 200; tfcPercentage = 100; }
           else if (tfcStatus === 'Gold during Review'){ tfcScore = 200; tfcPercentage = 100; }
           else if (tfcStatus === 'Gold QoQ') { tfcScore = 200; tfcPercentage = 100; }
           else if (tfcStatus === 'Gold Year Round') { tfcScore = 200; tfcPercentage = 100; }
           else if (tfcStatus === 'Bronze during Review') { tfcScore = 150; tfcPercentage = 75; }
          }

       score += tfcScore;
       breakdown['TFC Contributions'] = `${tfcScore}  (${tfcPercentage}%)`;
   

    // AWS Tenure
    if (AwsTenure !== null) {
      let tenureScore = 0;
      let tenurePercentage = 0;


      if(TAM_level === 6)
      {
      if (AwsTenure < 2) { tenureScore = 6.25; tenurePercentage = 25; }
      else if (AwsTenure === 2) {tenureScore = 12.5; tenurePercentage = 50; }
      else if (AwsTenure === 3) {tenureScore = 12.5; tenurePercentage = 50; }
      else if (AwsTenure === 4) {tenureScore = 18.75; tenurePercentage = 75; }
      else if (AwsTenure >= 5) {tenureScore = 25; tenurePercentage = 100; }
     }

     if(TAM_level === 5)
      {
      if (AwsTenure < 2) { tenureScore = 12.5; tenurePercentage = 50; }
      else if (AwsTenure === 2) {tenureScore = 18.75; tenurePercentage = 75; }
      else if (AwsTenure === 3) {tenureScore = 18.75; tenurePercentage = 75; }
      else if (AwsTenure >= 4) {tenureScore = 25; tenurePercentage = 100; }
     }

     if(TAM_level === 4)
      {
      if (AwsTenure < 2) { tenureScore = 18.75; tenurePercentage = 75; }
      else if (AwsTenure === 2) {tenureScore = 18.75; tenurePercentage = 75; }
      else if (AwsTenure >= 3) {tenureScore = 25; tenurePercentage = 100; }
     }

      score += tenureScore;
      breakdown['AWS Tenure'] = `${tenureScore}  (${tenurePercentage}%)`;
    }

    // Time in Level
    if (TimeWithLevel !== null) {
      let levelScore = 0;
      let tenurepercent = 0;

      if(TAM_level === 6)
        {
       if (TimeWithLevel < 2) {levelScore = 25;  tenurepercent = 25;}
      else if (TimeWithLevel === 2) {levelScore = 50;  tenurepercent = 50;}
      else if (TimeWithLevel === 3)  {levelScore = 50;  tenurepercent = 50;}
      else if (TimeWithLevel === 4) {levelScore = 75;  tenurepercent = 75;} 
      else if (TimeWithLevel >= 5) {levelScore = 100;  tenurepercent = 100;}
        }

      if(TAM_level === 5)
        {
       if (TimeWithLevel < 2) {levelScore = 50;  tenurepercent = 50;}
      else if (TimeWithLevel === 2) {levelScore = 75;  tenurepercent = 75;}
      else if (TimeWithLevel === 3)  {levelScore = 75;  tenurepercent = 75;}
      else if (TimeWithLevel >= 4) {levelScore = 100;  tenurepercent = 100;}
        }

      if(TAM_level === 4)
        {
       if (TimeWithLevel < 2) {levelScore = 75;  tenurepercent = 75;}
      else if (TimeWithLevel === 2) {levelScore = 75;  tenurepercent = 75;}
      else if (TimeWithLevel >= 3) {levelScore = 100;  tenurepercent = 100;}
        }

      score += levelScore;
      breakdown['Time in Level'] = `${levelScore}  (${tenurepercent}%)`;
    }

    // Time in Role
    if (TimeWithRoleTenure !== null) {
      let roleScore = 0;
      let tenureroleepercent = 0;

      if(TAM_level === 6)
        {
      if (TimeWithRoleTenure < 2) {roleScore = 6.25; tenureroleepercent= 25 }
      else if (TimeWithRoleTenure === 2) { roleScore = 12.5; tenureroleepercent= 50 }
      else if (TimeWithRoleTenure === 3) {roleScore = 12.5; tenureroleepercent= 50 }
      else if (TimeWithRoleTenure === 4) {roleScore = 18.75;tenureroleepercent= 75 }
      else if (TimeWithRoleTenure >= 5) {roleScore = 25;tenureroleepercent= 100 }
        }

        if(TAM_level === 5)
          {
        if (TimeWithRoleTenure < 2) {roleScore = 12.5; tenureroleepercent= 50 }
        else if (TimeWithRoleTenure === 2) { roleScore = 18.75; tenureroleepercent= 75 }
        else if (TimeWithRoleTenure === 3) {roleScore = 18.75; tenureroleepercent= 75}
        else if (TimeWithRoleTenure >= 4) {roleScore = 25;tenureroleepercent= 100 }
          }
          
          
          if(TAM_level === 4)
            {
          if (TimeWithRoleTenure < 2) {roleScore = 18.75; tenureroleepercent= 75 }
          else if (TimeWithRoleTenure === 2) { roleScore = 18.75; tenureroleepercent= 75 }
          else if (TimeWithRoleTenure >= 3) {roleScore = 25;tenureroleepercent= 100 }
            }

      score += roleScore;
      breakdown['Time in Role'] = `${roleScore}  (${tenureroleepercent}%)`;
    }

    // Performance Bar Rating
    const performanceRating = selectedPerformanceStatus.length > 0 ? selectedPerformanceStatus[0].value : 'Under the performance Bar in any of the preceding 2 years';
    let performanceScore = 0;
    let performancepercentage = 0;

    if(TAM_level === 6)
      {
    // if (performanceRating === 'Meet the performance Bar in the preceding 2 years showing YoY improvement') { performanceScore = 50; performancepercentage = 50; }
    // else if (performanceRating === 'Exceed the Bar in both of the preceding 2 years') { performanceScore = 75; performancepercentage = 75; }
    // else if (performanceRating === 'Role Model in both of the preceding 2 years') { performanceScore = 100; performancepercentage = 100; }
    // else if (performanceRating === 'Under the performance Bar in any of the preceding 2 year') { performanceScore = 0; performancepercentage = 0; }
    // else if (performanceRating === 'No Performance Rating Data') { performanceScore = 0; performancepercentage = 0; }

    if (performanceRating === 'Role Model YoY within last 24 months') { performanceScore = 100; performancepercentage = 100; }
    else if (performanceRating === 'Role Model once within last 24 months') { performanceScore = 85; performancepercentage = 85; }
    else if (performanceRating === 'Exceeded the bar YoY within last 24 months') { performanceScore = 75; performancepercentage = 75; }
    else if (performanceRating === 'Exceeded the bar once within last 24 months') { performanceScore = 65; performancepercentage = 65; }
    else if (performanceRating === 'Met the bar YoY within last 24 months') { performanceScore = 50; performancepercentage = 50; }
    else if (performanceRating === 'Under the bar once within last 24 months') { performanceScore = 0; performancepercentage = 0; }
    else if (performanceRating === 'No Performance Rating Data') { performanceScore = 0; performancepercentage = 0; }
      }

      if(TAM_level === 5)
        {
          if (performanceRating === 'Role Model YoY within last 24 months') { performanceScore = 100; performancepercentage = 100; }
          else if (performanceRating === 'Role Model once within last 24 months') { performanceScore = 85; performancepercentage = 85; }
          else if (performanceRating === 'Exceeded the bar YoY within last 24 months') { performanceScore = 75; performancepercentage = 75; }
          else if (performanceRating === 'Exceeded the bar once within last 24 months') { performanceScore = 65; performancepercentage = 65; }
          else if (performanceRating === 'Met the bar YoY within last 24 months') { performanceScore = 50; performancepercentage = 50; }
          else if (performanceRating === 'Under the bar once within last 24 months') { performanceScore = 0; performancepercentage = 0; }
          else if (performanceRating === 'No Performance Rating Data') { performanceScore = 0; performancepercentage = 0; }
            }

        if(TAM_level === 4)
          {
            if (performanceRating === 'Role Model YoY within last 24 months') { performanceScore = 100; performancepercentage = 100; }
            else if (performanceRating === 'Role Model once within last 24 months') { performanceScore = 85; performancepercentage = 85; }
            else if (performanceRating === 'Exceeded the bar YoY within last 24 months') { performanceScore = 75; performancepercentage = 75; }
            else if (performanceRating === 'Exceeded the bar once within last 24 months') { performanceScore = 65; performancepercentage = 65; }
            else if (performanceRating === 'Met the bar YoY within last 24 months') { performanceScore = 50; performancepercentage = 50; }
            else if (performanceRating === 'Under the bar once within last 24 months') { performanceScore = 0; performancepercentage = 0; }
            else if (performanceRating === 'No Performance Rating Data') { performanceScore = 0; performancepercentage = 0; }
              }

    score += performanceScore;
    breakdown['Performance Bar Rating'] =  `${performanceScore}  (${performancepercentage}%)`;

      // Feedback providers
      let feedbackProvidersScore = 0;
      let feedbackProviderspercent = 0;
      //const numFeedbackProviders = feedbackProviders.length;
      const metCountnumFeedbackProviders = feedbackProviders.filter(provider => provider.status === 'Met').length;

      if (metCountnumFeedbackProviders <= 5) {
        { feedbackProvidersScore = 0; feedbackProviderspercent = 0; }
      } else if (metCountnumFeedbackProviders === 6) {
        {feedbackProvidersScore = 85; feedbackProviderspercent = 85; }
      } else if (metCountnumFeedbackProviders > 6) {
       { feedbackProvidersScore = 100; feedbackProviderspercent = 100; }
      }

      score += feedbackProvidersScore;
      breakdown['Feedback Providers'] = `${feedbackProvidersScore}  (${feedbackProviderspercent}%)`;


    // Other Contribution Stories (Technical/Business)
    let otherContributionsScore = 0;
    let otherPercentContributionsScore = 0;
    const selectedOtherContributionValues = selectedOtherContributionStatus.map(option => option.value);

    if (selectedOtherContributionValues.includes("Doesn't meet Scope and Impact of Next level Role - Participates in/Leads Local initiatives"))
       { otherContributionsScore = 50; otherPercentContributionsScore = 25; } 
    else if (selectedOtherContributionValues.includes('Meets Scope and Impact of Next level Role - Participates in Global Initiatives')) 
      { otherContributionsScore = 150; otherPercentContributionsScore = 75;
    } else if (selectedOtherContributionValues.includes('Exceeds Scope and Impact of Next level Role - Leads Global Initiatives')) {
      otherContributionsScore = 200; otherPercentContributionsScore = 100;
    }

    score += otherContributionsScore;
    breakdown['Other Contribution Stories'] = `${otherContributionsScore}  (${otherPercentContributionsScore}%)`;

setTotalScore(score);
setScoreBreakdown(breakdown);
//console.log("scoreeeeee",breakdown )
  };

  useEffect(() => {
    const performanceBarRatingScore = typeof scoreBreakdown['Performance Bar Rating'] === 'string'
      ? parseInt(scoreBreakdown['Performance Bar Rating'].split(' ')[0])
      : scoreBreakdown['Performance Bar Rating'] || 0;
  
    const feedbackProvidersScore = typeof scoreBreakdown['Feedback Providers'] === 'string'
      ? parseInt(scoreBreakdown['Feedback Providers'].split(' ')[0])
      : scoreBreakdown['Feedback Providers'] || 0;
  
    if (performanceBarRatingScore === 0 || feedbackProvidersScore === 0) {
      // console.log('Setting totalScore to 0');
      setTotalScore(0);
    }
  }, [scoreBreakdown, setTotalScore]);


const getScoreLabel = (value) => {

    const roundedvalue = Math.round(value);
   
  if (roundedvalue < 600) {
    return 'Subpar';
  } else if (roundedvalue >= 600 && roundedvalue < 700) {
    return 'Marginal';
  } else if (roundedvalue >= 700 && roundedvalue < 775) {
    return 'Inconclusive';
  } else if (roundedvalue >= 775 && roundedvalue < 925) {
    return 'Satisfactory';
  } else if (roundedvalue >= 925 && roundedvalue < 1000) {
    return 'Exceptional (Lower)';
  } else if (roundedvalue >= 1000 && roundedvalue <= 1150) {
    return 'Exceptional (Higher)';
  }

  const performanceBarRatingScore = typeof scoreBreakdown['Performance Bar Rating'] === 'string'
  ? parseInt(scoreBreakdown['Performance Bar Rating'].split(' ')[0])
  : scoreBreakdown['Performance Bar Rating'] || 0;

const feedbackProvidersScore = typeof scoreBreakdown['Feedback Providers'] === 'string'
  ? parseInt(scoreBreakdown['Feedback Providers'].split(' ')[0])
  : scoreBreakdown['Feedback Providers'] || 0;

if (performanceBarRatingScore === 0 || feedbackProvidersScore === 0) {

  setTotalScore(0);
  return 'Subpar';
}
    return '';
  };
  

   const fetchCertifications = useCallback(() => {
    if (!selectedTAM) return;
 
    const url = `https://mqgrmeiggk.execute-api.us-east-2.amazonaws.com/credly/badges?email=${selectedTAM.value}@amazon.com`;
    const maxRetries =3;
    let attempt = 0;
 
    const makeRequest = () => {
    $.ajax({
        url,
        method: 'GET',
        success: (data: any) => {
            try {
                const certNames = data.map((item: any) => item.name);
                const updatedCertOptions = certificateOptions.map(option => ({
                    ...option,
                    checked: certNames.includes(option.label)
                }));
                setSelectedCerts(updatedCertOptions.filter(option => option.checked));
                setCertificationResponse(certNames);
            } catch (error) {
                console.error("Failed to parse certifications data", error);
            }
        }
    });
  };
    makeRequest();
}, [selectedTAM, certificateOptions]);
 
 
  useEffect(() => {
    if (selectedTAM) {
      fetchCertifications();
 
    }
  }, [selectedTAM]);

  const { user: papiProfileUser, isLookupRunning } = usePapiProfile(selectedTAM?.value);

  useEffect(() => {
    if (selectedTAM?.value && !isLookupRunning) {
      const hiredDate = papiProfileUser?.job?.[0]?.hireDate || '';
      const levelentry = papiProfileUser?.job?.[0]?.jobEntryDate || '';
      const roleentry = papiProfileUser?.job?.[0]?.departmentEntryDate || '';




      sethiredDate(hiredDate);
      setjoblevelentrydate(levelentry)
      setroleentrydate(roleentry)

          if (hiredDate){
            const { years, days } = calculateYearsAndDaysSinceHire(hiredDate);
            // console.log(`Hire Date: ${hiredDate}`);
            // console.log(`Time since hire: ${years} years and ${days} days`);
            sethiredyears(years.toString());
            setAwsTenure(years);
            sethireddays(days.toString());
          }

          if (levelentry){;
            const { years, days } = calculateYearsAndDaysSinceHire(levelentry);
            // console.log(`Level Date: ${levelentry}`);
            // console.log(`Time since hire: ${years} years and ${days} days`);
            setlevelentryyears(years.toString());
            setTimeWithLevel(years);
            setlevelentrydays(days.toString());
          }

          if (roleentry){
            const { years, days } = calculateYearsAndDaysSinceHire(roleentry);
            // console.log(`Level Date: ${roleentry}`);
            // console.log(`Time since hire: ${years} years and ${days} days`);
            setroleentryyears(years.toString());
            setTimeWithRoleTenure(years);
            setroleentrydays(days.toString());
          }

          setIsOverallLoading(false); 
    }
  }, [selectedTAM?.value, , papiProfileUser, isLookupRunning]);
 
 
  useEffect(() => {
    setInputtedTAMs([]);
    setVerificationResults([]);
  }, [selectedTAM]);

  useEffect(() => {
    calculateScore();
  }, [selectedTAM, selectedTechPillars, selectedCerts, selectedTfcStatus, selectedPerformanceStatus, selectedOtherContributionStatus, selectedCustomerStories, feedbackProviders, AwsTenure, TimeWithRoleTenure, TimeWithLevel]);

  useEffect(() => {
    if (tfcupdateSuccess || ratingUpdateSuccess || othercontributionsUpdateSuccess || otherpillarUpdateSuccess || feedbackUpdateSuccess) {
      const timer = setTimeout(() => {
        settfcUpdateSuccess(false);
        setratingUpdateSuccess(false);
        setothercontributionsUpdateSuccess(false);
        setotherpillarUpdateSuccess(false);
        setfeedbackUpdateSuccess(false);
      }, 3000);
  
      return () => clearTimeout(timer);
    }
  }, [tfcupdateSuccess, ratingUpdateSuccess, othercontributionsUpdateSuccess, otherpillarUpdateSuccess, feedbackUpdateSuccess]);

  const techPillarOptions: ReadonlyArray<OptionDefinition> = [
    { label: 'Security', value: 'Security' },
    { label: 'Operational Excellence', value: 'Operational Excellence' },
    { label: 'Reliability', value: 'Reliability' },
    { label: 'Performance Efficiency', value: 'Performance Efficiency' },
  ];

  const CustomerStories: ReadonlyArray<OptionDefinition> = [
    { label: '2 or more stories exemplifying more Pillar demonstration', value: '2 or more stories exemplifying more Pillar demonstration' },
    { label: '3 stories that align with Next Level Role guidelines', value: '3 stories that align with Next Level Role guidelines' },
    { label: '2 stories that align with Next Level Role guidelines', value: '2 stories that align with Next Level Role guidelines' },
    { label: '1 story that aligns with Next Level Role guidelines', value: '1 story that aligns with Next Level Role guidelines' },
    { label: 'No story that aligns with Next Level Role guidelines', value: 'No story that aligns with Next Level Role guidelines' },
  ];


  const tfcPillarOptions: ReadonlyArray<OptionDefinition> = [
    { label: 'Gold Year Round', value: 'Gold Year Round'},
    { label: 'Gold QoQ', value: 'Gold QoQ'},
    { label: 'Gold during Review', value: 'Gold during Review' },
    { label: 'Silver during Review', value: 'Silver during Review'},
    { label: 'Bronze during Review', value: 'Bronze during Review'},
    { label: 'No Membership', value: 'No Membership'},
   
  ];


  
  const performanceRatingOptions: ReadonlyArray<OptionDefinition> = [
    { label: 'Role Model YoY within last 24 months', value: 'Role Model YoY within last 24 months'},
    { label: 'Role Model once within last 24 months', value:'Role Model once within last 24 months'},
    { label: 'Exceeded the bar YoY within last 24 months', value: 'Exceeded the bar YoY within last 24 months'},
    { label: 'Exceeded the bar once within last 24 months', value: 'Exceeded the bar once within last 24 months'},
    { label: 'Met the bar YoY within last 24 months', value: 'Met the bar YoY within last 24 months'},
    { label: 'Under the bar once within last 24 months', value: 'Under the bar once within last 24 months'},
    { label: 'No Performance Rating Data', value: 'No Performance Rating'}
  ];

  const OtherContributionsOptions: ReadonlyArray<OptionDefinition> = [
    { label: 'Exceeds Scope and Impact of Next level Role - Leads Global Initiatives', value: 'Exceeds Scope and Impact of Next level Role - Leads Global Initiatives'},
    { label: 'Meets Scope and Impact of Next level Role - Participates in Global Initiatives', value:'Meets Scope and Impact of Next level Role - Participates in Global Initiatives'},
    { label: "Doesn't meet Scope and Impact of Next level Role - Participates in/Leads Local initiatives", value: "Doesn't meet Scope and Impact of Next level Role - Participates in/Leads Local initiatives"},
    { label: "No Other Contributions Data", value: "No Other Contributions Data"}
  ];


  const timeInRoleForTAM = useCallback((tamAlias: string) => {
    const selectedTAMItem = directReports.find((item) => item.alias === tamAlias);
    if (!selectedTAMItem || !selectedTAMItem.hireDate) return null;


  const hireDate = new Date(selectedTAMItem.hireDate);
     const currentDate = new Date();
     const timeDiff = Math.abs(currentDate.getTime() - hireDate.getTime());
     const yearsInRole = Math.floor(timeDiff / (1000 * 3600 * 24 * 365));
     return yearsInRole;
 }, [directReports]);
  
 const handleAddTAM = () => {
   if (newTAMName && !inputtedTAMs.includes(newTAMName)) {
       setInputtedTAMs([...inputtedTAMs, newTAMName]);
       setNewTAMName('');
   }
 };
  
 const handleRemoveTAM = (index: number) => {
   setInputtedTAMs(prevTAMs => prevTAMs.filter((_, i) => i !== index));
 };
  
 const handleVerifyTAMs = () => {
   const results = inputtedTAMs.map((tamAlias) => {
       const years = timeInRoleForTAM(tamAlias);
       if (years !== null) {
           return `${tamAlias}: ${years >= 2 ? 'Met' : 'Not Qualified'} (${years} years)`;
       }
       return `${tamAlias}: No data available`;
   });
   setVerificationResults(results);
 };
  
 const handleRemoveFeedbackProvider = (index: number) => {
  setFeedbackProviders((prevFeedbackProviders) => {
    const updatedFeedbackProviders = [...prevFeedbackProviders];
    updatedFeedbackProviders.splice(index, 1);
    return updatedFeedbackProviders;
  });
};

useEffect(() => {
  //console.log('feedbackProviders.length:', feedbackProviders.length);
  setNumFeedbackProviders(feedbackProviders.length);
}, [feedbackProviders]);


 const handleTechPillarMultiSelectChange = useCallback(
  (event: any) => {
    const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
    setSelectedTechPillars(selectedOptions);
  },
  [setSelectedTechPillars]
);

const handleCustomerStories = useCallback(
  (event: any) => {
    const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
    setselectedCustomerStories(selectedOptions);
  },
  [setselectedCustomerStories]
)



  const handleCertMultiSelectChange = useCallback(
    (event: any) => {
      const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
      setSelectedCerts(selectedOptions);

    },
    [setSelectedCerts]
  );

  const handleTfcStatusMultiSelectChange = useCallback(
    (event: any) => {
      const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
      setselectedTfcStatus(selectedOptions);
    },
    [setselectedTfcStatus]
  );


  const [initialTfcStatusFetched, setInitialTfcStatusFetched] = useState(false);
  const [initialPillarStatusFetched, setinitialPillarStatusFetched] = useState(false);
  const [initialratingStatusFetched, setinitialratingStatusFetched] = useState(false);
  const [initialothercontributionStatusFetched, setinitialothercontributionStatusFetched] = useState(false);
 

  useEffect(() => {
    const updateTfcStatus = async () => {
      if (selectedTAM?.value && initialTfcStatusFetched) {
        const data = await getPromoReadinessRecord(selectedTAM.value);
        if (data?.tfcMembership) {
          const tfcOption = tfcPillarOptions.find(
            option => option.value.toLowerCase() === data.tfcMembership.toLowerCase()
          );
          if (tfcOption) {
            setselectedTfcStatus([tfcOption]);
          } else {
            setselectedTfcStatus([]);
          }
        } else {
          setselectedTfcStatus([]);
        }
      }
    };
  
    const updatePillarStatus = async () => {
      if (selectedTAM?.value && initialPillarStatusFetched) {
        const data = await getPromoReadinessRecord(selectedTAM.value);
        if (data?.techPillar) {
          const PillarOption = CustomerStories.find(
            option => option.value.toLowerCase() === (data?.techPillar?.[0]?.toLowerCase() || ''),


          );
          if (PillarOption) {
            setselectedCustomerStories([PillarOption]);
          } else {
            setselectedCustomerStories([]);
          }
        } else {
          setselectedCustomerStories([]);
        }
      }
    };
    const updateratingStatus = async () => {
      if (selectedTAM?.value && initialratingStatusFetched) {
        const data = await getPromoReadinessRecord(selectedTAM.value);
        if (data?.rating) {
          const RatingOption = performanceRatingOptions.find(
            option => option.value.toLowerCase() === data?.rating.toLowerCase()

          );
          if (RatingOption) {
            setselectedPerformanceStatus([RatingOption]);
          } else {
            setselectedPerformanceStatus([]);
          }
        } else {
          setselectedPerformanceStatus([]);
        }
      }
    };
    const updateothercontributionsStatus = async () => {
      if (selectedTAM?.value && initialothercontributionStatusFetched) {
        const data = await getPromoReadinessRecord(selectedTAM.value);
        if (data?.otherContributions) {
          const OtherContributionsoptions = OtherContributionsOptions.find(
            option => option.value.toLowerCase() === data?.otherContributions.toLowerCase()


          );
          if (OtherContributionsoptions) {
            setselectedOtherContributionStatus([OtherContributionsoptions]);
          } else {
            setselectedOtherContributionStatus([]);
          }
        } else {
          setselectedOtherContributionStatus([]);
        }
      }
    };
    const updateFeedbackProviders = async () => {
      if (selectedTAM?.value) {
        const data = await getPromoReadinessRecord(selectedTAM.value);
        if (data?.feedbackProviders) {
          const mappedFeedbackProviders = data.feedbackProviders.map((provider: string) => {
            const [, name, status] = provider.match(/\{name=(.+?), status=(.+?)\}/) || [];
            return { name: name || '', status: status || '' };
          });
          setFeedbackProviders(mappedFeedbackProviders);
        } else {
          setFeedbackProviders([]);
        }
      }
    };
    
    updateTfcStatus();
    updatePillarStatus();
    updateratingStatus();
    updateothercontributionsStatus();
    updateFeedbackProviders();
   
  }, [selectedTAM?.value, getPromoReadinessRecord, initialTfcStatusFetched, initialPillarStatusFetched, initialratingStatusFetched, initialothercontributionStatusFetched]);


  useEffect(() => {
  const fetchInitialtfc = async () => {
    if (selectedTAM?.value && !initialTfcStatusFetched) {
      const data = await getPromoReadinessRecord(selectedTAM.value);
      if (data?.tfcMembership) {
        const tfcOption = tfcPillarOptions.find(
          option => option.value.toLowerCase() === data.tfcMembership.toLowerCase()
        );
        if (tfcOption) {
          setselectedTfcStatus([tfcOption]);
        }
      }
      setInitialTfcStatusFetched(true);
    }
  };
   
  const fetchInitialpillar = async () => {
    if (selectedTAM?.value && !initialPillarStatusFetched) {
      const data = await getPromoReadinessRecord(selectedTAM.value);
      if (data?.techPillar) {
        const pillarOption = CustomerStories.find(
          option => option.value.toLowerCase() === (data?.techPillar?.[0]?.toLowerCase() || '')
        );
        if (pillarOption) {
          setselectedCustomerStories([pillarOption]);
        }
      }
      setinitialPillarStatusFetched(true);
    }
  };

  const fetchInitialrating = async () => {
    if (selectedTAM?.value && !initialratingStatusFetched) {
      const data = await getPromoReadinessRecord(selectedTAM.value);
      if (data?.rating) {
        const ratingOption = performanceRatingOptions.find(
          option => option.value.toLowerCase() === data.rating.toLowerCase()
        );
        if (ratingOption) {
          setselectedPerformanceStatus([ratingOption]);
        }
      }
      setinitialratingStatusFetched(true);
    }
  };


  const fetchInitialothercontributions = async () => {
    if (selectedTAM?.value && !initialothercontributionStatusFetched) {
      const data = await getPromoReadinessRecord(selectedTAM.value);
      // console.log("Fetched data :", data );
      if (data?.otherContributions) {
        const othercontOption = OtherContributionsOptions.find(
          option => option.value.toLowerCase() === data.otherContributions.toLowerCase()
        );
        if (othercontOption) {
          setselectedOtherContributionStatus([othercontOption]);
        }
      }
      setinitialothercontributionStatusFetched(true);
    }
  };


  fetchInitialtfc();
  fetchInitialpillar();
  fetchInitialrating();
  fetchInitialothercontributions();

}, [selectedTAM?.value, getPromoReadinessRecord, tfcPillarOptions, CustomerStories,performanceRatingOptions,OtherContributionsOptions ]);



  
  const handlePerformanceStatusMultiSelectChange = useCallback(
    (event: any) => {
      const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
      setselectedPerformanceStatus(selectedOptions);
    },
    [setselectedPerformanceStatus]
  );

  const handleOtherContributionsStatusMultiSelectChange = useCallback(
    (event: any) => {
      const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
      setselectedOtherContributionStatus(selectedOptions);
    },
    [setselectedOtherContributionStatus]
  );


  const handleSliderChange = (value: number) => {
      setTotalScore(value);

  }

  const items: CandidateStatusItem[] = useMemo(
    () =>
      directReports
        .map((directReport) => {
          const candidatePath = candidateToPath.get(directReport.alias);
          return {
            ...directReport,
            ...candidatePath,
            id: directReport.alias,
            name: directReport.name || `${directReport.firstName} ${directReport.lastName}` || '',
            pathName: candidatePath?.name,
            pathId: candidatePath?.id,
          };
        })
        .sort((a, b) => -a.jobLevel + b.jobLevel || (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    [directReports, candidateToPath]
  );
  
  const esmOptions: ReadonlyArray<OptionDefinition> = useMemo(() => {  
    
    if (user?.jobLevel === 7 && directReports && directReports.length > 0) {
      return directReports.map(report => ({
        label: `${report.name} (${report.alias})`,
        value: report.alias,
      }));
    } else {
      return [{ 
        label: `${user?.name} (${user?.alias})`,
        value: user?.alias || '',
      }];
    }
  }, [user?.jobLevel, user?.name, user?.alias, directReports]);

  useEffect(() => {
  }, [selectedEsmDirectReports]);
  const getTamOptionsMap = useCallback(() => {
    const map: { [key: string]: OptionDefinition[] } = {};
    if (user?.jobLevel === 7 && selectedEsmDirectReports) {
      map[selectedESM?.value || ''] = selectedEsmDirectReports.map(report => ({
        label: `${report.name} (${report.alias})`,
        value: report.alias,
        jobLevel: report.jobLevel,
      }));
    } else {
      map[user?.alias || ''] = items
        .filter(item => item.alias !== user?.alias)
        .map((item) => ({
          label: `${item?.name} (${item?.alias})`,
          value: `${item.alias}`,
        }));
    }
    
    return map;
  }, [user?.jobLevel, user?.alias, selectedEsmDirectReports, items]);

  const tamOptionsMap = useMemo(() => getTamOptionsMap(), [getTamOptionsMap]);

  const tamOptions = useMemo(() => {
    if (!selectedESM || !tamOptionsMap[selectedESM.value]) {
      return [];
    }
    return tamOptionsMap[selectedESM.value];
  }, [selectedESM, tamOptionsMap]);
  
  const handleESMChange = useCallback(
    (event: any) => {
      const selectedOption = event.detail.selectedOption as OptionDefinition;
      setSelectedESM(selectedOption);
      setSelectedTAM(null);
    },
    [setSelectedESM, setSelectedTAM]
  );

  const handleTAMChange = useCallback(
    (event: any) => {
      const selectedOption = event.detail.selectedOption as OptionDefinition;
      setSelectedTAM(selectedOption);
    },[]);



  const timeInRole = useCallback(() => {
            if (!selectedTAM) {
              return null;
            }

            const selectedTAMAlias = selectedTAM.value;
            const selectedTAMItem = items.find((item) => item.alias === selectedTAMAlias);

            if (!selectedTAMItem || !selectedTAMItem.hireDate) {
              return null;
            }

            const { hireDate } = selectedTAMItem;
            const currentDate = new Date();
            const timeDiff = Math.abs(currentDate.getTime() - new Date(hireDate).getTime());
            const daysInRole = Math.ceil(timeDiff / (1000 * 3600 * 24));
            const years = Math.floor(daysInRole / 365);
            const remainingDays = daysInRole % 365;

            if (years === 0) {
              return `${remainingDays} days`;
            } else if (remainingDays === 0) {
              return `${years} years`;
            } else {
              return `${years} years, ${remainingDays} days`;
            }
  }, [selectedTAM, items]);


function calculateYearsAndDaysSinceHire(hireDateString: string): { years: number, days: number } {
  const hireDate = new Date(hireDateString);
  const currentDate = new Date();

  // Difference in milliseconds
  const diffInMs = currentDate.getTime() - hireDate.getTime();
 
  // Convert to years and days
  const diffInYears = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365.25)); // Consider leap years
  const remainingMs = diffInMs % (1000 * 60 * 60 * 24 * 365.25);
  const diffInDays = Math.floor(remainingMs / (1000 * 60 * 60 * 24));

  return { years: diffInYears, days: diffInDays };
}

  const getTimeInAmazonColor = (TAM_level: number, timeInAmazon: number) => {
    if (TAM_level === 6) {
      if (timeInAmazon >= 5) return 'forestgreen';
      if (timeInAmazon == 4 ) return 'orange';
      if (timeInAmazon == 2 || timeInAmazon == 3) return 'yellow';
      return 'red';
    } else if (TAM_level === 5) {
      if (timeInAmazon >= 4) return 'forestgreen';
      if (timeInAmazon == 2 || timeInAmazon == 3) return 'orange';
      return 'red';
    } else if (TAM_level === 4) {
      if (timeInAmazon >= 3) return 'forestgreen';
      if (timeInAmazon == 2 ) return 'orange';
      return 'red';
    }
    //return 'red'; // default color if TAM_level is different or undefined
  };

  const getTimeInCurrenteColor = (TAM_level: number, timeInLevel: number) => {
    if (TAM_level === 6) {
      if (timeInLevel >= 5) return 'forestgreen';
      if (timeInLevel == 4 ) return 'orange';
      if (timeInLevel == 2 || timeInLevel == 3) return 'yellow';
      return 'red';
    } else if (TAM_level === 5) {
      if (timeInLevel >= 4) return 'forestgreen';
      if (timeInLevel == 2 || timeInLevel == 3 ) return 'orange';
      return 'red';
    } else if (TAM_level === 4) {
      if (timeInLevel >= 3) return 'forestgreen';
      if (timeInLevel == 2) return 'orange';
      return 'red';
    }
    //return 'red'; // default color if TAM_level is different or undefined
  };

  const getTimeInRoleColor = (TAM_level: number, timeInRole: number) => {
    if (TAM_level === 6) {
      if (timeInRole >= 5) return 'forestgreen';
      if (timeInRole == 4 ) return 'orange';
      if (timeInRole == 2 || timeInRole == 3) return 'yellow';
      return 'red';
    } else if (TAM_level === 5) {
      if (timeInRole >= 4) return 'forestgreen';
      if (timeInRole == 2 || timeInRole == 3 ) return 'orange';
      return 'red';
    } else if (TAM_level === 4) {
      if (timeInRole >= 3) return 'forestgreen';
      if (timeInRole == 2) return 'orange';
      return 'red';
    }
    //return 'red'; // default color if TAM_level is different or undefined
  };
  const getFeedbackColor = (TAM_level: number) => {
  
    const requiredCount = feedbackProviders.length ;
    const metCount = feedbackProviders.filter(provider => provider.status === 'Met').length;
 
    if (TAM_level === 6) {
 
      const allMet = requiredCount >= 6 && metCount >= 6;
      return allMet ? 'forestgreen' : 'red';
 
    } else if (TAM_level === 5) {
         const allMet = requiredCount >= 6 && metCount >= 6;
      return allMet ? 'forestgreen' : 'red';
    } else if (TAM_level === 4) {
       const allMet = requiredCount >= 6 && metCount >= 6;
      return allMet ? 'forestgreen' : 'red';
    }
    return 'grey';
  };
 


  const tamIndicatorByLevel = {
    4: {
      "certifcation": {
        "Associate": 1,
        "Exceeds": {"Associate": 0,
                    "Professional": 0,
                    "Specialty": 0}
      },
      "pillar": {
        "Security": 200,
        "Operational Excellence": 200,
        "Reliability": 200,
        "Performance Efficiency": 200
      },
      "timeInRole": 365
    },
    5: {
      "certifcation": {
        "Professional": 1,
        "Exceeds": {
          "Associate": 0,
          "Professional": 1,
          "Specialty": 0
        }
      },
      "pillar": {
        "Security": 200,
        "Operational Excellence": 200,
        "Reliability": 200,
        "Performance Efficiency": 200
      },
      "timeInRole": 365
    },
    6: {
      "certifcation": {
        "Professional": 1,
        "Exceeds": {
          "Professional": 1,
          "Specialty": 0}
      },
      "pillar": {
        "Security": 200,
        "Operational Excellence": 200,
        "Reliability": 200,
        "Performance Efficiency": 200
      },
      "timeInRole": 365
    }
  };
  const calculateCertCriteria = useMemo(() => {
    if (!selectedTAM) {
      return null;
    }

    const selectedTAMItem = items.find((item) => item.jobLevel);
    //console.log("selectedTAMItem", selectedTAMItem)

    if (!selectedTAMItem || !selectedTAMItem.hireDate) {
      // console.log(selectedTAMItem)
      return null;
    }
    const numAssociateCerts = selectedCerts.filter(cert => cert.label.includes("Associate")).length;
    const numProfessionalCerts = selectedCerts.filter(cert => cert.label.includes("Professional")).length;
    const numSpecialtyCerts = selectedCerts.filter(cert => cert.label.includes("Specialty")).length;

    const meets = numAssociateCerts >= tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Associate ||
    numProfessionalCerts >= tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Professional ||
    numSpecialtyCerts >= tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Specialty

    const exceeds = numAssociateCerts > tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Exceeds.Associate ||
    numProfessionalCerts > tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Exceeds.Professional ||
    numSpecialtyCerts > tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Exceeds.Specialty

    if (meets && exceeds) {
      return "forestgreen";
    } else if (meets) {
      return "orange";
    } else {
      return "red";
    }

  }, [selectedTAM, items, tamIndicatorByLevel, selectedCerts]);

  const selectedTAMDetails = useMemo(() => {
    if (!selectedTAM) return null;
  
    if (user?.jobLevel === 7 && selectedESM) {
      const selectedEsmOptions = tamOptionsMap[selectedESM.value] || [];
      return selectedEsmOptions.find(item => item.value === selectedTAM.value) || null;
    } else {
      return items.find(item => item.alias === selectedTAM.value) || null;
    }
  }, [selectedTAM, items, user?.jobLevel, selectedESM, tamOptionsMap]);
  
  const TAM_level = Number(selectedTAMDetails?.jobLevel);


  const getCustomerStoriesColor = (TAM_level: number) => {

    let selected = selectedCustomerStories
      const includes1story = selected.some(item => item.label.includes("1 story that align with Next Level Role guidelines"));
      const includes2story = selected.some(item => item.label.includes("2 stories that align with Next Level Role guidelines"));
      const includes3story = selected.some(item => item.label.includes("3 stories that align with Next Level Role guidelines"));
      //const includesMorestory = selected.some(item => item.label.includes("2 or more stories exemplifying more Pillar demonstration"));
      if (TAM_level === 6) {
        //if (includesMorestory) return 'forestgreen';
        if (includes3story) return 'forestgreen';
        if (includes2story) return 'orange';
        if (includes1story) return 'yellow';
        return 'grey';
      } else if (TAM_level === 5) {
       // if (includesMorestory) return 'forestgreen';
        if (includes3story) return 'forestgreen';
        if (includes2story) return 'orange';
        if (includes1story) return 'yellow';
        return 'grey';
      } else if (TAM_level === 4) {
       // if (includesMorestory) return 'forestgreen';
        if (includes3story) return 'forestgreen';
        if (includes2story) return 'orange';
        if (includes1story) return 'yellow';
        return 'grey';
      }
  };

    const getTfcStatusColor = (TAM_level: number) => {
      let selected = selectedTfcStatus

      const includesGold = selected.some(item => item.label.includes("Gold during Review"));
      const includesSilver = selected.some(item => item.label.includes("Silver during Review"));
      const includesBronze = selected.some(item => item.label.includes("Bronze during Review"));
      const includesGoldQoQ = selected.some(item => item.label.includes("Gold QoQ"));
      const includesGoldYearRound = selected.some(item => item.label.includes("Gold Year Round"));
      

      if (TAM_level === 6) {
        if (includesGoldYearRound) return 'forestgreen';
        if (includesGoldQoQ) return 'limegreen';
        if (includesGold) return 'orange';
        if (includesSilver) return 'yellow';
        if (includesBronze) return 'red';
        return 'grey';
      } else if (TAM_level === 5) {
        if (includesGoldYearRound) return 'forestgreen';
        if (includesGoldQoQ) return 'limegreen';
        if (includesGold) return 'orange';
        if (includesSilver) return 'yellow';
        if (includesBronze) return 'red';
        return 'grey'
      } else if (TAM_level === 4) {
        if (includesGoldYearRound) return 'forestgreen';
        if (includesGoldQoQ) return 'limegreen';
        if (includesGold) return 'orange';
        if (includesSilver) return 'yellow';
        if (includesBronze) return 'red';
        return 'grey'
      }
    };
    
    function calculateMonths(hireDateString: string): number {
      const hireDate = new Date(hireDateString);
      const currentDate = new Date();
    
      // Difference in milliseconds
      const diffInMs = currentDate.getTime() - hireDate.getTime();
    
      // Convert to months
      const diffInMonths = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30.44)); // Approximate number of days in a month
    
      return diffInMonths;
    }
    
    const [currentTAMName, setCurrentTAMName] = useState('');
    const [feedbacker, setFeedbacker] = useState<any>(null);
    const { user: fetchedFeedbacker } = usePapiProfile(currentTAMName);
    const [pendingProvider, setPendingProvider] = useState<string | null>(null);


    // Track when feedbacker data is ready and pendingProvider is set
    useEffect(() => {
      if (currentTAMName) {
        setFeedbacker(fetchedFeedbacker);
      }
    }, [currentTAMName, fetchedFeedbacker]);

    useEffect(() => {
   
      if (feedbacker && pendingProvider) {
        const hiredDate = feedbacker?.job?.[0]?.hireDate || '';
        const levelEntry = feedbacker?.job?.[0]?.jobEntryDate || '';
        const roleEntry = feedbacker?.job?.[0]?.departmentEntryDate || '';
        const jobLevel = feedbacker?.jobLevel || '';
        const costCenter = feedbacker?.costCenterId || '';
        const businessTitle = feedbacker?.businessTitle || '';
        const manager = feedbacker?.manager || '';

        
        if (hiredDate && levelEntry && roleEntry) {
          const hiredMonths = calculateMonths(hiredDate);
          const levelMonths = calculateMonths(levelEntry);
          const roleMonths = calculateMonths(roleEntry);

          let status;
          if (costCenter === 7319) {
            const isMet =
              hiredMonths >= 24 &&
              levelMonths >= 12 &&
              roleMonths >= 12 &&
              (jobLevel > TAM_level );
            status = isMet ? 'Met' : 'Not Met';
          } else {
            const isMet =
              hiredMonths >= 24 &&
              levelMonths >= 12 &&
              (jobLevel > TAM_level );
            status = isMet ? 'Met' : 'Not Met';
          }
  

          // Additional condition to check if currentTAMName is equal to manager
           if (currentTAMName === manager) {
                status = 'Not Met';
               }

          setFeedbackProviders((prevProviders) => {
            const updatedProviders = [...prevProviders, { name: pendingProvider, status }];
            return updatedProviders;
          });
          setPendingProvider(null); // Clear pending provider
          setCurrentTAMName(''); // Reset TAM name
          setNewTAMName(''); // Clear input field after adding feedback provider
          setFeedbacker('');
        } else {
          console.log("Missing dates for hire, job, or role entry");
        }
      }
 
    }, [feedbacker, pendingProvider]);

    const handleAddFeedbackProvider = useCallback(
      
      (newTAMName: string) => {
        if (newTAMName && !feedbackProviders.some((provider) => provider.name === newTAMName)) {
          setCurrentTAMName(newTAMName); // Update currentTAMName for new feedbacker
          setPendingProvider(newTAMName); // Set pending provider
        } else {
          console.log("Feedback provider already exists");
        }
      },
      [feedbackProviders]
    );


    const getPerformanceBarColor = () => {
      let selected = selectedPerformanceStatus

      const roleModelYoY = selected.some(item => item.label.includes("Role Model YoY within last 24 months"));
      const roleModelLastReview = selected.some(item => item.label.includes("Role Model once within last 24 months"));
      const includesExceededYoY = selected.some(item => item.label.includes("Exceeded the bar YoY within last 24 months"));
      const includesExceededLastReview = selected.some(item => item.label.includes("Exceeded the bar once within last 24 months"));
      const includesMeets = selected.some(item => item.label.includes("Met the bar YoY within last 24 months"));
      const includesNotMeets = selected.some(item => item.label.includes("Under the bar once within last 24 months"));

      if (roleModelYoY) return 'forestgreen';
      if (roleModelLastReview) return 'limegreen';
      if (includesExceededYoY) return 'orange';
      if (includesExceededLastReview) return 'orange';
      if (includesNotMeets) return 'red';
      if (includesMeets) return 'yellow';
      return 'grey';
     
    };
    
    const getOtherContributionsBarColor = () => {
      let selected = selectedOtherContributionStatus

      const includesExceededScope = selected.some(item => item.label.includes("Exceeds Scope and Impact of Next level Role - Leads Global Initiatives"));
      const includesMeetsScope = selected.some(item => item.label.includes("Meets Scope and Impact of Next level Role - Participates in Global Initiatives"));
      const includesNotMeetsScope = selected.some(item => item.label.includes("Doesn't meet Scope and Impact of Next level Role - Participates in/Leads Local initiatives"));

      if (includesExceededScope) return 'forestgreen';
      if (includesNotMeetsScope) return 'red';
      if (includesMeetsScope) return 'orange';
      return 'grey';
     
    };
    const dimensionIdToLabel: { [key: string]: string } = {
      "c9cdb322-6475-4145-9795-fb7eeb99ccc7": "Diversity, Equity, and Inclusion (DEI)",
      "e1d8953a-1bfa-4c1a-bf22-e6736b902534": "Execution",
      "4c68191c-510e-4017-add1-3b475282e700": "Impact",
      "a6a1a8a8-c2b0-4082-abf9-82f3149f6acb": "Ambiguity",
      "f6cbd667-7ff5-4d8a-b0d8-bfc8d913d3ac": "Problem complexity",
      "62658a7c-2b1d-40d1-a918-5e9f0c2de305": "Process improvement",
      "b43bbc1c-9f3c-4bb4-88df-24eb242799b6": "Communication"
    };
    

    const tableData = [
      {
        category: 'Certifications',
        score: typeof scoreBreakdown['Certifications'] === 'string' ? scoreBreakdown['Certifications'].split(' ')[0] : scoreBreakdown['Certifications'],
        percentage: typeof scoreBreakdown['Certifications'] === 'string' ? scoreBreakdown['Certifications'].match(/\((\d+)%\)/)?.[1] + '%' : '',
        max_Score: 120,
      },
      {
        category: 'Customer Stories/Pillar Areas',
        score: typeof scoreBreakdown['Customer Stories/Pillar Areas'] === 'string' ? scoreBreakdown['Customer Stories/Pillar Areas'].split(' ')[0] : scoreBreakdown['Customer Stories/Pillar Areas'],
        percentage: typeof scoreBreakdown['Customer Stories/Pillar Areas'] === 'string' ? scoreBreakdown['Customer Stories/Pillar Areas'].match(/\((\d+)%\)/)?.[1] + '%' : '',
        max_Score: 300,
      },
      {
        category: 'TFC Contributions',
        score: typeof scoreBreakdown['TFC Contributions'] === 'string' ? scoreBreakdown['TFC Contributions'].split(' ')[0] : scoreBreakdown['TFC Contributions'],
        percentage: typeof scoreBreakdown['TFC Contributions'] === 'string' ? scoreBreakdown['TFC Contributions'].match(/\((\d+)%\)/)?.[1] + '%' : '',
        max_Score: 200,
      },
      {
        category: 'AWS Tenure',
        score: typeof scoreBreakdown['AWS Tenure'] === 'string' ? scoreBreakdown['AWS Tenure'].split(' ')[0] : scoreBreakdown['AWS Tenure'],
        percentage: typeof scoreBreakdown['AWS Tenure'] === 'string' ? scoreBreakdown['AWS Tenure'].match(/\((\d+)%\)/)?.[1] + '%' : '',
        max_Score: 25,
      },
      {
        category: 'Time in Level',
        score: typeof scoreBreakdown['Time in Level'] === 'string' ? scoreBreakdown['Time in Level'].split(' ')[0] : scoreBreakdown['Time in Level'],
        percentage: typeof scoreBreakdown['Time in Level'] === 'string' ? scoreBreakdown['Time in Level'].match(/\((\d+)%\)/)?.[1] + '%' : '',
        max_Score: 100,
      },
      {
        category: 'Time in Role',
        score: typeof scoreBreakdown['Time in Role'] === 'string' ? scoreBreakdown['Time in Role'].split(' ')[0] : scoreBreakdown['Time in Role'],
        percentage: typeof scoreBreakdown['Time in Role'] === 'string' ? scoreBreakdown['Time in Role'].match(/\((\d+)%\)/)?.[1] + '%' : '',
        max_Score: 25,
      },
      {
        category: 'Performance Bar Rating',
        score: typeof scoreBreakdown['Performance Bar Rating'] === 'string' ? scoreBreakdown['Performance Bar Rating'].split(' ')[0] : scoreBreakdown['Performance Bar Rating'],
        percentage: typeof scoreBreakdown['Performance Bar Rating'] === 'string' ? scoreBreakdown['Performance Bar Rating'].match(/\((\d+)%\)/)?.[1] + '%' : '',
        max_Score: 100,
      },
      {
        category: 'Feedback Providers',
        score: typeof scoreBreakdown['Feedback Providers'] === 'string' ? scoreBreakdown['Feedback Providers'].split(' ')[0] : scoreBreakdown['Feedback Providers'],
        percentage: typeof scoreBreakdown['Feedback Providers'] === 'string' ? scoreBreakdown['Feedback Providers'].match(/\((\d+)%\)/)?.[1] + '%' : '',
        max_Score: 100,
      },
      {
        category: 'Other Contribution Stories',
        score: typeof scoreBreakdown['Other Contribution Stories'] === 'string' ? scoreBreakdown['Other Contribution Stories'].split(' ')[0] : scoreBreakdown['Other Contribution Stories'],
        percentage: typeof scoreBreakdown['Other Contribution Stories'] === 'string' ? scoreBreakdown['Other Contribution Stories'].match(/\((\d+)%\)/)?.[1] + '%' : '',
        max_Score: 200,
      },
      {
        category: <strong>Total</strong>,
        score: <strong>{totalScore}</strong>,
        max_Score: <strong>1150</strong>,
        percentage: <strong>{Math.round((totalScore / 1150) * 100)}%</strong>,  
      }  
      
    ];
    
    const pieChartData = [
      {
        title: 'Certifications',
        value: typeof scoreBreakdown['Certifications'] === 'string' ? parseInt(scoreBreakdown['Certifications'].split(' ')[0]) : scoreBreakdown['Certifications'],
        percentage: typeof scoreBreakdown['Certifications'] === 'string' ? scoreBreakdown['Certifications'].match(/\((\d+)%\)/)?.[1] : 0,
      },
      {
        title: 'Customer Stories/Pillar Areas',
        value: typeof scoreBreakdown['Customer Stories/Pillar Areas'] === 'string' ? parseInt(scoreBreakdown['Customer Stories/Pillar Areas'].split(' ')[0]) : scoreBreakdown['Customer Stories/Pillar Areas'],
        percentage: typeof scoreBreakdown['Customer Stories/Pillar Areas'] === 'string' ? scoreBreakdown['Customer Stories/Pillar Areas'].match(/\((\d+)%\)/)?.[1] : 0,
      },
      {
        title: 'TFC Contributions',
        value: typeof scoreBreakdown['TFC Contributions'] === 'string' ? parseInt(scoreBreakdown['TFC Contributions'].split(' ')[0]) : scoreBreakdown['TFC Contributions'],
        percentage: typeof scoreBreakdown['TFC Contributions'] === 'string' ? scoreBreakdown['TFC Contributions'].match(/\((\d+)%\)/)?.[1] : 0,
      },
      {
        title: 'AWS Tenure',
        value: typeof scoreBreakdown['AWS Tenure'] === 'string' ? parseInt(scoreBreakdown['AWS Tenure'].split(' ')[0]) : scoreBreakdown['AWS Tenure'],
        percentage: typeof scoreBreakdown['AWS Tenure'] === 'string' ? scoreBreakdown['AWS Tenure'].match(/\((\d+)%\)/)?.[1] : 0,
      },
      {
        title: 'Time in Level',
        value: typeof scoreBreakdown['Time in Level'] === 'string' ? parseInt(scoreBreakdown['Time in Level'].split(' ')[0]) : scoreBreakdown['Time in Level'],
        percentage: typeof scoreBreakdown['Time in Level'] === 'string' ? scoreBreakdown['Time in Level'].match(/\((\d+)%\)/)?.[1] : 0,
      },
      {
        title: 'Time in Role',
        value: typeof scoreBreakdown['Time in Role'] === 'string' ? parseInt(scoreBreakdown['Time in Role'].split(' ')[0]) : scoreBreakdown['Time in Role'],
        percentage: typeof scoreBreakdown['Time in Role'] === 'string' ? scoreBreakdown['Time in Role'].match(/\((\d+)%\)/)?.[1] : 0,
      },
      {
        title: 'Performance Bar Rating',
        value: typeof scoreBreakdown['Performance Bar Rating'] === 'string' ? parseInt(scoreBreakdown['Performance Bar Rating'].split(' ')[0]) : scoreBreakdown['Performance Bar Rating'],
        percentage: typeof scoreBreakdown['Performance Bar Rating'] === 'string' ? scoreBreakdown['Performance Bar Rating'].match(/\((\d+)%\)/)?.[1] : 0,
      },
      {
        title: 'Feedback Providers',
        value: typeof scoreBreakdown['Feedback Providers'] === 'string' ? parseInt(scoreBreakdown['Feedback Providers'].split(' ')[0]) : scoreBreakdown['Feedback Providers'],
        percentage: typeof scoreBreakdown['Feedback Providers'] === 'string' ? scoreBreakdown['Feedback Providers'].match(/\((\d+)%\)/)?.[1] : 0,
      },
      {
        title: 'Other Contribution Stories',
        value: typeof scoreBreakdown['Other Contribution Stories'] === 'string' ? parseInt(scoreBreakdown['Other Contribution Stories'].split(' ')[0]) : scoreBreakdown['Other Contribution Stories'],
        percentage: typeof scoreBreakdown['Other Contribution Stories'] === 'string' ? scoreBreakdown['Other Contribution Stories'].match(/\((\d+)%\)/)?.[1] : 0,
      },
    ];


  return(
    <div style={{ display: 'flex', flexDirection: 'column' }}>
    {isDirectReportsLoading ? (
      <Spinner size="normal" />
    ) : (
      <>
        <Select
          options={esmOptions}
          selectedOption={selectedESM}
          onChange={handleESMChange}
          placeholder="Select ESM"
        />
        <br />
        <Select
          options={tamOptions}
          selectedOption={selectedTAM}
          onChange={handleTAMChange}
          placeholder="Select TAM"
          disabled={!selectedESM}
        />
        <br />
      </>
    )}
  {selectedESM && selectedTAM && (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

       
    {/* Flashbar component */}
    {showFlashbar && (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginLeft: '55px' }}>
  <Flashbar
    items={[
      {
        type: 'info',
        content: 'Please choose the mandatory fields - Performance Bar Rating and Feedback Providers (FPs) for the slider to move',
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setShowFlashbar(!showFlashbar)
      }
    ]}

  />
</div>
    )}


     <div style={{ marginLeft: '165px', marginBottom: '-5px', alignSelf: 'flex-start' }}>
      <h3 style={{ marginLeft: '-160px' }}>Confidence Signal:</h3>
      {/* Slider component */}
      <div className="slider-container">
        <Slider
          onChange={({ detail }) => setTotalScore(detail.value)}
          value={totalScore}
          max={1150}
          min={0}
          valueFormatter={value => getScoreLabel(Math.round(value))}
          ariaDescription="From Subpar to Exceptional (Higher)"
          referenceValues={[0, 600, 650, 675, 700, 725, 750, 775, 800, 825, 850, 875, 900, 925, 950, 960, 975, 985, 1000,1015, 1025, 1035, 1050, 1075, 1100, 1125, 1150]}

          step={1}
          className="custom-slider-width"
          readOnly={true}
        />
      </div>
    </div>

    <div className="grid-container">
      <div className="grid-item">
          <div style={{ padding: '0 20px', maxWidth: '100%' }}>
          {isOverallLoading ? (
      <Spinner size="normal" />
    ) : (
            <Table
              renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) => `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`}
              className="custom-table"
              columnDefinitions={[
                {
                  id: "Metrics Name",
                  header: "Metrics Name",
                  cell: item => item.category,
                  sortingField: "category"
                },
                {
                  id: "Score",
                  header: "Score",
                  cell: item => item.score,
                  sortingField: "score"
                },
                {
                  id: "Max Score",
                  header: "Max Score",
                  cell: item => item.max_Score,
                  sortingField: "max_score"
                },
                {
                  id: "Percentage",
                  header: "Percentage",
                  cell: item => item.percentage,
                  sortingField: "percentage"
                }
              ]}
              enableKeyboardNavigation
              items={tableData}
              loadingText="Loading resources"
              sortingDisabled
              wrapLines
              empty={
                <Box
                  margin={{ vertical: "xs" }}
                  textAlign="center"
                  color="inherit"
                >
                  <SpaceBetween size="m">
                    <b>No resources</b>
                    <Button>Create resource</Button>
                  </SpaceBetween>
                </Box>
              }
              header={
                <Header>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Score Breakdown</span>
                  </div>
                </Header>
              }
        
            />
    )}
          </div>
          <br />
  
      </div>

      <div style={{ width: '100%' }}>
        <Box>
          <PieChart
            data={pieChartData}
            detailPopoverContent={(datum, sum) => [
              { key: 'Score', value: datum.value },
              { key: 'Percentage', value: `${datum.percentage}%` },
            ]}
            segmentDescription={(datum, sum) => `${datum.value} (${datum.percentage}%)`}
            ariaDescription="Pie chart showing the score breakdown."
            hideLegend
            hideFilter
            ariaLabel="Score Breakdown Pie Chart"
            empty={
              <Box textAlign="center" color="inherit">
                <b>No data available</b>
                <Box variant="p" color="inherit">
                  There is no data available
                </Box>
              </Box>
            }
            noMatch={
              <Box textAlign="center" color="inherit">
                <b>No matching data</b>
                <Box variant="p" color="inherit">
                  There is no matching data to display
                </Box>
                <Button>Clear filter</Button>
              </Box>
            }
            
          />
        </Box>
      </div>

    </div>
  </div>
)}

        {
          selectedESM && selectedTAM && (
            <Cards

              cardDefinition={{
                header: item => item.name,
                sections: [
                  {
                    id: "description",
                    header: "Description",
                    content: item => item.description
                  },
                  {
                    id: "GoalIndicator",
                    header: "Goal Indicator :" ,
                    //content: item => item.GoalIndicator
                  },
                  {
                    id: "UpdatePillar",
                    content: item => {
                      if (item.name === "Total Tech Pillar Areas") {
                        const customerStories = workSummaries.filter(summary => summary.workSummaryType === "Customer");

                        console.log("customerStories customerStories", customerStories)

                        const color = pillarColor;
                        const [showContent, setShowContent] = useState(false);
                  
                        return (
                          <div style={{ marginBottom: '-120px' }}>
                            <span style={{ borderRadius: '30px', backgroundColor: color, textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize: '17px', display: 'block', whiteSpace: 'nowrap', width: 'auto', fontWeight: 'bold', padding: '2px 5%', textAlign: 'center', marginBottom: '25px', marginTop: '-5px' }}>
                              Level {((TAM_level) + 1)}
                            </span>
                  
                            {/* <Multiselect
                              selectedOptions={selectedCustomerStories}
                              onChange={handleCustomerStories}
                              options={CustomerStories.map(option => ({ ...option, disabled: selectedCustomerStories.length > 0 && !selectedCustomerStories.includes(option) }))}
                              placeholder="Choose Story"
                              keepOpen={false}
                            /> */}

                            <div id="UpdateTfc">
                              {updatePillarMessage && (
                                <div className="update-pillar-message">
                                  {updatePillarMessage}
                                </div>
                              )}

                            </div>
{/* 

                            <div style={{ marginTop: '35px', marginLeft: '35px' }}>
                              <Button onClick={() => handleUpdateEntry((selectedTAM.value), selectedCustomerStories, 'techPillar')}>Update the Pillars/Stories</Button>
                              <br />
                              <br />
                              <span style={{ marginLeft: '-25px', fontSize: '13px', color: 'white' }}>
                                (Remove (X) the current selection to update the Pillars/Stories)
                              </span>
                              {otherpillarUpdateSuccess && (
                                <div style={{ color: 'orange', padding: '5px' }}>
                                  Pillars/Stories status updated successfully!
                                </div>
                              )}
                            </div> */}
                  
                            <br />
                  
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Button onClick={() => setShowContent(!showContent)}>
                                Click to View Stories/Dimensions/Pillars
                              </Button>
                            </div>
                  
                            {showContent && (
                              <div style={{ border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
                                <div style={{ marginLeft: 'auto', width: '90%' }}>
                                  {customerStories.map(story => (
                                    <div key={story.id}>
                                      <h4>Title: {story.title}</h4>
                                      <div style={{ marginLeft: '20px' }}>
                                        <h4>Dimensions:</h4>
                                          {story.baselineRoleDimensionIds && story.baselineRoleDimensionIds.length > 0 ? (
                                            story.baselineRoleDimensionIds.map(dimensionId => (
                                              <p key={`${story.id}-${dimensionId}`} style={{ marginLeft: '20px' }}>
                                                - {dimensionIdToLabel[dimensionId] || "Unknown dimension"}
                                              </p>
                                            ))
                                          ) : (
                                            <p style={{ marginLeft: '20px' }}>No baseline role dimensions found</p>
                                          )}

                                        {/* {story.baselineRoleDimensionIds && story.baselineRoleDimensionIds.length > 0 ? (
                                              <>
                                                {story.baselineRoleDimensionIds.forEach((id, index) => {
                                                  const { baselineRoleDimension } = useBaselineRoleDimension(id, 5);
                                                  const titles = [baselineRoleDimension?.title || 'N/A'];
                                                  return (
                                                    <p key={`${story.id}-baseline-${id}`} style={{ marginLeft: '20px' }}>
                                                      {titles.join(', ')}
                                                    </p>
                                                  );
                                                })}
                                              </>
                                            ) : (
                                              <p style={{ marginLeft: '20px' }}>No baseline role dimensions found</p>
                                            )} */}
    
                                      </div>
                        
                                      <div style={{ marginLeft: '20px' }}>
                                      <h4>Pillars:</h4>
                                      {story.leadershipPrinciples && story.leadershipPrinciples.length > 0 ? (
                                        (() => {
                                          const filteredPrinciples = story.leadershipPrinciples.filter(principle =>
                                            principle.toLowerCase().includes('level')
                                          );
                                          return filteredPrinciples.length > 0 ? (
                                            filteredPrinciples.map((principle, index) => (
                                              <p key={`${story.id}-principle-${index}`} style={{ marginLeft: '20px' }}>
                                                - {principle}
                                              </p>
                                            ))
                                          ) : (
                                            <p style={{ marginLeft: '20px' }}>No Pillars Covered</p>
                                          );
                                        })()
                                      ) : (
                                        <p style={{ marginLeft: '20px' }}>No Pillars Covered</p>
                                      )}
                                    </div>

                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }
                    }
                  }, 
                  
                {
                  id: "TimeInRole",
                  content: item => {
                    if (item.name === "Time At Amazon") {

                     const years =   Number(hiredyears);
                     const color = getTimeInAmazonColor(TAM_level, years );
                      return (

                        <div>
                          <span style={{ borderRadius: '30px', backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', display: 'block',whiteSpace: 'nowrap', width:'auto', fontWeight: 'bold', padding: '2px 5%', textAlign: 'center', marginBottom: '30px', marginTop: '-25px' }}>
                         Level {((TAM_level)+1)}</span>
                         {item.Status}: {`${hiredyears} years ${hireddays} days`} <br/><br/>
                         Hired Date: {hiredDate}

                        </div>

                      );
                    }

                    if (item.name === "Time in Current Level") {

                      const years =  Number(levelentryyears);
                      const color = getTimeInCurrenteColor(TAM_level, years );
                       return (
                         <div>
                       <span style={{ borderRadius: '30px', backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 5%', textAlign: 'center', marginBottom: '30px', marginTop: '-25px' }}>
                          Level {((TAM_level)+1)}</span>
                          {item.Status}: {`${levelentryyears} years ${levelentrydays} days`} <br/><br/>
                          Level Entry Date: {joblevelentrydate}
                         </div>

                       );
                     }

                     if (item.name === "Time in Role") {

                      const years =  Number(roleentryyears);
                      const color = getTimeInRoleColor(TAM_level, years );
                       return (
                         <div>
                       <span style={{ borderRadius: '30px', backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 5%', textAlign: 'center', marginBottom: '30px', marginTop: '-25px' }}>
                       Level {((TAM_level)+1)}</span>
                          {item.Status}: {`${roleentryyears} years ${roleentrydays} days`} <br/><br/>
                          Role Entry Date: {roleentrydate}
                        
                       
                         </div>
                         

                       );
                     }

                  }
                },
                {
                  id: "UpdateCert",
                  content: item => {
                    if (item.name === "Maintain Technical Certifications") {
                      const certCriteria = calculateCertCriteria;
                      return (
                        <div>
                               <span style={{ borderRadius: '30px', backgroundColor: certCriteria as string, textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px',whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 5%', textAlign: 'center', marginBottom: '25px', marginTop: '-50px' }}>
                               Level {((TAM_level)+1)}</span>
                          <Multiselect
                            selectedOptions={selectedCerts}
                            onChange={handleCertMultiSelectChange}
                            options={certificateOptions}
                            placeholder="Edit certificates"
                          />
                        </div>
                      );
                    }

                  }
                },
                {
                  id: "UpdateTfc",
                  content: item => {

                    if (item.name === "Technical Contributions (TFC)") {

                      const color = getTfcStatusColor(TAM_level)
                    
             
                      return (
                        <div>
                          <span style={{ borderRadius: '30px', backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 5%', textAlign: 'center', marginBottom: '25px', marginTop: '-65px' }}>
                            Level {((TAM_level)+1)}</span>

                            <Multiselect selectedOptions={selectedTfcStatus} 
                            onChange={handleTfcStatusMultiSelectChange} 
                            options={tfcPillarOptions.map(option => ({ ...option, disabled: selectedTfcStatus.length > 0 && !selectedTfcStatus.includes(option) }))} 
                            placeholder="Choose status" 
                            keepOpen={false}
                             />
                                      <div style={{ marginTop: '35px', marginLeft: '35px' }}>
                                     
                                     <Button onClick={() => handleUpdateEntry((selectedTAM.value),selectedTfcStatus, 'tfcMembership' )}>Update TFC Membership</Button>
                                     <br/>
                                     <br/>
                                     <span style={{ marginLeft: '-25px', fontSize: '13px', color: 'white' }}>
                                 (Remove (X) the current selection to update the membership)
                                      </span>
                                     {tfcupdateSuccess && (
                                                        <div style={{  color: 'orange', padding: '5px' }}>
                                                           Membership status updated successfully!
                                                        </div>
                                                      )}
                                      </div>
                  
                            </div>
                      );

                    }
                  }
                },
                {
                  id: "UpdatePerformanceRating",
                  content: item => {

                    if (item.name === "Performance Bar Rating") {

                      const color = getPerformanceBarColor()
                      return (
                        <div>
                          <span style={{ borderRadius: '30px', backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 5%', textAlign: 'center', marginBottom: '25px', marginTop: '-85px' }}>
                            Level {((TAM_level)+1)}</span>

                        <Multiselect
                          selectedOptions={selectedPerformanceStatus}
                          onChange={handlePerformanceStatusMultiSelectChange}
                          options={performanceRatingOptions.map(option => ({ ...option, disabled: selectedPerformanceStatus.length > 0 && !selectedPerformanceStatus.includes(option) }))} 
                          placeholder="Choose rating"
                          keepOpen={false}
                        />
                                <div style={{ marginTop: '35px', marginLeft: '35px' }}>
                                     
                                     <Button onClick={() => handleUpdateEntry((selectedTAM.value),selectedPerformanceStatus, 'rating' )}>Update Performance Rating</Button>
                                
                                     <br/>
                                     <br/>
                                     <span style={{ marginLeft: '-25px', fontSize: '13px', color: 'white' }}>
                                 (Remove (X) the current selection to update the rating)
                                      </span>
                                     {ratingUpdateSuccess && (
                                                        <div style={{  color: 'orange', padding: '5px' }}>
                                                           Rating updated successfully!
                                                        </div>
                                                      )}

                                      </div>



                                      
                            </div>
                      );

                    }
                  }
                },
                {
                  id: "Feedback Providers(FPs) > 24 months at Amazon, > 12 month in Role/Level, Min no of FPs, with 50% of FPs in Technical role",
                  content: item => {
                    if (item.name === "Feedback Providers(FPs) > 24 months at Amazon, > 12 month in Role/Level, Min no of FPs, with 50% of FPs in Technical role") {
              
                
                      return (
                        <div>
                          <span style={{ borderRadius: '30px', backgroundColor: getFeedbackColor(TAM_level),  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 5%', textAlign: 'center', marginBottom: '25px', marginTop: '-100px' }}>
                          Level {((TAM_level)+1)}</span>
                          <SpaceBetween direction="vertical" size="s">
                            <FormField label="Enter a Feedback Provider Name">
                              <input
                                type="text"
                                value={newTAMName}
                                onChange={(e) => setNewTAMName(e.target.value)}
                              />
                             <Button onClick={(event) => handleAddFeedbackProvider(newTAMName)}>Add</Button>
                            </FormField>
                            <div>
                              <h4>Added Feedback Providers:</h4>
                              <ul>
                                {feedbackProviders.map((provider, index) => (
                                  <li key={index}>
                                    {provider.name} ({provider.status})
                                    <Button onClick={() => handleRemoveFeedbackProvider(index)}>Remove</Button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </SpaceBetween>

                          <div style={{ marginTop: '30px', marginLeft: '35px' }}>
                                     
                                     <Button onClick={() => handleUpdateEntry((selectedTAM.value),feedbackProviders, 'feedbackProviders' )}>Update Feedback Providers List</Button>
                                
                                     <br/>
                                     <br/>
                                     <span style={{ marginLeft: '-25px', fontSize: '13px', color: 'white' }}>
                               
                                      </span>
                                     {feedbackUpdateSuccess && (
                                                        <div style={{  color: 'orange', padding: '5px' }}>
                                                           Feedback providers updated successfully!
                                                        </div>
                                                      )}


                                
                                      </div>


                                      <div>      
                                  </div>

                        </div>
                      );
                    }
                  }
                }
                  ,
                {
                  id: "OtherContributions",
                  content: item => {

                    if (item.name === "Other Contributions (Non TFC)") {
                      
                      const customerStories = workSummaries.filter(summary => summary.workSummaryType === "Amazon");
                      const color = getOtherContributionsBarColor()
                      return (
                        <div>
                          <span style={{ borderRadius: '30px', backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',  whiteSpace: 'nowrap', width:'auto',fontSize:'17px', display: 'block', fontWeight: 'bold', padding: '2px 5%', textAlign: 'center', marginBottom: '25px', marginTop: '-110px' }}>
                            Level {((TAM_level)+1)}</span>

                        <Multiselect
                          selectedOptions={selectedOtherContributionStatus}
                          onChange={handleOtherContributionsStatusMultiSelectChange}
                          options={OtherContributionsOptions.map(option => ({ ...option, disabled: selectedOtherContributionStatus.length > 0 && !selectedOtherContributionStatus.includes(option) }))} 
                          placeholder="Choose Contribution Stories"
                          keepOpen={false}
                        />
                                <div style={{ marginTop: '35px', marginLeft: '35px' }}>
                                     
                                     <Button onClick={() => handleUpdateEntry((selectedTAM.value),selectedOtherContributionStatus, 'otherContributions' )}>Update Other Contributions</Button>
                                
                                     <br/>
                                     <br/>
                                     <span style={{ marginLeft: '-25px', fontSize: '13px', color: 'white' }}>
                                 (Remove (X) the current selection to update Other Contributions)
                                      </span>
                                     {othercontributionsUpdateSuccess && (
                                                        <div style={{  color: 'orange', padding: '5px' }}>
                                                           Other Contributions updated successfully!
                                                        </div>
                                                      )}

                                      </div>

                                                                      <br/> 

                                <div  style={{ marginLeft: 'auto', width: '90%' }}>

                                {customerStories.map(story => (
                                  <div key={story.id}>
                                    <h4>Title: {story.title}</h4>
                                    {story.categories.map(category => (
                                      <p key={category.id}> - {category.label}</p>
                                    ))}
                                  </div>
                                ))}
                                </div>

                            </div>
                      );

                    }
                  }
                }
             ]
            }}
              items={[
                {
                  "name": "Maintain Technical Certifications",
                  "alt": "First",
                  "description": "Overall Certifications Achieved - Professional/Specialty/Associate/Foundational/Practitioner",
                  "GoalIndicator": "",
                  "Status": ""
                },
                {
                  "name": "Total Tech Pillar Areas",
                  "alt": "Second",
                  "description": "Total number of Tech Pillar Areas TAM has shown expertise at 200/300/400 Level",
                  "GoalIndicator": "Red",
                  "Status": "",
                  "TAMDetails": {TAM_level}
                },
                {
                  "name": "Technical Contributions (TFC)",
                  "alt": "Third",
                  "description": "Calculated total Points for last 1 year ",
                  "GoalIndicator": "Orange",
                  "Status": "Edit Status"
                },
                {
                  "name": "Time At Amazon",
                  "alt": "Fourth",
                  "description": "Time in Amazon",
                  "GoalIndicator": "Green",
                  "Status": "Duration"
                },
                {
                  "name": "Time in Current Level",
                  "alt": "Fifth",
                  "description": "Time in Current Level",
                  "GoalIndicator": "Red",
                  "Status": "Duration"
                },
                {
                  "name": "Time in Role",
                  "alt": "Sixth",
                  "description": "Time in Role",
                  "GoalIndicator": "Red",
                  "Status": "Duration"
                },
                {
                  "name": "Performance Bar Rating",
                  "alt": "Seventh",
                  "description": "Check to make sure individual has been exceeding the bar in last 2 years",
                  "GoalIndicator": "Orange",
                  "Status": "Edit Rating"
                },
                {
                  "name": "Feedback Providers(FPs) > 24 months at Amazon, > 12 month in Role/Level, Min no of FPs, with 50% of FPs in Technical role",
                  "alt": "Eigth",
                  "description": "Number of Feedback Providers & Review all Feedback providers to check that each have been at Amazon at least 2 years.",
                  "GoalIndicator": "Orange",
                  "Status": "Edit Feedback Provider"
                },
                {
                  "name": "Other Contributions (Non TFC)",
                  "alt": "Nineth",
                  "description": "Other contributions stories (tech and non-tech) from pathfinder or strategy pillar projects",
                  "GoalIndicator": "Orange",
                  "Status": "Edit Contributions"
                },

              ]}
              cardsPerRow={[
                {
                  "cards": 1
                },
                {
                  "minWidth": 500,
                  "cards": 3
                }
              ]}
              loadingText="Loading resources"
    
              empty={<div className="awsui-util-t-c">
              <div className="awsui-util-pt-xs awsui-util-mb-xs">
                <b>
                  No resources
                </b>
              </div>
              <p className="awsui-util-mb-s">
                No resources to display
              </p>
              <div className="awsui-util-mb-m">
                <Button>
                  Create resource
                </Button>
              </div>
            </div>}
            ></Cards>       
              ) }        

     </div>
   );
 };

export default PromoReadinessTable;


