import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import { DatePicker } from '@amzn/awsui-components-react/polaris';
import {
  useCalibratedDocumentReviewerRecords,
  useDocumentReviewerActions,
  useDocumentReviewSession,
  useDocumentReviewSessionActions,
} from '@/api/document-review';
import { useAppContext } from '@/contexts';
import { CalibratedReviewerItem, DocumentReviewSessionResource, Nullable } from '@/models';
import { DocumentReviewSessionState } from '@/api/API';
import { DocumentReviewSessionsPage } from '@/common/pages';
import useNavigator from '@/common/hooks/use-navigator';
import { checkAlias, checkDate, checkDocumentReviewers, checkWorkdocsLink } from './form-validator';
import { MAX_ALIAS_LENGTH } from '@/common/constants';
import { getButtonText, getRemainingCharCount } from './common-components';
import PromoSpinner from '../common/PromoSpinner';
import { usePapiProfile } from '@/api/amzn-people';

interface FormParams {
  documentReviewSessionId?: string;
}

const DocumentReviewSessionCreateEditForm = ({ documentReviewSessionId }: FormParams): JSX.Element => {
  const { currentUser, spoofUser } = useAppContext();
  const user = spoofUser?.alias || currentUser?.alias;
  const [id, setId] = useState(documentReviewSessionId);
  const [candidateAlias, setCandidateAlias] = useState('');
  const [isFindingAlias, setIsFindingAlias] = useState(false);
  const [confirmedCandidateAlias, setConfirmedCandidateAlias] = useState('');
  const [confirmedCandidateAliasText, setConfirmedCandidateAliasText] = useState('');
  const [reviewers, setReviewers] = useState<string[]>([]);
  const [originalReviewers, setOriginalReviewers] = useState<CalibratedReviewerItem[]>([]);
  const [workdocsLink, setWorkdocsLink] = useState('');
  const [startDate, setStartDate] = useState('');
  const [chimeLink, setChimeLink] = useState('');
  const { documentReviewSession, isDocumentReviewSessionLoading } = useDocumentReviewSession(id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { goToPage } = useNavigator();
  const { getPapiProfile } = usePapiProfile();
  const { reviewSessionActions: documentReviewActions } = useDocumentReviewSessionActions(id);
  const { attachReviewerByAlias, detachReviewer } = useDocumentReviewerActions();
  const { calibratedDocumentReviewers } = useCalibratedDocumentReviewerRecords();
  const reviewerOptions = calibratedDocumentReviewers
    .filter((reviewer) => reviewer.alias !== user && reviewer.alias !== candidateAlias)
    .map((reviewer) => ({
      label: reviewer.alias,
      value: reviewer.alias,
    }));
  const [aliasValidationText, setAliasValidationText] = useState<string>();
  const [reviewerValidationText, setReviewerValidationText] = useState<string>();
  const [wdLinkValidationText, setWDLinkValidationText] = useState<string>();
  const [dateTimeValidationText, setDateValidationText] = useState<string>();

  const validateAndAddAlias = useCallback(async () => {
    if (checkAlias(candidateAlias, user)) {
      setAliasValidationText(checkAlias(candidateAlias, user));
    } else setAliasValidationText('');

    if (!aliasValidationText) {
      const userLookup = await getPapiProfile(candidateAlias.trim().toLowerCase());
      if (!userLookup) {
        setAliasValidationText(`No user found with alias [${candidateAlias}].`);
        setConfirmedCandidateAliasText(``);
      } else {
        setConfirmedCandidateAlias(candidateAlias);
        setConfirmedCandidateAliasText(`Profile for ${candidateAlias} found`);
      }
    }
  }, [aliasValidationText, candidateAlias, getPapiProfile, user]);

  const isParamsValid = () => {
    let isValid = true;

    const reviewersError = checkDocumentReviewers(reviewers, user, confirmedCandidateAlias);
    const wdError = checkWorkdocsLink(workdocsLink);
    const dateError = checkDate(startDate);
    setAliasValidationText(!confirmedCandidateAlias ? 'Please click Find to confirm user exists' : '');
    setReviewerValidationText(reviewersError);
    setWDLinkValidationText(wdError);
    setDateValidationText(dateError);

    if (!confirmedCandidateAlias || reviewersError || wdError || dateError) {
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    if (documentReviewSession) {
      setCandidateAlias(documentReviewSession.candidateAlias);
      setConfirmedCandidateAliasText(`Profile for ${documentReviewSession.candidateAlias} found`);
      setConfirmedCandidateAlias(documentReviewSession.candidateAlias);
      setWorkdocsLink(documentReviewSession.workdocsLink);
      setChimeLink(documentReviewSession.chimeLink ?? '');
      if (documentReviewSession?.documentReviewers.length) {
        setReviewers(documentReviewSession.documentReviewers.map((reviewer) => reviewer.alias));
        setOriginalReviewers(documentReviewSession.documentReviewers);
      }
      if (documentReviewSession.sessionStart) {
        setStartDate(documentReviewSession.sessionStart);
      }
    }
  }, [documentReviewSession]);

  const saveDocumentReviewSession = async () => {
    try {
      const input = {
        candidateAlias: confirmedCandidateAlias,
        workdocsLink,
        chimeLink,
        sessionStart: startDate,
      };

      let result: Nullable<DocumentReviewSessionResource>;
      if (documentReviewSession?.id) {
        if (
          documentReviewSession.candidateAlias !== input.candidateAlias ||
          documentReviewSession.workdocsLink !== input.workdocsLink ||
          documentReviewSession.chimeLink !== input.chimeLink ||
          documentReviewSession.sessionStart !== input.sessionStart
        ) {
          result = await documentReviewActions.update({
            ...input,
          });
        } else {
          result = documentReviewSession;
        }
      } else {
        result = await documentReviewActions.create({
          ...input,
          sessionState: DocumentReviewSessionState.SCHEDULED,
          ownerAlias: user ?? '',
        });
      }

      if (result?.id) {
        const reviewSessionId = result.id;
        const originalReviewerAliases = originalReviewers.map((item) => item.alias);
        const reviewersAliasesToAdd = reviewers.filter((reviewer) => !originalReviewerAliases.includes(reviewer));
        const reviewerAliasesToRemove = originalReviewerAliases.filter(
          (originalReviewerAlias) => !reviewers.includes(originalReviewerAlias)
        );

        const reviewerMappingIdsToRemove = reviewerAliasesToRemove.map((alias) => {
          const reviewerRecord = result.documentReviewers.find((item) => item.alias === alias);
          return reviewerRecord?.id;
        });

        if (reviewerMappingIdsToRemove.length) {
          await Promise.all(
            reviewerMappingIdsToRemove.map(async (reviewerMappingId) =>
              detachReviewer({
                id: reviewerMappingId!,
              })
            )
          );
        }

        if (reviewersAliasesToAdd.length) {
          await Promise.all(
            reviewersAliasesToAdd.map(async (reviewer) =>
              attachReviewerByAlias({
                documentReviewSessionRecordID: reviewSessionId,
                alias: reviewer,
              })
            )
          );
        }
      }
      setId(result?.id);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSave = () => {
    if (!isParamsValid()) {
      return;
    }

    setIsSubmitting(true);
    saveDocumentReviewSession().then(() => goToPage(DocumentReviewSessionsPage));
  };

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="l">
          <Button onClick={() => onSave()} variant="primary" loading={isSubmitting}>
            {getButtonText(isSubmitting, documentReviewSessionId)}
          </Button>
          <Button onClick={() => navigate(-1)} variant="link">
            Cancel
          </Button>
        </SpaceBetween>
      }
    >
      {isDocumentReviewSessionLoading ? (
        <PromoSpinner variant="inline" />
      ) : (
        <SpaceBetween direction="vertical" size="l">
          <FormField
            label="Candidate*"
            description="Enter candidate alias"
            errorText={aliasValidationText || ''}
            constraintText={`${getRemainingCharCount(candidateAlias as string, MAX_ALIAS_LENGTH)} characters remaining.`}
          >
            <SpaceBetween direction="horizontal" size="xs">
              <Input
                ariaRequired
                inputMode="text"
                placeholder="Ex: jsmith"
                onBlur={() => setAliasValidationText(checkAlias?.(candidateAlias, user))}
                onChange={({ detail }) => {
                  if (aliasValidationText) {
                    setAliasValidationText('');
                  }
                  if (detail.value.length > MAX_ALIAS_LENGTH) {
                    return;
                  }
                  setCandidateAlias(detail.value.trim());
                }}
                value={candidateAlias}
              />
              <Button
                variant="primary"
                iconName="search"
                loading={isFindingAlias}
                disabled={!candidateAlias}
                onClick={() => {
                  setIsFindingAlias(true);
                  validateAndAddAlias().finally(() => setIsFindingAlias(false));
                }}
              >
                Find
              </Button>
              <Input ariaRequired inputMode="text" Read-only value={confirmedCandidateAliasText} />
            </SpaceBetween>
          </FormField>
          <FormField
            label="Calibrated Doc Reviewers*"
            description="Select 1-4 reviewers"
            errorText={reviewerValidationText}
          >
            <Multiselect
              placeholder="Select Reviewers*"
              selectedOptions={reviewers.map((reviewer) => {
                return {
                  label: reviewer,
                  value: reviewer,
                };
              })}
              onChange={({ detail }) => setReviewers(detail.selectedOptions.map((reviewer) => reviewer.value!))}
              options={reviewerOptions}
            />
          </FormField>
          <FormField label="Workdocs link to doc artifact" errorText={wdLinkValidationText || ''}>
            <Input
              ariaRequired
              inputMode="text"
              placeholder="Ex: https://amazon.awsapps.com/workdocs-preview/index.html#/document/1234"
              value={workdocsLink}
              onBlur={() => setWDLinkValidationText(checkWorkdocsLink?.(workdocsLink))}
              onChange={({ detail }) => {
                if (wdLinkValidationText) {
                  setWDLinkValidationText('');
                }
                setWorkdocsLink(detail.value.trim());
              }}
            />
          </FormField>
          <FormField label="Due date*" errorText={dateTimeValidationText || ''}>
            <SpaceBetween direction="horizontal" size="xs">
              <DatePicker
                placeholder="YYYY/MM/DD"
                value={startDate}
                onBlur={() => setDateValidationText(checkDate?.(startDate))}
                onChange={({ detail }) => {
                  if (dateTimeValidationText) {
                    setDateValidationText('');
                  }
                  setStartDate(detail.value.trim());
                }}
              />
            </SpaceBetween>
          </FormField>
          <FormField label="Chime link">
            <Input
              ariaRequired
              inputMode="text"
              placeholder="Input Chime link (optional)"
              value={chimeLink}
              onChange={({ detail }) => {
                setChimeLink(detail.value.trim());
              }}
            />
          </FormField>
        </SpaceBetween>
      )}
    </Form>
  );
};

export default DocumentReviewSessionCreateEditForm;
