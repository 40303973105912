import React, { useState } from 'react';
import { Table, Header, Pagination, Button, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import { useCollection } from '@amzn/awsui-collection-hooks';
import PanelReviewSessionsModal, { SessionModalMode } from './components/PanelReviewSessionsModal';
import CancelPanelReviewSessionsModal from './components/CancelPanelReviewSessionsModal';
import { useSplitPanelControls } from '@/contexts';
import ViewPanelReviewSession from './components/ViewPanelReviewSession/ViewPanelReviewSession';

const defaultItems = [
  {
    sessionId: '123e4567-e89b-12d3-a456-426614174000',
    dateTime: new Date('2024-08-01T10:00:00').toLocaleString(),
    sessionOwner: 'John Doe',
    candidate: 'Jane Smith',
    state: 'SCHEDULED',
  },
  {
    sessionId: '456e7890-e89b-12d3-a456-426614174001',
    dateTime: new Date('2024-09-15T14:30:00').toLocaleString(),
    sessionOwner: 'Alice Johnson',
    candidate: 'Bob Williams',
    state: 'COMPLETED',
  },
  {
    sessionId: '789e0123-e89b-12d3-a456-426614174002',
    dateTime: new Date('2024-10-20T09:00:00').toLocaleString(),
    sessionOwner: 'Emily Davis',
    candidate: 'Michael Brown',
    state: 'SCHEDULED',
  },
];

const columnDefinitions = [
  {
    id: 'sessionId',
    header: 'Session ID',
    cell: (item) => item.sessionId,
    sortingField: 'sessionId',
  },
  {
    id: 'candidate',
    header: 'Candidate',
    cell: (item) => item.candidate,
    sortingField: 'candidate',
  },
  {
    id: 'sessionOwner',
    header: 'Session Owner',
    cell: (item) => item.sessionOwner,
    sortingField: 'sessionOwner',
  },
  {
    id: 'dateTime',
    header: 'Date Time',
    cell: (item) => item.dateTime,
    sortingField: 'dateTime',
  },
  {
    id: 'state',
    header: 'State',
    cell: (item) => item.state,
    sortingField: 'state',
  },
];

const sessionData = {
  completedDocReviewSession: {
    label: 'Alice Johnson - Session ID: 001',
    value: '001',
  },
  candidate: 'Alice Johnson',
  reviewers: [
    {
      label: 'David Lee',
      value: 'david',
      isSkipLevel: false,
    },
    {
      label: 'Frank Miller',
      value: 'frank',
      isSkipLevel: false,
    },
    {
      label: 'Emma Watson',
      value: 'emma',
      isSkipLevel: true,
    },
  ],
  skipLevelReviewer: {
    label: 'Emma Watson',
    value: 'emma',
    isSkipLevel: true,
  },
  workdocsLink: 'https://workdocs.example.com/document/001',
  date: '2024-08-15',
  time: '14:30',
  timezone: {
    label: 'UTC-5 (EST)',
    value: 'UTC-5',
  },
  chimeLink: 'https://example.com/chime/session1',
};

const PanelReviewSessionsTable = ({ items: initialItems = defaultItems }) => {
  const { setSplitPanelData } = useSplitPanelControls();

  const [sessionModalMode, setSessionModalMode] = useState<SessionModalMode>(SessionModalMode.CREATE);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const { items, paginationProps, collectionProps } = useCollection(initialItems, {
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {},
  });

  const handleCreateEditSession = (newSessionModalMode: SessionModalMode) => {
    setSessionModalMode(newSessionModalMode);
    setIsCreateModalVisible(true);
  };

  const handleCancelSession = () => {
    setIsCancelModalVisible(true);
  };

  const handleItemSelect = ({ detail }) => {
    const newSelectedItem = detail.selectedItems[0];
    setSelectedItem(newSelectedItem);
    setSplitPanelData({
      header: `View ${newSelectedItem.candidate} Panel Review Session Details`,
      content: <ViewPanelReviewSession />,
    });
  };

  return (
    <>
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions}
        items={items}
        selectionType="single"
        selectedItems={selectedItem === null ? [] : [selectedItem]}
        onSelectionChange={handleItemSelect}
        header={
          <Header
            counter={`(${items.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={handleCancelSession}
                  disabled={selectedItem === null || ['COMPLETED', 'CANCELLED'].includes(selectedItem.state)}
                >
                  Cancel Session
                </Button>
                <Button disabled={selectedItem === null} onClick={() => handleCreateEditSession(SessionModalMode.EDIT)}>
                  Edit Session
                </Button>
                <Button variant="primary" onClick={() => handleCreateEditSession(SessionModalMode.CREATE)}>
                  Create Session
                </Button>
              </SpaceBetween>
            }
          >
            Panel Review Sessions
          </Header>
        }
        pagination={<Pagination {...paginationProps} />}
      />
      <PanelReviewSessionsModal
        isOpen={isCreateModalVisible}
        sessionModalMode={sessionModalMode}
        sessionData={sessionModalMode === SessionModalMode.EDIT ? sessionData : undefined}
        onClose={() => setIsCreateModalVisible(false)}
        onCreate={(formData) => {
          console.log('call api with', { formData });
          setIsCreateModalVisible(false);
          setSelectedItem(null);
        }}
      />
      <CancelPanelReviewSessionsModal
        isOpen={isCancelModalVisible}
        onClose={() => setIsCancelModalVisible(false)}
        onCancel={() => {
          console.log('call api with', { selectedItem });
          setIsCancelModalVisible(false);
          setSelectedItem(null);
          setSplitPanelData();
        }}
      />
    </>
  );
};

export default PanelReviewSessionsTable;
