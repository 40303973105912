import { ForwardedRef, forwardRef, memo } from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { useTableContext } from '@/contexts/CrossTableContext';
import { useWorkSummary, useWorkSummaryActions } from '@/api/work-summary';
import PromoSpinner from '../common/PromoSpinner';

interface DeleteWorkSummaryModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  contextKey: string;
  onSuccess?: () => void;
}

const DeleteWorkSummaryComponent = (
  { contextKey, isVisible, onSetVisibility, onSuccess }: DeleteWorkSummaryModalParams,
  ref: ForwardedRef<HTMLElement>
) => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { selectedId } = useTableContext(contextKey);
  const { workSummary, isLoading } = useWorkSummary(selectedId);
  const { isMutating, actions } = useWorkSummaryActions(workSummary?.id);

  function resetModal() {
    onSetVisibility(false);
  }

  const onDelete = () =>
    actions
      .delete()
      .then(() => onSuccess?.())
      .finally(resetModal);

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModal()}
      closeAriaLabel="Close dialog"
      visible={isVisible}
      size="medium"
      header="Delete work summary"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isMutating} onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button variant="primary" loading={isMutating} disabled={isMutating} onClick={() => onDelete()}>
              {isMutating ? 'Deleting...' : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {isLoading ? (
        <PromoSpinner variant="inline" size="big" />
      ) : (
        <SpaceBetween direction="vertical" size="m">
          <Box variant="span">
            Delete work summary{` `}
            <Box variant="span" fontWeight="bold">
              {workSummary?.title}
            </Box>
            {` `}
            permanently? This action cannot be undone.
          </Box>
          <Alert statusIconAriaLabel="warning">
            Proceeding with this action will delete the work summary and associated content.{' '}
          </Alert>
        </SpaceBetween>
      )}
    </Modal>
  );
};

const DeleteWorkSummaryModal = memo(forwardRef(DeleteWorkSummaryComponent));

export default DeleteWorkSummaryModal;
