import type { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import ProgressBar from '@amzn/awsui-components-react/polaris/progress-bar';
import { DEFAULT_EMPLOYEES_PER_PAGE, DEFAULT_EMPLOYEE_VIEW_AS_VALUE, RESOURCES } from '@/common/constants';
import {
  getPageSizes,
  getPreferences,
  getFilteredColumns,
  getEmployeeColumnDef,
  getTableDefinition,
} from '@/components/common/table/table-config-helper';
import { useAppContext } from '@/contexts/AppContext';
import {
  ColumnDefinition,
  PromoTableConfig,
  CandidateStatusItem,
  PromoTableDefinition,
  PropertyDefinition,
} from '@/models';
import { getAWSDateAsQuarter, getDurationFromNow } from '@/common/utils';
import {
  DatePropertyFilter,
  NumericPropertyFilter,
  StringPropertyFilter,
} from '@/components/common/table/table-filters';

/** Identifier for this table. Also used as the key for tracking context. */
export const TABLE_KEY = 'Candidate progress overview';

/** Constructs a {@link ProgressBar} */
const PromoPathStatus = ({ value }) => <ProgressBar value={value || 0} />;

const StringKeys: (keyof CandidateStatusItem)[] = ['id', 'alias', 'name', 'pathName'];
const NumericKeys: (keyof CandidateStatusItem)[] = ['jobLevel', 'targetLevel', 'progressPct'];
const DateKeys: (keyof CandidateStatusItem)[] = ['hireDate'];

export const properties: {
  [Property in keyof Partial<CandidateStatusItem>]: PropertyDefinition;
} = {
  jobLevel: {
    key: 'jobLevel',
    label: 'Job level',
  },
  targetQuarter: {
    key: 'targetQuarter',
    label: 'Target',
  },
  progressPct: {
    key: 'progressPct',
    label: 'Progress',
  },
  pathName: {
    key: 'pathName',
    label: 'Promo path',
  },
  alias: {
    key: 'alias',
    label: 'Candidate alias',
  },
  name: {
    key: 'name',
    label: 'Candidate name',
  },
  hireDate: {
    key: 'hireDate',
    label: 'Tenure',
  },
};

/** Columns to render on the `PromoPathProgress` management table. Columns are displayed in order. */
const STATIC_COLUMN_DEFINITIONS: ColumnDefinition<CandidateStatusItem>[] = [
  {
    id: properties.jobLevel?.key as string,
    header: properties.jobLevel?.label as string,
    cell: (e) => `L${e.jobLevel}`,
    sortingField: properties.jobLevel?.key as string,
    width: 120,
  },
  {
    id: properties.hireDate?.key as string,
    header: properties.hireDate?.label as string,
    cell: (e) => getDurationFromNow(e.hireDate) || '-',
    width: 180,
    sortingComparator: (a, b) => (a.hireDate && b.hireDate && a.hireDate > b.hireDate ? -1 : 1),
  },
  {
    id: properties.targetQuarter?.key as string,
    header: properties.targetQuarter?.label as string,
    cell: (e) => getAWSDateAsQuarter(e.targetQuarter) || '-',
    sortingField: properties.targetQuarter?.key as string,
  },
  {
    id: properties.progressPct?.key as string,
    header: properties.progressPct?.label as string,
    cell: (e) => (typeof e.totalCompleted === 'number' ? <PromoPathStatus value={e.progressPct} /> : '-'),
    sortingField: properties.progressPct?.key as string,
  },
  {
    id: properties.pathName?.key as string,
    header: properties.pathName?.label as string,
    cell: (e) => e.pathName || '-',
    sortingField: properties.pathName?.key as string,
  },
];

const ColumnDefinitions = (): ColumnDefinition<CandidateStatusItem>[] => {
  const appContext = useAppContext();
  return [getEmployeeColumnDef(TABLE_KEY, appContext), ...STATIC_COLUMN_DEFINITIONS];
};

const PromoPathProgressTableDef = getTableDefinition(TABLE_KEY, RESOURCES.CANDIDATE);

// Type alias for readability in code. Contains same props as parent.
type PromoPathProgressTableConfig = PromoTableConfig<PromoTableDefinition, CandidateStatusItem>;

/**
 * Class to set the table configuration for `PromoPathProgress` table.
 * See parent: {@link PromoTableConfig}
 */
const CreateTableConfig = (): PromoPathProgressTableConfig => {
  return {
    tableDef: PromoPathProgressTableDef,
    allColumns: ColumnDefinitions(),
    pageSizes: getPageSizes(),
    displayableColumns: getFilteredColumns(ColumnDefinitions()),
    defaultPreferences: getPreferences(
      DEFAULT_EMPLOYEES_PER_PAGE,
      getFilteredColumns(ColumnDefinitions()),
      DEFAULT_EMPLOYEE_VIEW_AS_VALUE
    ),
  };
};

export function getPropertyFilters() {
  const stringFilters = StringKeys.map((key) => StringPropertyFilter(properties[key]?.key, properties[key]?.label));
  const dateFilters = DateKeys.map((key) => DatePropertyFilter(properties[key]?.key, properties[key]?.label));
  const numericFilters = NumericKeys.map((key) => {
    if (key === 'jobLevel' || key === 'targetLevel') {
      return NumericPropertyFilter(properties[key]?.key, properties[key]?.label, (v: number) => `L${v}`);
    }
    return NumericPropertyFilter(properties[key]?.key, properties[key]?.label);
  });
  return [...stringFilters, ...dateFilters, ...numericFilters].filter(
    (i): i is PropertyFilterProperty => i !== undefined
  );
}

/** Hook to fetch a new instance of the table config. */
export function useTableConfig() {
  return CreateTableConfig();
}
