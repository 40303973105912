import { SelectableOption } from '@/models';

export const CLOUDSCAPE_AUDO_I18N_LANGUAGES = [
  'en',
  'en-GB',
  'de',
  'es',
  'fr',
  'ja',
  'it',
  'pt-BR',
  'ko',
  'zh-CN',
  'zh-TW',
  'id',
];

export const APPSYNC_MAX_ITEMS = 1000;

export const AUTH_OVERRIDE_HEADER = 'x-amzn-internal-alias-override';

export const TOP_NAV_ELEMENT_ID = 'promohub-app-header';

export const FEDERATED_USER_PREFIX = 'amazonfederate_';

export const RESOURCES = {
  CANDIDATE: {
    resourceName: 'candidate',
    idKey: 'alias',
  },
  WORKSUMMARY: {
    resourceName: 'work summary',
    resourceShortName: 'summary',
    idKey: 'id',
    hrefToCreate: '/candidate/work-summary/create',
    hrefToEdit: '/candidate/work-summary/edit',
    hrefToReview: '/manager/work-summary/review',
    hrefToEditAdmin: '/admin/edit',
  },
  PROMOPATH: {
    resourceName: 'promo path',
    resourceShortName: 'path',
    idKey: 'id',
    hrefToCreate: '/manager/promo-path/create',
    hrefToEdit: '/manager/promo-path/edit',
  },
  PROMOREADINESS: {
    resourceName: 'promo readiness',
    resourceShortName: 'promo readiness',
    idKey: 'id',
    hrefToCreate: '/manager/promo-readiness/create',
    hrefToEdit: '/manager/promo-readiness/edit',
  },
  FILERECORD: {
    resourceName: 'file',
    resourceShortName: 'file',
    idKey: 'id',
    hrefToCreate: '',
  },
  PEER_REVIEW: {
    resourceName: 'peer review',
    resourceShortName: 'review',
    idKey: 'id',
    hrefToCreate: '',
    hrefToEdit: '/peer-review/edit',
  },
  COMMENT: {
    resourceName: 'comment',
    resourceShortName: 'comment',
    idKey: 'id',
    hrefToCreate: '',
  },
  AUDITTRAIL: {
    resourceName: 'audit history item',
    resourceShortName: 'audit history item',
    idKey: 'id',
    hrefToCreate: '',
  },
  DOCUMENT_REVIEW_SESSION: {
    resourceName: 'document review session',
    resourceShortName: 'document review session',
    idKey: 'id',
    hrefToCreate: '/document-review-sessions/create',
    hrefToEdit: '/document-review-sessions/edit',
    hrefToReview: '/document-review-sessions/review',
  },
  CALIBRATED_REVIEWER: {
    resourceName: 'calibrated reviewer',
    idKey: 'alias',
  },
};

/**
 * Default types of work summary that can be created. Used in `WorkSummaryForm`.
 */
export const WORK_SUMMARY_TYPES = [
  {
    label: 'Customer Facing',
    value: 'CUSTOMER',
    description:
      'Work activities that positively impact Amazon/AWS customers, such as cost optimization, resiliency, etc.',
  },
  {
    label: 'Amazon Internal',
    value: 'AMAZON',
    description: 'Activities such as TFC contributions, certifications, projects, etc.',
  },
];

/**
 * Custom options for how managers want to view employees in the table
 */
export const EMPLOYEE_VIEW_AS_OPTIONS: SelectableOption[] = [
  { value: 'name', label: 'Full Name' },
  { value: 'alias', label: 'Alias' },
  { value: 'both', label: 'Name and Alias' },
];

export const DEFAULT_PAGE_SIZES = [5, 10, 20, 30, 50];

export const DEFAULT_EMPLOYEES_PER_PAGE = 10;

export const DEFAULT_FILES_PER_PAGE = 20;

export const DEFAULT_WORK_SUMMARIES_PER_PAGE = 10;

export const DEFAULT_EMPLOYEE_VIEW_AS_VALUE = EMPLOYEE_VIEW_AS_OPTIONS[2].value;

export const DEFAULT_FEEDBACKS_PER_PAGE = 10;

export const DEFAULT_REVIEWERS_PER_PAGE = 30;

/**
 * The 16 Amazon leadership principles.
 * The `label` is the actual principle as written on the website.
 * The `value` is the numeric order in which the principle is listed on the site.
 */
export const LEADERSHIP_PRINCIPLES = [
  { value: '1', label: 'Customer Obsession' },
  { value: '2', label: 'Ownership' },
  { value: '3', label: 'Invent and Simplify' },
  { value: '4', label: 'Are Right, A Lot' },
  { value: '5', label: 'Learn and Be Curious' },
  { value: '6', label: 'Hire and Develop the Best' },
  { value: '7', label: 'Insist on the Highest Standards' },
  { value: '8', label: 'Think Big' },
  { value: '9', label: 'Bias for Action' },
  { value: '10', label: 'Frugality' },
  { value: '11', label: 'Earn Trust' },
  { value: '12', label: 'Dive Deep' },
  { value: '13', label: 'Have Backbone; Disagree and Commit' },
  { value: '14', label: 'Deliver Results' },
  { value: '15', label: "Strive to be Earth's Best Employer" },
  { value: '16', label: 'Success and Scale Bring Broad Responsibility' },
];

export const PILLARS = [
  { value: '17', label: 'Operational Excellence at Level 200' },
  { value: '18', label: 'Operational Excellence at Level 300' },
  { value: '19', label: 'Operational Excellence at Level 400' },
  { value: '20', label: 'Reliability at Level 200' },
  { value: '21', label: 'Reliability at Level 300' },
  { value: '22', label: 'Reliability at Level 400' },
  { value: '23', label: 'Security at Level 200' },
  { value: '24', label: 'Security at Level 300' },
  { value: '25', label: 'Security at Level 400' },
  { value: '26', label: 'Performance Efficiency at Level 200' },
  { value: '27', label: 'Performance Efficiency at Level 300' },
  { value: '28', label: 'Performance Efficiency at Level 400' },
  { value: '29', label: 'Cost Optimization at Level 200' },
  { value: '30', label: 'Cost Optimization at Level 300' },
  { value: '31', label: 'Cost Optimization at Level 400' },
  { value: '32', label: 'Sustainability at Level 200' },
  { value: '33', label: 'Sustainability at Level 300' },
  { value: '34', label: 'Sustainability at Level 400' }
];

/** Amazon job levels. */
export const JOB_LEVELS = [4, 5, 6, 7, 8, 9, 10, 11];

/** Max characters a DynamoDb string field can contain. */
export const MAX_ALIAS_LENGTH = 8;

export const MAX_CHAR_DYNAMODB_LIMIT = 59000;

export const MAX_CHAR_WORKSUMMARY = 2000;

export const MAX_CHAR_PEER_REVIEW = 2000;

export const MAX_CHAR_COMMENT = 2000;

export const MAX_CHAR_TITLE = 50;

export const MAX_CHAR_NAME = 50;

export const MAX_FILE_SIZE_MB = 50;

export const MAX_FILES_PER_WORKSUMMARY = 10;

export const MAX_REVIEWERS_PER_DOCUMENT_REVIEW = 4;

export const MIN_REVIEWERS_PER_DOCUMENT_REVIEW = 1;

export const MAX_REVIEWERS_PER_PANEL_REVIEW = 4;

export const MIN_REVIEWERS_PER_PANEL_REVIEW = 2;

export const PHONETOOL_URL = 'https://phonetool.amazon.com/users/';

export const PHOTO_URL = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=';

export const MAX_CHAR_DEFAULT_LIMIT = 59000;

export const SUPPORT_ITEMS_URLS = {
  promoHubWiki: 'https://w.amazon.com/bin/view/PromoHub',
  slackChannel: 'https://amzn-aws.slack.com/archives/C049KNPNHEZ',
  contactUs: 'https://t.corp.amazon.com/create/templates/b705d222-c0a2-431a-8c4a-8d47758ebfdb',
  submitBug: 'https://t.corp.amazon.com/create/templates/b705d222-c0a2-431a-8c4a-8d47758ebfdb',
  featureRequest: 'https://t.corp.amazon.com/create/templates/dd59e046-d05b-4a75-ac5b-de1a4c8b256c',
};

export const MARKDOWN_SYNTAX_GUIDE = 'https://docs.github.com/en/get-started/writing-on-github';
