import { ForwardedRef, forwardRef, memo, useState } from 'react';
import { capitalize } from 'lodash';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { CandidateStatusItem } from '@/models';
import { useCandidatePath, useCandidatePathActions } from '@/api/candidate-promo-path';
import KeyValuePair from '@/components/common/KeyValuePair';
import UserPhoto from '@/components/common/person/UserPhoto';
import DisplayName from '@/components/common/person/DisplayName';
import { PromoPathStatus } from '@/api/API';
import PromoSpinner from '../common/PromoSpinner';

const CompletePathNotice = memo(() => (
  <SpaceBetween direction="vertical" size="m">
    <Box variant="span">
      Set candidate's promo path as{` `}
      <Box variant="span" fontWeight="bold">
        Complete
      </Box>
      {` `}? This action cannot be undone.
    </Box>
    <Alert statusIconAriaLabel="info">
      This will finalize the promo path, preventing future changes and work summary submissions.{' '}
    </Alert>
  </SpaceBetween>
));

const DiscardPathNotice = memo(() => (
  <SpaceBetween direction="vertical" size="m">
    <Box variant="span">
      <Box variant="span" fontWeight="bold">
        Discard
      </Box>
      {` `} the candidate's assigned promo path? This action cannot be undone.
    </Box>
    <Alert statusIconAriaLabel="warning">
      This will remove the promo path assignment, and reset any related work summaries.{' '}
    </Alert>
  </SpaceBetween>
));

interface CompleteDiscardPromoPathModalParams {
  mode: 'complete' | 'discard';
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  item?: CandidateStatusItem;
}

const CompleteDiscardPromoPathComponent = (
  { mode, item, isVisible, onSetVisibility }: CompleteDiscardPromoPathModalParams,
  ref: ForwardedRef<HTMLElement>
) => {
  const [isSaving, setIsSaving] = useState(false);
  const { candidatePath, isCandidatePathLoading } = useCandidatePath({ id: item?.pathId });
  const { actions } = useCandidatePathActions(candidatePath?.id);
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;

  // Benign reset that just hides the modal and resets errors
  // Use for the dismiss action as it's likely to be triggered accidentally
  // and we don't want to clear the text in that case.
  function resetModalState() {
    onSetVisibility(false);
  }

  // Reset state and clear files
  function resetModal() {
    resetModalState();
  }

  const completeDiscardCandidatePath = async () => {
    if (!item) return;
    try {
      if (mode === 'complete') {
        await actions.update({ status: PromoPathStatus.COMPLETED });
      } else if (mode === 'discard') {
        await actions.delete();
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onCompleteDiscard = () => {
    setIsSaving(true);
    completeDiscardCandidatePath().then(resetModal);
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      closeAriaLabel="Close modal"
      visible={isVisible}
      header={`${capitalize(mode)} candidate path`}
      size="medium"
      onDismiss={() => resetModal()}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button variant="primary" loading={isSaving} disabled={isSaving} onClick={() => onCompleteDiscard()}>
              {isSaving ? 'Saving...' : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {isCandidatePathLoading ? (
        <PromoSpinner variant="inline" size="big" />
      ) : (
        <SpaceBetween direction="vertical" size="m">
          <ColumnLayout columns={2} variant="text-grid">
            <KeyValuePair label="Candidate">
              <SpaceBetween direction="horizontal" size="xxs">
                <UserPhoto alias={item?.alias || '-'} imageSize="s" />
                <Box margin={{ top: 's' }}>
                  <DisplayName item={item} format="both" />
                </Box>
              </SpaceBetween>
            </KeyValuePair>
            <KeyValuePair label="Promo path">{item?.pathName}</KeyValuePair>
          </ColumnLayout>
          {mode === 'complete' ? <CompletePathNotice /> : <DiscardPathNotice />}
        </SpaceBetween>
      )}
    </Modal>
  );
};

const CompleteDiscardPromoPathModal = memo(forwardRef(CompleteDiscardPromoPathComponent));

export default CompleteDiscardPromoPathModal;
