import { useParams, useLocation } from 'react-router-dom';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import WorkSummaryForm from '../components/WorkSummaryForm';

interface StateParams {
  promoPathId: string | undefined;
}

type LocationState = { state: StateParams };

const EditWorkSummary = () => {
  const stateParams: LocationState = useLocation();
  const { promoPathId } = stateParams.state ?? {};
  const { workSummaryId } = useParams();

  return (
    <ContentLayout header={<Header variant="h1">Edit work summary</Header>}>
      <WorkSummaryForm promoPathId={promoPathId} workSummaryId={workSummaryId} />
    </ContentLayout>
  );
};

export default EditWorkSummary;
