/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  AuditTrailRecordType,
  AuditTrails as AuditTrailsModel,
  ListAuditTrailsByTypeAndIdQuery,
  ListAuditTrailsByTypeAndIdQueryVariables,
} from './API';
import { listAuditTrailsByTypeAndId } from '../graphql/queries';
import { AuditTrailItem, OptionalString } from '@/models';
import { getConcreteModels, useApiQuery } from '@/backend/api';
import { QueryKeys } from './queryKeys';
import { getCompactId, getDateFromAPIValueUTC } from '@/common/utils';
import { UseAuditTrailsParams, UseAuditTrailsResult } from './models';
import { getReadableFromEnum } from '@/common/labels';

type Model =
  | AuditTrailsModel
  | NonNullable<NonNullable<ListAuditTrailsByTypeAndIdQuery['listAuditTrailsByTypeAndId']>['items'][number]>;

function isValidModel(model: Model | null | undefined): boolean {
  return !!model && !model._deleted;
}

function getItemFromModel(model: Model): AuditTrailItem {
  return {
    id: model.id,
    modifiedByAlias: model.modifiedBy ?? '',
    humanreadablelog: model.humanReadableLog ?? [],
    recordId: model.recordId,
    recordType: model.recordType,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
  };
}

function useAuditTrails({ queryKey, recordId, recordType }: UseAuditTrailsParams): UseAuditTrailsResult {
  const { getItems } = useApiQuery();

  const query = useQuery({
    queryKey,
    meta: {
      errorMessage: `Error fetching audit history for ${getReadableFromEnum(recordType)} ${getCompactId(recordId)}`,
    },
    queryFn: async () => {
      if (!recordId) return [];
      const data = await getItems<ListAuditTrailsByTypeAndIdQueryVariables, ListAuditTrailsByTypeAndIdQuery>({
        query: listAuditTrailsByTypeAndId,
        input: { recordId, recordType },
      });
      const models = getConcreteModels<Model>(data?.listAuditTrailsByTypeAndId?.items, isValidModel);
      return models?.map((item) => getItemFromModel(item)) ?? [];
    },
    enabled: !!recordId,
  });

  const auditTrails = useMemo(
    () => (query.data ?? []).sort((a, b) => (a.createdAt && b.createdAt && a.createdAt < b.createdAt ? 1 : -1)),
    [query.data]
  );

  return {
    auditTrails,
    isAuditTrailsLoading: !!recordId && query.isPending,
  };
}

export function usePromoPathAuditTrails(promoPathId: OptionalString): UseAuditTrailsResult {
  const auditTrailsResult = useAuditTrails({
    recordId: promoPathId,
    recordType: AuditTrailRecordType.PROMOPATH,
    queryKey: QueryKeys.promoPath.id(promoPathId).auditTrail,
  });

  return { ...auditTrailsResult };
}

export function useWorkSummaryAuditTrails(workSummaryId: OptionalString): UseAuditTrailsResult {
  const auditTrailsResult = useAuditTrails({
    recordId: workSummaryId,
    recordType: AuditTrailRecordType.WORKSUMMARY,
    queryKey: QueryKeys.workSummary.id(workSummaryId).auditTrail,
  });

  return { ...auditTrailsResult };
}
