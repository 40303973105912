import { useMemo } from 'react';
import { union } from 'lodash';
import { NonCancelableCustomEvent, TableProps } from '@amzn/awsui-components-react';
import * as localStorage from '@/common/local-storage';
import { DEFAULT_EMPLOYEE_VIEW_AS_VALUE, DEFAULT_PAGE_SIZES } from '@/common/constants';
import { getNameDefinition } from '@/common/labels';
import DisplayName from '../person/DisplayName';
import {
  ColumnDefinition,
  TableUserPreferences,
  Item,
  AppContext,
  ResourceDefinition,
  PromoTableDefinition,
} from '@/models';

export function getTableDefinition(tableKey: string, resource: ResourceDefinition): PromoTableDefinition {
  return {
    tableKey,
    resource,
    resourceName: getNameDefinition(resource),
  };
}

export function getEmployeeColumnDef<ItemDef extends { alias: string; name?: string }>(
  tableKey: string,
  context?: AppContext | undefined
) {
  const preferences = context?.tablePreferences.get(tableKey);
  const value = preferences?.custom ?? DEFAULT_EMPLOYEE_VIEW_AS_VALUE;

  return {
    id: 'candidate',
    header: 'Candidate',
    cell: (e: ItemDef) => <DisplayName item={{ alias: e.alias, name: e.name }} format={value} />,
    minWidth: 200,
    sortingComparator: (a: ItemDef, b: ItemDef) => (a.name && b.name && a.name > b?.name ? 1 : -1),
  };
}

/**
 * Gets the ordered list of page sizes. Called without parameters, it returns the default list.
 * @param customSizes List of custom size options for the `Preferences` UI
 * @param overwriteDefaults If `customSizes` are provided, defines if they are merged with d
 */
export function getPageSizes(customSizes?: number[], overwriteDefaults?: false): number[] {
  if (!customSizes) {
    return DEFAULT_PAGE_SIZES;
  }
  if (overwriteDefaults) {
    return customSizes;
  }
  return union(DEFAULT_PAGE_SIZES, customSizes).sort();
}

/** Filters a list of {@link ColumnDefinition}s based on include/exclude options */
export function getFilteredColumns<ItemDef extends Item>(
  allColumns: ColumnDefinition<ItemDef>[],
  idsToExclude?: string[],
  idsToInclude?: string[]
): ColumnDefinition<ItemDef>[] {
  return allColumns
    .filter((column) => !idsToInclude || idsToInclude.includes(column.id))
    .filter((column) => !idsToExclude || !idsToExclude.includes(column.id));
}

/**
 * Composes a {@link TableUserPreferences} object to use
 * with tables/cards and other components.
 */
export function getPreferences<ItemDef extends Item>(
  pageSize: number,
  visibleColumns: ColumnDefinition<ItemDef>[] | null,
  customValue?: string,
  wrapLines?: boolean
): TableUserPreferences {
  return {
    pageSize: pageSize || DEFAULT_PAGE_SIZES[0],
    visibleContent: visibleColumns ? visibleColumns.map((i) => i.id) : [],
    wrapLines: wrapLines || false,
    custom: customValue || undefined,
  };
}

export function addToColumnDefinitions<ItemDef extends Item>(
  definitions: ColumnDefinition<ItemDef>[],
  key: keyof ColumnDefinition<ItemDef>,
  columns: Pick<ColumnDefinition<ItemDef>, 'id' | 'width'>[]
) {
  return definitions.map((definition) => {
    const column = (columns || []).find((col) => col.id === definition.id);
    return {
      ...definition,
      [key]: (column && column[key]) || definition[key],
    };
  });
}

export function mapWithColumnDefinitionIds<ItemDef extends Item>(
  definitions: ColumnDefinition<ItemDef>[],
  key: keyof ColumnDefinition<ItemDef>,
  widths: readonly number[]
) {
  return definitions.map(({ id }, iex) => ({
    id,
    [key]: widths[iex],
  }));
}

export function useColumnWidths<ItemDef extends Item>(storageKey: string, definitions: ColumnDefinition<ItemDef>[]) {
  function handleWidthChange(event: NonCancelableCustomEvent<TableProps.ColumnWidthsChangeDetail>) {
    const savedWidths = mapWithColumnDefinitionIds(definitions, 'width', event.detail.widths);
    localStorage.save(storageKey, savedWidths);
  }
  const memoDefinitions = useMemo(() => {
    function isList(
      value: ColumnDefinition<ItemDef> | ColumnDefinition<ItemDef>[] | undefined
    ): value is ColumnDefinition<ItemDef>[] {
      return !!value && 'concat' in value;
    }
    const savedWidths = localStorage.load<ColumnDefinition<ItemDef>>(storageKey);
    if (isList(savedWidths)) {
      return addToColumnDefinitions(definitions, 'width', savedWidths);
    }
    return addToColumnDefinitions(definitions, 'width', savedWidths ? [savedWidths] : []);
  }, [definitions, storageKey]);

  return [memoDefinitions, handleWidthChange] as const;
}
