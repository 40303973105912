import { useCallback, useRef, useState } from 'react';
import { getPropertyFilters, TABLE_KEY, useTableConfig } from './table-config';
import PromoTable from '@/components/common/table/PromoTable';
import { getActionDefinitions, getHeaderComponent } from './common-components';
import { useFileRecords } from '@/api/file-record';
import AddFileRecordModal from './AddFileRecordModal';
import DeleteFileRecordModal from './DeleteFileRecordModal';
import { OptionalString } from '@/models';

interface FileRecordTableParams {
  userAlias: OptionalString;
}

const FileRecordTable = ({ userAlias }: FileRecordTableParams): JSX.Element => {
  const { fileRecords, isFileRecordsLoading } = useFileRecords(userAlias);
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const config = useTableConfig();
  const ref = useRef<HTMLElement>(null);

  const onCreate = useCallback(() => setIsAddVisible(true), []);
  const onDelete = useCallback(() => setIsDeleteVisible(true), []);

  return (
    <>
      <PromoTable
        allItems={fileRecords}
        tableConfig={config}
        headerComponent={getHeaderComponent()}
        tableVariant="full-page"
        isLoading={isFileRecordsLoading}
        actionDefs={getActionDefinitions(onCreate, onDelete)}
        propertyFilters={getPropertyFilters()}
      />
      <AddFileRecordModal isVisible={isAddVisible} onSetVisibility={setIsAddVisible} ref={ref} />
      <DeleteFileRecordModal
        contextKey={TABLE_KEY}
        isVisible={isDeleteVisible}
        onSetVisibility={setIsDeleteVisible}
        ref={ref}
      />
    </>
  );
};

export default FileRecordTable;
