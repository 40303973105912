import { MAX_CHAR_COMMENT } from '@/common/constants';

const NO_EMPTY_CONTENT_MESSAGE = 'Comment cannot be empty.';
const EXCEEDS_MAX_LENGTH_MESSAGE = `The comment exceeds the input limit of ${MAX_CHAR_COMMENT} by {char} characters.`;
const NO_CHANGE_MESSAGE = 'Please make a change to the text before saving.';

export function checkContent(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return NO_EMPTY_CONTENT_MESSAGE;
  }

  if (!/^[0-9\p{L}\p{M}\s'-.|]*$/u.test(value)) {
    return 'Title can only contain letters, numbers, spaces, and ,-. chars.';
  }

  if (value.length > MAX_CHAR_COMMENT) {
    return EXCEEDS_MAX_LENGTH_MESSAGE.replace('{char}', `${value.length - MAX_CHAR_COMMENT}`);
  }
  return '';
}

export function checkContentChanged(value: string | undefined, originalValue: string | undefined): string {
  if (value && originalValue && value === originalValue) {
    return NO_CHANGE_MESSAGE;
  }
  return '';
}
