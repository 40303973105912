import { useParams } from 'react-router-dom';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import { useWorkSummary } from '@/api/work-summary';
import WorkSummaryProgress from '../components/common/WorkSummaryProgress';
import { WorkSummaryStatus } from '@/api/API';
import WorkSummaryReviewForm from '../components/WorkSummaryReview/WorkSummaryReviewForm';
import PromoSpinner from '../components/common/PromoSpinner';
import { useAppContext } from '@/contexts';
import { ViewerType } from '@/models';

const ReviewWorkSummary = () => {
  const { workSummaryId } = useParams();
  const { currentUser, spoofUser } = useAppContext();
  const { workSummary, isLoading } = useWorkSummary(workSummaryId, ViewerType.MANAGER);
  return (
    <ContentLayout header={<Header variant="h1">Review work summary</Header>}>
      {isLoading || !workSummary ? (
        <PromoSpinner />
      ) : (
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
          <WorkSummaryProgress status={workSummary.status as WorkSummaryStatus} />
          <WorkSummaryReviewForm item={workSummary} manager={spoofUser ?? currentUser} />
        </Grid>
      )}
    </ContentLayout>
  );
};

export default ReviewWorkSummary;
