import Container from '@amzn/awsui-components-react/polaris/container';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import Tiles from '@amzn/awsui-components-react/polaris/tiles';
import { WORK_SUMMARY_TYPES } from '@/common/constants';
import { InfoLink } from '@/components/common/links';
import { SelectionFieldParams } from '@/models';

const WorkSummaryTypePanel = ({ value, onChangeValue, onShowTools }: SelectionFieldParams) => (
  <Container header={<Header>Work summary type</Header>}>
    <FormField
      label="Impact category"
      info={
        <InfoLink
          onShowTools={() => onShowTools && onShowTools({ sectionKey: 'workSummaryType' })}
          ariaLabel="Information about the work summary types."
        />
      }
    >
      <Tiles
        items={WORK_SUMMARY_TYPES}
        value={value as string}
        onChange={({ detail }) => onChangeValue(detail.value)}
      />
    </FormField>
  </Container>
);

export default WorkSummaryTypePanel;
