import React, { useMemo, useState } from 'react';
import Badge from '@amzn/awsui-components-react/polaris/badge';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Tabs, { TabsProps } from '@amzn/awsui-components-react/polaris/tabs';
import { WorkSummaryItem } from '@/models';
import CommentList from '../Comment/CommentList';
import { AuditTrailRecordType, CommentType, PeerReviewType } from '@/api/API';
import PromoSpinner from '@/components/common/PromoSpinner';
import ManagerView from '../WorkSummaryView/ManagerView';
import CandidateView from '../WorkSummaryView/CandidateView';
import AuditTrailsList from '../AuditTrails/AuditTrailsList';
import { useWorkSummary } from '@/api/work-summary';
import PeerReviewList from '../PeerReview/PeerReviewList';

const CommentsTabLabel = React.memo<{ count: number }>(
  ({ count }): JSX.Element => (
    <SpaceBetween direction="horizontal" size="xxs">
      <Box variant="div">Comments</Box>
      {count > 0 && (
        <Box variant="div">
          <Badge color="blue">{count}</Badge>
        </Box>
      )}
    </SpaceBetween>
  )
);

interface WorkSummaryDetailViewParams {
  item?: WorkSummaryItem;
  variant: 'candidate' | 'manager';
}

const WorkSummaryDetailView = ({ item, variant }: WorkSummaryDetailViewParams): JSX.Element => {
  const [tabId, setTabId] = useState(variant === 'candidate' ? 'content' : 'information');
  const { isLoading, workSummary } = useWorkSummary(item?.id);

  const defaultTabs: TabsProps.Tab[] = useMemo(
    () => [
      {
        id: 'comments',
        label: <CommentsTabLabel count={item?.commentCount ?? 0} />,
        content: <CommentList workSummaryId={workSummary?.id ?? ''} commentType={CommentType.CANDIDATE} />,
      },
      {
        id: 'peer-reviews',
        label: 'Peer reviews',
        content: (
          <PeerReviewList
            workSummaryId={workSummary?.id}
            reviewType={variant === 'candidate' ? PeerReviewType.CANDIDATE : PeerReviewType.MANAGER}
          />
        ),
      },
      {
        id: 'audit-history',
        label: 'Audit history',
        content: (
          <AuditTrailsList workSummaryId={workSummary?.id || ''} recordType={AuditTrailRecordType.WORKSUMMARY} />
        ),
      },
    ],
    [workSummary?.id, item?.commentCount, variant]
  );

  const managerVariantTabs = useMemo(
    (): TabsProps.Tab[] => [
      {
        id: 'information',
        label: 'Information',
        content: isLoading || !workSummary ? <PromoSpinner /> : <ManagerView workSummary={workSummary} />,
      },
      {
        id: 'content',
        label: 'Content',
        content: isLoading || !workSummary ? <PromoSpinner /> : <Box variant="p">{workSummary.content}</Box>,
      },
    ],
    [isLoading, workSummary]
  );

  const candidateVariantTabs = useMemo(
    (): TabsProps.Tab[] => [
      {
        id: 'content',
        label: 'Content',
        content: isLoading || !workSummary ? <PromoSpinner /> : <CandidateView workSummary={workSummary} />,
      },
    ],
    [isLoading, workSummary]
  );

  return (
    <Tabs
      activeTabId={tabId}
      onChange={({ detail }) => setTabId(detail.activeTabId)}
      ariaLabel="work summary tabbed view"
      tabs={[...(variant === 'candidate' ? candidateVariantTabs : managerVariantTabs), ...defaultTabs]}
      variant="default"
    />
  );
};

export default React.memo(WorkSummaryDetailView);
