import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ConsoleLogger, Hub } from 'aws-amplify/utils';
import { QueryKeys } from 'src/api/queryKeys';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Input from '@amzn/awsui-components-react/polaris/input';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Button from '@amzn/awsui-components-react/polaris/button';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { getPropertyFilters, useTableConfig } from './table-config';
import EditablePromoTable from '@/components/common/table/EditablePromoTable';
import { WorkSummaryAdminItem } from '@/models';
import { checkAlias } from '@/components/common/input-validations';
import { useAdminWorkSummaries, useWorkSummaryActions } from '@/api/work-summary';
import { useCandidatePathActions } from '@/api/candidate-promo-path';
import { MAX_ALIAS_LENGTH } from '@/common/constants';
import { WorkSummaryStatusReason, WorkSummaryStatus } from '@/api/API';
import { getEditAction, getHeaderComponent } from './common-components';

const getRemainingCharCount = (currentText: string, maxChar: number) => maxChar - (currentText?.length || 0);
const logger = new ConsoleLogger('PromoHub');

function hubNotification(message: string, event: string) {
  Hub.dispatch('PromoNotification', {
    event,
    message,
  });
}

const AdminWorkSummaryTable = () => {
  const [searchAliasValue, setSearchAlias] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [validationText, setValidationText] = useState<string>();
  const config = useTableConfig();
  const { isLoading, workSummaries } = useAdminWorkSummaries(searchAliasValue, isSearching);
  const { actions: workSummaryActions } = useWorkSummaryActions();
  const { actions: candidatePathActions } = useCandidatePathActions();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSearching && !isLoading) {
      setIsSearching(false);
    }
  }, [isLoading, isSearching]);

  const isParamsValid = () => {
    if (checkAlias(searchAliasValue)) {
      return false;
    }
    return true;
  };

  const actionDefs = [getEditAction()];

  const onSearch = () => {
    if (!isParamsValid()) {
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
  };

  const handleSubmit = useCallback(
    async (currentItem: WorkSummaryAdminItem, column: TableProps.ColumnDefinition<WorkSummaryAdminItem>, value) => {
      if (column.id === 'status') {
        if (currentItem.status !== value) {
          logger.debug(
            `Updating WorkSummary(${currentItem.id} v${currentItem.version}) status from ${currentItem.status} to ${value}`
          );

          if (currentItem.status === WorkSummaryStatus.DELETED) {
            logger.error('Undelete not implemented yet');
            return;
          }
          await workSummaryActions.update({
            id: currentItem.id,
            status: value,
            statusReason: WorkSummaryStatusReason.ADMIN_STATUS_CHANGE,
          });
        }
      }

      if (column.id === 'managerAlias') {
        if (currentItem.promoPathId) {
          if (checkAlias(value)) {
            hubNotification(`Manager alias [${value}] is invalid`, 'error');
            return;
          }
          logger.debug(
            `Updating Candidate Promo Path (${currentItem.promoPathId}) manager from ${currentItem.managerAlias} to ${value}`
          );
          await candidatePathActions.update({
            id: currentItem.promoPathId,
            manager: value,
          });
          queryClient.invalidateQueries({ queryKey: QueryKeys.workSummary.alias(currentItem.alias) });
        } else {
          hubNotification("No promopath assigned!  Can't change manager alias", 'error');
        }
      }
    },
    [workSummaryActions, candidatePathActions, queryClient]
  );

  return (
    <SpaceBetween direction="vertical" size="l">
      <FormField
        label="Search by alias"
        description="Enter an employee alias to search their Work Summaries"
        constraintText={`${getRemainingCharCount(searchAliasValue, MAX_ALIAS_LENGTH)} characters remaining.`}
        errorText={validationText || ''}
      >
        <SpaceBetween direction="horizontal" size="l">
          <Input
            ariaRequired
            inputMode="text"
            onBlur={() => setValidationText(checkAlias?.(searchAliasValue))}
            value={searchAliasValue}
            placeholder="Ex: johnsmith"
            type="text"
            onChange={({ detail }) => {
              if (validationText) {
                setValidationText('');
              }
              if (detail.value.length > MAX_ALIAS_LENGTH) {
                return;
              }
              setSearchAlias(detail.value.trim());
            }}
          />
          <Button onClick={() => onSearch()} variant="primary" loading={isSearching} disabled={isSearching}>
            {isSearching ? 'Searching...' : 'Search'}
          </Button>
        </SpaceBetween>
      </FormField>

      <EditablePromoTable
        allItems={workSummaries}
        tableConfig={config}
        headerComponent={getHeaderComponent()}
        isLoading={isLoading}
        actionDefs={actionDefs}
        propertyFilters={getPropertyFilters()}
        handleSubmit={handleSubmit}
        tableVariant="borderless"
      />
    </SpaceBetween>
  );
};

export default AdminWorkSummaryTable;
