import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import { useNotifications } from './contexts/NotificationContext';

/**
 * Used in the AppLayout to render notifications
 */
export const NotificationContent = () => {
  const { notifications } = useNotifications();
  if (!notifications) return null;
  return <Flashbar items={notifications} />;
};

export default NotificationContent;
