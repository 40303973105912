import { MAX_ALIAS_LENGTH } from '@/common/constants';

// eslint-disable-next-line import/prefer-default-export
export function checkAlias(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'Alias cannot be empty.';
  }
  if (value.length > MAX_ALIAS_LENGTH) {
    return 'Amazon alias must be 10 characters or less.';
  }

  const aliasRegex = /^[A-Za-z]+$/;
  if (!aliasRegex.test(value)) {
    return 'Alias can only contain alpha characters';
  }

  return '';
}


// in-source test suites
if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest

  describe('checkAlias', () => {
    it('should return an error message if alias is empty', () => {
      expect(checkAlias('')).toEqual('Alias cannot be empty.');
    })

    it('should return an error message if alias is longer than 10 characters', () => {
      expect(checkAlias('a'.repeat(11))).toEqual('Amazon alias must be 10 characters or less.');
    })

    it('should return an error message if alias contains non-alpha characters', () => {
      expect(checkAlias('a1b2c3')).toEqual('Alias can only contain alpha characters');
    })
  })
}
