import { useState, useEffect, useCallback } from 'react';
import Badge from '@amzn/awsui-components-react/polaris/badge';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { CategoryItem, OptionalString, BaselineRoleDimensionItem } from '@/models';
import { useBaselineRoleDimension } from '@/api/baseline-role-dimension';


interface WorkSummaryIdParams {
  id: OptionalString;
}
interface DynamicBaselineRoleDimensionSelectionProps {
  baselineRoleDimensionIds?: string[];
}
export const WorkSummaryId = ({ id }: WorkSummaryIdParams): React.ReactElement => (
  <Box variant="span">
    {!!id && (
      <Box margin={{ right: 'xxs' }} display="inline-block">
        <Popover
          size="small"
          position="top"
          triggerType="custom"
          dismissButton={false}
          content={<StatusIndicator type="success">Work summary ID copied</StatusIndicator>}
        >
          <Button variant="inline-icon" iconName="copy" onClick={() => navigator.clipboard.writeText(id)} />
        </Popover>
      </Box>
    )}
    {id ? id.slice(Math.max(0, id.length - 8)) : '-'}
  </Box>
);

interface CategorySelectionParams {
  categories?: CategoryItem[];
}

export const CategorySelection = ({ categories }: CategorySelectionParams): React.ReactElement =>
  categories?.length ? (
    <SpaceBetween direction="horizontal" size="xxs">
      {categories.map((category) => (
        <Badge color="blue" key={`${category.id}.label`}>
          {category.label}
        </Badge>
      ))}
    </SpaceBetween>
  ) : (
    <StatusIndicator type="info">Uncategorized</StatusIndicator>
  );

  interface BaselineRoleDimensionParams {
    baselineRoleDimensionItem?: BaselineRoleDimensionItem[];
  }
  
  
  export const BaselineRoleDimensionSelection = ({ baselineRoleDimensionItem }: BaselineRoleDimensionParams): React.ReactElement =>
    baselineRoleDimensionItem?.length ? (
      <SpaceBetween direction="horizontal" size="xxs">
        {baselineRoleDimensionItem.map((baselineRoleDimensionItem) => (
          <Badge color="blue" key={`${baselineRoleDimensionItem.id}.label`}>
            {baselineRoleDimensionItem.title}
          </Badge>
        ))}
      </SpaceBetween>
    ) : (
      <Box>-</Box>
    );

interface LeadershipPrincipleSelectionParams {
  lpItems: string[] | undefined;
}

export const LeadershipPrincipleSelection = ({ lpItems }: LeadershipPrincipleSelectionParams): React.ReactElement =>
  lpItems?.length ? (
    <SpaceBetween direction="horizontal" size="xxs">
      {lpItems.map((lp) => (
        <Badge color="blue" key={`${lp}.label`}>
          {lp}
        </Badge>
      ))}
    </SpaceBetween>
  ) : (
    <Box>-</Box>
  );
  
  export const DynamicBaselineRoleDimensionSelection: React.FC<DynamicBaselineRoleDimensionSelectionProps> = ({ baselineRoleDimensionIds }) => {
    const [dimensionLabels, setDimensionLabels] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { getBaselineRoleDimension } = useBaselineRoleDimension(undefined, 5);
  
    const fetchDimensions = useCallback(async () => {
      if (baselineRoleDimensionIds && baselineRoleDimensionIds.length > 0) {
        setIsLoading(true);
        const newDimensionLabels: string[] = [];
        for (const id of baselineRoleDimensionIds) {
          try {
            const dimension = await getBaselineRoleDimension(id);
            if (dimension) {
              newDimensionLabels.push(dimension.title);
            }
          } catch (error) {
            console.error(`Error fetching baseline role dimension ${id}:`, error);
          }
        }
        setDimensionLabels(newDimensionLabels);
        setIsLoading(false);
      } else {
        setDimensionLabels([]);
      }
    }, [baselineRoleDimensionIds, getBaselineRoleDimension]);
  
    useEffect(() => {
      fetchDimensions();
    }, [fetchDimensions]);
  
    if (!baselineRoleDimensionIds || baselineRoleDimensionIds.length === 0) {
      return <Box>-</Box>;
    }
  
    if (isLoading) {
      return <Box>Loading...</Box>;
    }
  
    return dimensionLabels.length ? (
      <SpaceBetween direction="horizontal" size="xxs">
        {dimensionLabels.map((label) => (
          <Badge color="blue" key={label}>
            {label}
          </Badge>
        ))}
      </SpaceBetween>
    ) : (
      <Box>No dimensions found</Box>
    );
  };
  