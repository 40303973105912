import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { noop } from 'lodash';
import { SplitPanelContext } from '@/models';

const Context = React.createContext<SplitPanelContext>({
  isClosedByUser: false,
  splitPanelOpen: false,
  splitPanelSize: 300,
  splitPanelPosition: 'bottom',
  setSplitPanelOpen: noop,
  setSplitPanelPosition: noop,
  setSplitPanelSize: noop,
  setIsClosedByUser: noop,
  setSplitPanelData: noop,
  resetSplitPanel: noop,
});

export const SplitPanelContextProvider = ({ children }): JSX.Element => {
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [splitPanelPosition, setSplitPanelPosition] = useState<'bottom' | 'side'>('bottom');
  const [splitPanelSize, setSplitPanelSize] = useState(200);
  const [splitPanel, setSplitPanel] = useState<JSX.Element | undefined>();
  const [panelHeader, setPanelHeader] = useState<SplitPanelContext['header']>();
  const [panelContent, setPanelContent] = useState<SplitPanelContext['content']>();
  const [isClosedByUser, setIsClosedByUser] = useState(false);

  const onSetPanelData = useCallback((params?: Parameters<SplitPanelContext['setSplitPanelData']>['0']) => {
    setPanelHeader((prevHeader) => (params?.header === prevHeader ? prevHeader : params?.header));
    setPanelContent((prevContent) => (prevContent === params?.content ? prevContent : params?.content));
  }, []);

  const onResetSplitPanel = useCallback(() => {
    onSetPanelData();
    setIsClosedByUser(false);
  }, [onSetPanelData]);

  const context: SplitPanelContext = useMemo(
    () => ({
      splitPanel,
      splitPanelOpen,
      splitPanelPosition,
      splitPanelSize,
      isClosedByUser,
      setIsClosedByUser,
      setSplitPanel,
      setSplitPanelOpen,
      setSplitPanelPosition,
      setSplitPanelSize,
      header: panelHeader,
      content: panelContent,
      setSplitPanelData: onSetPanelData,
      resetSplitPanel: onResetSplitPanel,
    }),
    [
      splitPanel,
      splitPanelOpen,
      splitPanelPosition,
      splitPanelSize,
      isClosedByUser,
      onSetPanelData,
      onResetSplitPanel,
      panelHeader,
      panelContent,
    ]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export function useSplitPanel() {
  const { resetSplitPanel, setSplitPanelData } = useContext(Context);
  return { resetSplitPanel, setSplitPanelData };
}

type UseSplitPanelControlsResult = Omit<SplitPanelContext, 'isClosedByUser' | 'setIsClosedByUser'>;

export function useSplitPanelControls(): UseSplitPanelControlsResult {
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const { header, content, isClosedByUser, setIsClosedByUser, ...rest } = useContext(Context);
  const onSetSplitPanelOpen = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setIsClosedByUser?.(true);
      }
      setSplitPanelOpen(isOpen);
    },
    [setIsClosedByUser]
  );

  useEffect(() => {
    if (header && content && !isClosedByUser) {
      setSplitPanelOpen(true);
    }
    if (!header && !content) {
      setSplitPanelOpen(false);
    }
  }, [header, content, isClosedByUser]);

  return {
    ...rest,
    header,
    content,
    splitPanelOpen,
    setSplitPanelOpen: onSetSplitPanelOpen,
  };
}
