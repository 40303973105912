import { useCallback } from 'react';
import { To, useNavigate, useSearchParams } from 'react-router-dom';
import { BrowserState, Nullable, OptionalString } from '../models';
import { PageDefinition, resolveFullPath } from '../pages';

const VALID_SEARCH_PARAMS = ['spoofAlias'];

type EventDetailBase = {
  href: OptionalString;
};

type FollowableEvent = CustomEvent<EventDetailBase>;

function useNavigator() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const goToRoute = useCallback(
    (href: OptionalString, state?: BrowserState) => {
      if (!href) return;
      const qParams = new URLSearchParams([...searchParams.entries()].filter(([k]) => VALID_SEARCH_PARAMS.includes(k)));
      const search = qParams.size ? `${qParams.toString()}` : '';
      const goTo: To = { search, pathname: href };
      navigate(goTo, { state, relative: 'route' });
    },
    [navigate, searchParams]
  );

  const goToPage = useCallback(
    (page: Nullable<PageDefinition>, state?: BrowserState, dynamicId?: Nullable<string>) => {
      if (!page) return;
      let pathToPage = resolveFullPath(page);
      pathToPage = pathToPage.startsWith('/') ? pathToPage : `/${pathToPage}`;
      pathToPage = dynamicId ? `${pathToPage}/${dynamicId}` : pathToPage;
      goToRoute(pathToPage, state);
    },
    [goToRoute]
  );

  const followToRoute = useCallback(
    (event: FollowableEvent) => {
      event.preventDefault();
      goToRoute(event.detail.href);
    },
    [goToRoute]
  );

  return {
    followToRoute,
    goToPage,
    goToRoute,
  };
}

export default useNavigator;
