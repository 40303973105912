import { useRef, useState } from 'react';
import { getPropertyFilters, TABLE_KEY, useTableConfig } from './table-config';
import PromoTable from '@/components/common/table/PromoTable';
import { useManagerPromoPaths } from '@/api/promo-path';
import { Nullable, PromoUserProfile } from '@/models';
import { getCreateAction, getDeleteAction, getEditAction, getHeaderComponent } from './common-components';
import DeletePromoPathModal from './DeletePromoPathModal';

interface PromoPathTableParams {
  user: Nullable<PromoUserProfile>;
}

const PromoPathTable = ({ user }: PromoPathTableParams): JSX.Element => {
  const { promoPaths, isPromoPathsLoading } = useManagerPromoPaths(user?.alias);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const config = useTableConfig();
  const ref = useRef<HTMLElement>(null);

  const onDelete = () => setIsDeleteVisible(true);

  return (
    <>
      <DeletePromoPathModal
        contextKey={TABLE_KEY}
        isVisible={isDeleteVisible}
        onSetVisibility={setIsDeleteVisible}
        ref={ref}
      />
      <PromoTable
        allItems={promoPaths}
        tableConfig={config}
        headerComponent={getHeaderComponent()}
        tableVariant="borderless"
        isLoading={isPromoPathsLoading}
        propertyFilters={getPropertyFilters()}
        actionDefs={[getEditAction(), getDeleteAction(onDelete), getCreateAction()]}
      />
    </>
  );
};

export default PromoPathTable;
