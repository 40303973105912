import Box from '@amzn/awsui-components-react/polaris/box';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import { WorkSummaryResource } from '@/models';
import KeyValuePair from '@/components/common/KeyValuePair';
import { LeadershipPrincipleSelection, WorkSummaryId } from './common-components';

interface CandidateViewParams {
  workSummary: WorkSummaryResource;
}

const CandidateView = ({ workSummary }: CandidateViewParams): JSX.Element => (
  <ColumnLayout columns={1}>
    <ColumnLayout columns={2} variant="text-grid">
      <KeyValuePair label="Work summary ID">
        <WorkSummaryId id={workSummary.id} />
      </KeyValuePair>
      <KeyValuePair label="Leadership principles">
        <LeadershipPrincipleSelection lpItems={workSummary.leadershipPrinciples} />
      </KeyValuePair>
    </ColumnLayout>
    <KeyValuePair label="Detail">
      <Box variant="p">{workSummary.content}</Box>
    </KeyValuePair>
  </ColumnLayout>
);

export default CandidateView;
