import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { DEFAULT_EMPLOYEES_PER_PAGE, RESOURCES } from '@/common/constants';
import {
  getPageSizes,
  getPreferences,
  getFilteredColumns,
  getTableDefinition,
} from '@/components/common/table/table-config-helper';
import {
  ColumnDefinition,
  PromoTableConfig,
  PromoTableDefinition,
  ManagerPathItem,
  PropertyDefinition,
} from '@/models';
import {
  DatePropertyFilter,
  NumericPropertyFilter,
  StringPropertyFilter,
} from '@/components/common/table/table-filters';
import { RoutableLink } from '../common/links';

/** Identifier for this table. Also used as the key for tracking context. */
export const TABLE_KEY = 'Promo paths';

const PathNumericKeys: (keyof ManagerPathItem)[] = ['startLevel', 'targetLevel'];
const PathStringKeys: (keyof ManagerPathItem)[] = ['name', 'role'];
const PathDateKeys: (keyof ManagerPathItem)[] = ['createdAt', 'updatedAt'];

export const properties: {
  [Property in keyof Partial<ManagerPathItem>]: PropertyDefinition;
} = {
  startLevel: {
    key: 'startLevel',
    label: 'Start level',
  },
  targetLevel: {
    key: 'targetLevel',
    label: 'Target level',
  },
  role: {
    key: 'role',
    label: 'Role',
  },
  name: {
    key: 'name',
    label: 'Name',
  },
  updatedAt: {
    key: 'updatedAt',
    label: 'Last updated',
  },
  createdAt: {
    key: 'createdAt',
    label: 'Created on',
  },
};

/** Columns to render on the `PromoPathProgress` management table. Columns are displayed in order. */
const STATIC_COLUMN_DEFINITIONS: ColumnDefinition<ManagerPathItem>[] = [
  {
    id: properties.name?.key as string,
    header: properties.name?.label as string,
    cell: (e) => (e.name ? <RoutableLink href={`${RESOURCES.PROMOPATH.hrefToEdit}/${e.id}`} text={e.name} /> : '-'),
    sortingField: properties.name?.key as string,
    minWidth: 160,
  },
  {
    id: properties.role?.key as string,
    header: properties.role?.label as string,
    cell: (e) => e.role || '-',
    sortingField: properties.role?.key as string,
    width: 135,
  },
  {
    id: properties.startLevel?.key as string,
    header: properties.startLevel?.label as string,
    cell: (e) => `L${e.startLevel}`,
    sortingField: properties.startLevel?.key as string,
    width: 135,
  },
  {
    id: properties.targetLevel?.key as string,
    header: properties.targetLevel?.label as string,
    cell: (e) => `L${e.targetLevel}`,
    sortingField: properties.targetLevel?.key as string,
    width: 135,
  },
];

const ColumnDefinitions = (): ColumnDefinition<ManagerPathItem>[] => {
  return [...STATIC_COLUMN_DEFINITIONS];
};

const PromoPathTableDef = getTableDefinition(TABLE_KEY, RESOURCES.PROMOPATH);

// Type alias for readability in code. Contains same props as parent.
type PromoPathTableConfig = PromoTableConfig<PromoTableDefinition, ManagerPathItem>;

/**
 * Class to set the table configuration for `PromoPath` table.
 * See parent: {@link PromoTableConfig}
 */
const CreateTableConfig = (): PromoPathTableConfig => {
  return {
    tableDef: PromoPathTableDef,
    allColumns: ColumnDefinitions(),
    pageSizes: getPageSizes(),
    displayableColumns: getFilteredColumns(ColumnDefinitions()),
    defaultPreferences: getPreferences(DEFAULT_EMPLOYEES_PER_PAGE, getFilteredColumns(ColumnDefinitions())),
  };
};

export function getPropertyFilters() {
  const stringFilters = PathStringKeys.map((key) => StringPropertyFilter(properties[key]?.key, properties[key]?.label));
  const dateFilters = PathDateKeys.map((key) => DatePropertyFilter(properties[key]?.key, properties[key]?.label));
  const numericFilters = PathNumericKeys.map((key) => {
    if (key === 'startLevel' || key === 'targetLevel') {
      return NumericPropertyFilter(properties[key]?.key, properties[key]?.label, (v: number) => `L${v}`);
    }
    return NumericPropertyFilter(properties[key]?.key, properties[key]?.label);
  });
  return [...stringFilters, ...dateFilters, ...numericFilters].filter(
    (i): i is PropertyFilterProperty => i !== undefined
  );
}

/** Hook to fetch a new instance of the table config. */
export function useTableConfig() {
  return CreateTableConfig();
}
