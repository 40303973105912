import React, { useState, useEffect } from 'react';
import { SpaceBetween, Cards } from '@amzn/awsui-components-react/polaris';
import CandidateInfo from './components/CandidateInfo';
import { SESSION_STATES, Vote } from './config';
import DocInfo from './components/DocInfo';
import SessionState from './components/SessionState';
import VotingSection from './components/VotingSection';

const mockSessionData = {
  id: 'session123',
  state: SESSION_STATES.FINAL_OUTCOME,
  candidate: {
    name: 'John Doe',
    role: 'Software Development Engineer',
    level: 'L5',
    tenure: '2 years',
    currentManager: 'Jane Smith',
  },
  reviewers: [
    {
      id: 'reviewer1',
      label: 'Alice Johnson',
      initialVote: Vote.Yes,
      finalVote: null,
      finalOutcome: null,
      isSkipLevel: false,
    },
    {
      id: 'reviewer2',
      label: 'Bob Williams',
      initialVote: Vote.No,
      finalVote: null,
      finalOutcome: null,
      isSkipLevel: false,
    },
    {
      id: 'reviewer3',
      label: 'Carol Davis',
      initialVote: null,
      finalVote: null,
      finalOutcome: null,
      isSkipLevel: true,
    },
    {
      id: 'reviewer4',
      label: 'David Brown',
      initialVote: Vote.Yes,
      finalVote: null,
      finalOutcome: null,
      isSkipLevel: false,
    },
  ],
  workdocsLink: 'https://workdocs.amazon.com/document123',
  originalDocReviewLink: 'https://docreview.amazon.com/review456',
  chimeLink: 'https://chime.aws/meeting789',
  date: '2024-08-15',
  time: '14:00',
  timezone: { label: 'PDT', value: 'America/Los_Angeles' },
  creator: { id: 'creator1', label: 'Emily Clark' },
};

const mockCurrentUser = {
  id: 'reviewer2',
  name: 'Bob Williams',
  isCreator: true,
  isSkipLevel: false,
};

const ViewPanelReviewSession = ({
  sessionData = mockSessionData,
  currentUser = mockCurrentUser,
  onUpdateSession = (sessionData: any) => {},
}) => {
  const [localSessionData, setLocalSessionData] = useState<any>(sessionData);
  const [userVote, setUserVote] = useState({ initialVote: null, finalVote: null, finalOutcome: null });
  const [sessionState, setSessionState] = useState(localSessionData.state);
  const isCreator = currentUser.isCreator;

  useEffect(() => {
    // Implement polling logic here to update session data
    const pollInterval = setInterval(() => {
      // Fetch updated session data and call setLocalSessionData
    }, 5000);

    return () => clearInterval(pollInterval);
  }, []);

  const handleSaveStateChange = (newState) => {
    onUpdateSession({ ...localSessionData, state: newState });
  };

  const handleStateChange = (newState) => {
    setSessionState(newState);
  };

  const handleVote = (voteType, value) => {
    setUserVote((prevVote) => ({ ...prevVote, [voteType]: value }));
    // Implement logic to send vote to backend
  };

  const items = [
    {
      header: 'Candidate Information',
      content: <CandidateInfo candidate={localSessionData.candidate} />,
    },
    {
      header: 'Document Information',
      content: <DocInfo sessionData={localSessionData} />,
    },
    {
      header: 'Session State',
      content: (
        <SessionState
          isCreator={isCreator}
          sessionState={sessionState}
          onStateChange={handleStateChange}
          onSaveStateChange={handleSaveStateChange}
        />
      ),
    },
    {
      header: isCreator ? 'Reviewer Votes' : 'Your Vote',
      content: (
        <VotingSection
          isCreator={isCreator}
          sessionData={localSessionData}
          userVote={userVote}
          onVote={handleVote}
          currentUser={currentUser}
        />
      ),
    },
  ];

  return (
    <SpaceBetween direction="vertical" size="l">
      <Cards
        items={items}
        cardDefinition={{
          header: (item) => item.header,
          sections: [
            {
              id: 'content',
              content: (item) => item.content,
            },
          ],
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
      />
    </SpaceBetween>
  );
};

export default ViewPanelReviewSession;
