import { Link } from '@amzn/awsui-components-react/polaris';
import { DEFAULT_EMPLOYEE_VIEW_AS_VALUE, PHONETOOL_URL } from '@/common/constants';

/**
 * Render the Employee cell based on the custom pref the user chose
 * Regardless of option, make the cell a hyperlink to phonetool cuz we're fancy
 */
const DisplayName = ({ item, format = DEFAULT_EMPLOYEE_VIEW_AS_VALUE }) => (
  <Link href={`${PHONETOOL_URL}${item?.alias}`} target="_blank">
    {`${format === 'alias' ? item?.alias : item?.name}${format === 'both' ? ` (${item?.alias})` : ''}`}
  </Link>
);

export default DisplayName;
