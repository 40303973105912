import { Nullable } from '@/models';

// eslint-disable-next-line import/prefer-default-export
export const enum ReviewSessionCreateEditMode {
  CREATE = 'Create',
  EDIT = 'Edit',
}

export const timezoneOptions = [
  { label: 'UTC', value: '00:00' },
  { label: 'UTC-3 (ART)', value: '-03:00' },
  { label: 'UTC-4 (AMT)', value: '-04:00' },
  { label: 'UTC-5 (EST)', value: '-05:00' },
  { label: 'UTC-6 (CST)', value: '-06:00' },
  { label: 'UTC-7 (MST)', value: '-07:00' },
  { label: 'UTC-8 (PST)', value: '-08:00' },
  { label: 'UTC-9 (AKST)', value: '-09:00' },
  { label: 'UTC-10 (HST)', value: '-10:00' },
  { label: 'UTC-11 (SST)', value: '-11:00' },
  { label: 'UTC+1 (CET)', value: '+01:00' },
  { label: 'UTC+2 (EET)', value: '+02:00' },
  { label: 'UTC+3 (MSK)', value: '+03:00' },
  { label: 'UTC+4 (GST)', value: '+04:00' },
  { label: 'UTC+5 (PKT)', value: '+05:00' },
  { label: 'UTC+6 (BST)', value: '+06:00' },
  { label: 'UTC+7 (IST)', value: '+07:00' },
  { label: 'UTC+8 (WIB)', value: '+08:00' },
  { label: 'UTC+9 (JST)', value: '+09:00' },
  { label: 'UTC+10 (AEST)', value: '+10:00' },
  { label: 'UTC+11 (NZST)', value: '+11:00' },
  { label: 'UTC+12 (NZDT)', value: '+12:00' },
];

export const getRemainingCharCount = (currentText: string, maxChar: number) => maxChar - (currentText?.length || 0);

export function getButtonText(isSubmitting: boolean, documentReviewSessionId: Nullable<string>) {
  if (isSubmitting) {
    if (documentReviewSessionId) {
      return 'Saving...';
    }
    return 'Create...';
  }
  if (documentReviewSessionId) {
    return 'Save review session';
  }
  return 'Create review session';
}
