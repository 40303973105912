import React from 'react';
import { Outlet } from 'react-router-dom';
import { PromoAccessDenied } from './pages';
import { AuthAction } from './resources/rbac-constants';
import { useAuthorizer } from './common/hooks/use-authorizer';

/**
 * Helper component to apply to page routes defined in the `index.tsx`.
 * This takes an optional `AuthAction` to validate against.
 * If the user doesn't have access, they're routed to the `PromoAccessDenied` page.
 *
 * Note: If no action is provided, we assume the user has access.
 */
const RouteGuard = ({ action }: { action?: AuthAction }): JSX.Element => {
  const { hasAccess } = useAuthorizer();
  return hasAccess(action) ? <Outlet /> : <PromoAccessDenied />;
};

export default React.memo(RouteGuard);
