import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import { useParams } from 'react-router-dom';
import DocumentReviewSessionCreateEditForm from '@/components/DocumentReviewSessionCreateEditForm';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">Edit document review session</Header>;
};

const EditDocumentReviewSession = () => {
  const { documentReviewSessionId } = useParams();
  return (
    <ContentLayout header={<PageHeader />}>
      <DocumentReviewSessionCreateEditForm documentReviewSessionId={documentReviewSessionId} />
    </ContentLayout>
  );
};

export default EditDocumentReviewSession;
