import { useCallback, useMemo } from 'react';

import type { Nullable, OptionalString, PromoUserProfile } from '@/models';
import { useCognitoSession } from '@/elevate-platform/auth/cognito-auth';

interface CognitoAuthQueryResult {
  user: Nullable<PromoUserProfile>;
  identityId: OptionalString;
}

interface UseCognitoAuthResult {
  session: Nullable<CognitoAuthQueryResult>;
  isLoading: boolean;
  refetch: () => Promise<Nullable<CognitoAuthQueryResult>>;
}

export function useCognitoAuth(): UseCognitoAuthResult {
  const { data, isLoading, refetch } = useCognitoSession();

  const onRefetch = useCallback(async () => {
    const refetched = await refetch();
    return { user: refetched?.data, identityId: refetched?.data?.identityId };
  }, [refetch]);

  const session = useMemo(() => ({ user: data, identityId: data?.identityId }), [data]);

  return {
    session,
    isLoading,
    refetch: onRefetch,
  };
}
