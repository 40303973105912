import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import { useParams } from 'react-router-dom';
import { useDocumentReviewSession } from '@/api/document-review';
import PromoSpinner from '@/components/common/PromoSpinner';
import DocumentReviewSessionViewForm from '@/components/DocumentReviewSessionViewForm';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">View document review session</Header>;
};

const ReviewDocumentReviewSession = () => {
  const { documentReviewSessionId } = useParams();
  const { isDocumentReviewSessionLoading, documentReviewSession } = useDocumentReviewSession(documentReviewSessionId);

  return (
    <ContentLayout header={<PageHeader />}>
      {isDocumentReviewSessionLoading ? (
        <PromoSpinner />
      ) : (
        <DocumentReviewSessionViewForm documentReviewSession={documentReviewSession} />
      )}
    </ContentLayout>
  );
};

export default ReviewDocumentReviewSession;
