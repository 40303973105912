/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { capitalize } from 'lodash';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { NoItemsTableState, NoMatchTableState } from '@/components/common/table/table-states';
import { PHONETOOL_URL, RESOURCES } from '@/common/constants';
import { OptionalString, PeerReviewItem } from '@/models';
import { useWorkSummaryPeerReviews } from '@/api/peer-review';
import { getNameDefinition } from '@/common/labels';
import UserPhoto from '@/components/common/person/UserPhoto';
import MarkdownContent from '@/common/MarkdownContent';
import { getFormattedDate } from '@/common/utils';
import { PeerReviewType } from '@/api/API';

const DEFAULT_CARDS_PER_ROW = 1;
const DEFAULT_TEXT_FILTER_FIELDS = ['content', 'createdAt', 'updatedAt', 'authorAlias', 'authorName'];
const resource = RESOURCES.PEER_REVIEW;

interface CardComponentProps {
  item: PeerReviewItem;
}

const PeerReviewHeader = React.memo<CardComponentProps>(({ item }) => (
  <Box variant="div">
    <Box float="left" display="inline">
      <UserPhoto alias={item.reviewerAlias} imageSize="s" />
    </Box>
    <Link fontSize="heading-xs" target="_blank" href={`${PHONETOOL_URL}${item.reviewerAlias}`}>
      {`${item.reviewerName} (${item.reviewerAlias})`}
    </Link>
    <Box fontSize="body-s" variant="awsui-key-label">
      {getFormattedDate(item.updatedAt)}
    </Box>
  </Box>
));

const ContentVersion = React.memo<CardComponentProps>(({ item }) => (
  <SpaceBetween direction="horizontal" size="xs">
    <Box>{item.reviewedVersion ?? '-  '}</Box>
    {item.reviewedVersion &&
      (item.reviewedVersion === (item.latestVersion ?? 0) ? (
        <Box>
          <StatusIndicator type="success" />
          {`(Latest) `}
        </Box>
      ) : (
        <Box>
          <Popover dismissButton={false} content="The work summary content has changed since this review was submitted">
            <StatusIndicator type="warning" />
          </Popover>
          {`(Previous version) `}
        </Box>
      ))}
  </SpaceBetween>
));

const ReviewContent = React.memo<CardComponentProps>(({ item }) => <MarkdownContent>{item.content}</MarkdownContent>);

interface PeerReviewListParams {
  workSummaryId: OptionalString;
  reviewType: PeerReviewType;
}

const PeerReviewList = ({ workSummaryId, reviewType }: PeerReviewListParams): JSX.Element => {
  const { peerReviews, isPeerReviewsLoading } = useWorkSummaryPeerReviews(workSummaryId, reviewType);

  const { items, actions, collectionProps } = useCollection(peerReviews, {
    filtering: {
      fields: DEFAULT_TEXT_FILTER_FIELDS,
      empty: <NoItemsTableState resourceName={getNameDefinition(resource)} showCreate={false} />,
      noMatch: <NoMatchTableState onClearFilter={() => actions.setFiltering('')} />,
    },
    selection: { trackBy: resource.idKey },
  });

  return (
    <Cards
      {...collectionProps}
      cardsPerRow={[{ cards: DEFAULT_CARDS_PER_ROW }]}
      stickyHeader
      stickyHeaderVerticalOffset={69}
      variant="container"
      loading={isPeerReviewsLoading}
      loadingText="Loading peer reviews"
      items={items}
      cardDefinition={{
        header: (item) => <PeerReviewHeader item={item} />,
        sections: [
          {
            id: 'review-type',
            header: 'Review type',
            content: (item) => capitalize(item.reviewType ?? '-'),
            width: 50,
          },
          {
            id: 'content-version',
            header: 'Reviewed content version',
            content: (item) => <ContentVersion item={item} />,
            width: 50,
          },
          {
            id: 'review-content',
            header: 'Content',
            content: (item) => <ReviewContent item={item} />,
          },
        ],
      }}
    />
  );
};

export default PeerReviewList;
