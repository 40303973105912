import _ from 'lodash';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { Nullable, PeerReviewResource } from '@/models';
import KeyValuePair from '@/components/common/KeyValuePair';
import PromoTextEditor from '@/components/common/PromoTextEditor';
import { MAX_CHAR_PEER_REVIEW } from '@/common/constants';

interface PeerReviewPanelParams {
  peerReview: Nullable<PeerReviewResource>;
  content: Nullable<string>;
  onChangeContent: (v: string) => void;
  validationError: string;
}

const PeerReviewPanel = ({
  peerReview,
  content,
  onChangeContent,
  validationError,
}: PeerReviewPanelParams): JSX.Element => (
  <Container header={<Header variant="h2">Peer review details</Header>}>
    <SpaceBetween direction="vertical" size="l">
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <KeyValuePair label="Review type">{_.capitalize(peerReview?.reviewType || '')}</KeyValuePair>
        </SpaceBetween>
        <SpaceBetween size="l">
          <KeyValuePair label="Required by">{peerReview?.requiredBy?.toDateString()}</KeyValuePair>
        </SpaceBetween>
      </ColumnLayout>
      <PromoTextEditor
        textValue={content ?? ''}
        setTextValue={onChangeContent}
        label="Review content"
        maxCharCount={MAX_CHAR_PEER_REVIEW}
        rowCount={6}
        validationError={validationError}
      />
    </SpaceBetween>
  </Container>
);

export default PeerReviewPanel;
