import Container from '@amzn/awsui-components-react/polaris/container';
import Icon, { IconProps } from '@amzn/awsui-components-react/polaris/icon';
import { WorkSummaryStatus, WorkSummaryStatusReason } from '@/api/API';
import '../../styles/work-summary-progress.scss';

export const WorkSummaryStatusLabel: { [Property in keyof typeof WorkSummaryStatus]: string } = {
  DRAFT: 'Candidate - enter details',
  PEER_REVIEW: 'Peer review',
  MANAGER_REVIEW: 'Manager - review & approve content',
  MANAGER_PEER_REVIEW: 'Manager peer review',
  APPROVED: 'Manager - final review & approval',
  REJECTED: 'Content rejected',
  DELETED: 'Content deleted',
};

const workSummarySteps = Object.keys(WorkSummaryStatus).filter(
  (x) => ![WorkSummaryStatus.DELETED, WorkSummaryStatus.REJECTED].includes(x as WorkSummaryStatus)
);

function isStepOptional(statusStep: WorkSummaryStatus) {
  return [WorkSummaryStatus.PEER_REVIEW, WorkSummaryStatus.MANAGER_PEER_REVIEW].includes(statusStep);
}

interface ProgressContentParams {
  status: WorkSummaryStatus;
  reason?: WorkSummaryStatusReason;
}

interface WorkSummaryProgressParams extends ProgressContentParams {
  variant?: 'container' | 'default';
}

const ProgressContent = ({ status, reason }: ProgressContentParams): JSX.Element => {
  const steps = workSummarySteps;
  if (reason === WorkSummaryStatusReason.CONTENT_NOT_USABLE) {
    const targetIndex = Object.keys(WorkSummaryStatus).findIndex(
      (x) => (x as WorkSummaryStatus) === WorkSummaryStatus.MANAGER_REVIEW
    );
    steps.splice(targetIndex, 0, WorkSummaryStatus.REJECTED);
  }

  const currentStep = steps.findIndex((x) => (x as WorkSummaryStatus) === status);
  const isInErrorStep = [WorkSummaryStatus.DELETED, WorkSummaryStatus.REJECTED].includes(status);

  function getStatusIcon(iex: number): IconProps.Name {
    if (iex === currentStep && !isInErrorStep && iex + 1 === steps.length) {
      return 'status-positive';
    }
    if (iex === currentStep) {
      return isInErrorStep ? 'status-negative' : 'status-in-progress';
    }
    if (iex < currentStep) {
      return isInErrorStep && iex === currentStep - 1 ? 'status-stopped' : 'status-positive';
    }
    if (reason === WorkSummaryStatusReason.RETURNED_FOR_UPDATES && iex === currentStep + 1) {
      return 'status-warning';
    }
    return 'status-pending';
  }

  function getCSSSelectorFromIcon(name: IconProps.Name): string {
    switch (name) {
      case 'status-in-progress':
        return 'active';
      case 'status-positive':
        return 'enabled';
      case 'status-negative':
        return 'error';
      case 'status-stopped':
        return 'warning';
      default:
        return 'disabled';
    }
  }

  return (
    <ul>
      {steps.map((step, iex) => {
        const iconName = getStatusIcon(iex);
        const selector = getCSSSelectorFromIcon(iconName);
        return (
          <li className={`${selector} progress-step-${selector}`} key={`${step}.status.label`}>
            <hr />
            <span className="number">{`Step ${iex + 1}`}</span>
            <div>
              <Icon size={selector === 'active' ? 'medium' : 'small'} name={iconName} />
              <span className="title">
                {WorkSummaryStatusLabel[step]}
                {isStepOptional(step as WorkSummaryStatus) && <i> - optional</i>}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

const WorkSummaryProgress = ({ status, reason, variant = 'container' }: WorkSummaryProgressParams): JSX.Element => (
  <div className="work-summary-progress-tracker">
    {variant === 'container' ? (
      <Container disableContentPaddings>
        <ProgressContent status={status} reason={reason} />
      </Container>
    ) : (
      <ProgressContent status={status} reason={reason} />
    )}
  </div>
);

export default WorkSummaryProgress;
