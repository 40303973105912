import SplitPanel from '@amzn/awsui-components-react/polaris/split-panel';
import { Nullable, OptionalString } from './models';

interface PromoSplitPanelParams {
  header: OptionalString;
  content?: Nullable<JSX.Element>;
}

const PromoSplitPanel = ({ header, content }: PromoSplitPanelParams) =>
  header || content ? <SplitPanel header={header ?? ''}>{content}</SplitPanel> : null;

export default PromoSplitPanel;
