// ManagerView.tsx

import React, { useState, useEffect, useMemo } from 'react';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Box from '@amzn/awsui-components-react/polaris/box';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import type { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { Nullable, WorkSummaryResource } from '@/models';
import { WorkSummaryStatus, WorkSummaryType, UpdateWorkSummaryInput } from '@/api/API';
import { useWorkSummaryActions } from '@/api/work-summary';
import { useAppContext } from '@/contexts/AppContext';
import useNavigator from '@/common/hooks/use-navigator';
import { CandidateOverviewPage } from '@/common/pages';
import {
  CategorySelection,
  LeadershipPrincipleSelection,
  DynamicBaselineRoleDimensionSelection,
} from './common-components';
import { useBaselineRoleDimension } from '@/api/baseline-role-dimension';
import { getOptions, mapFlatListToSelectOptions } from '@/components/common/LeadershipPrinciplePanel';

interface KeyValuePairProps {
  label: React.ReactNode;
  children: React.ReactNode;
}

const KeyValuePair: React.FC<KeyValuePairProps> = ({ label, children }) => (
  <div>
    <div>{label}</div>
    <div>{children}</div>
  </div>
);

interface ManagerViewParams {
  workSummary: Nullable<WorkSummaryResource>;
  hideFormSaveButton?: boolean;
}

const ManagerView: React.FC<ManagerViewParams> = ({ workSummary, hideFormSaveButton = true }) => {
  const [editingField, setEditingField] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [editedValues, setEditedValues] = useState<Partial<UpdateWorkSummaryInput>>({});
  const { currentUser, spoofUser } = useAppContext();
  const { actions } = useWorkSummaryActions(workSummary?.id);
  const { goToPage } = useNavigator();
  const { baselineRoleDimensions } = useBaselineRoleDimension('', spoofUser?.jobLevel || currentUser?.jobLevel || 0);

  const [wsType, setWsType] = useState<WorkSummaryType>(WorkSummaryType.CUSTOMER);
  const [categoryIds, setCategoryIds] = useState<string[]>([]);
  const [baselineRoleDimensionIds, setBaselineRoleDimensionIds] = useState<string[]>([]);
  const [lps, setLps] = useState<string[]>([]);
  const [title, setTitle] = useState('');
  const [detailText, setDetailText] = useState('');

  const baselineRoleDimensionOptions =
    baselineRoleDimensions?.map((dimension) => ({
      label: dimension.title,
      value: dimension.id,
    })) || [];

  const categoryOptions: { label: string; value: string }[] =
    workSummary?.categories?.map((category) => ({
      label: category.label,
      value: category.id,
    })) || [];

  const lpOptions: SelectProps.Options = useMemo(() => {
    return getOptions(undefined);
  }, []);

  useEffect(() => {
    if (workSummary) {
      setWsType(workSummary.workSummaryType as WorkSummaryType);
      setCategoryIds(workSummary.categories?.map((cat) => cat.id).filter((id): id is string => id !== undefined) || []);
      setBaselineRoleDimensionIds(
        workSummary.baselineRoleDimensionIds?.filter((id): id is string => id !== undefined) || []
      );
      setLps(workSummary.leadershipPrinciples?.filter((lp): lp is string => lp !== undefined) || []);
      setTitle(workSummary.title || '');
      setDetailText(workSummary.content || '');
    }
  }, [workSummary]);

  const handleEdit = (field: string) => {
    setEditingField(field);
    setEditedValues((prev) => ({ ...prev, [field]: workSummary?.[field as keyof WorkSummaryResource] }));
  };

  const handleChange = (field: string, value: any) => {
    setEditedValues((prev) => ({ ...prev, [field]: value }));
    if (field === 'baselineRoleDimensionIds') {
      setBaselineRoleDimensionIds(value);
    }
  };

  const handleSave = async () => {
    if (!workSummary) return;

    setIsSaving(true);
    try {
      const updateInput: UpdateWorkSummaryInput = {
        id: workSummary.id,
        ...editedValues,
        baselineRoleDimensionIds,
      };
      await actions.update(updateInput);

      setBaselineRoleDimensionIds(updateInput.baselineRoleDimensionIds || []);

      setEditingField(null);
      setEditedValues({});
    } catch (error) {
      console.error('Failed to update work summary:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const allowSave = (status: Nullable<WorkSummaryStatus | keyof typeof WorkSummaryStatus>) => {
    return (
      !status ||
      (!!status &&
        ![WorkSummaryStatus.APPROVED, WorkSummaryStatus.DELETED, WorkSummaryStatus.REJECTED].includes(
          status as WorkSummaryStatus
        ))
    );
  };

  const renderEditableField = (
    label: string,
    field: string,
    component: React.ReactNode,
    editComponent: React.ReactNode
  ) => (
    <KeyValuePair
      label={
        <Box display="block">
          <span>{label} </span>
          {editingField !== field && (
            <Button variant="icon" iconName="edit" onClick={() => handleEdit(field)} ariaLabel={`Edit ${label}`} />
          )}
        </Box>
      }
    >
      {editingField === field ? (
        <SpaceBetween direction="vertical" size="xs">
          {editComponent}
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={() => setEditingField(null)}>Cancel</Button>
            <Button onClick={handleSave} variant="primary">
              Save
            </Button>
          </SpaceBetween>
        </SpaceBetween>
      ) : (
        component
      )}
    </KeyValuePair>
  );

  return (
    <Form
      actions={
        hideFormSaveButton ? undefined : (
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={() => goToPage(CandidateOverviewPage)} variant="link" disabled={isSaving}>
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              variant="primary"
              loading={isSaving}
              disabled={isSaving || !allowSave(workSummary?.status)}
            >
              {isSaving ? 'Saving...' : 'Save changes'}
            </Button>
          </SpaceBetween>
        )
      }
    >
      <ColumnLayout columns={2} variant="text-grid">
        {renderEditableField(
          'Title',
          'title',
          <span>{title} </span>,
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        )}

        {renderEditableField(
          'Baseline Role Dimensions',
          'baselineRoleDimensions',
          <DynamicBaselineRoleDimensionSelection baselineRoleDimensionIds={baselineRoleDimensionIds} />,
          <Multiselect
            selectedOptions={baselineRoleDimensionOptions.filter((option) =>
              baselineRoleDimensionIds.includes(option.value)
            )}
            onChange={({ detail }) => {
              const newBaselineRoleDimensions = detail.selectedOptions
                .map((o) => o.value)
                .filter((value): value is string => value !== undefined);
              handleChange('baselineRoleDimensionIds', newBaselineRoleDimensions);
            }}
            options={baselineRoleDimensionOptions}
            filteringType="auto"
            placeholder="Select baseline role dimensions"
          />
        )}

        {renderEditableField(
          'Pillars and Leadership Principles',
          'leadershipPrinciples',
          <LeadershipPrincipleSelection lpItems={lps} />,
          <Multiselect
            selectedOptions={mapFlatListToSelectOptions(lps)}
            onChange={({ detail }) => {
              const newLps = detail.selectedOptions.map((option) => option.label as string);
              handleChange('leadershipPrinciples', newLps);
              setLps(newLps);
            }}
            options={lpOptions}
            filteringType="auto"
            placeholder="Select pillars and leadership principles"
          />
        )}
        {renderEditableField(
          'Categories',
          'categories',
          <CategorySelection
            categories={
              workSummary?.categories?.map((category) => ({
                id: category.id,
                label: category.label,
              })) || []
            }
          />,
          <Multiselect
            selectedOptions={categoryOptions.filter((option) => categoryIds.includes(option.value))}
            onChange={({ detail }) => {
              const newCategories = detail.selectedOptions
                .map((o) => o.value)
                .filter((value): value is string => typeof value === 'string');
              setCategoryIds(newCategories);
            }}
            options={categoryOptions}
            filteringType="auto"
            placeholder="Select categories"
          />
        )}
      </ColumnLayout>
    </Form>
  );
};

export default ManagerView;
