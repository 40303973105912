import { useLocation } from 'react-router-dom';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import WorkSummaryForm from '../components/WorkSummaryForm';
import WorkSummaryProgress from '../components/common/WorkSummaryProgress';
import { WorkSummaryStatus } from '@/api/API';

interface StateParams {
  promoPathId: string | undefined;
}

type LocationState = { state?: StateParams };

const CreateWorkSummary = () => {
  const stateParams: LocationState = useLocation();
  const { promoPathId } = stateParams.state ?? {};

  return (
    <ContentLayout header={<Header variant="h1">Create work summary</Header>}>
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
        <WorkSummaryProgress status={WorkSummaryStatus.DRAFT} />
        <WorkSummaryForm promoPathId={promoPathId} />
      </Grid>
    </ContentLayout>
  );
};

export default CreateWorkSummary;
