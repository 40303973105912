import { MAX_CHAR_PEER_REVIEW } from '@/common/constants';

export function checkAlias(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'Feedback provider alias cannot be empty.';
  }
  if (value.length !== 8) {
    return 'Amazon alias must be 8 characters.';
  }
  return '';
}

export function checkContent(value: string | undefined): string {
  if (!value || !value.trim().length) {
    return 'Content cannot be empty.';
  }
  if (value.length > MAX_CHAR_PEER_REVIEW) {
    return `Content cannot exceed ${MAX_CHAR_PEER_REVIEW} characters.`;
  }
  return '';
}
