import '@amzn/awsui-global-styles/polaris.css';
import { I18nProvider } from '@amzn/awsui-components-react/polaris/i18n';
import { AwsRum } from 'aws-rum-web';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { createRoot } from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
  Outlet,
  RouteObject,
  IndexRouteObject,
  NonIndexRouteObject,
} from 'react-router-dom';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider, type PersistQueryClientProviderProps } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import messages from '@amzn/awsui-components-react/polaris/i18n/messages/all.all';
import App from './App';
import {
  PromoHub,
  CreateWorkSummary,
  CreatePromoPath,
  CandidateDashboard,
  ManagerDashboard,
  EditWorkSummary,
  FileManager,
  PromoAppError,
  PeerReviewerDashboard,
  AdminDashboard,
  ListCalibratedDocumentReviewers,
  ListDocumentReviewSessions,
  ListPanelReviewSessions,
  ListCalibratedPanelReviewers,
  EditPromoPath,
  EditPeerReview,
  ReviewWorkSummary,
  CreateDocumentReviewSession,
  EditDocumentReviewSession,
} from './pages';
import {
  AppContextProvider,
  LayoutToolsContextProvider,
  NotificationContextProvider,
  SplitPanelContextProvider,
} from './contexts';
import PromoErrorBoundary from './PromoErrorBoundary';
import {
  CreateWorkSummaryPage,
  CandidateProgressPage,
  ManagerPage,
  CandidateOverviewPage,
  ReviewWorkSummariesPage,
  CandidatePage,
  CreatePromoPathPage,
  EditPromoPathPage,
  EditWorkSummaryPage,
  ManagePromoPathsPage,
  PromoReadinessPage,
  PeerReviewPage,
  PeerReviewDashboardPage,
  EditPeerReviewPage,
  FilesPage,
  ViewFilesPage,
  AdminPage,
  DocumentReviewSessionsPage,
  ListDocumentReviewSessionsPage,
  ListDocumentReviewersPage,
  AdminWorkSummarySearchPage,
  ReviewWorkSummaryPage,
  PanelReviewSessionsPage,
  ListPanelReviewSessionsPage,
  ListPanelReviewersPage,
  ReviewDocumentReviewSessionPage,
  CreateDocumentReviewSessionPage,
  EditDocumentReviewSessionPage,
} from './common/pages';
import rumConfig from './resources/rum-config';
import PromoHubTopNav from './PromoHubTopNav';
import { TOP_NAV_ELEMENT_ID } from './common/constants';
import { getResourceConfig } from './backend';
import RouteGuard from './RouteGuard';
import { getErrorMessageFromException } from './backend/api';
import { getSupportedLocale } from './common/utils';
import ReviewDocumentReviewSession from './pages/ReviewDocumentReviewSession';

Amplify.configure(getResourceConfig());

const locale = getSupportedLocale();

let rumAgent: AwsRum | undefined;

try {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rumAgent = new AwsRum(...rumConfig());
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

/**
 * When adding a new page, add an entry in this object for it with a `path` and `element`
 * See here: {@link https://reactrouter.com/en/main/routers/create-browser-router}
 */
let pageRoutes: RouteObject[] = [
  { path: '/', element: <PromoHub /> },
  {
    path: CandidatePage.path,
    element: <Outlet />,
    children: [
      { index: true, element: <CandidateDashboard /> },
      { path: CandidateOverviewPage.path, element: <CandidateDashboard /> },
      { path: CreateWorkSummaryPage.path, element: <CreateWorkSummary /> },
      { path: `${EditWorkSummaryPage.path}/:workSummaryId`, element: <EditWorkSummary /> },
    ],
  },
  {
    path: ManagerPage.path,
    element: <RouteGuard action={ManagerPage.authAction} />,
    children: [
      { index: true, element: <ManagerDashboard tabId={CandidateProgressPage.id} /> },
      { path: CandidateProgressPage.path, element: <ManagerDashboard tabId={CandidateProgressPage.id} /> },
      { path: ReviewWorkSummariesPage.path, element: <ManagerDashboard tabId={ReviewWorkSummariesPage.id} /> },
      { path: `${ReviewWorkSummaryPage.path}/:workSummaryId`, element: <ReviewWorkSummary /> },
      { path: ManagePromoPathsPage.path, element: <ManagerDashboard tabId={ManagePromoPathsPage.id} /> },
      { path: PromoReadinessPage.path, element: <ManagerDashboard tabId={PromoReadinessPage.id} /> },
      { path: CreatePromoPathPage.path, element: <CreatePromoPath /> },
      { path: `${EditPromoPathPage.path}/:promoPathId`, element: <EditPromoPath /> },
    ],
  },
  {
    path: PeerReviewPage.path,
    element: <Outlet />,
    children: [
      { index: true, element: <PeerReviewerDashboard /> },
      { path: PeerReviewDashboardPage.path, element: <PeerReviewerDashboard /> },
      { path: `${EditPeerReviewPage.path}/:peerReviewId`, element: <EditPeerReview /> },
    ],
  },
  {
    path: FilesPage.path,
    element: <Outlet />,
    children: [
      { index: true, element: <FileManager /> },
      { path: ViewFilesPage.path, element: <FileManager /> },
    ],
  },
  {
    path: AdminPage.path,
    element: <RouteGuard action={AdminPage.authAction} />,
    children: [
      { index: true, element: <AdminDashboard /> },
      { path: AdminWorkSummarySearchPage.path, element: <AdminDashboard /> },
    ],
  },
  {
    path: DocumentReviewSessionsPage.path,
    element: <Outlet />,
    children: [
      { index: true, element: <ListDocumentReviewSessions /> },
      { path: ListDocumentReviewSessionsPage.path, element: <ListDocumentReviewSessions /> },
      {
        path: `${ReviewDocumentReviewSessionPage.path}/:documentReviewSessionId`,
        element: <ReviewDocumentReviewSession />,
      },
      { path: CreateDocumentReviewSessionPage.path, element: <CreateDocumentReviewSession /> },
      {
        path: `${EditDocumentReviewSessionPage.path}/:documentReviewSessionId`,
        element: <EditDocumentReviewSession />,
      },
      { path: ListDocumentReviewersPage.path, element: <ListCalibratedDocumentReviewers /> },
    ],
  },
  {
    path: PanelReviewSessionsPage.path,
    element: <Outlet />,
    children: [
      { index: true, element: <ListPanelReviewSessions /> },
      { path: ListPanelReviewSessionsPage.path, element: <ListPanelReviewSessions /> },
      { path: ListPanelReviewersPage.path, element: <ListCalibratedPanelReviewers /> },
    ],
  },
];

// Each route needs an errorElement for consistency (they aren't inherited from a parent)
// but since it's the same element for each, this simplifies the code.
pageRoutes = pageRoutes.map((route) => {
  let baseRoute: IndexRouteObject | NonIndexRouteObject;
  if (route.index) {
    baseRoute = { ...route } as IndexRouteObject;
  } else {
    baseRoute = {
      ...route,
      children: route.children?.map((child) => ({
        ...child,
        errorElement: <PromoAppError />,
      })),
    } as NonIndexRouteObject;
  }
  return {
    ...baseRoute,
    errorElement: <PromoAppError />,
  };
});

const routes: RouteObject[] = [
  {
    // The App element is a layout route, which wraps everything in the AppLayout component.
    // https://reactrouter.com/en/v6.3.0/getting-started/concepts#layout-routes
    element: (
      <>
        <div id={TOP_NAV_ELEMENT_ID} style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
          <PromoHubTopNav />
        </div>
        <App />
      </>
    ),
    errorElement: <PromoAppError />,
    children: pageRoutes,
  },
];

const router = createBrowserRouter(routes);

const root = createRoot(document.querySelector('#root') as Element);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (ex, query) => {
      Hub.dispatch('PromoNotification', {
        event: 'error',
        message: query.meta?.errorMessage ? (query.meta?.errorMessage as string) : 'Error fetching data',
        data: getErrorMessageFromException(ex),
      });
    },
  }),
  defaultOptions: {
    queries: {
      gcTime: 3 * 60 * 1000, // cache for 10 min
      retry: 1, // only retry a failure once
      structuralSharing: true, // If performance is slow on large responses set this to false
      refetchOnWindowFocus: false, // This doesn't interact well w/ the Polaris components. If this is active, most interactions with a page will trigger all the queries to refetch
    },
    mutations: {
      retry: 1, // only retry a failure once
      gcTime: 0, // Don't cache mutation responses ever
    },
  },
});

const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage });

// Special query keys that we want to cache between page loads
// These should be specific to stuff that we need to display the page
// Use sparingly. We can only cache 10 Mb of data
// https://en.wikipedia.org/wiki/Web_storage#Storage_size
const persistedQueryKeys = new Set<string>([]);
const persistOptions: PersistQueryClientProviderProps['persistOptions'] = {
  maxAge: 5 * 60 * 1000,
  persister: localStoragePersister,
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) => queryKey.some((key) => persistedQueryKeys.has(String(key))),
  },
};

root.render(
  <PromoErrorBoundary>
    <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
      {import.meta.env.MODE === 'development' && <ReactQueryDevtools initialIsOpen />}
      <I18nProvider locale={locale} messages={[messages]}>
        <AppContextProvider>
          <NotificationContextProvider>
            <LayoutToolsContextProvider>
              <SplitPanelContextProvider>
                <RouterProvider router={router} />
              </SplitPanelContextProvider>
            </LayoutToolsContextProvider>
          </NotificationContextProvider>
        </AppContextProvider>
      </I18nProvider>
    </PersistQueryClientProvider>
  </PromoErrorBoundary>
);
