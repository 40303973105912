import Box from '@amzn/awsui-components-react/polaris/box';
import { CreateWorkSummaryPage, EditWorkSummaryPage, CreatePromoPathPage, EditPromoPathPage } from './pages';

const workSummaryFormTools = {
  default: {
    title: 'Work summary',
    content: (
      <Box variant="p">
        A brief description of the work and what is the impact to customer or to Amazon Business. Use S.T.A.R format and
        provide any supporting Artifacts.
      </Box>
    ),
  },
  workSummaryCategory: {
    title: 'Category',
    content: <Box variant="p">Select the categories (dimensions) most applicable for the work summary content.</Box>,
  },
  workSummaryType: {
    title: 'Work summary type',
    content: (
      <div>
        <Box variant="h4">Customer</Box>
        <Box variant="p">Create a Customer Impacting Work Summary if :</Box>
        <Box variant="p">
          You helped customer in a postive way, for example you performed cost optimization or you helped with
          Cloudsecurity etc.
        </Box>
        <Box variant="p">
          Add all relavant data and artifacts ( like emails/screen shots etc ) of the action you performed, state the
          problem , what was the task and what action you took
        </Box>
        <Box variant="p">Add customer quotes if any.</Box>
        <Box variant="h4" margin={{ top: 'm' }}>
          Amazon Internal
        </Box>
        <Box variant="p">Add your technical or Business contributions to Amazon</Box>
        <Box variant="p">
          You can add number of certification you got, number of workshops/trainings you provided, or how you helped
          with internal tool design or how you helped with Hiring etc. please provide all relavant data as well.
        </Box>
      </div>
    ),
  },
  leadershipPrinciples: {
    title: 'Leaderhip principles of Amazon',
    content: <Box variant="p">Select a LP that can associated with the Work summary</Box>,
    links: [
      {
        href: 'https://amazon.jobs/en/internal/principles',
        text: 'Leadership principles',
      },
    ],
  },
};

const promoPathFormTools = {
  default: {
    title: 'Promotion path',
    content: (
      <div>
        <Box variant="p">
          A general blueprint to define parameters and basic requirements a candidate must meet to achieve the target
          role and level.
        </Box>
        <Box variant="p">A promo path can be assigned to one or more candidate employees.</Box>
      </div>
    ),
    links: [
      {
        href: 'https://ivy-help-center.talent.a2z.com/article/article-1568200618517-Gtmgg1nif?ref=share-button',
        text: 'Role Guidelines',
      },
    ],
  },
  promoPathCategory: {
    title: 'Category',
    content: (
      <Box variant="p">
        Customize the work summary categories for this Promo path, or use the pre-populated role defaults.
      </Box>
    ),
    links: [
      {
        href: 'https://ivy-help-center.talent.a2z.com/article/article-1568200618517-Gtmgg1nif?ref=share-button',
        text: 'Role guidelines',
      },
    ],
  },
  leadershipPrinciples: {
    title: 'Leadership principles of Amazon',
    content: (
      <Box variant="p">
        Select one or more Amazon leadership principles to include as suggestions for individuals assigned this path.
      </Box>
    ),
    links: [
      {
        href: 'https://amazon.jobs/en/internal/principles',
        text: 'Leadership principles',
      },
    ],
  },
};

export default {
  [CreateWorkSummaryPage.id]: workSummaryFormTools,
  [EditWorkSummaryPage.id]: workSummaryFormTools,
  [CreatePromoPathPage.id]: promoPathFormTools,
  [EditPromoPathPage.id]: promoPathFormTools,
};
