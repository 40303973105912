import { ForwardedRef, forwardRef } from 'react';
import { validate as validateGuid } from 'uuid';
import Button, { type ButtonProps } from '@amzn/awsui-components-react/polaris/button';
import ButtonDropdown, { type ButtonDropdownProps } from '@amzn/awsui-components-react/polaris/button-dropdown';
import { capitalize } from 'lodash';
import {
  ItemAction,
  ResourceNameDefinition,
  ActionTargetType,
  FunctionActionParams,
  RouteActionParams,
  ActionDefinition,
  Nullable,
  OptionalString,
} from '@/models';
import { PageDefinition } from '@/common/pages';
import useNavigator from '@/common/hooks/use-navigator';

function getDefaultVariant(action: ItemAction): ButtonProps.Variant {
  switch (action) {
    case 'upload':
    case 'save':
    case 'create':
    case 'review':
      return 'primary';
    default:
      return 'normal';
  }
}

function getButtonText(action: ItemAction, resourceName: ResourceNameDefinition): string {
  switch (action) {
    case 'upload':
    case 'save':
    case 'create':
      return capitalize(`${action} ${resourceName.fullName}`);
    default:
      return capitalize(`${action}`);
  }
}

interface ButtonParams {
  resourceName: ResourceNameDefinition;
  action: ItemAction;
  isDisabled?: boolean;
  variant?: ButtonProps.Variant;
}

interface PromoButtonParams extends ButtonParams {
  targetType: ActionTargetType;
  actionParams: Nullable<FunctionActionParams | RouteActionParams>;
}

interface FunctionButtonParams extends ButtonParams {
  doAction: () => void;
}

interface RouteButtonParams extends ButtonParams {
  page: PageDefinition;
  stateParams?: RouteActionParams['stateParams'];
  itemId?: OptionalString;
}

const FunctionActionButton = forwardRef(
  (
    { action, variant, doAction, resourceName, isDisabled }: FunctionButtonParams,
    ref: ForwardedRef<ButtonProps.Ref>
  ) => {
    return (
      <Button ref={ref} disabled={isDisabled} variant={variant || getDefaultVariant(action)} onClick={() => doAction()}>
        {getButtonText(action, resourceName)}
      </Button>
    );
  }
);

const RouteActionButton = forwardRef(
  (
    { action, variant, page, stateParams, itemId, resourceName, isDisabled }: RouteButtonParams,
    ref: ForwardedRef<ButtonProps.Ref>
  ) => {
    const { goToPage } = useNavigator();
    return (
      <Button
        ref={ref}
        disabled={isDisabled}
        variant={variant || getDefaultVariant(action)}
        href="#"
        onFollow={(event) => {
          event.preventDefault();
          goToPage(page, stateParams, itemId);
        }}
      >
        {getButtonText(action, resourceName)}
      </Button>
    );
  }
);

function isRouteAction(
  targetType: ActionTargetType,
  actionParams: RouteActionParams | FunctionActionParams
): actionParams is RouteActionParams {
  return targetType === 'route';
}

const NoActionButton = forwardRef(
  ({ action, variant, resourceName, isDisabled }: ButtonParams, ref: ForwardedRef<ButtonProps.Ref>) => {
    return (
      <Button ref={ref} disabled={isDisabled} variant={variant ?? getDefaultVariant(action)}>
        {getButtonText(action, resourceName)}
      </Button>
    );
  }
);

const ButtonComponent = (
  { targetType, actionParams, ...params }: PromoButtonParams,
  ref: ForwardedRef<ButtonProps.Ref>
) => {
  if (!actionParams) {
    return <NoActionButton {...params} ref={ref} />;
  }
  if (isRouteAction(targetType, actionParams)) {
    return <RouteActionButton {...params} {...actionParams} ref={ref} />;
  }
  return <FunctionActionButton {...params} {...actionParams} ref={ref} />;
};

interface PromoButtonDropdownParams {
  label?: string;
  resourceName: ResourceNameDefinition;
  actions: ActionDefinition[];
  actionToIsDisabled: Partial<{ [Property in ItemAction]: boolean }>;
  isDisabled?: boolean;
  isLoading?: boolean;
  variant?: ButtonDropdownProps.Variant;
}

function getDropdownGroupFromAction(
  actionDef: ActionDefinition,
  isDisabled: boolean,
  resourceName: ResourceNameDefinition
): ButtonDropdownProps.ItemGroup {
  return {
    id: actionDef.action,
    text: getButtonText(actionDef.action, resourceName),
    disabled: isDisabled,
    items: actionDef.actionOptions ?? [],
  };
}

function getDropdownItemFromAction(
  actionDef: ActionDefinition,
  isDisabled: boolean,
  resourceName: ResourceNameDefinition
): ButtonDropdownProps.Item {
  return {
    id: actionDef.action,
    text: getButtonText(actionDef.action, resourceName),
    disabled: isDisabled,
  };
}

const ButtonDropdownComponent = (
  {
    resourceName,
    actions,
    isDisabled,
    actionToIsDisabled,
    isLoading = false,
    label = 'Actions',
    variant = 'normal',
  }: PromoButtonDropdownParams,
  ref: ForwardedRef<ButtonProps.Ref>
) => {
  const { goToPage } = useNavigator();
  function onClickOption(optionId: ItemAction | string) {
    const optionIdParts = optionId.split('-');
    const action = optionIdParts.shift() as ItemAction;
    const selectedId: OptionalString = optionIdParts.join('-');
    const actionDef = actions.find((def) => def.action === action);
    if (!actionDef) {
      return;
    }
    if (!isRouteAction(actionDef.targetType, actionDef.actionParams)) {
      const args = validateGuid(selectedId) ? [selectedId] : [];
      actionDef.actionParams.doAction(...args);
    }
    const { stateParams, itemId, page } = actionDef.actionParams as RouteActionParams;
    goToPage(page, stateParams, itemId);
  }
  return (
    <ButtonDropdown
      ref={ref}
      disabled={isDisabled || Object.values(actionToIsDisabled).every((value) => !!value)}
      variant={variant}
      loading={isLoading}
      expandableGroups
      items={actions.map((actionDef) =>
        actionDef.actionOptions
          ? getDropdownGroupFromAction(actionDef, actionToIsDisabled[actionDef.action] || false, resourceName)
          : getDropdownItemFromAction(actionDef, actionToIsDisabled[actionDef.action] || false, resourceName)
      )}
      onItemClick={({ detail }) => onClickOption(detail.id as ItemAction | string)}
    >
      {label}
    </ButtonDropdown>
  );
};

const PromoButton = forwardRef(ButtonComponent);

const PromoButtonDropdown = forwardRef(ButtonDropdownComponent);

export { PromoButton, PromoButtonDropdown };
