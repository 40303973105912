import { useEffect } from 'react';
import { getPropertyFilters, TABLE_KEY, useTableConfig } from './table-config';
import PromoTable from '@/components/common/table/PromoTable';
import { Nullable, PromoUserProfile } from '@/models';
import { usePeerReviews } from '@/api/peer-review';
import { getReviewAction, getHeaderComponent } from './common-components';
import { useSplitPanel, useTableContext } from '@/contexts';
import PeerReviewDetailView from './PeerReviewDetailView';
import { PeerReviewStatus, PeerReviewType } from '@/api/API';

interface PeerReviewTableParams {
  user: Nullable<PromoUserProfile>;
}

const PeerReviewTable = ({ user }: PeerReviewTableParams): JSX.Element => {
  const config = useTableConfig();
  const { setSplitPanelData } = useSplitPanel();
  const statusFilters = [PeerReviewStatus.REQUESTED, PeerReviewStatus.PENDING];
  const { peerReviews, isPeerReviewsLoading } = usePeerReviews(user?.alias, statusFilters);
  const { selectedId } = useTableContext(TABLE_KEY);

  useEffect(() => {
    const peerReview = peerReviews.find((review) => review.id === selectedId);
    if (peerReview) {
      setSplitPanelData({
        header: `${peerReview?.workSummaryTitle} - Requested by ${
          peerReview.reviewType === PeerReviewType.MANAGER
            ? peerReview?.candidateManagerAlias
            : peerReview.candidateAlias
        }@`,
        content: <PeerReviewDetailView peerReview={peerReview} />,
      });
    }
  }, [peerReviews, selectedId, setSplitPanelData]);

  const actionDefs = [getReviewAction()];

  return (
    <PromoTable
      allItems={peerReviews}
      tableConfig={config}
      headerComponent={getHeaderComponent()}
      isLoading={isPeerReviewsLoading}
      onEmptyShowCreate
      actionDefs={actionDefs}
      propertyFilters={getPropertyFilters()}
      tableVariant="full-page"
    />
  );
};

export default PeerReviewTable;
