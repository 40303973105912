import '../../styles/date-picker.scss';
import { ForwardedRef, forwardRef, memo, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { ManagerPathItem, Nullable, OptionalString, PromoUserProfile } from '@/models';
import { useCandidatePath, useCandidatePathActions } from '@/api/candidate-promo-path';
import KeyValuePair from '@/components/common/KeyValuePair';
import UserPhoto from '@/components/common/person/UserPhoto';
import DisplayName from '@/components/common/person/DisplayName';
import PromoSpinner from '../common/PromoSpinner';

const DATE_REQUIRED_TEXT = 'A target date must be selected.';
const currentQtr = DateTime.now().startOf('quarter').toJSDate();

interface AssignPromoPathModal {
  template: Nullable<ManagerPathItem>;
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  manager: Nullable<PromoUserProfile>;
  currentPathId?: OptionalString;
  candidateAlias: OptionalString;
  candidateName: OptionalString;
}

const AssignPromoPathComponent = (
  { template, isVisible, onSetVisibility, manager, candidateAlias, candidateName, currentPathId }: AssignPromoPathModal,
  ref: ForwardedRef<HTMLElement>
) => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const [targetDate, setTargetDate] = useState<Date | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [dateError, setDateError] = useState('');
  const { candidatePath, isCandidatePathLoading } = useCandidatePath({ id: currentPathId });
  const { actions } = useCandidatePathActions(candidatePath?.id);

  // Benign reset that just hides the modal and resets errors
  // Use for the dismiss action as it's likely to be triggered accidentally
  // and we don't want to clear the text in that case.
  function resetModalState() {
    onSetVisibility(false);
  }

  // Reset state and clear files
  function resetModal() {
    resetModalState();
    setTargetDate(null);
  }

  useEffect(() => {
    if (isVisible && candidatePath?.id && candidatePath?.targetQuarter) {
      setTargetDate(new Date(candidatePath.targetQuarter));
    }
  }, [isVisible, candidatePath?.id, candidatePath?.targetQuarter]);

  const assignPathToCandidate = async () => {
    try {
      if (currentPathId) {
        await actions.delete();
      }
      await actions.create({
        templateId: template?.id,
        manager: manager?.alias,
        candidate: candidateAlias,
        targetDate: targetDate?.toISOString().split('T')[0],
      });
    } finally {
      setIsSaving(false);
    }
  };

  const onAssign = () => {
    if (dateError) {
      return;
    }
    setIsSaving(true);
    assignPathToCandidate().then(resetModal);
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      closeAriaLabel="Close modal"
      visible={isVisible}
      header={candidatePath?.id ? 'Switch assigned promo path' : 'Assign promo path'}
      size="medium"
      onDismiss={() => resetModal()}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isSaving} onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button variant="primary" loading={isSaving} disabled={isSaving} onClick={() => onAssign()}>
              {isSaving ? 'Saving...' : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {isCandidatePathLoading ? (
        <PromoSpinner variant="inline" size="big" />
      ) : (
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween direction="vertical" size="m">
            <KeyValuePair label="Candidate">
              <SpaceBetween direction="horizontal" size="xxs">
                <UserPhoto alias={candidateAlias || '-'} imageSize="s" />
                <Box margin={{ top: 's' }}>
                  <DisplayName item={{ alias: candidateAlias, name: candidateName }} format="both" />
                </Box>
              </SpaceBetween>
            </KeyValuePair>
            {!candidatePath?.id && <KeyValuePair label="Promo path">{template?.name}</KeyValuePair>}
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="m">
            {!candidatePath?.id && <div />}
            <FormField
              label="Target date"
              description="Select a target for completion of the promo path."
              errorText={dateError ?? ''}
            >
              <DatePicker
                onFocus={() => setDateError((prevError) => (prevError ? '' : prevError))}
                onBlur={() => !targetDate && setDateError(DATE_REQUIRED_TEXT)}
                selected={targetDate}
                onChange={(date) => setTargetDate(date)}
                dateFormat="QQQ, yyyy"
                minDate={currentQtr}
                showQuarterYearPicker
                popperPlacement="bottom-start"
                placeholderText="QQ, yyyy"
              />
            </FormField>
          </SpaceBetween>
        </ColumnLayout>
      )}
      {candidatePath?.id && !isCandidatePathLoading && (
        <SpaceBetween direction="vertical" size="m">
          <Grid gridDefinition={[{ colspan: 5 }, { colspan: 2 }, { colspan: 5 }]}>
            <KeyValuePair label="Current path">{candidatePath?.name}</KeyValuePair>
            <Icon name="angle-right-double" />
            <KeyValuePair label="New path">{template?.name}</KeyValuePair>
          </Grid>
          <Alert type="warning">
            Changing the promo path will reset all related work summaries, and cannot be undone.
          </Alert>
        </SpaceBetween>
      )}
    </Modal>
  );
};

const AssignPromoPathModal = memo(forwardRef(AssignPromoPathComponent));

export default AssignPromoPathModal;
