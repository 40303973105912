export enum AuthAction {
  NAVIGATE_USE_APP = 'NAVIGATE_USE_APP',
  NAVIGATE_ADMIN = 'NAVIGATE_ADMIN',
  NAVIGATE_MANAGER = 'NAVIGATE_MANAGER',
  SPOOF_USER_ALIAS = 'SPOOF_USER_ALIAS',
}

export enum AuthGroup {
  APP_ADMIN = 'APP_ADMIN',
  MANAGER = 'MANAGER',
  USER = 'USER',
}

export const PermissionsMatrix = new Map<AuthAction, AuthGroup[]>();

PermissionsMatrix.set(AuthAction.NAVIGATE_ADMIN, [AuthGroup.APP_ADMIN]);
PermissionsMatrix.set(AuthAction.NAVIGATE_MANAGER, [AuthGroup.APP_ADMIN, AuthGroup.MANAGER]);
PermissionsMatrix.set(AuthAction.NAVIGATE_USE_APP, [AuthGroup.APP_ADMIN, AuthGroup.MANAGER, AuthGroup.USER]);
PermissionsMatrix.set(AuthAction.SPOOF_USER_ALIAS, [AuthGroup.APP_ADMIN]);
