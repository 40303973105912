import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import DocumentReviewSessionCreateEditForm from '@/components/DocumentReviewSessionCreateEditForm';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">Create document review session</Header>;
};

const CreateDocumentReviewSession = () => {
  return (
    <ContentLayout header={<PageHeader />}>
      <DocumentReviewSessionCreateEditForm />
    </ContentLayout>
  );
};

export default CreateDocumentReviewSession;
