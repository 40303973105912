import React, { useContext, useEffect, useCallback, useMemo, useState } from 'react';
import { Hub } from 'aws-amplify/utils';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { NotificationContext, PromoNotification } from '@/models';

const Context = React.createContext<NotificationContext>({
  notifications: [],
  addNotification: _.noop,
  clearNotifications: _.noop,
});

export const NotificationContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState<PromoNotification[]>([]);
  // const allNotifications: PromoNotification[] = [];

  const removeNotification = useCallback((itemId: string, timestamp: number) => {
    setNotifications((prevNtx) => prevNtx.filter((ntx) => ntx.timestamp !== timestamp && ntx.id !== itemId));
  }, []);

  useEffect(() => {
    const cancellableListener = Hub.listen('PromoNotification', (data) => {
      const { payload } = data;
      // @ts-expect-error fix that later
      const ntxId = payload.data?.id || uuidv4();
      const ntxTimestamp = Date.now();
      const notification: PromoNotification = {
        type: payload.event === 'error' ? 'error' : 'success',
        header: payload.message,
        // @ts-expect-error fix that later
        content: payload.data || undefined,
        timestamp: ntxTimestamp,
        id: ntxId,
        dismissible: true,
        onDismiss: () => removeNotification(ntxId, ntxTimestamp),
      };
      setNotifications((prevNtx) => [...prevNtx, notification]);
    });

    return () => cancellableListener();
  });

  const addNotification = useCallback(
    (notification: PromoNotification) => {
      const notificationItem = notification;
      if (typeof notificationItem.dismissible !== 'boolean') {
        notificationItem.dismissible = true;
      }
      if (!notificationItem.id) {
        notificationItem.id = uuidv4();
      }

      if (!notificationItem.timestamp) {
        notificationItem.timestamp = Date.now();
      }

      if (notificationItem.dismissible) {
        notificationItem.onDismiss = () => {
          removeNotification(notificationItem.id as string, notificationItem.timestamp as number);
        };
      }
      setNotifications((prevNtx) => [...prevNtx, notification]);
    },
    [removeNotification]
  );

  /**
   * Removes notifications based on the timestamp they were added.
   *  Do not use remove notifications button or a promise as we do not
   *  want to force child components to re render based on notifications state
   *  change.
   * Default time: 20 seconds
   */
  const clearNotifications = useCallback(() => {
    const newNotifications = notifications.filter((ntx) => ntx.timestamp && Date.now() - ntx.timestamp < 20000);
    setNotifications((prevNtx) => (_.isEqual(prevNtx, newNotifications) ? prevNtx : newNotifications));
  }, [notifications]);

  const context: NotificationContext = useMemo(
    () => ({
      notifications,
      addNotification,
      clearNotifications,
    }),
    [notifications, addNotification, clearNotifications]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

/**
 * useNotifications
 *
 * This hook provides access to addNotification; it also automatically
 * runs clearNotifications via useEffect
 *
 * It can be used by components that have access to the NotificationsProvider context
 */
export function useNotifications() {
  const { notifications, addNotification, clearNotifications } = useContext(Context);
  useEffect(() => {
    const timer = setTimeout(() => {
      clearNotifications();
    }, 30000);
    return () => clearTimeout(timer);
  }, [notifications, clearNotifications]);
  return {
    addNotification,
    notifications,
  };
}
