import { useParams } from 'react-router-dom';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import PromoSpinner from '../components/common/PromoSpinner';
import { useManagerPromoPath } from '@/api/promo-path';
import PromoPathForm from '../components/PromoPathForm/PromoPathForm';

const EditPromoPath = () => {
  const { promoPathId } = useParams();
  const { promoPath, isPromoPathLoading } = useManagerPromoPath(promoPathId);

  return (
    <ContentLayout header={<Header variant="h1">Edit promo path</Header>}>
      {isPromoPathLoading ? <PromoSpinner /> : <PromoPathForm promoPath={promoPath} />}
    </ContentLayout>
  );
};

export default EditPromoPath;
