import {
  colorTextBodyDefault,
  colorBackgroundContainerContent,
  colorBackgroundHomeHeader,
  colorBackgroundButtonPrimaryDisabled,
  colorBackgroundLayoutMain,
  colorBackgroundControlDisabled,
  colorBackgroundButtonPrimaryDefault,
  colorTextInputDisabled,
  colorTextBodySecondary,
  colorBorderDividerDefault,
} from '@amzn/awsui-design-tokens';

const getClassName = (element: string) => `density-switch-${element}`;

const TableRow = ({ offset, separator = true, compact = false, isHeader = false }) => {
  const offsetTop = 0.4482;
  const offsetBottom = 3.4482;
  const separatorDistance = compact ? 7 : 8;
  return (
    <g
      transform={`translate(0, ${offset})`}
      style={{ fill: isHeader ? colorTextInputDisabled : colorBackgroundControlDisabled }}
    >
      <path d={`M53 ${offsetTop}H56V${offsetBottom}H53V${offsetTop}Z`} />
      <path
        d={`M61 ${offsetTop}H85V${offsetBottom}H61V${offsetTop}Z`}
        style={{ fill: isHeader ? colorTextBodySecondary : undefined }}
      />
      <path d={`M138 ${offsetTop}H118V${offsetBottom}H138V${offsetTop}Z`} />
      <path d={`M185 ${offsetTop}H141V${offsetBottom}H185V${offsetTop}Z`} />
      {separator && (
        <path
          d={`M48 ${separatorDistance}H187.387`}
          style={{ fill: colorBorderDividerDefault }}
          strokeLinecap="square"
        />
      )}
    </g>
  );
};

const TableRows = ({ offsetTop, rows, compact = false }) => {
  const distance = compact ? 10 : 13;
  return (
    <g>
      {[...Array(rows)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={index} offset={offsetTop + index * distance} compact={compact} separator={index + 1 !== rows} />
      ))}
    </g>
  );
};

const WindowPath = () => (
  <path
    d="M24 1.00006H211C211.552 1.00006 212 1.44778 212 2.00006V105C212 105.552 211.552 106 211 106H24C23.4477 106 23 105.552 23 105V2.00006C23 1.44778 23.4477 1.00006 24 1.00006Z"
    style={{
      fill: colorBackgroundContainerContent,
      stroke: colorBackgroundHomeHeader,
    }}
    strokeWidth="2"
  />
);
const TopNavigation = () => (
  <g className="awsui-context-top-navigation">
    <rect x="24" y="2" width="187" height="6" style={{ fill: colorBackgroundButtonPrimaryDisabled }} />
  </g>
);

export const comfortableImage = (
  <svg viewBox="0 0 230 107" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden>
    <WindowPath />
    <TopNavigation />
    <g className="awsui-context-content-header">
      <path style={{ fill: colorBackgroundLayoutMain }} d="M24 8.00006H211V28.0001H24V8.00006Z" />
      <g style={{ fill: colorTextBodyDefault }}>
        <circle cx="29" cy="17.5001" r="2.5" style={{ fill: colorBackgroundControlDisabled }} />
        <path d="M47 16.0001H77.1484V19.0001H47V16.0001Z" />
        <path d="M121 17.5C121 15.567 122.567 14 124.5 14H137.86C139.793 14 141.36 15.567 141.36 17.5V17.5C141.36 19.433 139.793 21 137.86 21H124.5C122.567 21 121 19.433 121 17.5V17.5Z" />
        <path d="M145 17.5C145 15.567 146.567 14 148.5 14H161.86C163.793 14 165.36 15.567 165.36 17.5V17.5C165.36 19.433 163.793 21 161.86 21H148.5C146.567 21 145 19.433 145 17.5V17.5Z" />
        <path
          d="M168 17.5C168 15.567 169.567 14 171.5 14H184.86C186.793 14 188.36 15.567 188.36 17.5V17.5C188.36 19.433 186.793 21 184.86 21H171.5C169.567 21 168 19.433 168 17.5V17.5Z"
          style={{ fill: colorBackgroundButtonPrimaryDefault }}
        />
        <circle cx="206.5" cy="17.5001" r="2.5" style={{ fill: colorBackgroundControlDisabled }} />
      </g>
    </g>
    <TableRow offset={32} isHeader />

    <TableRows offsetTop={45} rows={5} />
  </svg>
);

export const compactImage = (
  <svg viewBox="0 0 230 107" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden>
    <WindowPath />
    <TopNavigation />
    <g className="awsui-context-content-header">
      <path style={{ fill: colorBackgroundLayoutMain }} d="M24 8.00006H211V24.0001H24V8.00006Z" />
      <g style={{ fill: colorTextBodyDefault }}>
        <circle cx="29" cy="15.5001" r="2.5" style={{ fill: colorBackgroundControlDisabled }} />
        <path d="M47 14H77.1484V17H47V14Z" />
        <path d="M121 15C121 13.8954 121.895 13 123 13H139.36C140.465 13 141.36 13.8954 141.36 15V15C141.36 16.1046 140.465 17 139.36 17H123C121.895 17 121 16.1046 121 15V15Z" />
        <path d="M145 15C145 13.8954 145.895 13 147 13H163.36C164.465 13 165.36 13.8954 165.36 15V15C165.36 16.1046 164.465 17 163.36 17H147C145.895 17 145 16.1046 145 15V15Z" />
        <path
          d="M168 15C168 13.8954 168.895 13 170 13H186.36C187.465 13 188.36 13.8954 188.36 15V15C188.36 16.1046 187.465 17 186.36 17H170C168.895 17 168 16.1046 168 15V15Z"
          style={{ fill: colorBackgroundButtonPrimaryDefault }}
        />
        <circle cx="206.5" cy="15.5001" r="2.5" style={{ fill: colorBackgroundControlDisabled }} />
      </g>
    </g>
    <TableRow offset={27} compact isHeader />
    <TableRows offsetTop={37} compact rows={7} />
  </svg>
);
