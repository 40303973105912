import { useEffect, useState } from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useWorkSummary, useWorkSummaryActions, useWorkSummaryFiles } from '@/api/work-summary';
import { useAppContext } from '@/contexts/AppContext';
import { FileRecordAttachment, Nullable, WorkSummaryResource, BaselineRoleDimensionOptionItem } from '@/models';
import { WorkSummaryStatus, WorkSummaryType } from '@/api/API';
import WorkSummaryTypePanel from './WorkSummaryTypePanel';
import LeadershipPrinciplePanel from '@/components/common/LeadershipPrinciplePanel';
import DetailsPanel from './DetailsPanel';
import { checkContent, checkTitle, checkFileAttachments } from './form-validator';
import { useCandidatePath } from '@/api/candidate-promo-path';
import { useBaselineRoleDimension } from '@/api/baseline-role-dimension';
import { useLayoutTools } from '@/common/ToolsPanel';
import PromoSpinner from '@/components/common/PromoSpinner';
import useNavigator from '@/common/hooks/use-navigator';
import { CandidateOverviewPage } from '@/common/pages';

function allowSave(status: Nullable<WorkSummaryStatus | keyof typeof WorkSummaryStatus>) {
  return (
    !status ||
    (!!status &&
      ![WorkSummaryStatus.APPROVED, WorkSummaryStatus.DELETED, WorkSummaryStatus.REJECTED].includes(
        status as WorkSummaryStatus
      ))
  );
}

interface FormParams {
  promoPathId?: string;
  workSummaryId?: string;
}

const WorkSummaryForm = ({ promoPathId, workSummaryId }: FormParams) => {
  const [id, setId] = useState(workSummaryId);
  const { workSummary, isLoading } = useWorkSummary(id);
  const { currentUser, spoofUser } = useAppContext();
  const [wsType, setWsType] = useState<string>(WorkSummaryType.CUSTOMER);
  const [categoryIds, setCategoryIds] = useState<string[]>([]);
  const [baselineRoleDimensionIds, setBaselineRoleDimensionIds] = useState<string[]>([]);
  const [detailText, setDetailText] = useState('');
  const [lps, setLps] = useState<string[]>([]);
  const [files, setFiles] = useState<string[]>([]);
  const [title, setTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [originalFiles, setOriginalFiles] = useState<FileRecordAttachment[]>([]);
  const { setToolsForSection } = useLayoutTools();
  const { candidatePath } = useCandidatePath({ alias: spoofUser?.alias ?? currentUser?.alias, id: promoPathId });
  const { actions } = useWorkSummaryActions(workSummary?.id);
  const { attachFile, detachFile } = useWorkSummaryFiles();
  const { goToPage } = useNavigator();
  const { baselineRoleDimensions } = useBaselineRoleDimension("", currentUser?.jobLevel || 0);  
  const isParamsValid = () => !(checkTitle(title) || checkContent(detailText) || checkFileAttachments(files));

  const baselineRoleDimensionOptions: BaselineRoleDimensionOptionItem[] = 
  baselineRoleDimensions && baselineRoleDimensions.length > 0
    ? baselineRoleDimensions.map(dimension => ({
        id: dimension.id,
        label: dimension.title,
      }))
    : [];
  
  useEffect(() => {
    if (workSummary) {
      setWsType(workSummary.workSummaryType.toUpperCase() as WorkSummaryType);
      const originalCategoryIdValues = (workSummary?.categories ?? []).map((cat) => cat.id);
      setCategoryIds((prevIds) =>
        workSummary?.categories.length &&
        prevIds.every((pCategoryId) => originalCategoryIdValues.includes(pCategoryId)) &&
        originalCategoryIdValues.every((ogCategoryId) => prevIds.includes(ogCategoryId))
          ? prevIds
          : originalCategoryIdValues
      );
      setBaselineRoleDimensionIds(workSummary.baselineRoleDimensionIds || []);
      setDetailText(workSummary.content);
      setTitle(workSummary.title);
      setLps((prevLps) =>
        workSummary.leadershipPrinciples?.length &&
        prevLps.every((lp) => workSummary?.leadershipPrinciples?.includes(lp)) &&
        workSummary?.leadershipPrinciples?.every((lp) => prevLps.includes(lp as string))
          ? prevLps
          : (workSummary?.leadershipPrinciples as string[])
      );
      if (workSummary?.documents.length) {
        setFiles(workSummary.documents.map((doc) => doc.id));
        setOriginalFiles(workSummary.documents);
      }
    }
  }, [workSummary]);
  const saveWorkSummaryItem = async () => {
    try {
      const input = {
        title,
        categoryIds,
        candidatePathId: candidatePath?.id && categoryIds.length ? candidatePath.id : undefined,
        alias: spoofUser?.alias ?? currentUser?.alias ?? '',
        baselineRoleDimensionIds,
        content: detailText,
        type: wsType as WorkSummaryType,
        leadershipPrinciples: lps,
        contentVersion: workSummary?.content === detailText ? undefined : (workSummary?.contentVersion ?? 0) + 1,
      };
      let result: Nullable<WorkSummaryResource>;
      if (workSummary?.id) {
        result = await actions.update({ ...input });
      } else {
        result = await actions.create(input);
      }
      if (result?.id && files.length) {
        // collect any new files to upload
        const originalFileIds = originalFiles.map((item) => item.id);
        const filesToAttach = files.filter((file) => !originalFileIds.includes(file));
        // collect files we need to delete. These will be in the 'original' list but not new.
        const fileIdsToRemove = originalFiles
          .filter((ogFile) => !files.includes(ogFile.id) && !!ogFile.fileAttachmentId)
          .map((ogFile) => ogFile.fileAttachmentId)
          .filter((fileAttachmentId): fileAttachmentId is string => !!fileAttachmentId);
        await Promise.all(fileIdsToRemove.map(async (fileAttachmentId) => detachFile({ id: fileAttachmentId })));
        const workSummaryID = result.id;
        await Promise.all(filesToAttach.map(async (file) => attachFile({ workSummaryID, fileRecordID: file })));
      }
      setId((prevId) => prevId ?? result?.id);
    } finally {
      setIsSaving(false);
    }
  };

  const onSave = () => {
    if (!isParamsValid()) {
      return;
    }
    setIsSaving(true);
    saveWorkSummaryItem().then(() => goToPage(CandidateOverviewPage));
  };

  return (
    <Form
      variant="full-page"
      actions={
        // located at the bottom of the form
        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={() => goToPage(CandidateOverviewPage)} variant="link" disabled={isSaving}>
            Cancel
          </Button>
          <Button
            onClick={() => onSave()}
            variant="primary"
            loading={isSaving}
            disabled={isSaving || !allowSave(workSummary?.status)}
          >
            {isSaving ? 'Saving...' : `${workSummary?.id ? 'Update' : 'Create'} work summary`}
          </Button>
        </SpaceBetween>
      }
    >
      {isLoading ? (
        <PromoSpinner variant="inline" />
      ) : (
        <SpaceBetween direction="vertical" size="l">
          <WorkSummaryTypePanel value={wsType} onChangeValue={setWsType} onShowTools={setToolsForSection} />
          <DetailsPanel
            categoryParams={{
              selections: categoryIds,
              onChangeValue: setCategoryIds,
              options: candidatePath?.categories ?? [],
            }}
            baselineRoleDimensionFieldParams={{
              selections: baselineRoleDimensionIds,
              onChangeValue: setBaselineRoleDimensionIds,
              options: baselineRoleDimensionOptions,
            }}
            contentParams={{ value: detailText, onChangeValue: setDetailText, validator: checkContent }}
            fileSelectorParams={{
              candidateAlias: spoofUser?.alias ?? currentUser?.alias,
              selections: files,
              onChangeValue: setFiles,
            }}
            titleParams={{ value: title, onChangeValue: setTitle, validator: checkTitle }}
            onShowTools={setToolsForSection}
          />
          <LeadershipPrinciplePanel
            label="Pillars and leadership principles"
            selections={lps}
            onChangeValue={setLps}
            onShowTools={setToolsForSection}
            suggestions={candidatePath?.suggestedLPs}
          />
        </SpaceBetween>
      )}
    </Form>
  );
};

export default WorkSummaryForm;
