import { useRef, useState, useEffect } from 'react';
import { getPropertyFilters, getStaticFilterOptions, useTableConfig, VIEW_MODES } from './table-config';
import PromoTable from '@/components/common/table/PromoTable';
import { Nullable, PromoUserProfile, ViewerType, WorkSummaryItem } from '@/models';
import {
  getCreateAction,
  getDeleteAction,
  getEditAction,
  getHeaderComponent,
  getRecallAction,
  getReqPeerReviewAction,
  getSubmitForReviewAction,
} from './common-components';
import DeleteWorkSummaryModal from './DeleteWorkSummaryModal';
import { useWorkSummaries } from '@/api/work-summary';
import SubmitRecallModal from './SubmitRecallModal';
import WorkSummaryDetailView from '../WorkSummary/WorkSummaryDetailView';
import { useSplitPanel, useTableContext } from '@/contexts';
import SubmitPeerReviewRequestModal from './SubmitPeerReviewRequestModal';
import { useCandidatePath } from '@/api/candidate-promo-path';

const TABLE_KEY = 'My work summaries';

interface WorkSummaryTableParams {
  candidate: Nullable<PromoUserProfile>;
}

const WorkSummaryTable = ({ candidate }: WorkSummaryTableParams) => {
  const { isCandidatePathLoading, candidatePath } = useCandidatePath({ alias: candidate?.alias });
  const [isSubmitRecallVisible, setIsSubmitRecallVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isReqPeerReviewVisible, setIsReqPeerReviewVisible] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const { workSummaries, isLoading } = useWorkSummaries(candidate?.alias);
  const { setSplitPanelData, resetSplitPanel } = useSplitPanel();
  const config = useTableConfig<WorkSummaryItem>(TABLE_KEY, VIEW_MODES.INDIVIDUAL);
  const { selectedId } = useTableContext(TABLE_KEY);

  useEffect(() => {
    const workSummary = workSummaries?.find((ws) => ws.id === selectedId);
    if (workSummary) {
      setSplitPanelData({
        header: workSummary.title ?? '',
        content: <WorkSummaryDetailView item={workSummary} variant="candidate" />,
      });
    } else {
      resetSplitPanel();
    }
  }, [selectedId, workSummaries, setSplitPanelData, resetSplitPanel]);

  const onRequestPeerReview = () => setIsReqPeerReviewVisible(true);
  const onDelete = () => setIsDeleteVisible(true);
  const onSubmitForReview = () => setIsSubmitRecallVisible(true);
  const onRecall = () => setIsSubmitRecallVisible(true);

  const actionDefs = [
    getSubmitForReviewAction(onSubmitForReview),
    getRecallAction(onRecall),
    getEditAction(candidatePath),
    getDeleteAction(onDelete),
    getCreateAction(candidatePath),
    getReqPeerReviewAction(onRequestPeerReview),
  ];

  return (
    <>
      <SubmitPeerReviewRequestModal
        contextKey={TABLE_KEY}
        isVisible={isReqPeerReviewVisible}
        onSetVisibility={setIsReqPeerReviewVisible}
        viewer={ViewerType.CANDIDATE}
        ref={ref}
      />
      <SubmitRecallModal
        candidate={candidate}
        viewer={ViewerType.CANDIDATE}
        contextKey={TABLE_KEY}
        isVisible={isSubmitRecallVisible}
        onSetVisibility={setIsSubmitRecallVisible}
        ref={ref}
      />
      <DeleteWorkSummaryModal
        contextKey={TABLE_KEY}
        isVisible={isDeleteVisible}
        onSetVisibility={setIsDeleteVisible}
        ref={ref}
      />
      <PromoTable
        allItems={workSummaries ?? []}
        tableConfig={config}
        headerComponent={getHeaderComponent()}
        isLoading={isLoading || isCandidatePathLoading}
        actionDefs={actionDefs}
        propertyFilters={getPropertyFilters()}
        staticFilterOptions={getStaticFilterOptions(candidatePath?.categories)}
        tableVariant="stacked"
      />
    </>
  );
};

export default WorkSummaryTable;
