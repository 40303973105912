import React, { useCallback, useState } from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import TokenGroup, { TokenGroupProps } from '@amzn/awsui-components-react/polaris/token-group';
import { PeerReviewStatus } from '@/api/API';
import { PeopleProfileRecord, Nullable, PeerReviewRequest, ViewerType } from '@/models';
import { useAmazonLookup } from '@/api/amzn-people';
import { useUserProfile } from '@/api/user-profile';
import { useAppContext } from '@/contexts';

interface PeerReviewerPanelParams {
  requests: PeerReviewRequest[];
  setRequests: React.Dispatch<React.SetStateAction<PeerReviewRequest[]>>;
  setDeletedRequests: React.Dispatch<React.SetStateAction<PeerReviewRequest[]>>;
  candidateAlias: string;
  viewer: ViewerType;
}

const PeerReviewerPanel = ({
  requests,
  setRequests,
  setDeletedRequests,
  candidateAlias,
  viewer,
}: PeerReviewerPanelParams): JSX.Element => {
  const { currentUser } = useAppContext();
  const { findUser } = useAmazonLookup();
  const { getProfile } = useUserProfile();
  const [isFindingAlias, setIsFindingAlias] = useState(false);
  const [alias, setAlias] = useState<string>('');
  const [error, setError] = useState('');

  const tgItems: TokenGroupProps.Item[] = (requests ?? []).map((req) => ({
    label: `${req.name} (${req.alias})`,
    description: req.email,
    iconName: 'user-profile-active',
  }));

  const validateAndAddAlias = useCallback(async () => {
    let aliasError = '';
    if (!alias || !alias.trim().length) {
      aliasError = 'Peer reviewer alias cannot be empty.';
    } else if (alias.length > 8 || alias.length < 3) {
      aliasError = 'Amazon alias must be 3-8 characters long';
    } else if (requests?.some((opt) => opt.alias === alias.trim().toLowerCase())) {
      aliasError = 'Alias already added as peer reviewer';
    } else if (alias === currentUser?.alias) {
      aliasError = 'You cannot add yourself as a peer reviewer';
    } else if (alias === candidateAlias) {
      aliasError = 'The candidate cannot be a peer reviewer';
    } else if (viewer === ViewerType.CANDIDATE && currentUser?.manager.alias === alias) {
      aliasError = 'Your manager cannot be a peer reviewer';
    }
    let user: Nullable<PeopleProfileRecord>;
    if (!aliasError) {
      user = await findUser(alias.trim().toLowerCase());
      if (!user) {
        aliasError = 'No user found with the provided alias.';
      } else if (viewer === ViewerType.MANAGER && !user.isManager) {
        aliasError = 'User must be a manager to provide a manager peer review';
      }
    }

    setError(aliasError);
    if (user && !aliasError) {
      const profile = await getProfile(user.alias);
      setRequests((prevRequests) => [
        ...prevRequests,
        {
          email: user?.email,
          alias: user?.alias,
          name: `${user?.firstName} ${user?.lastName}`,
          isManager: user?.isManager,
          manager: user?.manager.alias,
          jobLevel: user?.jobLevel,
          isVerified: true,
          userProfileExists: !!profile,
          requestStatus: PeerReviewStatus.REQUESTED,
        },
      ]);
      setAlias('');
    }
  }, [
    alias,
    requests,
    candidateAlias,
    setRequests,
    viewer,
    currentUser?.alias,
    currentUser?.manager.alias,
    getProfile,
    findUser,
  ]);

  return (
    <ColumnLayout>
      <FormField label="Peer reviewer(s)" errorText={error}>
        <SpaceBetween direction="horizontal" size="xs">
          <Input
            disabled={isFindingAlias}
            type="text"
            inputMode="text"
            placeholder="Enter an Amazon alias"
            value={alias ?? ''}
            onChange={({ detail }) => setAlias(detail.value)}
          />
          <Button
            variant="primary"
            iconName="search"
            loading={isFindingAlias}
            disabled={!alias}
            onClick={() => {
              setIsFindingAlias(true);
              validateAndAddAlias().finally(() => setIsFindingAlias(false));
            }}
          >
            Find
          </Button>
        </SpaceBetween>
      </FormField>
      <TokenGroup
        items={tgItems}
        onDismiss={({ detail }) => {
          setRequests([...requests.slice(0, detail.itemIndex), ...requests.slice(detail.itemIndex + 1)]);
          setDeletedRequests((prevDeletedRequests) => [...prevDeletedRequests, requests[detail.itemIndex]]);
        }}
      />
    </ColumnLayout>
  );
};

export default PeerReviewerPanel;
