import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { AbsolutePathToPageDef, HomePage } from './common/pages';
import Breadcrumbs from './Breadcrumbs';
import ServiceNavigation from './ServiceNavigation';
import { useLayoutToolsContext } from './contexts/LayoutToolsContext';
import { pageHasToolsContent } from './common/ToolsPanel';
import NotificationContent from './NotificationContent';
import { TOP_NAV_ELEMENT_ID } from './common/constants';
import { useAppContext, useSplitPanelControls } from './contexts';
import { PromoAccessDenied, PromoAppLoading } from './pages';
import { AuthAction } from './resources/rbac-constants';
import PromoSplitPanel from './common/PromoSplitPanel';
import { useAuthorizer } from './common/hooks/use-authorizer';

const App = () => {
  const { hasAccess } = useAuthorizer();
  const { setSpoofAlias, isFinishedLoading } = useAppContext();
  const {
    header,
    content,
    splitPanelOpen,
    setSplitPanelOpen,
    splitPanelPosition,
    setSplitPanelPosition,
    splitPanelSize,
    setSplitPanelSize,
    resetSplitPanel,
  } = useSplitPanelControls();

  const location = useLocation();
  // The location.pathname returns the absolute path with a leading slash, which we don't want.
  const pathname = location.pathname.substring(1);
  const [urlParams, setUrlParams] = useSearchParams();
  const requestedSpoofAlias = urlParams.get('spoofAlias');

  // Use the absolute path to lookup which PageDefinition we're at.
  let currentPage = pathname ? AbsolutePathToPageDef.get(pathname) : HomePage;
  if (!currentPage) {
    currentPage = AbsolutePathToPageDef.get(pathname.split('/').slice(0, -1).join('/'));
    if (!currentPage) {
      currentPage = AbsolutePathToPageDef.get(pathname.split('/').slice(0, -2).join('/'));
    }
  }
  const [isNavOpen, setIsNavOpen] = useState(currentPage === HomePage);
  const [isNavClosedByUser, setIsNavClosedByUser] = useState(false);
  const onToggleNavigation = useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setIsNavClosedByUser(true);
    }
    setIsNavOpen(isOpen);
  }, []);

  useEffect(() => {
    if (requestedSpoofAlias && isFinishedLoading) {
      if (hasAccess(AuthAction.SPOOF_USER_ALIAS)) {
        setSpoofAlias?.(requestedSpoofAlias);
      } else {
        setUrlParams((prevUrlParams) => {
          const newUrlParams = prevUrlParams;
          newUrlParams.delete('spoofAlias');
          return newUrlParams;
        });
      }
    }
  }, [hasAccess, requestedSpoofAlias, setSpoofAlias, setUrlParams, isFinishedLoading]);

  useEffect(() => {
    if (!isNavOpen) {
      setIsNavOpen(currentPage === HomePage && !isNavClosedByUser);
    }
  }, [currentPage, isNavOpen, isNavClosedByUser]);

  useEffect(() => {
    resetSplitPanel();
  }, [resetSplitPanel, location.pathname]);

  // fetch the current LayoutToolsContext to determine how the tools panel shoudl display.
  const { isShowTools, isToolsOpen, toolsContent, setPage, setToolsOpen, setShowTools } = useLayoutToolsContext();

  useEffect(() => {
    if (!currentPage) return;
    // allows to propagate tools toggle, display, and content throughout all pages.
    // this has to be wrapped in useEffect to prevent conflicting rendering operations.
    // Logic: if the page we're loading has tool panel content a user can view, show the tools toggle (upper right)
    setShowTools(pageHasToolsContent(currentPage));
    // Setting which page we're on helps define what tools panel content is fetched.
    setPage(currentPage);
  }, [currentPage, setPage, setShowTools]);

  if (!isFinishedLoading) {
    return <PromoAppLoading />;
  }

  return hasAccess(AuthAction.NAVIGATE_USE_APP) ? (
    <AppLayout
      headerSelector={`#${TOP_NAV_ELEMENT_ID}`}
      notifications={<NotificationContent />}
      navigationOpen={isNavOpen}
      navigationWidth={190}
      onNavigationChange={({ detail }) => onToggleNavigation(detail.open)}
      disableContentPaddings={currentPage === HomePage}
      navigation={<ServiceNavigation />}
      breadcrumbs={<Breadcrumbs currentPage={currentPage || HomePage} />}
      content={<Outlet />}
      toolsOpen={isToolsOpen}
      toolsHide={!isShowTools}
      tools={toolsContent}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      contentType={currentPage?.contentType || 'default'}
      splitPanel={<PromoSplitPanel header={header} content={content} />}
      splitPanelOpen={splitPanelOpen}
      splitPanelPreferences={{ position: splitPanelPosition }}
      splitPanelSize={splitPanelSize}
      onSplitPanelToggle={() => setSplitPanelOpen(!splitPanelOpen)}
      onSplitPanelPreferencesChange={({ detail }) => setSplitPanelPosition(detail.position)}
      onSplitPanelResize={({ detail }) => setSplitPanelSize(detail.size)}
    />
  ) : (
    <PromoAccessDenied />
  );
};

export default App;
