import React from 'react';
import { Button, Modal, SpaceBetween, Box } from '@amzn/awsui-components-react/polaris';

const CancelPanelReviewSessionsModal = ({ isOpen, onClose, onCancel }) => {
  return (
    <Modal
      visible={isOpen}
      onDismiss={() => onClose()}
      header="Cancel Session"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onCancel();
              }}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      Are you sure you want to cancel this session? This action cannot be undone.
    </Modal>
  );
};

export default CancelPanelReviewSessionsModal;
