/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useMemo } from 'react';
import AttributeEditor, { type AttributeEditorProps } from '@amzn/awsui-components-react/polaris/attribute-editor';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Input from '@amzn/awsui-components-react/polaris/input';
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import { CategoryResource } from '@/models';

const labels = {
  addButtonText: 'Add new category',
  removeButtonText: 'Remove',
  empty: 'No categories associated to the PromoPath',
};

interface ControlParams {
  value: string | undefined | null;
  index: number;
  onChangeValue: (v: string | number, i: number, p: keyof CategoryResource) => void;
}

const DescriptionControl = React.memo<ControlParams>(({ value, index, onChangeValue }) => (
  <FormField label="Description">
    <Textarea
      ariaLabel="Category description"
      value={value ?? ''}
      placeholder="Enter description"
      rows={4}
      onChange={({ detail }) => onChangeValue(detail.value, index, 'description')}
    />
  </FormField>
));

const NameControl = React.memo<ControlParams>(({ value, index, onChangeValue }) => (
  <FormField label="Name">
    <Input
      ariaLabel="Category name"
      value={value ?? ''}
      placeholder="Enter category name"
      inputMode="text"
      type="text"
      onChange={({ detail }) => onChangeValue(detail.value, index, 'label')}
    />
  </FormField>
));

interface CategoryPanelParams {
  categories: CategoryResource[];
  setCategories: React.Dispatch<React.SetStateAction<CategoryResource[]>>;
}

const CategoriesPanel = ({ categories, setCategories }: CategoryPanelParams) => {
  const changeCategoryPropValue = useCallback(
    (newValue: number | string, itemIndex: number, prop: keyof CategoryResource) => {
      setCategories((prevCategories) => {
        const updatedCategories = [...prevCategories];
        updatedCategories[itemIndex] = {
          ...updatedCategories[itemIndex],
          [prop]: newValue,
        };
        return updatedCategories;
      });
    },
    [setCategories]
  );

  const definition = useMemo(
    () =>
      [
        {
          control: (category, index) => (
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
              <NameControl value={category.label} index={index} onChangeValue={changeCategoryPropValue} />
              <DescriptionControl value={category.description} index={index} onChangeValue={changeCategoryPropValue} />
            </Grid>
          ),
        },
      ] as AttributeEditorProps.FieldDefinition<CategoryResource>[],
    [changeCategoryPropValue]
  );

  const onAddCategory = useCallback(() => {
    setCategories((items) => [...items, { label: '', description: '', id: '', isDefault: false }]);
  }, [setCategories]);

  const onRemoveCategory = useCallback(
    ({ detail: { itemIndex } }) => {
      setCategories((items) => {
        const newItems = items.slice();
        newItems.splice(itemIndex, 1);
        return newItems;
      });
    },
    [setCategories]
  );

  const addlInfoText = useMemo(() => (categories.length === 0 ? 'Add at least one category.' : ''), [categories]);

  return (
    <AttributeEditor
      {...labels}
      additionalInfo={addlInfoText}
      items={categories}
      definition={definition}
      onAddButtonClick={onAddCategory}
      onRemoveButtonClick={onRemoveCategory}
      empty="No categories associated with this promo path."
    />
  );
};

export default CategoriesPanel;
