import { useState, ForwardedRef, forwardRef, memo } from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { deleteFile } from '@/backend/storage';
import { useFileRecord, useFileRecordActions } from '@/api/file-record';
import { useTableContext } from '@/contexts/CrossTableContext';
import PromoSpinner from '../common/PromoSpinner';

interface DeleteFileRecordModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  contextKey: string;
}

const DeleteFileRecordComponent = (
  { contextKey, isVisible, onSetVisibility }: DeleteFileRecordModalParams,
  ref: ForwardedRef<HTMLElement>
) => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { selectedId } = useTableContext(contextKey ?? '');
  const [isDeleting, setIsDeleting] = useState(false);
  const { fileRecord, isFileRecordLoading } = useFileRecord(selectedId);
  const { actions, isPreparing } = useFileRecordActions(fileRecord?.id);

  function resetModal() {
    onSetVisibility(false);
  }

  const deleteFileAndRecord = async () => {
    if (!fileRecord) return;
    try {
      await deleteFile(fileRecord.s3Key, fileRecord.name, false);
      await actions.delete();
    } finally {
      setIsDeleting(false);
      resetModal();
    }
  };

  const onDelete = () => {
    setIsDeleting(true);
    deleteFileAndRecord();
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModal()}
      closeAriaLabel="Close dialog"
      visible={isVisible}
      size="medium"
      header="Delete file"
      footer={
        <>
          <Box float="left">
            <SpaceBetween direction="horizontal" size="xs">
              <Button iconName="download" loading={isPreparing} onClick={() => actions.download()}>
                Download
              </Button>
            </SpaceBetween>
          </Box>
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" disabled={isDeleting || isPreparing} onClick={() => resetModal()}>
                Cancel
              </Button>
              <Button
                variant="primary"
                loading={isDeleting}
                disabled={isDeleting || isPreparing}
                onClick={() => onDelete()}
              >
                {isDeleting ? 'Deleting...' : 'Confirm'}
              </Button>
            </SpaceBetween>
          </Box>
        </>
      }
    >
      {isFileRecordLoading ? (
        <PromoSpinner variant="inline" size="big" />
      ) : (
        <SpaceBetween direction="vertical" size="m">
          <Box variant="span">
            Delete file{` `}
            <Box variant="span" fontWeight="bold">
              {fileRecord?.name}
            </Box>
            {` `}
            permanently? This action cannot be undone.
          </Box>
          <Alert statusIconAriaLabel="Info">
            Proceeding with this action will delete file(s) from S3 and associated references to work summaries.{' '}
          </Alert>
        </SpaceBetween>
      )}
    </Modal>
  );
};

const DeleteFileRecordModal = memo(forwardRef(DeleteFileRecordComponent));

export default DeleteFileRecordModal;
