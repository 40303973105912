import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { Item, PromoTableDefinition, TableHeaderParams, HeaderActionSetParams } from '@/models';
import { PromoButton } from '../Buttons';

const getCounterText = (itemCount: number, counter?: string | undefined) => counter || `(${itemCount || 0})`;

/**
 * A component that renders 3 {@link https://polaris.a2z.com/components/button/?tabId=api Button}s
 * to interact with a selected item in a table.
 * Buttons: `View`, `Edit`, `Delete`
 *
 * Each button is always displayed, but is only enabled if there's a selected item in the table.
 */
const DefaultActionSet = <ItemDef extends Item>({
  selectedItem,
  actionDefs,
  resourceName,
}: HeaderActionSetParams<ItemDef>) => {
  const editAction = actionDefs.find((def) => def.action === 'edit');
  const deleteAction = actionDefs.find((def) => def.action === 'delete');
  return (
    // Dynamically set the button state based on whether an item from the table is selected
    <SpaceBetween direction="horizontal" size="s">
      <Button disabled={!selectedItem} variant="primary">
        View Details
      </Button>
      <PromoButton
        action="edit"
        isDisabled={!selectedItem}
        resourceName={resourceName}
        targetType={editAction?.targetType || 'function'}
        actionParams={editAction ? { ...editAction?.actionParams, itemId: selectedItem?.id } : undefined}
      />
      <PromoButton
        action="delete"
        isDisabled={!selectedItem}
        resourceName={resourceName}
        targetType={deleteAction?.targetType || 'function'}
        actionParams={deleteAction ? { ...deleteAction?.actionParams, itemId: selectedItem?.id } : undefined}
      />
    </SpaceBetween>
  );
};

const getHeaderActions = <ItemDef extends Item>(
  ActionSet: React.FC<HeaderActionSetParams<ItemDef>> | undefined,
  actionParams: HeaderActionSetParams<ItemDef>
) => (ActionSet ? <ActionSet {...actionParams} /> : <DefaultActionSet {...actionParams} />);

/**
 * Table header component for a `WorkSummary` table.
 * Header text consists of the `tableKey` from  and the total item count.
 * When all available summaries are being shown, the header will indicate the total `Approved` summaries.
 *
 * Header includes an `DefaultActionSet`: `View`, `Edit`, `Delete`. Options are
 * selectively enabled based on whether a row is selected.
 *
 * Returns a Polaris Header Component: https://polaris.a2z.com/components/header/?tabId=api
 */
const TableHeader = <TableDef extends PromoTableDefinition, ItemDef extends Item>({
  counter,
  actionSet,
  selectedItem,
  tableDef,
  actionDefs,
  totalFilteredItems,
  variant = 'h2',
}: TableHeaderParams<TableDef, ItemDef>) => {
  return (
    <Header
      counter={getCounterText(totalFilteredItems, counter)}
      actions={getHeaderActions(actionSet, {
        selectedItem,
        actionDefs,
        resourceName: tableDef.resourceName,
      })}
      variant={variant}
    >
      {tableDef.tableKey}
    </Header>
  );
};

export default TableHeader;
