import Modal from '@amzn/awsui-components-react/polaris/modal';
import { Box, SpaceBetween, Button, FormField, Input } from '@amzn/awsui-components-react';
import checkOverrideReason from '../form-validator';

interface WarningModalProps {
  visible: boolean;
  minReviewers: number;
  overrideReason: string;
  setOverrideReason: (overrideReason: string) => void;
  overrideReasonValidationText: string;
  setOverrideReasonValidationText: (validationText: string) => void;
  onDismiss: () => void;
  onProceed: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({
  visible,
  minReviewers,
  overrideReason,
  setOverrideReason,
  overrideReasonValidationText,
  setOverrideReasonValidationText,
  onDismiss,
  onProceed,
}) => (
  <Modal
    onDismiss={onDismiss}
    visible={visible}
    header="Warning: Insufficient Yes/Yes Votes"
    footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={onDismiss}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onProceed}>
            Override approval
          </Button>
        </SpaceBetween>
      </Box>
    }
  >
    <p>
      Current Yes/Yes vote count is below the minimum threshold of {minReviewers}. Please enter an override reason in
      order to complete this session.
    </p>
    <FormField label="Override reason*" errorText={overrideReasonValidationText}/>
    <Input
      ariaRequired
      inputMode="text"
      value={overrideReason}
      onBlur={() => setOverrideReasonValidationText(checkOverrideReason(overrideReason))}
      onChange={({ detail }) => {
        setOverrideReason(detail.value);
      }}
    />
  </Modal>
);

export default WarningModal;
