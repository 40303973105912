import { useState, ForwardedRef, forwardRef, memo, useMemo } from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useTableContext } from '@/contexts';
import { Nullable, OptionalString, PromoUserProfile } from '@/models';
import { CommentStatus, CommentType, WorkSummaryStatus, WorkSummaryStatusReason } from '@/api/API';
import { useWorkSummary, useWorkSummaryActions } from '@/api/work-summary';
import PromoTextEditor from '@/components/common/PromoTextEditor';
import { useCommentActions } from '@/api/comment';
import { checkContent } from '../Comment/comment-validator';
import PromoSpinner from '../common/PromoSpinner';

interface ReturnWorkSummaryModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  contextKey?: string;
  itemId?: OptionalString;
  onSuccess?: () => void;
  manager: Nullable<PromoUserProfile>;
}

const ReturnWorkSummaryComponent = (
  { contextKey, isVisible, onSetVisibility, itemId, onSuccess, manager }: ReturnWorkSummaryModalParams,
  ref: ForwardedRef<HTMLElement>
): JSX.Element => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { selectedId } = useTableContext(contextKey ?? '');
  const workSummaryId = useMemo(() => itemId ?? selectedId, [itemId, selectedId]);
  const { workSummary, isLoading } = useWorkSummary(workSummaryId);

  const { actions: workSummaryActions } = useWorkSummaryActions(workSummary?.id);
  const { actions: commentActions } = useCommentActions();
  const [isSaving, setIsSaving] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [commentError, setCommentError] = useState('');

  function resetModalState() {
    onSetVisibility(false);
  }

  function resetModal() {
    resetModalState();
    setCommentText('');
    setCommentError('');
  }

  async function saveWorkSummaryItem() {
    try {
      if (!workSummary?.id) return;
      if (commentText) {
        const contentError = checkContent(commentText);
        setCommentError(contentError);
        if (contentError) {
          setIsSaving(false);
          return;
        }

        await commentActions.create({
          alias: manager?.alias ?? '',
          content: commentText,
          type: CommentType.CANDIDATE,
          workSummaryId: workSummary?.id,
          status: CommentStatus.ACTIVE,
        });
      }

      await workSummaryActions.update({
        status: WorkSummaryStatus.DRAFT,
        statusReason: WorkSummaryStatusReason.RETURNED_FOR_UPDATES,
        coOwners: [],
      });
      onSuccess?.();
    } finally {
      setIsSaving(false);
      resetModal();
    }
  }

  const onSave = () => {
    setIsSaving(true);
    saveWorkSummaryItem();
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModal()}
      closeAriaLabel="Close dialog"
      visible={isVisible}
      size="medium"
      header="Return work summary"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={isSaving}
              disabled={isSaving || workSummary?.status !== WorkSummaryStatus.MANAGER_REVIEW}
              onClick={() => onSave()}
            >
              {isSaving ? `Saving...` : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {isLoading ? (
        <PromoSpinner variant="inline" size="big" />
      ) : (
        <SpaceBetween direction="vertical" size="m">
          <SpaceBetween direction="horizontal" size="xxs">
            <Box>Return work summary</Box>
            <Box fontWeight="bold">{workSummary?.title}</Box>
            <Box>to candidate</Box>
            <Box fontWeight="bold">{`${workSummary?.alias}@`}</Box>
            <Box>for updates?</Box>
          </SpaceBetween>
          <Alert statusIconAriaLabel="Info" type="info">
            Proceeding will return this work summary to candidate {workSummary?.alias}@.{' '}
          </Alert>
          <ExpandableSection defaultExpanded={false} header="Add comment">
            <PromoTextEditor textValue={commentText} setTextValue={setCommentText} validationError={commentError} />
          </ExpandableSection>
        </SpaceBetween>
      )}
    </Modal>
  );
};

const ReturnWorkSummaryModal = memo(forwardRef(ReturnWorkSummaryComponent));

export default ReturnWorkSummaryModal;
