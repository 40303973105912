import React, { useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';
import { PageDefinition } from '@/common/pages';
import { LayoutToolsContext } from '@/models';

const defaultContext: LayoutToolsContext = {
  isShowTools: false,
  isToolsOpen: false,
  toolsContent: undefined,
  currentPage: undefined,
  setShowTools: noop,
  setToolsOpen: noop,
  setTools: noop,
  setPage: noop,
};

const Context = React.createContext<LayoutToolsContext>(defaultContext);

/**
 * This context allows the various pages/components in the app to
 * control the state of the `Tools` / `HelpPanel` on the right of the page.
 */
export const LayoutToolsContextProvider = ({ children }) => {
  const [isShowTools, setIsShowTools] = useState(false);
  const [toolsContent, setToolsContent] = useState<JSX.Element>();
  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<PageDefinition>();

  function onSetIsShowTools(value: boolean) {
    setIsShowTools(value);
  }

  function onSetToolsContent(value: JSX.Element | undefined) {
    setToolsContent(value);
  }

  function onSetIsToolsOpen(value: boolean) {
    setIsToolsOpen(value);
  }

  function onSetCurrentPage(value: PageDefinition) {
    setCurrentPage(value);
  }

  const context: LayoutToolsContext = useMemo(
    () => ({
      isToolsOpen,
      isShowTools,
      toolsContent,
      currentPage,
      setToolsOpen: onSetIsToolsOpen,
      setTools: onSetToolsContent,
      setShowTools: onSetIsShowTools,
      setPage: onSetCurrentPage,
    }),
    [isToolsOpen, isShowTools, toolsContent, currentPage]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export function useLayoutToolsContext() {
  return useContext(Context);
}
