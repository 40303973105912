import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { usePeerReviewActions } from '@/api/peer-review';
import { useAppContext } from '@/contexts/AppContext';
import { Nullable, OptionalNumber, OptionalString, PeerReviewResource, ViewerType } from '@/models';
import { checkContent } from './form-validator';
import PeerReviewPanel from './PeerReviewPanel';
import WorkSummaryPanel from './WorkSummaryPanel';
import { PeerReviewStatus, PeerReviewType, WorkSummaryStatus } from '@/api/API';
import { useWorkSummaryActions } from '@/api/work-summary';
import useNavigator from '@/common/hooks/use-navigator';
import { PeerReviewDashboardPage } from '@/common/pages';

interface FormParams {
  peerReview?: Nullable<PeerReviewResource>;
  workSummaryId?: OptionalString;
  viewer?: ViewerType;
}

const PeerReviewForm = ({ peerReview, workSummaryId, viewer }: FormParams): JSX.Element => {
  const { currentUser, spoofUser } = useAppContext();
  const [content, setContent] = useState('');
  const [contentError, setContentError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { actions: workSummaryActions } = useWorkSummaryActions(peerReview?.workSummaryId ?? workSummaryId);
  const { actions: peerReviewActions } = usePeerReviewActions(peerReview?.id);
  const { goToPage } = useNavigator();

  function isParamsValid() {
    const newContentError = checkContent(content);
    setContentError(newContentError);
    return !contentError;
  }

  useEffect(() => {
    if (peerReview?.content) {
      setContent((prevText) => (prevText === peerReview.content ? prevText : peerReview.content));
    }
  }, [peerReview?.content]);

  const savePeerReview = async (updateState: 'save' | 'submit') => {
    let reviewStatus: PeerReviewStatus;
    let reviewedContentVersion: OptionalNumber;
    let updateWorkSummary = false;
    const reviewType =
      peerReview?.reviewType ?? viewer === ViewerType.MANAGER ? PeerReviewType.MANAGER : PeerReviewType.CANDIDATE;
    switch (updateState) {
      case 'submit':
        reviewStatus = PeerReviewStatus.COMPLETED;
        reviewedContentVersion = peerReview?.latestVersion;
        updateWorkSummary = true;
        break;
      case 'save':
      default:
        reviewStatus = PeerReviewStatus.PENDING;
    }

    if (!reviewType || !peerReview?.requiredBy) return;

    if (peerReview?.id) {
      await peerReviewActions.update({ content, reviewedContentVersion, reviewStatus });
    } else {
      await peerReviewActions.create({
        reviewStatus,
        content,
        reviewedContentVersion,
        reviewType,
        requiredBy: '',
        reviewerAlias: spoofUser?.alias ?? currentUser?.alias ?? '',
        workSummaryId: peerReview?.workSummaryId ?? workSummaryId,
      });
    }

    if (updateWorkSummary) {
      await workSummaryActions.update({
        status:
          peerReview?.reviewType === PeerReviewType.CANDIDATE
            ? WorkSummaryStatus.DRAFT
            : WorkSummaryStatus.MANAGER_REVIEW,
      });
    }
    setIsSaving(false);
    setIsSubmitting(false);

    if (updateState !== 'save') {
      goToPage(PeerReviewDashboardPage);
    }
  };

  const onSave = (updateState: 'save' | 'submit') => {
    if (!isParamsValid()) {
      return;
    }
    if (updateState === 'save') {
      setIsSaving(true);
    } else if (updateState === 'submit') {
      setIsSubmitting(true);
    }
    savePeerReview(updateState);
  };

  return (
    <Form
      variant="full-page"
      actions={
        <SpaceBetween direction="horizontal" size="l">
          <Button onClick={() => navigate(-1)} variant="link" disabled={isSaving || isSubmitting}>
            Cancel
          </Button>
          <Button
            onClick={() => onSave('save')}
            variant="normal"
            loading={isSaving}
            disabled={isSaving || isSubmitting}
          >
            {isSaving ? 'Saving...' : 'Save draft'}
          </Button>
          <Button
            onClick={() => onSave('submit')}
            variant="primary"
            loading={isSubmitting}
            disabled={isSaving || isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit peer review'}
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <WorkSummaryPanel contentParams={peerReview} />
        <PeerReviewPanel
          peerReview={peerReview}
          content={content}
          onChangeContent={(value) => {
            setContent(value);
            if (contentError) {
              setContentError(!value ? '' : checkContent(content));
            }
          }}
          validationError={contentError}
        />
      </SpaceBetween>
    </Form>
  );
};

export default PeerReviewForm;
