/* eslint-disable no-underscore-dangle */
import { useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getPromoReadinessRecordQuery,
  PromoReadinessRecord as PromoReadinessRecordModel,
  getPromoReadinessRecordQueryVariables,
  UpdatePromoReadinessRecordInput,
  CreatePromoReadinessRecordInput,
  CreatePromoReadinessRecordMutation,
  UpdatePromoReadinessRecordMutation
} from './API';
import { getPromoReadinessRecord as getPromoReadiness} from '../graphql/queries';
import { createPromoReadinessRecord, updatePromoReadinessRecord } from '@/graphql/mutations';
import { PromoReadinessBase, OptionalString } from '@/models';
import { getConcreteModels, useApiQuery, useApiMutation } from '@/backend/api';
import { QueryKeys } from './queryKeys';
import { getDateFromAPIValueUTC } from '@/common/utils';
import { useCognitoAuth } from '@/common/hooks/use-auth';

const ITEM_TYPE = 'promoReadiness';

window.LOG_LEVEL = 'DEBUG';

type Model = 
| PromoReadinessRecordModel
| NonNullable<getPromoReadinessRecordQuery['getPromoReadinessRecord']>

function isValidModel(model: Model | null | undefined): boolean {
    return !!model && !model._deleted;
  }

function getItemFromModel(model: Model): PromoReadinessBase {
  return {
    alias: model.alias,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
    id: model.id,
    certList: model.certList,
    techPillar: model.techPillar,
    tfcMembership: model.tfcMembership,
    rating: model.rating,
    feedbackProviders: model.feedbackProviders,
    otherContributions: model.otherContributions,
    lastModifiedBy: model.lastModifiedBy,
    version: model._version
  };
}


export function usePromoReadinessProfile(alias?: OptionalString, isCurrentUser = false) {
    const { getItem } = useApiQuery();
    const queryClient = useQueryClient();
    const getQueryParams = useCallback(
      (userAlias: OptionalString, includeIdentity: boolean) => ({
        meta: { errorMessage: `Error fetching profile for user ${userAlias}@` },
        queryKey: QueryKeys.profile.alias(userAlias),
        queryFn: async () => {
          if (!userAlias) return null;
          const data = await getItem<getPromoReadinessRecordQueryVariables, getPromoReadinessRecordQuery>({
            query: getPromoReadiness,
            input: { alias: userAlias },
          });
          const models = getConcreteModels(data?.getPromoReadinessRecord, isValidModel);
          return models?.length ? getItemFromModel(models[0]) : null;;
        },
      }),
      [getItem]
    );
    const query = useQuery({
      ...getQueryParams(alias, isCurrentUser),
      enabled: !!alias,
    });
    const getPromoReadinessRecord = useCallback(
      async (userAlias: OptionalString, includeIdentity = false) =>
        queryClient.fetchQuery({ ...getQueryParams(userAlias, includeIdentity) }),
      [queryClient, getQueryParams]
    );

    const onFetchLatest = useCallback(async () => (await query.refetch()).data, [query]);
    return {
      getPromoReadinessRecord,
      promoReadiness: query.data,
      isProfileLoading: query.isPending,
      fetchLatest: onFetchLatest
    };
};

export function usePromoReadinessActions(alias?: OptionalString) {
  const { updateItem, createItem } = useApiMutation();
  const { session } = useCognitoAuth();
  const { getPromoReadinessRecord, isProfileLoading } = usePromoReadinessProfile(alias, session?.user?.alias === alias);

  type UpdateParams = Partial<Omit<UpdatePromoReadinessRecordInput, '_version'>>;

  const createMutation = useMutation({
    mutationFn: async (params: CreatePromoReadinessRecordInput) => {
      const input: CreatePromoReadinessRecordInput = { ...params };
      const data = await createItem<CreatePromoReadinessRecordMutation>(createPromoReadinessRecord, input, ITEM_TYPE);
      return data?.createPromoReadinessRecord ? getItemFromModel(data.createPromoReadinessRecord as PromoReadinessRecordModel) : null;
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateParams) => {
      const promoReadiness = await getPromoReadinessRecord(params.alias ?? alias);
      if (!promoReadiness) return null;
      const input: UpdatePromoReadinessRecordInput = { ...params, alias: promoReadiness.alias };
      const data = await updateItem<UpdatePromoReadinessRecordMutation>(updatePromoReadinessRecord, input, ITEM_TYPE);
      return data?.updatePromoReadinessRecord ? getItemFromModel(data.updatePromoReadinessRecord as PromoReadinessRecordModel) : null;
    },
  });

  const createPromoReadinessEntry = useCallback(
    async (params: CreatePromoReadinessRecordInput) => createMutation.mutateAsync(params),
    [createMutation]
  );

  const updatePromoReadinessEntry = useCallback(
    async (params: UpdateParams) => updateMutation.mutateAsync(params),
    [updateMutation]
  );

  return {
    createPromoReadinessEntry,
    updatePromoReadinessEntry,
    isProfileMutating: createMutation.isPending || updateMutation.isPending || isProfileLoading,
  };
}