/* eslint-disable no-underscore-dangle */
import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  GetBaselineRoleDimensionQueryVariables,
  GetBaselineRoleDimensionQuery,
  ListBaselineRoleDimensionsByLevelQueryVariables
} from './API';
import { 
  getBaselineRoleDimension as getBaselineRoleDimensionQuery,
  listBaselineRoleDimensionsByLevel as listBaselineRoleDimensionsByLevelQuery
} from '../graphql/queries';
import { OptionalString, Nullable, BaselineRoleDimension, ListBaselineRoleDimensionsByLevelQueryResult, BaselineRoleDimensionResource } from '@/models';
import { useApiQuery } from '@/backend/api';
import { QueryKeys } from './queryKeys';
import { getCompactId } from '@/common/utils';

type Model = BaselineRoleDimensionResource | NonNullable<GetBaselineRoleDimensionQuery['getBaselineRoleDimension']>;

function getItemFromModel(model: Partial<GetBaselineRoleDimensionQuery>): BaselineRoleDimensionResource {
  const baselineRoleDimension = model.getBaselineRoleDimension ?? {} as Partial<BaselineRoleDimensionResource>;
  return {
    jobLevel: baselineRoleDimension.jobLevel ?? 0,
    description: baselineRoleDimension.description ?? '',
    title: baselineRoleDimension.title ?? '',
    id: baselineRoleDimension.id ?? '',
  };
}

function listItemFromModel(model: Nullable<ListBaselineRoleDimensionsByLevelQueryResult>): BaselineRoleDimension[] {
  const items = model?.listBaselineRoleDimensionsByLevel?.items ?? [];
  return items.filter((item): item is BaselineRoleDimension => item !== null);
}

export function useBaselineRoleDimension(id: OptionalString, jobLevel: number) {
  const { getItem } = useApiQuery();

  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (baselineRoleDimensionIds: OptionalString) => ({
      meta: { errorMessage: `Error fetching category: ${getCompactId(baselineRoleDimensionIds)}` },
      queryKey: QueryKeys.baselineRoleDimension.id(baselineRoleDimensionIds),
      queryFn: async () => {
        if (!baselineRoleDimensionIds) return null;
        const data = await getItem<GetBaselineRoleDimensionQueryVariables, GetBaselineRoleDimensionQuery>({
          query: getBaselineRoleDimensionQuery,
          input: { id: baselineRoleDimensionIds },
        });
        return data?.getBaselineRoleDimension;
      },
    }),
    [getItem]
  );
  
  const listQueryParams = useCallback(
    (jobLevel: number) => ({
      meta: { errorMessage: `Error fetching baseline role dimensions for role: ${jobLevel}` },
      queryKey: QueryKeys.baselineRoleDimension.jobLevel(jobLevel),
      queryFn: async () => {
        const data = await getItem<ListBaselineRoleDimensionsByLevelQueryVariables, ListBaselineRoleDimensionsByLevelQueryResult>({
          query: listBaselineRoleDimensionsByLevelQuery,
          input: { jobLevel },
        });
        return listItemFromModel(data);
      },
    }),
    [getItem]
  );

  const query = useQuery({
    ...getQueryParams(id),
    enabled: !!id,
  });

  const listQuery = useQuery({
    ...listQueryParams(jobLevel ?? ''),
    enabled: !!jobLevel,
  });
  
  const getBaselineRoleDimension = useCallback(
    async (baselineRoleDimensionIds: OptionalString) => queryClient.fetchQuery({ ...getQueryParams(baselineRoleDimensionIds) }),
    [queryClient, getQueryParams]
  );

  const listBaselineRoleDimensionsByLevel = useCallback(
    async (jobLevel: number) => queryClient.fetchQuery({ ...listQueryParams(jobLevel) }),
    [queryClient, listQueryParams]
  );

  const onFetchLatest = useCallback(async () => (await query.refetch()).data, [query]);
  const onFetchLatestList = useCallback(async () => (await listQuery.refetch()).data, [listQuery]);

  return {
    getBaselineRoleDimension,
    listBaselineRoleDimensionsByLevel,
    baselineRoleDimension: query.data,
    baselineRoleDimensions: listQuery.data,
    isBaselineRoleDimensionLoading: !!id && query.isPending,
    isBaselineRoleDimensionsLoading: !!jobLevel && listQuery.isPending,
    fetchLatest: onFetchLatest,
    fetchLatestList: onFetchLatestList,
  };
}

export const getBaselineRoleDimensionItemFromModel = getItemFromModel;
export const listBaselineRoleDimensionItemsFromModel = listItemFromModel;

