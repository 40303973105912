import { useCallback, useMemo, useRef, useState } from 'react';
import { getPropertyFilters, TABLE_KEY, useTableConfig } from './table-config';
import PromoTable from '@/components/common/table/PromoTable';
import ViewEmployeeAsOption from '@/components/common/person/ViewEmployeeAsOption';
import { getAssignAction, getCompleteAction, getHeaderComponent, getUnassignAction } from './common-components';
import { DirectReportRecord, CandidateStatusItem, ManagerPathItem, Nullable, PromoUserProfile } from '@/models';
import AssignPromoPathModal from './AssignPromoPathModal';
import CompleteDiscardPromoPathModal from './CompleteDiscardPromoPathModal';
import { useCandidatePromoPathList } from '@/api/candidate-promo-path';
import { useTableContext } from '@/contexts';

interface PromoPathProgressTableParams {
  directReports: DirectReportRecord[];
  isParentLoading: boolean;
  manager: Nullable<PromoUserProfile>;
}

const PromoPathProgressTable = ({
  directReports,
  isParentLoading,
  manager,
}: PromoPathProgressTableParams): JSX.Element => {
  const { isCandidatePathsLoading, candidateToPath } = useCandidatePromoPathList(manager?.alias);
  const [isAssignVisible, setIsAssignVisible] = useState(false);
  const [isCompleteDiscardVisible, setIsCompleteDiscardVisible] = useState(false);
  const [completeDiscardMode, setCompleteDiscardMode] = useState<'complete' | 'discard'>('complete');
  const [assignableTemplate, setAssignableTemplate] = useState<ManagerPathItem>();
  const ref = useRef<HTMLElement>(null);
  const config = useTableConfig();

  const onAssign = (template: ManagerPathItem) => {
    setAssignableTemplate(template);
    setIsAssignVisible(true);
  };

  const onComplete = useCallback(() => {
    setCompleteDiscardMode('complete');
    setIsCompleteDiscardVisible(true);
  }, []);

  const onDiscard = useCallback(() => {
    setCompleteDiscardMode('discard');
    setIsCompleteDiscardVisible(true);
  }, []);

  const items: CandidateStatusItem[] = useMemo(
    () =>
      directReports
        .map((directReport) => {
          const candidatePath = candidateToPath.get(directReport.alias);
          return {
            ...directReport,
            ...candidatePath,
            id: directReport.alias,
            name: directReport.name || `${directReport.firstName} ${directReport.lastName}` || '',
            pathName: candidatePath?.name,
            pathId: candidatePath?.id,
          };
        })
        .sort((a, b) => -a.jobLevel + b.jobLevel || (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    [directReports, candidateToPath]
  );

  const { selectedId } = useTableContext(TABLE_KEY);
  const selection = useMemo(() => items.find((item) => item.id === selectedId), [items, selectedId]);

  return (
    <>
      <AssignPromoPathModal
        template={assignableTemplate}
        manager={manager}
        candidateAlias={selection?.alias}
        candidateName={selection?.name}
        currentPathId={selection?.pathId}
        isVisible={isAssignVisible}
        onSetVisibility={setIsAssignVisible}
        ref={ref}
      />
      <CompleteDiscardPromoPathModal
        item={selection}
        mode={completeDiscardMode}
        isVisible={isCompleteDiscardVisible}
        onSetVisibility={setIsCompleteDiscardVisible}
        ref={ref}
      />
      <PromoTable
        allItems={items}
        tableConfig={config}
        headerComponent={getHeaderComponent()}
        customPreferenceComponent={ViewEmployeeAsOption}
        tableVariant="borderless"
        isLoading={isCandidatePathsLoading || isParentLoading}
        actionDefs={[getAssignAction(onAssign), getCompleteAction(onComplete), getUnassignAction(onDiscard)]}
        propertyFilters={getPropertyFilters()}
        disableInitialSort
      />
    </>
  );
};

export default PromoPathProgressTable;
