import { Box, SpaceBetween, Button, Select, Form, StatusIndicator } from '@amzn/awsui-components-react';
import { SESSION_STATES } from '../config';

interface SessionStateProps {
  isCreator: boolean;
  sessionState: SESSION_STATES;
  onStateChange: (newState: SESSION_STATES) => void;
  onSaveStateChange: (newState: SESSION_STATES) => void;
}

const SessionState: React.FC<SessionStateProps> = ({ isCreator, sessionState, onStateChange, onSaveStateChange }) => {
  const getNextState = (currentState: SESSION_STATES): SESSION_STATES => {
    switch (currentState) {
      case SESSION_STATES.SCHEDULED:
        return SESSION_STATES.INITIAL_VOTE;
      case SESSION_STATES.INITIAL_VOTE:
        return SESSION_STATES.FINAL_VOTE;
      case SESSION_STATES.FINAL_VOTE:
        return SESSION_STATES.FINAL_OUTCOME;
      case SESSION_STATES.FINAL_OUTCOME:
        return SESSION_STATES.COMPLETE;
      default:
        return currentState;
    }
  };

  const getPreviousState = (currentState: SESSION_STATES): SESSION_STATES => {
    switch (currentState) {
      case SESSION_STATES.INITIAL_VOTE:
        return SESSION_STATES.SCHEDULED;
      case SESSION_STATES.FINAL_VOTE:
        return SESSION_STATES.INITIAL_VOTE;
      case SESSION_STATES.FINAL_OUTCOME:
        return SESSION_STATES.FINAL_VOTE;
      case SESSION_STATES.COMPLETE:
        return SESSION_STATES.FINAL_OUTCOME;
      default:
        return currentState;
    }
  };
  const getIsDisabled = (state: SESSION_STATES, currentState: SESSION_STATES): boolean => {
    if (state === SESSION_STATES.INITIAL_VOTE) {
      return currentState !== SESSION_STATES.SCHEDULED && currentState !== SESSION_STATES.FINAL_VOTE;
    }

    if (state === SESSION_STATES.FINAL_VOTE) {
      return currentState !== SESSION_STATES.INITIAL_VOTE && currentState !== SESSION_STATES.FINAL_OUTCOME;
    }

    if (state === SESSION_STATES.FINAL_OUTCOME) {
      return currentState !== SESSION_STATES.FINAL_VOTE;
    }

    if (state === SESSION_STATES.COMPLETE) {
      return currentState !== SESSION_STATES.FINAL_OUTCOME;
    }

    return true;
  };

  return (
    <Box>
      {isCreator ? (
        <form onSubmit={(e) => e.preventDefault()}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => onStateChange(getPreviousState(sessionState))}>Previous State</Button>
                <Button onClick={() => onStateChange(getNextState(sessionState))}>Next State</Button>
                <Button variant="primary" onClick={() => onSaveStateChange(sessionState)}>
                  Save
                </Button>
              </SpaceBetween>
            }
          >
            <Select
              selectedOption={{ label: sessionState }}
              onChange={({ detail }) => onStateChange(detail.selectedOption.value as SESSION_STATES)}
              options={Object.values(SESSION_STATES).map((state) => ({
                label: state,
                value: state,
                disabled: getIsDisabled(state, sessionState),
              }))}
            />
          </Form>
        </form>
      ) : (
        <StatusIndicator type="info">{sessionState}</StatusIndicator>
      )}
    </Box>
  );
};

export default SessionState;
