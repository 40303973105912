import Link from '@amzn/awsui-components-react/polaris/link';
import React from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';
import {
  CalibratedReviewerItem,
  TableHeaderParams,
  PromoTableDefinition,
  ActionDefinition,
  HeaderActionSetParams,
  ItemAction,
} from '@/models';
import TableHeader from '@/components/common/table/TableHeader';
import { PHONETOOL_URL, RESOURCES } from '@/common/constants';
import { ListDocumentReviewersPage } from '@/common/pages';
import { PromoButton } from '../common/Buttons';
import { getNameDefinition } from '@/common/labels';

interface CardComponentProps {
  item: CalibratedReviewerItem;
}

const Resource = RESOURCES.CALIBRATED_REVIEWER;
const ResourceName = getNameDefinition(Resource);

const CounterText = (items: readonly CalibratedReviewerItem[]) => {
  const totalItems = items && items.length > 0 ? items.length : 0;
  // Per AWS Style Guide always set to 0 if no items.
  if (totalItems === 0) {
    return '(0)';
  }
  return `(${totalItems})`;
};

const DisplayName = React.memo<CardComponentProps>(({ item }) => (
  <Link href={`${PHONETOOL_URL}${item?.alias}`} target="_blank">
    {item?.alias}
  </Link>
));

export default DisplayName;

export function getEditAction(): ActionDefinition {
  return {
    action: 'edit',
    targetType: 'route',
    actionParams: { page: ListDocumentReviewersPage },
  };
}

/** Params - {@link HeaderActionSetParams} */
export const ActionSet = ({ selectedItem, actionDefs }: HeaderActionSetParams<CalibratedReviewerItem>) => {
  const actionToIsDisabled: Partial<{ [K in ItemAction]: boolean }> = {
    create: false,
    edit: !selectedItem,
    submit: false,
  };
  const actions: ItemAction[] = ['edit'];
  // Action buttons have a specific order of precedence, which the caller doesn't need to know about
  // so deliberately order based on the setup specific to this action set.
  const orderedActionDefs: ActionDefinition[] = [];
  actions.forEach((action) => {
    const actionDef = actionDefs.find((def) => def.action === action);
    if (actionDef) {
      orderedActionDefs.push(actionDef);
    }
  });

  return (
    <SpaceBetween direction="horizontal" size="s">
      {orderedActionDefs.map((def) => (
        <PromoButton
          key={`reviewer.action.${def.action}`}
          action={def.action}
          variant={def.action === 'edit' ? 'primary' : undefined}
          actionParams={def.actionParams}
          isDisabled={actionToIsDisabled[def.action]}
          resourceName={ResourceName}
          targetType={def.targetType}
        />
      ))}
    </SpaceBetween>
  );
};

/**
 * Table header component for a `WorkSummary` table.
 * Header text consists of the `tableKey` from {@link PromoTableDefinition}, and the total item count.
 * When all available summaries are being shown, the header will indicate the total `Approved` summaries.
 *
 * Returns a {@link https://refresh.polaris.a2z.com/components/header/?tabId=playground Polaris Header}
 */
const CalibratedDocumentReviewerHeader = ({
  items,
  ...params
}: TableHeaderParams<PromoTableDefinition, CalibratedReviewerItem>): React.ReactElement => {
  return <TableHeader {...params} counter={CounterText(items || [])} actionSet={ActionSet} />;
};

// eslint-disable-next-line import/prefer-default-export
export const getHeaderComponent = () => CalibratedDocumentReviewerHeader;
