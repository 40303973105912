import Box from '@amzn/awsui-components-react/polaris/box';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { getExternalLinkAriaLabel } from '@/common/labels';
import { useFileRecordActions } from '@/api/file-record';
import useNavigator from '@/common/hooks/use-navigator';
import { OptionalString } from '@/models';

interface DefaultLinkParams {
  href: string;
  text: string;
}

export const ExternalLink = ({ href, text }: DefaultLinkParams): JSX.Element => (
  <Link href={href} ariaLabel={`${text} ${getExternalLinkAriaLabel}`} target="_blank">
    {text}
  </Link>
);

interface InfoLinkParams {
  onShowTools: () => void;
  ariaLabel: string;
}

/**
 * Simple info-tip link to use with `FormField` and `Header` components
 * to trigger tools content panel updates.
 */
export const InfoLink = ({ onShowTools, ariaLabel }: InfoLinkParams): JSX.Element => (
  <Link variant="info" onFollow={onShowTools} ariaLabel={ariaLabel}>
    Info
  </Link>
);

export const RoutableLink = ({ href, text }: DefaultLinkParams): JSX.Element => {
  const { followToRoute } = useNavigator();
  return (
    <Link href={href} onFollow={followToRoute}>
      {text}
    </Link>
  );
};

interface FileDownloadLinkParams {
  fileRecordId: string;
  fileName: string;
  workSummaryFileId?: OptionalString;
}

export const FileDownloadLink = ({
  fileName,
  fileRecordId,
  workSummaryFileId,
}: FileDownloadLinkParams): JSX.Element => {
  const { actions } = useFileRecordActions();

  return (
    <Link
      href="#"
      onFollow={(event) => {
        event.preventDefault();
        actions.download({ workSummaryFileId, id: fileRecordId });
      }}
    >
      <SpaceBetween direction="horizontal" size="xs">
        <Icon name="download" />
        <Box variant="p">{fileName}</Box>
      </SpaceBetween>
    </Link>
  );
};
