import { useState, ForwardedRef, forwardRef, memo } from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useTableContext } from '@/contexts';
import { CommentStatus, CommentType, WorkSummaryStatus, WorkSummaryStatusReason } from '@/api/API';
import { useWorkSummary, useWorkSummaryActions } from '@/api/work-summary';
import PromoTextEditor from '@/components/common/PromoTextEditor';
import { useCommentActions } from '@/api/comment';
import { checkContent } from '../Comment/comment-validator';
import { useCandidatePathActions, useCandidatePath } from '@/api/candidate-promo-path';
import PromoSpinner from '../common/PromoSpinner';
import { Nullable, PromoUserProfile, ViewerType } from '@/models';

interface ApproveWorkSummaryModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  contextKey: string;
  manager: Nullable<PromoUserProfile>;
  onSuccess?: () => void;
}

const ApproveWorkSummaryComponent = (
  { contextKey, isVisible, onSetVisibility, manager, onSuccess }: ApproveWorkSummaryModalParams,
  ref: ForwardedRef<HTMLElement>
): JSX.Element => {
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { selectedId } = useTableContext(contextKey);
  const { workSummary, isLoading } = useWorkSummary(selectedId, ViewerType.MANAGER);
  const { actions: workSummaryActions } = useWorkSummaryActions(workSummary?.id);
  const { candidatePath, isCandidatePathLoading } = useCandidatePath({ id: workSummary?.promoPathId });
  const { actions: candidatePathActions } = useCandidatePathActions(candidatePath?.id);
  const { actions: commentActions } = useCommentActions();

  const [isSaving, setIsSaving] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [commentError, setCommentError] = useState('');

  function resetModalState() {
    onSetVisibility(false);
  }

  function resetModal() {
    resetModalState();
    setCommentText('');
    setCommentError('');
  }

  async function saveWorkSummaryItem() {
    try {
      if (commentText) {
        const contentError = checkContent(commentText);
        setCommentError(contentError);
        if (contentError) {
          setIsSaving(false);
          return;
        }
        await commentActions.create({
          alias: manager?.alias ?? '',
          content: commentText,
          type: CommentType.CANDIDATE,
          workSummaryId: workSummary?.id,
          status: CommentStatus.ACTIVE,
        });
      }
      await workSummaryActions.update({
        status: WorkSummaryStatus.APPROVED,
        statusReason: WorkSummaryStatusReason.MANAGER_FINAL_APPROVAL,
      });

      if (candidatePath) {
        await candidatePathActions.update({
          totalCompleted: (candidatePath?.totalCompleted ?? 0) + 1,
        });
      }
      onSuccess?.();
    } finally {
      setIsSaving(false);
      resetModal();
    }
  }

  const onSave = () => {
    setIsSaving(true);
    saveWorkSummaryItem();
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModal()}
      closeAriaLabel="Close dialog"
      visible={isVisible}
      size="medium"
      header="Approve work summary"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isSaving} onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={isSaving}
              disabled={
                isCandidatePathLoading ||
                isLoading ||
                isSaving ||
                workSummary?.status !== WorkSummaryStatus.MANAGER_REVIEW
              }
              onClick={() => onSave()}
            >
              {isSaving ? `Saving...` : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {isLoading ? (
        <PromoSpinner variant="inline" size="big" />
      ) : (
        <SpaceBetween direction="vertical" size="m">
          <Box variant="span">
            Approve work summary{` `}
            <Box variant="span" fontWeight="bold">
              {workSummary?.title}
            </Box>
            ?
          </Box>
          <Alert statusIconAriaLabel="Info" type="info">
            Proceeding will close the work summary and increment the candidate's promo path progress.{' '}
          </Alert>
          <ExpandableSection defaultExpanded={false} header="Add comment">
            <PromoTextEditor textValue={commentText} setTextValue={setCommentText} validationError={commentError} />
          </ExpandableSection>
        </SpaceBetween>
      )}
    </Modal>
  );
};

const ApproveWorkSummaryModal = memo(forwardRef(ApproveWorkSummaryComponent));

export default ApproveWorkSummaryModal;
