import CollectionPreferences, {
  CollectionPreferencesProps,
} from '@amzn/awsui-components-react/polaris/collection-preferences';
import { Item, ColumnDefinition, ContentPreferencesParams, PromoTableDefinition } from '@/models';

/** Creates the list of Page Size options to show the user. */
const getPageSizeOptions = (resourceLabel: string, sizes: number[]): CollectionPreferencesProps.PageSizeOption[] =>
  sizes.map((size) => ({ value: size, label: `${size} ${resourceLabel}` }));

/** Creates the list of columns the user can toggle on/off in the `Preferences` */
export const getVisibleColumnOptions = <ItemType extends Item>(
  columns: ColumnDefinition<ItemType>[]
): CollectionPreferencesProps.VisibleContentOption[] =>
  columns.map((column) => ({ id: column.id, label: column.header }));

/**
 * Creates a {@link https://polaris.a2z.com/components/collection-preferences/?tabId=api CollectionPreferences}
 * UI component. Can be used with tables, cards, etc. This is meant to simplify building new components needing
 * such functionality in the app.
 */
const ContentPreferences = <TableDef extends PromoTableDefinition, ItemType extends Item>({
  tableConfig,
  onChangePreferences,
  customPreferenceOption,
  userPreferences,
}: ContentPreferencesParams<TableDef, ItemType>) => {
  const { tableDef } = tableConfig;
  const pageSizeOptions = getPageSizeOptions(tableDef.resourceName.shortNamePlural, tableConfig.pageSizes);
  const visibleColumnOptions = getVisibleColumnOptions(tableConfig.displayableColumns);

  return (
    <CollectionPreferences
      title="Preferences"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      // These are the user defined or default preferences. This is how user-defined values will be surfaced to the UI.
      preferences={userPreferences}
      // Function to trigger if the user confirms changes to the preferences. This should update state.
      onConfirm={({ detail }) => onChangePreferences(detail)}
      pageSizePreference={{
        title: 'Select page size',
        options: pageSizeOptions,
      }}
      visibleContentPreference={{
        title: 'Visible columns',
        options: [{ label: '', options: visibleColumnOptions }],
      }}
      customPreference={customPreferenceOption}
    />
  );
};

export default ContentPreferences;
