import { Nullable, PromoUserProfile } from '@/models';
import PromoTable from '@/components/common/table/PromoTable';
import { useTableConfig, getPropertyFilters } from './table-config';
import { getEditAction, getHeaderComponent } from './common-components';
import { useCalibratedDocumentReviewerRecords } from '@/api/document-review';

interface CalibratedDocumentReviewersTableTableParams {
  user: Nullable<PromoUserProfile>;
}

const CalibratedDocumentReviewersTable = ({ user }: CalibratedDocumentReviewersTableTableParams): JSX.Element => {
  const config = useTableConfig();
  const { calibratedDocumentReviewers, isCalibratedDocumentReviewersLoading } = useCalibratedDocumentReviewerRecords();

  const actionDefs = [getEditAction()];

  return (
    <PromoTable
      allItems={calibratedDocumentReviewers}
      tableConfig={config}
      headerComponent={getHeaderComponent()}
      isLoading={isCalibratedDocumentReviewersLoading}
      onEmptyShowCreate={false}
      actionDefs={actionDefs}
      propertyFilters={getPropertyFilters()}
      tableVariant="full-page"
    />
  );
};

export default CalibratedDocumentReviewersTable;
