import React from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { SUPPORT_ITEMS_URLS } from '@/common/constants';
import PromoSpinner from '../components/common/PromoSpinner';

/**
 * Default page to render when `react-router` hits an error navigating to a page
 * or when the error boundary is triggered.
 *
 * Note: This page renders standalone without a parent, so we need to use `AppLayout` component.
 */
export const PromoAppError: React.MemoExoticComponent<() => JSX.Element> = React.memo(() => (
  <AppLayout
    navigationHide
    toolsHide
    content={
      <ContentLayout
        header={
          <Header
            variant="awsui-h1-sticky"
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <Button variant="normal" href={SUPPORT_ITEMS_URLS.contactUs} target="_blank">
                  Contact Us
                </Button>
                <Button variant="primary" href="/">
                  Go Home
                </Button>
              </SpaceBetween>
            }
          >
            <Icon name="bug" size="big" /> Something went wrong <Icon name="bug" size="big" />
          </Header>
        }
      >
        <Box variant="p" textAlign="center">
          Sorry, something went wrong on our end. Please feel free to contact us if the problem persists.
        </Box>
      </ContentLayout>
    }
  />
));

/**
 * Full page spinner to display while any initial auth/login things are happening.
 * This prevents going to a landing page while user preferences load (since they can influence the initial landing page)
 */
export const PromoAppLoading = (): JSX.Element => (
  <AppLayout navigationHide toolsHide content={<PromoSpinner variant="full-page" />} />
);

/**
 * Returned when an access/auth check fails on page navigation.
 * This is intentionally not wrapped in `AppLayout` because this would only ever be returned w/in the app layout
 * when accessing a page.
 */
export const PromoAccessDenied = (): JSX.Element => (
  <ContentLayout
    header={
      <Header variant="awsui-h1-sticky">
        <Icon name="lock-private" size="big" />
        {` Access Denied `}
        <Icon name="lock-private" size="big" />
      </Header>
    }
  >
    <Box textAlign="center">
      <Alert type="error">You are not authorized to access this resource.</Alert>
    </Box>
  </ContentLayout>
);
