import { useCallback } from 'react';
import { NonCancelableCustomEvent } from '@amzn/awsui-components-react/polaris/interfaces';
import Pagination, { PaginationProps } from '@amzn/awsui-components-react/polaris/pagination';
import { PaginationParams, PromoTableDefinition, Item } from '@/models';
import { getPaginationLabels } from '@/common/labels';
import { useTableContext } from '@/contexts';

/**
 * Creates a {@link https://polaris.a2z.com/components/pagination/?tabId=api Pagination}
 * UI component customized by the table's {@link PromoTableDefinition definition} and
 * {@link Item} type.
 *
 * See also: {@link PaginationParams}
 */
const TablePagination = <TableDef extends PromoTableDefinition, ItemDef extends Item>({
  tableDef,
  paginationState,
  actions,
}: PaginationParams<TableDef, ItemDef>) => {
  const { setPageNumber } = useTableContext(tableDef.tableKey);
  /**
   * Function to encapsulate other functions which update table/collection state on page change.
   * Allows updating both the {@link https://polaris.a2z.com/get-started/dev-guides/collection-hooks/#api collection-hooks}
   * and any `context` tracking current page.
   */
  const onPageChange = useCallback(
    (event: NonCancelableCustomEvent<PaginationProps.ChangeDetail>) => {
      setPageNumber(event.detail.currentPageIndex);
      paginationState.onChange(event);
    },
    [paginationState, setPageNumber]
  );

  /** See `onPageChange` */
  const onPageClick = useCallback(
    (requestedPage: number) => {
      setPageNumber(requestedPage);
      actions.setCurrentPage(requestedPage);
    },
    [actions, setPageNumber]
  );

  return (
    <Pagination
      {...paginationState}
      onNextPageClick={({ detail }) => onPageClick(detail.requestedPageIndex)}
      onPreviousPageClick={({ detail }) => onPageClick(detail.requestedPageIndex)}
      ariaLabels={getPaginationLabels(paginationState.pagesCount)}
      onChange={(event) => onPageChange(event)}
    />
  );
};

export default TablePagination;
