import React, { ForwardedRef, forwardRef, useState, memo } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import FileUpload from '@amzn/awsui-components-react/polaris/file-upload';
import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import ProgressBar from '@amzn/awsui-components-react/polaris/progress-bar';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { uploadFile } from '@/backend/storage';
import { useFileRecordActions } from '@/api/file-record';
import { MAX_FILE_SIZE_MB } from '@/common/constants';
import { formatFileSize } from '@/components/common/file-upload-helper';
import { getFormattedDate } from '@/common/utils';

function validateFileSelection(value: File): string | null {
  if (!value) {
    return 'Unable to resolve file object';
  }
  if (value.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
    return `File cannot exceed ${MAX_FILE_SIZE_MB} MB in size`;
  }
  return null;
}

interface AddFileRecordModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
}

const AddFileRecordComponent = (
  { isVisible, onSetVisibility }: AddFileRecordModalParams,
  ref: ForwardedRef<HTMLElement>
) => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const [files, setFiles] = useState<File[]>([]);
  const [fileErrors, setFileErrors] = useState<(null | string)[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [uploadPercent, setUploadPercent] = useState(0);
  const { actions } = useFileRecordActions();

  // Benign reset that just hides the modal and resets errors
  // Use for the dismiss action as it's likely to be triggered accidentally
  // and we don't want to clear the text in that case.
  function resetModalState() {
    setFilesUploaded(0);
    setUploadPercent(0);
    onSetVisibility(false);
  }

  // Reset state and clear files
  function resetModal() {
    resetModalState();
    setFiles([]);
  }

  const uploadAndCreateRecords = async () => {
    if (!files.length || fileErrors.some((error) => !!error)) return;
    let totalUploaded = 0;
    await Promise.allSettled(
      files.map(async (file) => {
        const S3key = await uploadFile(file);
        await actions.create({
          fileName: file.name,
          s3Key: S3key ?? '',
          fileSizeBytes: file.size,
          fileDateModified: file.lastModified,
        });
        totalUploaded += 1;
        setFilesUploaded(totalUploaded);
        setUploadPercent((totalUploaded / files.length) * 100);
      })
    );
    setTimeout(() => {
      setIsUploading(false);
      resetModal();
    }, 2000);
  };

  const onUpload = () => {
    setIsUploading(true);
    uploadAndCreateRecords();
  };

  function onUpdateFiles(newFiles: File[]) {
    setFiles(newFiles);
    setFileErrors(newFiles.map((newFile) => validateFileSelection(newFile)));
  }

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      closeAriaLabel="Close modal"
      visible={isVisible}
      header="Upload files"
      size="medium"
      onDismiss={() => resetModal()}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={isUploading}
              disabled={isUploading || !files.length}
              onClick={() => onUpload()}
            >
              {isUploading ? 'Uploading...' : 'Upload'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {isUploading && (
        <Flashbar
          items={[
            {
              header: uploadPercent < 100 ? 'File upload progress' : 'Upload result',
              content: (
                <ProgressBar
                  label={uploadPercent < 100 ? 'Uploading files..' : `Successfully uploaded ${filesUploaded} files.`}
                  value={uploadPercent}
                  variant="flash"
                  status={uploadPercent < 100 ? 'in-progress' : 'success'}
                />
              ),
              loading: uploadPercent < 100,
              type: uploadPercent < 100 ? 'info' : 'success',
            },
          ]}
        />
      )}
      <FormField label="File(s) to upload">
        <FileUpload
          ariaRequired
          multiple
          showFileLastModified
          showFileSize
          accept="text/plain, .eml, .msg, .pdf, .ppt, .pptx, .xls, .xlsx, .doc, .docx, image/*"
          value={files}
          fileErrors={fileErrors}
          onChange={({ detail }) => onUpdateFiles(detail.value)}
          constraintText=".eml, .msg, .pdf, .ppt(x), .xls(x), .doc(x), plain text, and images"
          tokenLimit={5}
          i18nStrings={{
            dropzoneText: (multiple) => `Drag & drop file${multiple ? 's' : ''} to upload`,
            errorIconAriaLabel: 'Error',
            formatFileSize: (sizeBytes) => formatFileSize(sizeBytes) ?? 'N/A',
            formatFileLastModified: (date) => getFormattedDate(date) ?? 'N/A',
            limitShowFewer: 'Show fewer files',
            limitShowMore: 'Show more files',
            removeFileAriaLabel: (iex) => `Remove file ${iex + 1}`,
            uploadButtonText: (multiple) => `Choose file${multiple ? 's' : ''}`,
          }}
        />
      </FormField>
    </Modal>
  );
};

const AddFileRecordModal = memo(forwardRef(AddFileRecordComponent));

export default AddFileRecordModal;
