import { createUseStyles } from 'react-jss';
import Box from '@amzn/awsui-components-react/polaris/box';
import Container from '@amzn/awsui-components-react/polaris/container';
import Spinner, { SpinnerProps } from '@amzn/awsui-components-react/polaris/spinner';

interface TypedSpinnerParams {
  size?: SpinnerProps.Size;
}

const InlineSpinner = ({ size }: TypedSpinnerParams): JSX.Element => (
  <Box textAlign="center">
    <Spinner size={size} />
  </Box>
);

/**
 * Containerized version of the Polaris `Spinner` component.
 * Spinner is positioned within a `Container` so it can be used on pages and support modular loading.
 */
const ContainerizedSpinner = (): JSX.Element => (
  <Container>
    <InlineSpinner size="large" />
  </Container>
);

/**
 * Full-page version of the Polaris `Spinner` component.
 * This version includes styling overrides to force the spinner to middle of the page
 */
const FullPageSpinner = (): JSX.Element => {
  const styles = createUseStyles({
    spinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      textAlign: 'center',
    },
  })();

  return (
    <Box className={styles.spinner} textAlign="center">
      <Spinner size="large" variant="normal" />
    </Box>
  );
};

interface PromoSpinnerParams {
  variant?: 'full-page' | 'container' | 'inline';
  size?: SpinnerProps.Size;
}

/**
 * A purpose-built version of the polaris `Spinner` component.
 * Spinner can be returned for use on a full page (i.e. while logging in) or w/in
 * a container while data in a module of a page is loading.
 *
 * See also: {@link https://refresh.polaris.a2z.com/components/spinner/?tabId=api Spinner}
 */
const PromoSpinner = ({ variant = 'container', size = 'large' }: PromoSpinnerParams): JSX.Element => {
  switch (variant) {
    case 'full-page':
      return <FullPageSpinner />;
    case 'container':
      return <ContainerizedSpinner />;
    default:
      return <InlineSpinner size={size} />;
  }
};

export default PromoSpinner;
