import PeerReview from '../components/PeerReview';
import { CrossTableContextProvider } from '@/contexts/CrossTableContext';
import { useAppContext } from '@/contexts/AppContext';

const PeerReviewerDashboard = () => {
  const { currentUser, spoofUser } = useAppContext();
  return (
    <CrossTableContextProvider>
      <div id="peerReviewList-fptable">
        <PeerReview user={spoofUser ?? currentUser} />
      </div>
    </CrossTableContextProvider>
  );
};

export default PeerReviewerDashboard;
