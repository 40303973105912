import Button from '@amzn/awsui-components-react/polaris/button';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import type { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { useMemo, useState } from 'react';
import { useFileRecords } from '@/api/file-record';
import { getFormattedDate } from '@/common/utils';
import { FileRecordItem, OptionalString } from '@/models';
import { OptionalLabel } from '@/components/common/PromoFormField';
import { formatFileSize } from '@/components/common/file-upload-helper';
import { checkFileAttachments } from './form-validator';

function getOptionFromRecord(fileRecord: FileRecordItem): SelectProps.Option {
  return {
    value: fileRecord.id,
    label: fileRecord.name,
    description: `Size: ${formatFileSize(fileRecord.size)} | Date modified: ${getFormattedDate(
      fileRecord.fileDateModified
    )}`,
  };
}

interface FileSelectorParams {
  selections: string[];
  candidateAlias: OptionalString;
  onChangeValue: (v: string[]) => void;
}

const FileSelector = ({ selections, candidateAlias, onChangeValue }: FileSelectorParams): React.ReactElement => {
  const [validationText, setValidationText] = useState<string>();
  const { isFileRecordsLoading, fileRecords, refresh } = useFileRecords(candidateAlias);

  const fileRecordOptions = useMemo(
    () =>
      fileRecords
        .map((rec) => getOptionFromRecord(rec))
        .sort((a, b) => (a.label && b.label && a.label > b.label ? 1 : -1)),
    [fileRecords]
  );

  return (
    <FormField
      label={<OptionalLabel baseLabel="Supporting documents" />}
      description="Select one or more of your files to attach."
      secondaryControl={<Button iconName="refresh" onClick={refresh} />}
      errorText={validationText ?? ''}
    >
      <Multiselect
        options={fileRecordOptions}
        filteringType="auto"
        placeholder="Select one or more of your files."
        selectedOptions={fileRecordOptions.filter((opt) => selections.includes(opt.value as string))}
        onChange={({ detail }) => {
          const value = detail.selectedOptions.map((opt) => opt.value as string);
          onChangeValue(value);
          setValidationText(checkFileAttachments(value));
        }}
        loadingText="Loading files"
        empty="No files available"
        statusType={isFileRecordsLoading ? 'loading' : 'finished'}
      />
    </FormField>
  );
};

export default FileSelector;
