/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { AuthAction, PermissionsMatrix } from '@/resources/rbac-constants';
import { useAppContext } from '@/contexts';

export function useAuthorizer() {
  const { currentUser, spoofUser } = useAppContext();

  const hasAccess = useCallback(
    (action?: AuthAction): boolean => {
      if (!currentUser?.isValidUser) {
        return false;
      }
      if (action && PermissionsMatrix.has(action)) {
        const groups = action !== AuthAction.SPOOF_USER_ALIAS && spoofUser ? spoofUser.groups : currentUser?.groups;
        return !!PermissionsMatrix.get(action)?.some((group) => groups?.includes(group));
      }
      return true;
    },
    [currentUser, spoofUser]
  );

  return { hasAccess };
}
