import '../../styles/promo-text-editor.scss';
import React, { useState } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Link from '@amzn/awsui-components-react/polaris/link';
import Toggle from '@amzn/awsui-components-react/polaris/toggle';
import { MAX_CHAR_DYNAMODB_LIMIT, MARKDOWN_SYNTAX_GUIDE } from '@/common/constants';
import MarkdownContent from '@/common/MarkdownContent';

const DEFAULT_ROW_COUNT = 3;

const MarkdownReference = () => (
  <Link variant="secondary" fontSize="body-s" href={MARKDOWN_SYNTAX_GUIDE} target="_blank" external>
    Markdown syntax
  </Link>
);

interface PromoTextEditorParams {
  label?: string;
  textValue: string;
  setTextValue: (v: string) => void;
  validationError: string;
  rendererComponent?: React.FC<Record<string, unknown>>;
  maxCharCount?: number;
  rowCount?: number;
}

const PromoTextEditor = ({
  textValue,
  setTextValue,
  validationError,
  rendererComponent,
  label = '',
  maxCharCount = MAX_CHAR_DYNAMODB_LIMIT,
  rowCount = DEFAULT_ROW_COUNT,
}: PromoTextEditorParams) => {
  const [isShowPreview, setIsShowPreview] = useState(false);
  const RenderedContent = rendererComponent || MarkdownContent;

  return (
    <FormField
      label={label}
      description={
        <Box variant="span" fontSize="body-s" color="text-body-secondary">
          This field supports plain text and <Box variant="span" /> <MarkdownReference /> .
        </Box>
      }
      constraintText={`${maxCharCount - textValue.length} characters remaining`}
      errorText={validationError || ''}
    >
      <SpaceBetween direction="vertical" size="xxs">
        <Box className="promo-text-box" variant="div">
          {isShowPreview ? (
            <Box className="content-preview" variant="div">
              <RenderedContent>{textValue}</RenderedContent>
            </Box>
          ) : (
            <Box className="content-editor-area" variant="div" data-value={textValue}>
              <textarea
                rows={rowCount}
                aria-required
                autoComplete="on"
                className="content-editor"
                value={textValue}
                maxLength={maxCharCount}
                onChange={({ target }) => setTextValue(target.value)}
              />
            </Box>
          )}
        </Box>
        <Toggle checked={isShowPreview} onChange={({ detail }) => setIsShowPreview(detail.checked)}>
          Preview content
        </Toggle>
      </SpaceBetween>
    </FormField>
  );
};

export default PromoTextEditor;
