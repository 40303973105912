import FormField from '@amzn/awsui-components-react/polaris/form-field';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import { EMPLOYEE_VIEW_AS_OPTIONS } from '@/common/constants';

/**
 * custom table preference option to pick how to view the Employee column.
 * This gets injected into the Preferences component of a Table.
 * @param {string} currentOption
 * @param {(option: string)=>void} setViewAsOption
 * @returns {JSX.Element}
 */
const ViewEmployeeAsOption = (currentOption, setViewAsOption) => (
  <FormField label="View Employee as">
    <RadioGroup
      value={currentOption}
      onChange={({ detail }) => setViewAsOption(detail.value)}
      items={EMPLOYEE_VIEW_AS_OPTIONS}
    />
  </FormField>
);

export default ViewEmployeeAsOption;
