import { useCallback } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import { ExternalLink } from '../components/common/links';
import { useLayoutToolsContext } from '@/contexts/LayoutToolsContext';
import { PageDefinition } from './pages';
import toolsPanelContent from './tools-panel-content';
import { ToolsContentSetter } from '@/models';

interface LinkItem {
  text: string;
  href: string;
}

interface ToolsPanelParams {
  /** the title to display for the tool help */
  title: string;
  /** the react content */
  content: React.ReactNode;
  /** list of links to display */
  links: LinkItem[] | undefined;
  children?: JSX.Element;
}

/**
 * Represents the content in the tools drawer for a given page / page-section.
 */
export const ToolsPanel = ({ title, content, links, children }: ToolsPanelParams) => (
  <HelpPanel
    header={
      <Box variant="h2" fontWeight="bold">
        {title}
      </Box>
    }
    footer={
      <div>
        {links && links.length > 0 && (
          <div>
            <Box variant="h3">
              Learn more <Icon name="external" />
            </Box>
            <ul>
              {links.map((link, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>
                  <ExternalLink href={link.href} text={link.text} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    }
  >
    {content}
    {children}
  </HelpPanel>
);

/** Checks if the provided page has tools content. This decides whether the tools panel (top right) is displayed */
export function pageHasToolsContent(page: PageDefinition) {
  return toolsPanelContent[page.id] !== undefined;
}

/**
 * Custom hook that provides a method for a page to use to set/update the
 * tools drawer content.
 */
export function useLayoutTools() {
  const { currentPage, setTools, setToolsOpen } = useLayoutToolsContext();
  const pageContent = currentPage ? toolsPanelContent[currentPage.id] : undefined;
  const setToolsForSection: ToolsContentSetter = useCallback(
    ({ sectionKey, content }) => {
      const sectionContent = sectionKey ? pageContent?.[sectionKey] : pageContent?.default;
      setTools(sectionContent ? <ToolsPanel {...sectionContent}>{content}</ToolsPanel> : undefined);
      setToolsOpen(sectionContent !== undefined);
    },
    [setTools, setToolsOpen, pageContent]
  );
  return { setToolsForSection };
}

