import { useCollection } from '@amzn/awsui-collection-hooks';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { getSelectionLabels } from '@/common/labels';
import { NoItemsTableState, NoMatchTableState } from './table-states';
import TablePagination from './TablePagination';
import { Item, TableParams, PromoTableDefinition } from '@/models';
import ContentPreferences from '../ContentPreferences';
import { BasePropertyFilter } from './table-filters';
import { useColumnWidths } from './table-config-helper';
import { useTableContext } from '@/contexts';

const EditablePromoTable = <TableDef extends PromoTableDefinition, ItemDef extends Item>({
  allItems,
  tableConfig,
  handleSubmit,
  headerComponent,
  customFilterFunction,
  onEmptyShowCreate,
  customPreferenceComponent,
  isLoading,
  actionDefs,
  propertyFilters,
  isMultiSelect = false,
  tableVariant = 'container',
  defaultFilter = { tokens: [], operation: 'and' },
  disableInitialSort = false,
}: TableParams<TableDef, ItemDef>) => {
  // setup variables / unpack parameters
  const { tableDef } = tableConfig;
  const TableHeader = headerComponent;
  const { preferences, setPreferences, setSelectedId } = useTableContext(tableDef.tableKey);
  // the context may not have a value for each preference, so merge with the defaults (favoring the user's)
  const tablePrefs = { ...tableConfig.defaultPreferences, ...preferences };
  // If the user hasn't specified any column display preferences, default to all displayable ones.
  const visibleColumns = tablePrefs.visibleContent || tableConfig.displayableColumns.map((op) => op.id);
  const [columnDefinitions, saveWidths] = useColumnWidths<ItemDef>(
    `React-${tableDef.tableKey.replace(' ', '')}Table-Widths`,
    tableConfig.allColumns
  );
  // See here: https://refresh.polaris.a2z.com/get-started/dev-guides/collection-hooks/
  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
    allItems || [],
    {
      propertyFiltering: {
        empty: (
          <NoItemsTableState
            resourceName={tableDef.resourceName}
            showCreate={onEmptyShowCreate}
            createActionDef={actionDefs.find((def) => def.action === 'create')}
          />
        ),
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        noMatch: <NoMatchTableState onClearFilter={clearFilter} />,
        // If no custom filtering function was passed in set to undefined. This queues the component to
        // use the builtin filtering mechanism.
        filteringFunction: customFilterFunction ? customFilterFunction() : undefined,
        filteringProperties: propertyFilters,
        defaultQuery: defaultFilter,
      },
      pagination: { pageSize: tablePrefs.pageSize },
      // by default, sort by the first column in the table (farthese left).
      sorting: { defaultState: disableInitialSort ? undefined : { sortingColumn: tableConfig.allColumns[0] } },
      selection: { trackBy: tableDef.resource.idKey },
    }
  );

  // Function that's passed to the NoMatchFilterState > 'Clear Filter' button, to allow resetting the page.
  function clearFilter() {
    actions.setPropertyFiltering(defaultFilter);
  }

  /**
   * Function to encapsulate other functions which update table/collection state on selection change.
   * Allows updating both the {@link https://refresh.polaris.a2z.com/get-started/dev-guides/collection-hooks/#api collection-hooks}
   * and any `context` tracking the selection.
   */
  function onSelectionChange(event: { detail: TableProps.SelectionChangeDetail<ItemDef> }) {
    setSelectedId(event.detail.selectedItems[0].id);
    collectionProps?.onSelectionChange?.(event);
  }

  return (
    <Table
      {...collectionProps}
      variant={tableVariant}
      onSelectionChange={(event) => onSelectionChange(event)}
      ariaLabels={getSelectionLabels(tableDef.resource)}
      columnDefinitions={columnDefinitions}
      onColumnWidthsChange={saveWidths}
      items={items}
      resizableColumns
      loading={isLoading}
      loadingText={`Loading ${tableDef.resourceName.shortNamePlural}`}
      selectionType={isMultiSelect ? 'multi' : 'single'}
      submitEdit={handleSubmit}
      stickyHeaderVerticalOffset={0}
      visibleColumns={visibleColumns}
      filter={
        <BasePropertyFilter
          tableDef={tableDef}
          filteredItemsCount={filteredItemsCount}
          filterState={propertyFilterProps}
        />
      }
      header={
        <TableHeader
          items={items}
          selectedItem={collectionProps.selectedItems?.[0]}
          tableDef={tableDef}
          actionDefs={actionDefs}
          totalFilteredItems={filteredItemsCount || 0}
          variant={tableVariant === 'full-page' ? 'awsui-h1-sticky' : 'h2'}
        />
      }
      pagination={<TablePagination tableDef={tableDef} paginationState={paginationProps} actions={actions} />}
      preferences={
        <ContentPreferences
          tableConfig={tableConfig}
          onChangePreferences={setPreferences}
          userPreferences={tablePrefs}
          customPreferenceOption={customPreferenceComponent}
        />
      }
    />
  );
};

export default EditablePromoTable;
