/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Comment as CommentModel,
  ListCommentsByWorkSummaryAndTypeQuery,
  ListCommentsByWorkSummaryAndTypeQueryVariables,
  CreateCommentInput,
  CreateCommentMutation,
  DeleteCommentInput,
  DeleteCommentMutation,
  UpdateCommentInput,
  UpdateCommentMutation,
  CommentType,
  GetCommentQueryVariables,
  GetCommentQuery,
  CommentStatus,
} from './API';
import { listCommentsByWorkSummaryAndType, getComment as getCommentQuery } from '../graphql/queries';
import {
  createComment as createCommentMutation,
  updateComment as updateCommentMutation,
  deleteComment as deleteCommentMutation,
} from '../graphql/mutations';
import { CommentItem, Nullable, OptionalString } from '@/models';
import { getConcreteModels, useApiMutation, useApiQuery } from '@/backend/api';
import { RESOURCES } from '@/common/constants';
import { getReadableFromEnum } from '@/common/labels';
import { getCompactId, getDateFromAPIValueUTC } from '@/common/utils';
import { QueryKeys } from './queryKeys';
import { useNotifications } from '@/contexts';
import { CommentActions, UseCommentActionsResult, UseCommentsResult } from './models';

const ITEM_TYPE = RESOURCES.COMMENT.resourceName;

type Model =
  | CommentModel
  | NonNullable<NonNullable<ListCommentsByWorkSummaryAndTypeQuery['listCommentsByWorkSummary']>['items'][number]>;

function isValidModel(model: Model | null | undefined): boolean {
  return !!model && !model._deleted;
}

function getItemFromModel(model: Model): CommentItem {
  return {
    id: model.id,
    authorAlias: model.author.alias,
    authorName: model.author.name || '',
    content: model.content,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
    workSummaryId: model.workSummaryId,
    version: model._version,
  };
}

export function useComments(workSummaryId: OptionalString, type: CommentType): UseCommentsResult {
  const { getItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error fetching comments for ${getReadableFromEnum(type)}: ${getCompactId(workSummaryId)}` },
    queryKey: QueryKeys.workSummary.id(workSummaryId).comments.type(type),
    queryFn: async () => {
      if (!workSummaryId) return [];
      const data = await getItems<
        ListCommentsByWorkSummaryAndTypeQueryVariables,
        ListCommentsByWorkSummaryAndTypeQuery
      >({
        query: listCommentsByWorkSummaryAndType,
        input: { type, workSummaryId, status: CommentStatus.ACTIVE },
      });
      const models = getConcreteModels<Model>(data?.listCommentsByWorkSummary?.items, isValidModel);
      return models?.map((item) => getItemFromModel(item)) ?? [];
    },
    enabled: !!workSummaryId,
  });

  const comments = useMemo(
    () =>
      query.data?.sort((a, b) =>
        a.createdAt && b.createdAt && a.createdAt.getTime() < b.createdAt.getTime() ? 1 : -1
      ),
    [query.data]
  );

  return {
    comments: comments ?? [],
    isCommentsLoading: !!workSummaryId && query.isPending,
  };
}

export function useCommentActions(commentId?: OptionalString): UseCommentActionsResult {
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  type CreateParams = Parameters<CommentActions['create']>[0];
  type UpdateParams = Parameters<CommentActions['update']>[0];

  const getComment = useCallback(
    async () =>
      queryClient.fetchQuery({
        queryKey: QueryKeys.comment.id(commentId),
        queryFn: async () => {
          if (!commentId) return null;
          const data = await getItem<GetCommentQueryVariables, GetCommentQuery>({
            query: getCommentQuery,
            input: { id: commentId },
          });
          return data?.getComment as Nullable<CommentModel>;
        },
      }),
    [commentId, getItem, queryClient]
  );

  const invalidateQueries = useCallback(
    async (id: OptionalString, workSummaryId: OptionalString) => {
      const invalidations = [
        queryClient.invalidateQueries({ queryKey: QueryKeys.comment.id(id) }),
        queryClient.invalidateQueries({ queryKey: QueryKeys.workSummary.id(workSummaryId).all }),
      ];
      await Promise.allSettled(invalidations);
    },
    [queryClient]
  );

  const createMutation = useMutation({
    mutationFn: async (params: CreateParams) => {
      const input: CreateCommentInput = { ...params, status: params.status ?? CommentStatus.ACTIVE };
      const data = await createItem<CreateCommentMutation>(createCommentMutation, input, ITEM_TYPE);
      return data?.createComment ? getItemFromModel(data.createComment) : null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully created comment: ${getCompactId(result?.id)}` });
      invalidateQueries(result?.id, result?.workSummaryId);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateParams) => {
      const comment = await getComment();
      if (!comment) return null;
      const input: UpdateCommentInput = { ...params, id: comment.id, _version: comment._version ?? 1 };
      const data = await updateItem<UpdateCommentMutation>(updateCommentMutation, input, ITEM_TYPE);
      return data?.updateComment ? getItemFromModel(data.updateComment) : null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully updated comment: ${getCompactId(result?.id)}` });
      invalidateQueries(result?.id, result?.workSummaryId);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async () => {
      const comment = await getComment();
      if (!comment) return null;
      const updateParams: UpdateCommentInput = { id: comment.id, _version: comment._version ?? 1 };
      const updateResult = await updateItem<UpdateCommentMutation>(updateCommentMutation, updateParams);
      const input: DeleteCommentInput = { id: comment.id, _version: updateResult?.updateComment?._version };
      const data = await deleteItem<DeleteCommentMutation>(deleteCommentMutation, input, ITEM_TYPE);
      return data?.deleteComment ?? null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully deleted comment: ${getCompactId(result?.id)}` });
      invalidateQueries(result?.id, result?.workSummaryId);
    },
  });

  const onCreate = useCallback(async (params: CreateParams) => createMutation.mutateAsync(params), [createMutation]);
  const onUpdate = useCallback(async (params: UpdateParams) => updateMutation.mutateAsync(params), [updateMutation]);
  const onDelete = useCallback(async () => deleteMutation.mutateAsync(), [deleteMutation]);

  const actions = useMemo(
    () => ({ create: onCreate, update: onUpdate, delete: onDelete }),
    [onCreate, onUpdate, onDelete]
  );

  return {
    actions,
    isMutating: createMutation.isPending || updateMutation.isPending || deleteMutation.isPending,
  };
}
