import '@amzn/awsui-global-styles/dark-mode-utils.css';
import { createUseStyles } from 'react-jss';
import { ForwardedRef, forwardRef, memo, useEffect, useState } from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SegmentedControl from '@amzn/awsui-components-react/polaris/segmented-control';
import Tiles from '@amzn/awsui-components-react/polaris/tiles';
import { useProfileActions } from '@/api/user-profile';
import { Density, VisualMode } from '@/api/API';
import { comfortableImage, compactImage } from './density-switch-helper';
import { useAppContext } from '@/contexts';

interface UserProfileModalParams {
  alias: string;
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  currentVisualMode: VisualMode;
  currentDensity: Density;
}

const modeDescriptions = {
  DARK: 'Ideal for improving contrast and reducing eye strain, especially in very high/low light.',
  LIGHT: 'The default mode, and is ideal for consuming large amounts of text and well-lit spaces.',
  AUTO: 'Automatically sets light or dark mode based on your browser.',
};

const styleDefs = {
  vizMode: {
    boxSizing: 'border-box',
    display: 'block',
    marginLeft: '8px',
  },
  content: {
    // display: 'block',
    maxWidth: '50%',
  },
};

const imageStyle = {
  maxWidth: '50%',
};

const VisualModeExample = ({ mode }: { mode: VisualMode }) => {
  const styles = createUseStyles(styleDefs)();
  return (
    <Box className={styles.vizMode}>
      <Box className={styles.content} variant="p" fontSize="body-s">
        {modeDescriptions[mode]}
      </Box>
      {mode === VisualMode.AUTO ? (
        <>
          <img
            style={imageStyle}
            className="awsui-util-show-in-dark-mode"
            src="mode-switch-images/darkmode.png"
            alt="Sample of dark mode focussed color scheme."
          />
          <img
            style={imageStyle}
            className="awsui-util-hide-in-dark-mode"
            src="mode-switch-images/lightmode.png"
            alt="Sample of light mode focussed color scheme."
          />
        </>
      ) : (
        <img
          style={imageStyle}
          src={`mode-switch-images/${mode.toLowerCase()}mode.png`}
          alt={`Sample of ${mode.toLowerCase()} mode focussed color scheme.`}
        />
      )}
    </Box>
  );
};

const EditPreferencesComponent = (
  { alias, isVisible, onSetVisibility, currentVisualMode, currentDensity }: UserProfileModalParams,
  ref: ForwardedRef<HTMLElement>
) => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { savePreferences, isProfileMutating } = useProfileActions(alias);
  const [visualMode, setVisualMode] = useState(currentVisualMode);
  const [densityMode, setDensityMode] = useState(currentDensity);
  const { updateDensity, updateVisualMode } = useAppContext();

  useEffect(() => {
    if (currentDensity) {
      setDensityMode(currentDensity);
    }
  }, [currentDensity]);

  useEffect(() => {
    if (currentVisualMode) {
      setVisualMode(currentVisualMode);
    }
  }, [currentVisualMode]);

  // Benign reset that just hides the modal and resets errors
  // Use for the dismiss action as it's likely to be triggered accidentally
  // and we don't want to clear the text in that case.
  function resetModalState() {
    onSetVisibility(false);
  }

  // Reset state and clear files
  function resetModal() {
    resetModalState();
    setVisualMode(currentVisualMode);
  }

  const saveProfilePreferences = async () => {
    if (visualMode !== currentVisualMode || densityMode !== currentDensity) {
      await savePreferences(visualMode, densityMode);
    }
    resetModal();
    updateDensity?.(densityMode);
    updateVisualMode?.(visualMode);
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      closeAriaLabel="Close modal"
      visible={isVisible}
      header="Profile settings"
      size="large"
      onDismiss={() => resetModal()}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isProfileMutating} onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={isProfileMutating}
              disabled={isProfileMutating}
              onClick={() => saveProfilePreferences()}
            >
              {isProfileMutating ? 'Saving...' : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <FormField label="Content density" description="Choose the preferred level of content density for the app.">
          <Tiles
            onChange={({ detail }) => setDensityMode(detail.value.toUpperCase() as Density)}
            value={densityMode.toLowerCase()}
            items={[
              {
                value: 'comfortable',
                label: 'Comfortable',
                description: 'Default spacing that optimizes information consumption.',
                image: comfortableImage,
              },
              {
                value: 'compact',
                label: 'Compact',
                description: 'Reduced spacing that provides more visibility over content.',
                image: compactImage,
              },
            ]}
          />
        </FormField>
        <FormField label="Visual mode" description="Choose the preferred visual mode for the app.">
          <SegmentedControl
            label="Visual mode"
            selectedId={visualMode.toLowerCase()}
            onChange={({ detail }) => setVisualMode(detail.selectedId.toUpperCase() as VisualMode)}
            options={[
              {
                id: 'light',
                text: 'Light',
              },
              {
                id: 'dark',
                text: 'Dark',
              },
              {
                id: 'auto',
                text: 'Browser',
              },
            ]}
          />
          <VisualModeExample mode={visualMode} />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

const EditPreferencesModal = memo(forwardRef(EditPreferencesComponent));

export default EditPreferencesModal;
